import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>3B</span>Quantity</strong> <br /> <strong>1</strong> Complete the dialogue with <i>some or any</i>. <leftarrow name='3.1, 3.2'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page112/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>Aiden</strong>
            <div>
              There aren’t <sup>1</sup> <input />
              good programmes on TV. Have you got <sup>2</sup> <input /> DVDs ?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Emily</strong>
            <div>
              Yes. There are <sup>3</sup> <input />
              DVDs on the shelf.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Aiden</strong>
            <div>
              Let’s see … You’ve got <sup>4</sup> <input />
              great films here! What do you fancy watching?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Emily</strong>
            <div>
              How about <i>Grown Ups 2?</i> My sister says there are <sup>5</sup> <input />
              funny scenes in that.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Aiden</strong>
            <div>
              Really? I heard it wasn’t very good. But there are <sup>6</sup> <input />
              good actors in it: Adam Sandler, Chris Rock …
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Emily</strong>
            <div>
              Let’s try it. Would you like <sup>7</sup> <input />
               popcorn?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Aiden</strong>
            <div>
            Yes, please!
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Emily</strong>
            <div>
              Oh, actually, we haven’t got <sup>8</sup> <input />  popcorn. Sorry! <br />
              But there are <sup>9</sup> <input /> crisps.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Aiden</strong>
            <div>
              Great. Can I have<sup>10</sup> <input />
              water too?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>Emily</strong>
            <div>
              Yes, of course.
            </div>
          </div>
				`,
				answer: ['any', 'any', 'some', 'some', 'some', 'some', 'some', 'any', 'some', 'some'],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: "Complete the recipe with <i>a little</i> or <i>a few</i>. <leftarrow name='3.1, 3.3'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page112/E2/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="width: 600px;  background-color: rgb(241 235 216); box-shadow: 5px 5px 5px rgb(203 202 202); padding: 20px; font-size: 22px">
            <span>
              Take your ready-made pizza base and add <sup>1</sup> <input /> 
              cheese. Slice <sup>2</sup> <input />
              mushrooms and <sup>3</sup> <input />
              ham and sprinkle on top. Cut <sup>4</sup> <input />
              small tomatoes in half and put them between the mushrooms. Finally, add <sup>5</sup> <input /> 
              more cheese and <sup>6</sup> <input />
              olives and cook the pizza in a hot oven. Ten minutes later, enjoy your pizza with <sup>7</sup> <input />
              salad on the side. Why not invite <sup>8</sup> <input />
              friends to join you?
            </span>
          </div>
				`,
				answer: [' a little', 'a few', 'a little', 'a few', 'a little', 'a few', ' a little', 'a few'],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: "Replace a lot of with much or many. <leftarrow name='3.1, 3.4'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page112/E3/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="display: flex; column-gap: 20px">
          <strong>1</strong>
          <span>Do you get a lot of tourists here in the summer?</span>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>2</strong>
          <span>I don’t spend a lot of time in my bedroom.</span>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>3</strong>
          <span>There aren’t a lot of people in our village.</span>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>4</strong>
          <span>Have you got a lot of information about university courses?</span>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>5</strong>
          <span>They never do a lot of homework.</span>
        </div>

        <div style="margin-top:20px">
        <b>ANSWER:</b>
          <div style="display:flex;">
            <div><b>1</b>#</div>
            <div><b>2</b>#</div>
            <div><b>3</b>#</div>
            <div><b>4</b>#</div>
            <div><b>5</b>#</div>
          </div>
        </div>
				`,
				answer: ['many', 'much', 'many', 'much', 'much'],
			},
		],
	},
	4: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					"Complete the email with the words and phrases below. Use each word or phrase once only <leftarrow name='3.1, 3.2, 3.3, 3.4, 3.5'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 130,
		exerciseKey: 'img/FriendsPlus/Page112/E4/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px; flex-wrap: wrap; color: rgb(246 140 31)">
            <span>a few</span>
            <span>a little</span>
            <span>any</span>
            <span>How many</span>
            <span>Home much</span>
            <span>many</span>
            <span>much</span>
          </div>
          <div style="position: relative; margin-top: 20px">
            <img src="img/FriendsPlus/Page112/E4/1.png" style="width: 600px"/>
            <div style="position: absolute; top: 40px; left: 20px; width: 550px;">
              <div style="padding: 10px 20px; font-size: 21px; color: rgb(0 68 98);">
                <div>Hi Hailey</div>
                <div>
                  How are you? I hope you enjoyed your trip to London. <input />
                  money did you spend? Did you do <input />
                  sightseeing? I’m inviting <input />
                  friends round for a film night next week. There won’t be <input />
                  people – just four or five. Can you come? <input />
                  DVDs can you bring? We can spend  <input />
                  time choosing the ones we want to watch. Don’t bring <input />
                  food or drink, though. My 
                  mum is making dinner for us! See you soon! 
                </div>
                <div>Isabella</div>
              </div>
            </div>
          </div>
				`,
				answer: ['How much', 'much', 'a few', 'many', 'How many', ' a little', 'any'],
			},
		],
	},
	5: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E5',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page112/E5/Key/answerKey.png',

		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>3D</span><i>must, mustn’t</i> and <i>needn’t / don’t</i> have to</strong> <br /> <strong>5</strong> Circle the correct answers. <leftarrow name='3.2, 3.3'></leftarrow>",
				color: 'black',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
				},
				limitSelect: 1,
				listWords: [
					"must / mustn't",
					"must / mustn't",
					"must / mustn't",
					"must / mustn't",
					"must / mustn't",
					"must / mustn't",
				],
				answers: ['0-0', '1-0', '2-4', '3-4', '4-0', '5-4'],
				initialValue: [],
			},
			Layout: /*html */ `
       <div style="display: flex; column-gap: 20px">
        <strong>1</strong>
        <div>
          You <strong><input id="0" type="Circle" /></strong>
          finish your breakfast or you’ll be hungry later.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>2</strong>
        <div>
          I <strong><input id="1" type="Circle" /></strong>
          leave before 8.30 or I’ll be late for school.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>3</strong>
        <div>
          You <strong><input id="2" type="Circle" /></strong>
          eat that bread. It’s a week old!
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>4</strong>
        <div>
          In football, you <strong><input id="3" type="Circle" /></strong>
          touch the ball with your hand.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>5</strong>
        <div>
          You <strong><input id="4" type="Circle" /></strong>
          visit Paris some time. It’s a wonderful city.
        </div>
       </div>
       <div style="display: flex; column-gap: 20px">
        <strong>6</strong>
        <div>
          You <strong><input id="5" type="Circle" /></strong>
          go near the edge of the cliff. It’s very dangerous.
        </div>
       </div>
      `,
		},
	},
	6: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E6',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '6',
				title: "Complete the sentences with must, mustn’t or needn’t. <leftarrow name='3.2, 3.3, 3.4'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page112/E6/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              You <input />
              take off your shoes if they are clean.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              Students <input />
              turn off their mobiles during lessons or the teacher will take them away.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              You <input />
              go to the check-in desk if you have checked in online.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              In most Arab countries, you <input />
              eat with your left hand. You should use your right hand.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              When you’re driving, you <input />
              stop if someone steps onto the pedestrian crossing.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              We <input />
              waste any more time.
            </div>
          </div>
				`,
				answer: [`needn't`, 'must', `needn't`, `mustn't`, 'must', `mustn't`],
			},
		],
	},
	7: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E7',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '7',
				title: "Complete the sentences with must or have to and the verbs below. <leftarrow name='3.5'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 150,
		exerciseKey: 'img/FriendsPlus/Page112/E7/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
           <div style="display: flex; column-gap: 20px; flex-wrap: wrap; color: rgb(246 140 31)">
            <span>drive</span>
            <span>eat</span>
            <span>get up</span>
            <span>phone</span>
            <span>stop</span>
            <span>take</span>
            <span>wear</span>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>1</strong>
            <div>
              At school, we <input />
              a white shirt and a blue sweater.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              We <input />
              at the new Chinese restaurant in town. I’ve heard it’s really good.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              In Britain, we  <input />
              on the left.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              Sue really <input />
              eating so many sweets – it’s bad for her teeth!
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              You <input />
              your grandparents more often. You know how much they enjoy talking to you.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              We <input />
              exams at the end of our final year at school.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              Jason <input />
              early because he’s got a doctor’s appointment at eight in the morning.
            </div>
          </div>
				`,
				answer: ['have to wear', 'must eat', 'have to drive', 'must stop', 'must phone', 'have to take', 'must get up'],
			},
		],
	},
	8: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P112-E8',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '8',
				title: 'Complete the sentences with a modal verb followed by a passive infinitive. Use the words in brackets.',
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 200,
		exerciseKey: 'img/FriendsPlus/Page112/E8/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              During lessons, your mobile phone <input />
              in your bag. (must / leave)
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              Meat <input />
              right through. (need / cook)
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              School uniforms <input />
              on the school trip. (not need / wear)
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              Books <input />
              out of the library. (must not / take)
            </div>
          </div>

				`,
				answer: [' must be left', ' need be cooked', `needn't be worn`, `mustn't be taken`],
			},
		],
	},
}

export default json
