import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Money',
    id: 'FG10-M-P79-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(45,206,137);">SPEAKING</span> 
          Read the task. In pairs, think of three possible things you could spend the money on.
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page79/E1/E1.png' }]],
  },
  2: {
    unit: 'Money',
    id: 'FG10-M-P79-E2',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    hideBtnFooter: true,
    textareaStyle: { width: 400 },
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the essay. Does it mention any of your ideas
          from exercise 1? Which of the ideas in the essay do
          you think is best? Why?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; gap: 20px;">
            <div style="flex: 3"><img src="/img/FriendsPlus/Page79/E2/E2.png" style="max-width: 100%;" /></div>
            <div style="flex: 2"><textarea id="0" rows="15"></textarea></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Money',
    id: 'FG10-M-P79-E3',
    exerciseKey: '/img/FriendsPlus/Page79/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 60,
    maxLength: 4,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
          Answer the questions about the essay.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; gap: 20px; align-items: start;">
            <div style="flex: 1;"><img src="/img/FriendsPlus/Page79/E2/E2.png" style="max-width: 100%;" /></div>
            
            <div style="flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(0,0,0,0.2);">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div>How many paragraphs are there? #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div>In which paragraph does the writer mention all three ways of spending the money? #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div>In which paragraph does the writer explain who would get half the money? #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div>In which two paragraphs does the writer explain who would get the rest of the money, and why? #, #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">5</div>
                <div>In which paragraph does the writer paraphrase the main point of the essay? #</div>
              </div>	
            </div>
          </div>
        `,
        answer: ['five', '5', '2', '3', '4', '1'],
      },
    ],
  },
  4: {
    unit: 'Money',
    id: 'FG10-M-P79-E4',
    exerciseKey: '/img/FriendsPlus/Page79/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 450,
    stylesTextInput: {
      fontSize: 21,
    },
    textareaStyle: { width: 450, fontSize: '21px' },
    titleQuestion: [
      {
        num: '4',
        title: `
          Read the <span style="color: rgb(34, 132, 198);">Writing Strategy</span>. 
          How well does the writer in exercise 2 follow this advice? Can you find …
        `,
        color: '#860052',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
          <div style="max-width: 1000px; font-size: 21px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(34, 132, 198); background-color: rgb(232, 239, 248); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing strategy</span>
              <div>
                In your writing, avoid starting too many sentences which repeat the same words.<br /> 
                Remember that you can:
                <ul>
                  <li>
                    use a variety of expressions for introducing opinions 
                    (<i>It seems to me … , In my opinion …</i> , etc.) and for making 
                    additional points (<i>Moreover … , Furthermore …</i> , etc.).
                  </li>
                  <li>start conditional sentences with the <i>if</i> clause or the main clause.</li>
                  <li>use concession clauses (<i>although … / even though …</i>) at the beginning or end of a sentence.</li>
                </ul>
              </div>
            </div>

          <div style="margin-top: 20px; font-size: 21px; display: flex; gap: 50px; align-items: start;">
            <div style="flex: 1;"><img src="/img/FriendsPlus/Page79/E2/E2.png" style="max-width: 100%;" /></div>

            <div style="flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(34, 132, 198); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div>an expression for introducing an opinion?<br />#</div>
              </div>
              <div style="margin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div>an expression for making an additional point?<br />#</div>
              </div>	
              <div style="margin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div>a conditional sentence beginning with the <i>if</i> clause?<br /><textarea id="2" rows="3"></textarea></div>
              </div>	
              <div style="margin-top: -17px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div>a conditional sentence beginning with the main clause?<br /><textarea id="3" rows="2"></div>
              </div>	
              <div style="margin-top: -17px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">5</div>
                <div>a concession clause?<br /><textarea id="4" rows="2"></div>
              </div>	
            </div>
        `,
        answer: [
          'As I see it',
          'Furthermore',
          'If I had €1 million, it would be easy to find three good ways to use the money and help people who deserve it.',
          'The teachers would find it far easier to teach sports and PE if they had some equipment.',
          "He borrows mine all the time, even though he's got one, because mine is better.",
        ],
      },
    ],
  },
  5: {
    unit: 'Money',
    id: 'FG10-M-P79-E5',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 460,
    maxLength: 400,
    titleQuestion: [
      {
        num: '5',
        title: `
            Imagine you had €1 million to spend but could not
            spend any of it on yourself. Think of three ways
            to spend it. Make notes in the table below. Then
            compare ideas in pairs.
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
            <table style="border-collapse: collapse; width: 800px;">
              <thead>
                <tr style="background-color: rgb(39,131,197); color: white;">
                  <th style="border: 1px solid;padding: 8px">Choices (1–3)</th>
                  <th style="border: 1px solid ; padding: 8px">Effect the money
                    would have </th>
                  <th style="border: 1px solid ; padding: 8px">Reason(s) for
                    choice</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">1 (50%)</td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                      <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                    </div>
                  </td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                    <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">2 (25%)</td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                      <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                    </div>
                  </td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                    <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">3 (25%)</td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                      <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                    </div>
                  </td>
                  <td style="border: 1px solid rgb(39,131,197); padding: 8px">
                    <input type="text" style="border: none; border-bottom: 1px solid; width: 100%; outline: none" />
                  </td>
                </tr>
              </tbody>
            </table>
               
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Money',
    id: 'FG10-M-P79-E6',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 460,
    maxLength: 400,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '6',
        title: `
            Write an essay (120–150 words) using your plan from
            exercise 5. Include an introduction and a conclusion
            like the model in exercise 2.
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
             <div>
        <ul>
          <li>Say what you would spend half the money on.</li>
          <li>Choose two more ways to spend the rest.</li>
          <li>Explain what effect the money would have.</li>
          <li>Give reasons for your choices.</li>
        </ul>
      </div>
      <div  style="margin-top: 20px;">
        <textarea rows="12"></textarea>
      </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
