import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P29-E4',
    audio: '',
    video: '',
    component: T6,
    maxLength: 1,

    stylesTextInput: {
      background: 'white',
      fontSize: 18,
      height: 5,
      width: 40,
      // borderBottom: 'dash',
      border: '1px solid ',
    },
    isHiddenCheck: true,
    // inputSize: 60,
    textareaStyle: { width: 700 },
    inputSize: 50,
    // isHiddenCheck: true,
    // maxLength: 1,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page29/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          'Match sentences A–F with gaps 1– 5 in the text. There is one extra sentence. Do any of the sentences match your ideas from exercise 3?',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            
          <div style=' position: relative; '>
                <img src='img/FriendsPlus/Page29/E4/1.jpg'>
                <div style=" position: absolute; top: 498px; left: 297px;">#</div>
                <div style=" position: absolute; top: 622px; left: 288px;">#</div>
                <div style=" position: absolute; top: 471px; left: 428px;">#</div>
                <div style=" position: absolute; top: 405px; left: 475px;">#</div>
                <div style=" position: absolute; top: 346px; left: 634px;">#</div>
            </div>       
          </div>       
          `,
        answer: ['D', 'A', 'C', 'E', 'F'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P29-E5',
    audio: '',
    video: '',
    component: T6,

    maxLength: 1,
    borderBottom: 'dash',
    // inputSize: 60,
    textareaStyle: { width: 700 },
    inputSize: 50,
    // isHiddenCheck: true,
    // maxLength: 1,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page29/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: 'Read the text again. Are the sentences true or false? Write T or F.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           

        <div style='line-heigh:45px'>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>
                Dan was the first person to see that there was a
                problem with the boat.#
            </div>       
          </div>       
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>
                The boat sank before Dan and Kate could jump into
              the water.#
            </div>       
          </div>       
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>
                A helicopter saw Dan and Kate in the sea, but it
              couldn’t rescue them.#
            </div>       
          </div>       
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:20px'>
                When Dan and Kate reached land, they couldn’t get
              out of the water at first.#
            </div>       
          </div>       
          <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:20px'>
                There was nobody on the beach when Dan and Kate
              got out of the sea.#
            </div>       
          </div>  
        </div>       
          <img src='img/FriendsPlus/Page28/E2/1.jpg'>     
          `,
        answer: ['F', 'F', 'F', 'T', 'T'],
      },
    ],
  },

  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P29-E6',
    audio: '',
    video: '',
    component: T6,
    maxLength: 6,
    stylesTextInput: {
      fontSize: '28px',
      borderBottom: 'none',
    },
    inputHeight: '40px',
    textareaStyle: { width: 700 },
    inputSize: 150,
    // isHiddenCheck: true,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page29/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title:
          'Read the <span style="color:rgb(62, 57, 138)">Learn this</span>! box and complete it with the adjectives below.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           <div style='margin-left: 100px;'>
           <b style="color:rgb(62, 57, 138)">small &emsp; good &emsp; hungry &emsp; dirty</b>
           </div>

         
         <div style=' position: relative; '>
            <img src='img/FriendsPlus/Page29/E6/1.jpg' style='width: 25cm' />  
            <div style=" position: absolute; top: 183px; left: 278px;">#</div>
            <div style=" position: absolute; top: 109px; left:634px;">#</div>
            <div style=" position: absolute; top: 145px; left:674px;">#</div>
            <div style=" position: absolute; top: 182px; left:616px;">#</div>
					</div>     
            
          `,
        answer: ['good', 'dirty', 'hungry', 'small'],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P29-E7',
    audio: '',
    video: '',
    component: T6,
    maxLength: 10,
    stylesTextInput: { borderBottom: 'dash' },
    inputHeight: '40px',
    textareaStyle: { width: 700 },
    inputSize: 150,
    // isHiddenCheck: true,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page29/E7/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: 'Match the extreme adjectives highlighted in the text <br/>with the simpler adjectives below.',
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           

         
        <span style="color:rgb(62, 57, 138)">Extreme adjectives</span> 
          <div style='display:flex;line-height:50px'>
            <div>
              <b>1</b>&emsp;very bad#<br>
              <b>2</b>&emsp;very good#<br>
              <b>3</b>&emsp;very big#<br>
            </div>
            <div style='margin-left:100px'>
              <b>4</b>&emsp;very sad #<br>
              <b>5</b>&emsp;very frightening #<br>
              <b>6</b>&emsp;very tired #<br>
            </div>
          </div>
          `,
        answer: ['awful', 'brilliant', 'huge', 'tragic', 'terrifying', 'exhausted'],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P28-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '8',
        title:
          '<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Act out an interview with Dan or Kate.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page29/E8/1.jpg' }]],
  },
};

export default json;
