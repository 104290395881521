import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P48-E1',
    exerciseKey: '/img/FriendsPlus/Page48/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 850,
    maxLength: 190,
    // textAlign: 'center',
    titleQuestion: [
      {
        num: '1',
        title: `
          Read part of the text. What is a shooting star?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
              <div  style="
                  border: 1px solid rgb(114, 112, 113);
                  border-radius: 10px;
                  background-color: rgb(227, 243, 243);
                  box-shadow: 5px, 10px;
                  padding:15px;">
              
                <span style="margin-left: 10px;margin-top: 10px"><strong>Part 1</strong></span>
                <p style="margin-left: 10px; margin-top: 10px">
                  On a clear night, you can often see meteors – space rocks– as they fall towards the Earth. Each rock is
                  smaller than an apple, but they look as bright as stars in the night sky, so people call them ‘shooting
                  stars’. Larger meteors are much rarer, but their effects are far more spectacular. In 2013, a meteor
                  exploded in the sky above the city of Chelyabinsk in Russia. The explosion was more powerful than the 1945
                  Hiroshima atomic bomb, but its effect was not as devastating as the bomb because the meteor exploded much
                  further from the ground. Scientists compared the Chelyabinsk meteor to an earlier – and far bigger –
                  explosion: the Tunguska Event.
                </p>
              </div>

              <div style="margin-top:20px">
                <span><strong>Answers:</strong></span> 
                #
              </div>
        </div>
      `,
        answer: ['A shooting star is a meteor (a space rock) that is falling towards the Earth.'],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P48-E2',
    exerciseKey: '/img/FriendsPlus/Page48/E2/Key/anwerKey.png',
    audio: '',
    video: '',
    component: T6v2,
    isHiddenCheck: true,
    inputSize: 200,
    // maxLength: 60,
    titleQuestion: [
      {
        num: '2',
        title: `
            Complete the table with comparative adjectives from part 1 of the text.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <table style="border-collapse: collapse; width:700px">
        <thead>
          <tr style="background-color: rgb(245,143,32); color: white;">
            <th style="border: 1px solid;padding: 8px">Short adjective</th>
            <th style="border: 1px solid ; padding: 8px">Comparative </th>
            <th style="border: 1px solid ; padding: 8px">Rule</th>
          </tr>
        </thead>
      <tbody>
        <tr>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">small</td>

          <td style="border: 1px solid rgb(245,143,32); padding: 8px">
            <sup>1</sup>
            <div style="margin-top: -25px">
              <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
            </div>
          </td>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">+ -er</td>
        </tr> 
        <tr>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">large</td>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">
              <sup>2</sup>
              <div style="margin-top: -25px">
                <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
              </div>
            </td>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">+ -r</td>
        </tr>
        <tr>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">early</td>

          <td style="border: 1px solid rgb(245,143,32); padding: 8px">
            <sup>3</sup>
            <div style="margin-top: -25px">
              <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
            </div>
          </td>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">-y → -ier</td>
        </tr>
        <tr>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">big</td>

          <td style="border: 1px solid rgb(245,143,32); padding: 8px">
            <sup>4</sup>
            <div style="margin-top: -25px">
              <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
            </div>
          </td>
          <td style="border: 1px solid rgb(245,143,32); padding: 8px">double consonant + –er</td>
        </tr>
         <tr style="background-color: rgb(245,143,32); color: white;">
            <th style="border: 1px solid;padding: 8px">Short adjective</th>
            <th style="border: 1px solid ; padding: 8px">Comparative </th>
            <th style="border: 1px solid ; padding: 8px">Rule</th>
          </tr>
          <tr>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">powerful</td>

            <td style="border: 1px solid rgb(245,143,32); padding: 8px">
              <sup>5</sup>
              <div style="margin-top: -25px">
                <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
              </div>
            </td>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">more + adjective</td>
          </tr> 

          <tr style="background-color: rgb(245,143,32); color: white;">
            <th style="border: 1px solid;padding: 8px">Irregular adjective</th>
            <th style="border: 1px solid ; padding: 8px">Comparative</th>
            <th style="border: 1px solid ; padding: 8px"></th>
          </tr>
          <tr>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">far</td>

            <td style="border: 1px solid rgb(245,143,32); padding: 8px">
              <sup>6</sup>
              <div style="margin-top: -25px">
                <input type="text" style="border: none; border-bottom: 1px solid; width: 100px; outline: none" />
              </div>
            </td>
            <td style="border-right: 1px solid rgb(245,143,32); padding: 8px"></td>
          </tr>  
          <tr>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">good</td>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">better</td>
            
            <td style="border-right: 1px solid rgb(245,143,32);padding: 8px">(no rules)</td>
          </tr> 
          <tr>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px">bad</td>
            <td style="border: 1px solid rgb(245,143,32); padding: 8px"> worse</td>
            <td style="border-bottom: 1px solid rgb(245,143,32);border-right: 1px solid rgb(245,143,32);  padding: 8px"></td>
          </tr> 
      </tbody>
      
    </table>
      `,
        answer: ['smaller', 'larger', 'earlier', 'bigger', 'more powerful', 'further / farther | farther / further'],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P48-E3',
    exerciseKey: '/img/FriendsPlus/Page48/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: {
      textAlign: 'center',
    },
    inputSize: 40,
    maxLength: 1,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '3',
        title: `
            Read the <span style="color: rgb(249, 175, 110)">Learn this!</span> box. Then find examples of
            each rule (a–d) in part1 of the text.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         
          <div>
            <img src="img/FriendsPlus/Page48/6-E3.jpg" alt="" style="width: 700px;" />
          </div>
              <div  style="width: 1000px; border: 1px solid rgb(114, 112, 113);border-radius: 10px;background-color: rgb(227, 243, 243);box-shadow: 5px, 10px;padding:15px;">
                <span style="margin-left: 10px;margin-top: 10px"><strong>Part 1</strong></span>
                <p style="margin-left: 10px; margin-top: 10px">
                  On a clear night, you can often see meteors – space rocks– as they fall towards the Earth. <b><sup>(1)</sup></b> Each rock is
                  smaller than an apple, but <b><sup>(2)</sup></b> they look as bright as stars in the night sky, so people call them ‘shooting
                  stars’. <b><sup>(3)</sup></b> Larger meteors are much rarer, but <b><sup>(4)</sup></b> their effects are far more spectacular. In 2013, a meteor
                  exploded in the sky above the city of Chelyabinsk in Russia. <b><sup>(5)</sup></b> The explosion was more powerful than the 1945
                  Hiroshima atomic bomb, but <b><sup>(6)</sup></b> its effect was not as devastating as the bomb because <b><sup>(7)</sup></b> the meteor exploded much
                  further from the ground. Scientists compared the Chelyabinsk meteor to an earlier – and <b><sup>(8)</sup></b> far bigger –
                  explosion: the Tunguska Event.
                </p>
              </div>

          <div style='line-height:50px'>
            <p style="font-weight: bold">Answers(1/2/.../8):</p>
            <div style="display: flex;"><span style="margin-right: 10px; margin-top: -5px; font-weight: bold">rule a:</span>#,#.</div>
            <div style="display: flex;"><span style="margin-right: 10px;margin-top: -5px; font-weight: bold">rule b:</span>#.</div>
            <div style="display: flex;"><span style="margin-right: 10px;margin-top: -5px; font-weight: bold">rule c:</span>#.</div>
            <div style="display: flex;"><span style="margin-right: 10px;margin-top: -5px; font-weight: bold">rule d:</span>#,#,#,#.</div>
          </div>
          
      `,
        answer: [`1/5`, `1/5`, `6`, `2`, `3/4/7/8`, `3/4/7/8`, `3/4/7/8`, `3/4/7/8`],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P48-E4',
    exerciseKey: '',
    audio: 'Audios/Friends Global Grade 10 SB_CD2_03.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: `
          <audioimage name="2.03"></audioimage>
           Listen to the examples. What do you notice about the pronunciation of
          than and as? Practise saying the sentences.
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
      `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P48-E5',
    exerciseKey: '/img/FriendsPlus/Page48/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6v2,
    inputSize: 185,
    isHiddenCheck: true,
    // maxLength: 26,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      backgroundColor: 'rgb(227, 243, 243)',
    },

    titleQuestion: [
      {
        num: '5',
        title: `
            Read the 
            <span style="color: rgb(239,140,27);">Look out! </span>
            box. Complete part 2 of the text with the comparative form of the words in  brackets.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-top: 15px; margin-left: -30px;">
          <img src="img/FriendsPlus/Page48/10-E5.jpg" alt="" style="width:700px;">
        </div>
        <div style="margin-top:20px">
          <div style="width:1000px;border: 2px solid rgb(189, 190, 192); background-color: rgb(227, 243, 243); border-radius: 10px; padding:15px;">
            <div style="color: rgb(88, 89, 94); font-weight: bold; margin-bottom: 10px; margin-top: 5px; margin-left: 10px">
              Part 2
            </div>
            <div style="margin-left: 10px; margin-right: 10px;  margin-bottom: 10px">
              The Tunguska Event of 1908 was <b><sup>1 #</sup></b>
              
                (far) from a city so <b><sup>2 #</sup></b> (few) people saw it, but it was much
                <b><sup>3 <input type="text" width="180px"></sup></b>
                (powerful) than the one in Chelyabinsk.
                It destroyed more than 80 million trees in the Siberian forest, so it’s lucky it was not 
                <b><sup>4 #</sup></b>
                (close) to a town or city.
                The night sky became <b><sup>5 #</sup></b> (bright) than normal for a few days, 
                and people a hundred kilometres away could read a newspaper outdoors even at midnight. Most scientists believe it was a
                meteor explosion, 
                but there are some <b><sup>6 <input type="text" width="170px"></sup></b> (unusual) theories too. Perhaps it
                was a UFO!
            </div>
          </div>
        </div>
      `,
        answer: [
          'further / farther | farther / further',
          'fewer',
          'more powerful',
          'closer',
          'brighter',
          'more unusual',
        ],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P48-E6',
    exerciseKey: '/img/FriendsPlus/Page48/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 900,
    maxLength: 160,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '6',
        title: `
              <span style="color:rgb(8,139,89) ;">SPEAKING</span> 
                Work in pair. Ask and answer questions
                beginning with Which … ? Use the comparative form of
                the adjectives.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
      <div>
        <span><strong>1</strong> dangerous / a blizzard / a thunderstorm / ?</span>
        <div style="color: rgb(35,106,122);"><i>Which is more dangerous, a blizzard or a thunderstorm?</i></div>
      </div>
      <div><strong>2</strong> beautiful / snowflake / rainbow / ?</div>
      <div>#</div>
      <div><strong>3</strong> holiday venue / good / the mountains / the beach / ?</div>
      <div>#</div>
      <div><strong>4</strong> city / have / many seasons / Ha Noi / Ho Chi Minh City / ?</div>
      <div>#</div>
      <div><strong>5</strong> for you personally / temperature / bad / -5°C / 35°C / ?</div>
      <div>#</div>
      <div><strong>6</strong> Ha Noi / month / hot / June / August / ?</div>
      <div>#</div>
      `,
        answer: [
          ' Which is more beautiful, a snowflake or a rainbow?',
          'Which holiday venue is better, the mountains or the beach?',
          'Which city has more seasons, Ha Noi or Ho Chi Minh City?',
          'For you personally, which temperature is worse, -5°C or 35°C?',
          'In Ha Noi, which month is hotter, June or August?',
        ],
      },
    ],
  },
};

export default json;
