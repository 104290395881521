import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E1',
		audio: '',
		video: '',
		component: T6,
		//
		checkDuplicated: true,
		maxLength: 16,
		stylesTextInput: { borderBottom: 'dash' },
		textareaStyle: { width: 700 },
		inputSize: 200,
		// isHiddenCheck: true,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page30/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Match two or more of the pieces of equipment below with each photo (A–C).',
				color: '#8e258c',
				prefix: { icon: [''], text: 'VOCABULARY' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
         <span style="color:rgb(10 127 64)"><b >Sports equipment</b>&emsp;&emsp;boots&emsp;&emsp;helmet&emsp;&emsp;dinghy&emsp;&emsp;
life jacket&emsp;&emsp;paddles&emsp;&emsp;poles&emsp;&emsp;rope&emsp;&emsp;rucksack&emsp;&emsp;
safety harness </span>
            <div style='line-height:50px'>
              <b>A</b>#,#,#<br>
              <b>B</b>#,#,#,#<br>
              <b>C</b>#,#<br>
            </div>
           <img src='img/FriendsPlus/Page30/E1/1.jpg'  />  
          `,
				answer: [
					'boots / poles / rucksack',
					'boots / poles / rucksack',
					'boots / poles / rucksack',
					'helmet / dinghy / life jacket / paddles',
					'helmet / dinghy / life jacket / paddles',
					'helmet / dinghy / life jacket / paddles',
					'helmet / dinghy / life jacket / paddles',
					'rope / safety harness',
					'rope / safety harness',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E2',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_27.mp3',
		video: '',
		component: T6,

		maxLength: 6,
		textareaStyle: { width: 700 },
		inputSize: 550,
		// isHiddenCheck: true,
		titleImage: '',
		// textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page30/E2/Key/answerKey.png',
		selectStyle: {
			color: 'black',
			width: 170,
			textAlign: 'center',
			fontSize: 26,
		},
		selectOptionRandom: true,
		selectOptionValues: ['Student 1', 'Student 2'],
		titleQuestion: [
			{
				num: '2',
				title:
					'<headphone1 name="1.27"></headphone1> Read the <span style="color:rgb(10 127 64)">Speaking Strategy</span>. Then listen to two students describing photos A and B. Who follows the strategy better, in your opinion?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <div style='margin:20px 0px; background:rgb(229 241 231);border: 6px dotted rgb(10 127 64);width:22cm ;border-radius:18px; padding:5px 20px'>
            <b style='font-size:28px;color:rgb(10 127 64)'>Speaking Strategy</b>
            <div>
              Make sure your photo description has a simple structure.
              Start by saying what the photo shows in general (Who?
              What? Where?). Then mention interesting details. You
              can finish with a personal comment or some speculation.
            </div>
          </div>
          <div style='position: relative;'>
            <img src='img/FriendsPlus/Page30/E2/1.jpg'>
            <div style=" position: absolute; top: 10px; left: 68px; "><select id=0></select></div>
          </div>
          `,
				answer: ['Student 2'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E3',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_27.mp3',
		video: '',
		component: T6,
		maxLength: 5,
		stylesTextInput: { borderBottom: 'dash' },
		textareaStyle: { width: 700 },
		inputSize: 100,
		// isHiddenCheck: true,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page30/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title:
					'<headphone1 name="1.27"></headphone1> <b style="color:rgb(142 37 140)">KEY PHRASES</b> Complete the phrases for describing a photo <br>with the words below. You need to use some words more than once. <br>Then listen again and check.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
         <b style="color:rgb(10 127 64);margin-left: 100px;">at&emsp;&emsp;in&emsp;&emsp;looks&emsp;&emsp;on&emsp;&emsp;seems&emsp;&emsp;shows</b>
            <div style='line-height:50px;border-left:3px solid rgb(10 127 64)'>
              <div style="margin-left: 30px;"> 
                <div style="color:rgb(10 127 64);margin-left: 30px;"><b>Describing a photo</b></div>
                The photo <b><sup>1</sup></b>#...<br>
                <b><sup>2</sup></b># the foreground / background <br>
                <b><sup>3</sup></b># the top left corner / bottom right corner <br>
                <b><sup>4</sup></b># the top / bottom <br>
                <b><sup>5</sup></b># the left / right <br>
                <b><sup>6</sup></b># the centre <br>
              It <b><sup>7</sup></b># like a ...<br>
              It <b><sup>8</sup></b># as if they’re ...<br>
                She <b><sup>9</sup></b># to be ...<br>
              </div>
            </div>
          `,
				answer: ['shows', 'In', 'In', 'At', 'On', 'In', 'looks', 'looks / seems', 'seems'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E4',
		audio: '',
		video: '',
		component: T6,
		recorder: true,
		stylesTextInput: { borderBottom: 'dash' },
		textareaStyle: { width: 700 },
		inputSize: 120,
		hideBtnFooter: true,
		titleImage: '',
		textAlign: 'center',
		// exerciseKey: 'img/FriendsPlus/Page30/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					'<b style="color:rgb(142 37 140)">KEY PHRASES</b>  Work in pairs. How do you think the people in photos A and B are feeling? Use the words below or other adjectives from lesson 1A on page 10.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
         <span style="color:rgb(10 127 64)">angry&emsp;&emsp;bored&emsp;&emsp;calm&emsp;&emsp;excited&emsp;&emsp;nervous&emsp;&emsp;relaxed&emsp;&emsp;
scared&emsp;&emsp;shocked</span>
            <div style='line-height:50px'>
              <span style="color:rgb(10 127 64)">Speculating about feelings</span>
              
              <div style='width:18cm'>
                  She’s probably feeling # and #
                  I expect they’re feeling # , but maybe a bit #
                  Judging by her expression, I’d say #
                  To me, they look as if they’re feeling #
              </div>
            </div>
            
          `,
				answer: ['', '', '', '', '', ''],
			},
		],
	},
	5: {
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_28.mp3',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page30/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title:
					"<headphone1 name='1.28'></headphone1> Listen to the second student answering the teacher’s questions about photo B. Which adjectives from exercise 4 does she use?",
				color: '#8e258c',
			},
		],
		question: {
			Write: {
				inputStyle: { borderBottom: 'none', fontSize: 18, width: 120 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					borderRadius: '50%',
					borderColor: 'gray',
					fontSize: 30,
				},
				selectWordStyle: {
					padding: 3,
					border: 'solid 3px',
					borderColor: 'gray',
				},
				limitSelect: 3,
				listWords: [
					'angry|&emsp;&emsp;|bored|&emsp;&emsp;|calm|&emsp;&emsp;|excited|&emsp;&emsp;|nervous|&emsp;&emsp;|relaxed|&emsp;&emsp;|scared|&emsp;&emsp;|shocked',
				],
				answers: ['0-4', '0-24', '0-12'],
				initialValue: [],
			},
			Layout: `
        <div style='margin:30px 20px'><input id='0' type='Circle' /></div>
        <img src='img/FriendsPlus/Page30/E2/1.jpg'>
        
      `,
		},
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E6',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_28.mp3',
		video: '',
		component: T6,

		maxLength: 12,
		stylesTextInput: { borderBottom: 'dash' },
		textareaStyle: { width: 700 },
		inputSize: 220,
		// isHiddenCheck: true,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page30/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title:
					'<headphone1 name="1.28"></headphone1> Listen again. Complete the phrases at the beginning of extracts 1–6.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height:50px'>
            <b>1</b> &emsp;<u style='color:rgb(21 110 139)'> Well, basiscally</u>, you   can see it in their faces <br>
            <b>2</b> &emsp;#, no, I wouldn’t. <br>
            <b>3</b> &emsp;#, the worst thing would be that I couldn’t stop.<br>
            <b>4</b> &emsp;#, I went on a bike ride with a friend of mine.<br>
            <b>5</b> &emsp;#, it was a really good day out.<br>
            <b>6</b> &emsp;#, that’s the last time I did an outdoor activity.<br>

        </div> 

            
          `,
				answer: ['To be honest', 'For me', 'A while ago', 'In fact', 'I suppose'],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P30-E7',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '7',
				title: 'Work in groups. Describe photo A, B or C using words and phrases from this lesson. ',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page30/E1/1.jpg' }]],
	},
}

export default json
