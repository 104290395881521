import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page87/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(2, 146, 97);'>SPEAKING</span> Work in pairs. Describe the photo.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
            <div><b> Where are the family? </b></div>
            <textarea id="0" rows="2"></textarea>
            <div><b> What are they doing? </b></div>
            <textarea id="1" rows="2"></textarea>
          </div>
          <div><img src= 'img/FriendsPlus/Page87/E1/1.jpg' style="width: 500px;"/></div>
        </div>
			  `,
        answer: [
          'The family is at the airport, checking in. ',
          'They are handing over checked-in luggage to the airport staff.',
        ],
        // answer: [
        //   "The family is at the airport, checking in. ", "They are showing their tickets to the airport staff.",
        // ],
      },
    ],
  },

  2: {
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page87/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the text. Who first realised that the family were getting on the wrong flight?
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['a <br> b <br> c <br> d'],
        answers: ['0-8'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex; gap:10px;'>
              <div style='display: flex; gap:10px;'>
                <div><b> <input id='0' type='Circle' /> </b></div>
                <div>
                  <div> a passport control officer</div>
                  <div> a security guard</div>
                  <div> the flight attendant</div>
                  <div> a police officer</div>
                </div>
          </div>
          <img src='/img/FriendsPlus/Page87/E2/1.jpg' alt = '' style='width:650px;'/>
        </div>

      `,
    },
  },

  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page87/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				<span style='color:rgb(124, 8, 79);'>VOCABULARY</span> Read the text again. Complete the compound nouns.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 125,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,
    questions: [
      {
        title: `
        <img src='/img/FriendsPlus/Page87/E3/1.jpg' alt = '' style='width:750px;'/>
        <div style='border-left: 5px solid rgb(168, 95, 170)'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(153, 34, 136);'>Travelling by plane</b></div>
            <div style='display: flex; gap:70px;'>
                <div>
                  <div>bag <b><sup>1</sup>#</b></div>
                  <div>boarding <b><sup>2</sup>#</b></div>
                  <div><b><sup>3</sup>#</b> desk</div>
                  <div>departure gate </div>
                  <div>departure <b><sup>4</sup>#</b></div>
                  <div><b><sup>5</sup>#</b> screen</div>
                </div>
                <div>
                  <div><b><sup>6</sup>#</b> attendant</div>
                  <div>flight number</div>
                  <div>hand luggage</div>
                  <div>passport <b><sup>7</sup>#</b></div>
                  <div>seat belt</div>
                  <div>security <b><sup>8</sup>#</b></div>
                  <div>window seat</div>
                </div>
              </div>
          </div>
        </div>
			  `,
        answer: ['drop', 'pass', 'check-in', 'lounge', 'departure', 'flight', 'control', 'check'],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page87/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				Which items in exercise 3 do you find in an airport terminal? Which four do you find on a plane?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 250,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'terminal bag drop',
      'boarding pass',
      'check-in desk',
      'departure gate',
      'departure lounge',
      'departure screen',
      'flight attendant',
      'flight number',
      'hand luggage',
      'passport control',
      'seat belt',
      'security check',
      'window seat',
    ],

    checkDuplicated: true,
    hintBox: [
      {
        src: [
          'terminal bag drop',
          'boarding pass',
          'check-in desk',
          'departure gate',
          'departure lounge',
          'departure screen',
          'flight attendant',
          'flight number',
          'hand luggage',
          'passport control',
          'seat belt',
          'security check',
          'window seat',
        ],
        // arrow: true,
        width: 800,
        borderColor: 'rgb(153, 34, 136)',
      },
    ],
    questions: [
      {
        title: `
				    <div style='margin:20px 100px;'><hintBox id='0' ></hintBox></div>
            <div>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> In an airport: </b></div>
                <b><select id='0'></select></b>, 
                <b><select id='1'></select></b>, 
                <b><select id='2'></select></b>, 
                <b><select id='3'></select></b>, 
                <b><select id='4'></select></b>, 
                <b><select id='5'></select></b>, 
                <b><select id='6'></select></b>.
              </div>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> On a plane: </b></div>
                <b><select id='7'></select></b>, 
                <b><select id='8'></select></b>, 
                <b><select id='9'></select></b>, 
                <b><select id='10'></select></b>.
              </div>
            </div>
			  `,
        answer: [
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'terminal bag drop / check-in desk / departure gate / departure lounge / departure screen / passport control / security check',
          'flight attendant / hand luggage / seat belt / window seat',
          'flight attendant / hand luggage / seat belt / window seat',
          'flight attendant / hand luggage / seat belt / window seat',
          'flight attendant / hand luggage / seat belt / window seat',
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_08.mp3',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '5',
        title: ` <headphone1 name='3.08'></headphone1> Read the <span style='color:rgb(161, 64, 161);'>Learn this!</span> box. Then listen and repeat the compound nouns in exercise 3.
			  `,
        color: '#92278f',
      },
    ],
    hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    hintBox: [
      {
        src: [
          'terminal bag drop',
          'boarding pass',
          'check-in desk',
          'departure gate',
          'departure lounge',
          'departure screen',
          'flight attendant',
          'flight number',
          'hand luggage',
          'passport control',
          'seat belt',
          'security check',
          'window seat',
        ],
        // arrow: true,
        width: 800,
        borderColor: 'rgb(153, 34, 136)',
      },
    ],
    questions: [
      {
        title: `
				    <div style='margin:20px 0px;'><hintBox id='0' ></hintBox></div>
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page87/E5/LearnThis.jpg');  background-repeat: no-repeat;background-size: 750px 260px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(161, 64, 161);'>LEARN THIS!</b><b> Compound nouns: noun + noun</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div> Most compound nouns are formed from two nouns.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div> We usually write them as two words (<i>travel agent</i>), but sometimes as one word (<i>whiteboard</i>) or with a hyphen (<i>film-maker</i>). Check in a dictionary.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div> The stress is usually on the first noun. <i><u>white</u>board</i></div>
                    </div>
                </div>
            </div>
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page87/E5/LearnThis.jpg');  background-repeat: no-repeat;background-size: 750px 230px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(161, 64, 161);'>LEARN THIS!</b><b> Compound sentences</b></div>
                    <div> A compound sentence has two clauses linked by a comma and a conjunction, e.g. <i>and, but, so</i> and <i>or</i></div>
                    <div><i>I’m travelling alone, so I’m going to book a single room at the hotel.</i></div>
                </div>
            </div>
			  `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E56',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page87/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: ` Complete the text below with compound nouns from exercise 3.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    isHiddenCheck: true,
    inputSize: 187,
    stylesTextInput: [],
    maxLength: 15,
    textAlign: 'left',
    hintBox: [
      {
        src: [
          'terminal bag drop',
          'boarding pass',
          'check-in desk',
          'departure gate',
          'departure lounge',
          'departure screen',
          'flight attendant',
          'flight number',
          'hand luggage',
          'passport control',
          'seat belt',
          'security check',
          'window seat',
        ],
        // arrow: true,
        width: 800,
        borderColor: 'rgb(153, 34, 136)',
      },
    ],
    questions: [
      {
        title: `
				    <div style='margin:20px 0px;'><hintBox id='0' ></hintBox></div>
					  <div style=" width: 880px; background-image: url('img/FriendsPlus/Page87/E6/1.jpg');  background-repeat: no-repeat;background-size: 880px 430px;">
              <div style='padding:25px 15px 25px 155px;'>
                    <div>When you arrive at the airport, go to the <b><sup>1</sup>c#</b></b> .</div>
                    <div>The clerk will check in your luggage and might ask if you want an aisle seat or a <b><sup>2</sup>w#</b> . He or she will give you a &emsp; <b><sup>3</sup>b#</b> . Show your passport at <b><sup>4</sup>p#</b> . You will also have to go through a <b><sup>5</sup>s#</b> , where they X-ray your <b><sup>6</sup>h#</b> . </div>
                    <div>Then you reach the <b><sup>7</sup>d#</b> , where there are duty-free shops. You can find your flight on the departures board by looking at the 
                    <b><sup>8</sup>f#</b> or the departure time. 
                    </div>
                    <div>When they announce your flight, go to the gate.</div>
                </div>
            </div>
			  `,
        answer: [
          'heck-in desk',
          'indow seat',
          'oarding pass',
          'assport control',
          'ecurity check',
          'and luggage',
          'eparture lounge',
          'light number',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page87/E7/key/answerKey.png',
    inputSize: 1050,
    maxLength: 81,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '7',
        title: `
				Combine the following pairs of sentences, using and, but, so and or to make compound sentences.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 1 </b></div>
            <div>
              <div> Half board at our hotel includes breakfast and dinner. The rate is about $80 per night.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 2 </b></div>
            <div>
              <div> It is less comfortable in economy class. You have to pay much more for a first class fare.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 3 </b></div>
            <div>
              <div> August is high season. Holidays are much more expensive.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 4 </b></div>
            <div>
              <div> My mum is a check-in clerk. She only works part time.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 5 </b></div>
            <div>
              <div> You can get your boarding pass at the airport. You can use the online check-in and print yours at home.</div>
              <div>#</div>
            </div>
          </div>

			  `,
        answer: [
          'Half board at our hotel includes breakfast and dinner, and the rate is about $80 per night.',
          'It is much less comfortable in economy class, so you pay much more for a first class fare.',
          'August is high season, so holidays are much more expensive.',
          'My mum is a check-in clerk, but she only works part time.',
          'My mum is a check-in clerk, but she only works part time.',
        ],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P87-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '8',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Look at the text in exercise 2 for two minutes. Then cover it and retell the story in your own words.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <img src='/img/FriendsPlus/Page87/E8/1.jpg' style='width:850px;'/>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
