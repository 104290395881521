import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Science',
    id: 'FG10-S-P94-E1',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Look at the photos of gadgets (A–C). 
          Do you think they are good or bad ideas? Which is your favourite and why?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex; justify-content: center; align-items: start;">
          <img src="img/FriendsPlus/Page94/E1/1.jpg" style="max-width: 100%;" />
          <img src="img/FriendsPlus/Page94/E1/2.jpg" style="max-width: 100%;" />
        </div>
				`,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Science',
    id: 'FG10-S-P94-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_14.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page94/E2/Key/answerKey.png',
    component: T6,
    inputSize: 130,
    maxLength: 9,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 23,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '2',
        title: `
          <audioimage name="3.14"></audioimage> 
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
          Check the meaning of the words below. Then listen and repeat. 
          Which materials do you think are used to make the gadgets in exercise 1?
				`,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; justify-content: center; align-items: start;">
            <img src="img/FriendsPlus/Page94/E1/1.jpg" style="max-width: 100%;" />
            <img src="img/FriendsPlus/Page94/E1/2.jpg" style="max-width: 100%;" />
          </div>

          <div style="margin-top: 20px; color: rgb(146, 39, 144);">
            <div>
              <strong>Materials</strong>
              <span style="margin-left: 25px;">aluminium</span>
              <span style="margin-left: 25px;">cardboard</span>
              <span style="margin-left: 25px;">ceramic</span>
              <span style="margin-left: 25px;">concrete</span>
              <span style="margin-left: 25px;">copper<span/>
              <span style="margin-left: 25px;">glass<span/>
            </div>
            <div>
              <span>gold</span>
              <span style="margin-left: 25px;">iron</span>
              <span style="margin-left: 25px;">leather</span>
              <span style="margin-left: 25px;">nylon</span>
              <span style="margin-left: 25px;">paper</span>
              <span style="margin-left: 25px;">plastic</span>
              <span style="margin-left: 25px;">rubber</span>
              <span style="margin-left: 25px;">steel</span>
              <span style="margin-left: 25px;">stone</span>
              <span style="margin-left: 25px;">wood</span>
            </div>
          </div>	

          <div style="margin-top: 20px;">
            <strong>Which materials do you think are used to make the gadgets in exercise 1?</strong><br/>
            <i>Answer:</i> <br/>
            <span style="font-weight: bold; padding-right: 10px;">A</span> plastic, rubber, # <br/>
            
            <span style="font-weight: bold; padding-right: 10px;">B</span> plastic, rubber, #, #<br/>
            <span style="font-weight: bold; padding-right: 10px;">C</span>plastic, #, #<br/>
          </div>
				`,
        answer: ['steel', 'nylon', 'wood', 'aluminium', 'glass'],
      },
    ],
  },
  // todo
  3: {
    unit: 'Science',
    id: 'FG10-S-P94-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page94/E3/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
					Do the quiz in pairs. Then check your answers with your teacher.
				`,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
          padding: '5px',
        },
        limitSelect: 1,
        // listWords: [`a_cardboard. <span style="padding-left: 20px"></span>b_paper. c_plastic. d_rubber.`],
        listWords: [
          'a_cardboard.&nbsp;&nbsp; b_paper.&nbsp;&nbsp; c_plastic.&nbsp;&nbsp; d_rubber.',
          'a_wood_with_iron_tyres.&nbsp;&nbsp; b_iron_with_rubber_tyres.&nbsp;&nbsp;<br/> c_iron_with_wooden_tyres.&nbsp;&nbsp; d_rubber.',
          'a_ceramic&nbsp;&nbsp; b_concrete&nbsp;&nbsp; c_glass&nbsp;&nbsp; d_paper',
          'true / false',
          'true / false',
          'a_women’s clothing.&nbsp;&nbsp; b_toothbrushes.&nbsp;&nbsp;<br/> c_parachutes.&nbsp;&nbsp; d_guitar_trings.',
          'a_aluminium&nbsp;&nbsp; b_copper&nbsp;&nbsp; c_gold&nbsp;&nbsp; d_iron&nbsp;&nbsp; e_steel',
          'a_aluminium&nbsp;&nbsp; b_copper&nbsp;&nbsp; c_gold&nbsp;&nbsp; d_iron&nbsp;&nbsp; e_steel',
        ],
        answers: ['0-6', '1-0', '2-6', '3-4', '4-4', '5-4', '6-8', '7-4'],
        initialValue: [],
      },
      Layout: `
				<div style="position: relative; font-size: 20px;">
					<div style="display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(0, 175, 180);">1</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(182, 226, 228);">
							<div style="width: 70%;">During World War II (1939–1945), scientists used dandelions (see photo) to make</div>
							<input id='0' type='Circle' />
						</div>
					</div>

					<div style="margin-top: 5px; display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(57, 76, 47);">2</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(199, 203, 193);">
							<div style="width: 70%;">The first bicycles had wheels made of</div>
							<input id='1' type='Circle' />
						</div>
					</div>
					
					<div style="margin-top: 5px; display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(216, 200, 0);">3</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(243, 237, 185);">
							<div style="width: 70%;">Which one of these materials did the Ancient Romans not have?</div>
							<input id='2' type='Circle' />
						</div>
					</div>
					
					<div style="margin-top: 5px; display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(114, 121, 150);">4</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(218, 220, 229);">
							<div style="width: 70%;">Are these sentences true or false?</div>
							<div style="width: 70%;">
								<span style="font-weight: bold; padding-right: 10px;">a</span>
								<span>All types of wood float in water.</span> <input id='3' type='Circle' />
							</div>
							<div style="width: 70%;">
								<span style="font-weight: bold; padding-right: 10px;">b</span>
								<span>No type of stone floats in water.</span> <input id='4' type='Circle' />
							</div>
						</div>
					</div>

					<div style="margin-top: 5px; display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(18, 164, 114);">5</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(188, 221, 204);">
							<div style="width: 70%;">The earliest use of nylon was in</div>
							<input id='5' type='Circle' />
						</div>
					</div>

					<div style="margin-top: 5px; display: flex;">
						<div style="display:flex; justify-content: end; align-items: start; padding-right: 20px; flex: 1; font-size: 80px; color: rgb(255,255,255); background-color: rgb(199, 84, 53);">6</div>
						<div style="padding: 10px; flex: 9; background-color: rgb(239, 195, 176);">
							<div style="width: 70%;">Which of these metals is the hardest?</div>
							<input id='6' type='Circle' />
							<div style="width: 70%;">Which of these metals is the heaviest?</div>
							<input id='7' type='Circle' />
						</div>
					</div>

					<div style="position: absolute; top: 10px; left: 564px; display: flex; justify-content: center; align-items: center;">
						<img src="img/FriendsPlus/Page94/E3/1.jpg" style="max-width: 100%; border-radius: 50%; border: 5px solid rgb(0, 147, 180);" />
					</div>
				</div>
      `,
    },
  },
};

export default json;
