import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P22-E1',
    audio: '',
    video: '',
    component: UI,
    recorder: true,

    questionImage: [
      [{ url: 'img/FriendsPlus/Page22/3.jpg' }],
      // Row
      [{ url: 'img/FriendsPlus/Page22/E1/1.jpg' }],
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P22-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_19.mp3',
    video: '',
    component: T6,
    // checkDuplicated: true,
    isHiddenCheck: true,
    textareaStyle: { width: 300 },
    textAlign: 'center',
    inputSize: 130,
    maxLength: 9,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title:
          "<headphone1 name='1.19' src='' ></headphone1> <b style='color:rgb(142 37 140); font-size:27px'>VOCABULARY</b> Match two or more of the nouns below with each photo (A–E). Then listen to the descriptions and check your answers.",
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='color:rgb(142 37 140); width: 25cm'>
            <b >Landscape: features</b> &emsp;cave&emsp;&emsp;cliff&emsp;&emsp;desert&emsp;&emsp;forest&emsp;&emsp;hill&emsp;&emsp;lake&emsp;&emsp;
            mountain&emsp;&emsp;ocean&emsp;&emsp;river&emsp;&emsp;rocks&emsp;&emsp;shore&emsp;&emsp; stream&emsp;&emsp;valley&emsp;&emsp;
            volcano&emsp;&emsp;waterfall
          </div>
          <div style='display:flex; line-height:50px'>
          
            <div>
                <b>A</b>#,#,#,#<br>
                <b>B</b>#,#,#<br>
                <b>C</b>#,#,# <br>
                <b>D</b>#,#,#<br>
                <b>E</b>#,#,#<br>
            </div>
          </div>
          <img src='img/FriendsPlus/Page22/E2/1.jpg'>
         
          `,
        answer: [
          'forest / river / rocks / waterfall',
          'forest / river / rocks / waterfall',
          'forest / river / rocks / waterfall',
          'forest / river / rocks / waterfall',
          'hill / rocks / valley',
          'hill / rocks / valley',
          'hill / rocks / valley',
          'cliff / ocean / rocks',
          'cliff / ocean / rocks',
          'cliff / ocean / rocks',
          'cave / ocean / rocks',
          'cave / ocean / rocks',
          'cave / ocean / rocks',
          'lake / mountain / shore',
          'lake / mountain / shore',
          'lake / mountain / shore',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P22-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 10,
    textAlign: 'center',
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Check the meanings of the adjectives below. Find three pairs of opposites. ',
        color: '#8e258c',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='color:rgb(142 37 140); width: 25cm;margin-bottom:20px'>
            <b >Landscape: features</b> &emsp;dark&emsp;&emsp;deep&emsp;&emsp;icy&emsp;&emsp;low&emsp;&emsp;narrow&emsp;&emsp;rocky&emsp;&emsp;
            shallow&emsp;&emsp;steep&emsp;&emsp;tall&emsp;&emsp;wide
        </div>
        <div style="display: flex; gap: 70px;">
          <div>deep - #</div>
          <div>low - #</div>
          <div>narrow - #</div>
        </div>
          <img src='img/FriendsPlus/Page22/E2/1.jpg' alt="" style="width: 900px;"/>
          `,
        answer: [
          'shallow','tall','wide',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P22-E4',
    audio: 'img/FriendsPlus/Page22/Audio/audio-e4-p22.mp3',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E4/1.jpg' },
        {
          url: 'img/FriendsPlus/Page22/E4/2.jpg',
          audioUrl: 'img/FriendsPlus/Page22/Audio/tieude-e4-p22.mp3',
        },
        { url: 'img/FriendsPlus/Page22/E4/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E4/4.jpg' },
      ],
    ],
  },
};

export default json;
