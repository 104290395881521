import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';

const json = {
	1: {
		unit: 'Unit 1',
		id: 'SB10-U1-P20-E1',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Describe the photos. 
          Do they match your idea of typical British people?<br/> Why? / Why not?`,
			},
		],
		recorder: true,
		questionImage: [
			[
				// Column1
				{ url: '/img/FriendsPlus/Page20/E1/1.jpg' },
			],
		],
	},

	2: {
		unit: 'Unit 1',
		id: 'SB10-U1-P20-E2',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page20/E2/Key/answerKey.png',
		component: T6,
		inputSize: 35,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 21,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the text. 
          Are these sentences about the people who took part in the survey true or false? 
          Write T or F.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; gap: 20px; align-items: start; font-size: 21px;">
            <div style="flex: 3; padding: 10px 20px; border-radius: 10px; background-color: rgb(173, 197, 231);">
              <div style="text-align: center; font-size: 35px; text-transform: uppercase;">How Foreigners See The British</div>
              <div style="margin-top: 7px;">
                What is a typical British person like? 
                People who come to Britain from other countries probably have a few expectations: 
                British people are polite, rather reserved, and enjoy drinking tea and standing in queues! 
                But how accurate is this stereotype? In an online survey of 1,402 foreign nationals living in the UK, 
                just over half said that the British matched their expectations.
              </div>
              <div style="margin-top: 7px;">
                The survey also asked which aspects of the British character the foreign nationals liked and disliked. 
                British people’s good manners were popular with 49% and 40% liked the ability to queue. 
                Many agreed that the British are reserved and for 32% this was a good quality – but for 19% it was negative. 
                Other negative aspects were the British sense of humour (31%) and British culture in general (28%). 
                However, 77% said they liked British people in general and 61% said that their opinion of the British 
                got better as a result of living in the UK.
              </div>
              <div style="margin-top: 7px;">
                A spokesman for the researchers said: “People probably come to Britain with a stereotype of what to expect. 
                It’s good to see that, for the majority, the reality is better than the stereotype.”
              </div>
            </div>

            <div style="flex: 2; padding: 10px; border-radius: 10px; border: 1px solid rgb(173, 197, 231);">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">a</div>
                <div>Most have a positive view of the British overall. #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">b</div>
                <div>More than half have a better opinion of the British now that they live in the country. #</div>
              </div>	
            </div>
          </div> 
        `,
				answer: ['T', 'T'],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'SB10-U1-P20-E3',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page20/E3/Key/answerKey.png',
		padding: 0,
		component: T6,
		inputSize: 10,
		maxLength: 17,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: 'none',
			width: 190,
			fontSize: 19,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          Complete the labels for the charts with the words
          below. Use information from the text to help you.
        `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
          <div style='display: flex; gap: 20px; font-size: 20px;'>
            <div style="flex: 2; padding: 10px 20px; border-radius: 30px; background-color: rgb(173, 197, 231);">
              <div style="text-align: center; font-size: 28px; text-transform: uppercase;">How Foreigners See The British</div>
              <div style="margin-top: 7px;">
                What is a typical British person like? 
                People who come to Britain from other countries probably have a few expectations: 
                British people are polite, rather reserved, and enjoy drinking tea and standing in queues! 
                But how accurate is this stereotype? In an online survey of 1,402 foreign nationals living in the UK, 
                just over half said that the British matched their expectations.
              </div>
              <div style="margin-top: 7px;">
                The survey also asked which aspects of the British character the foreign nationals liked and disliked. 
                British people’s good manners were popular with 49% and 40% liked the ability to queue. 
                Many agreed that the British are reserved and for 32% this was a good quality – but for 19% it was negative. 
                Other negative aspects were the British sense of humour (31%) and British culture in general (28%). 
                However, 77% said they liked British people in general and 61% said that their opinion of the British 
                got better as a result of living in the UK.
              </div>
              <div style="margin-top: 7px;">
                A spokesman for the researchers said: “People probably come to Britain with a stereotype of what to expect. 
                It’s good to see that, for the majority, the reality is better than the stereotype.”
              </div>
            </div>

            <div style="flex: 3; display: flex; flex-direction: column; align-items: center; justify-content: space-between;">
              <div style="font-size: 22px; padding: 10px; border-radius: 20px; border: 2px solid rgb(173, 197, 231); font-weight: bold; color: rgb(39,131,197); display: flex; gap: 10px 30px; flex-wrap: wrap;">
                <span>better</span><span>culture</span><span>good manners</span><span>reserve (x2)</span><span>sense of humour</span><span>the same or worse</span>           
              </div>
              <div style="position: relative;">
                <div><img src='/img/FriendsPlus/Page20/E3/1.jpg' style='max-width: 100%;'/></div>
                <div style='position: absolute; top: 187px; left: 134px;'><input type="text" id="0" width="150px"></div>
                <div style='position: absolute; top: 187px; left: 442px;'><input type="text" id="1" width="100px"> </div>
                <div style='position: absolute; top: 427px; left: 122px;'># </div>
                <div style='position: absolute; top: 427px; left: 286px;'><input type="text" id="3" width="100px"> </div>
                <div style='position: absolute; top: 426px; left: 442px;'> <input type="text" id="4" width="100px"></div>
                <div style='position: absolute; top: 531px; left: 470px;'># </div>
                <div style='position: absolute; top: 612px; left: 473px;'><input type="text" id="6" width="80px"> </div>
              </div>
            </div>
          </div>
        `,
				answer: ['good manners', 'reserve', 'sense of humour', 'culture', 'reserve', 'the same or worse', 'better'],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'SB10-U1-P20-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_17.mp3',
		video: '',
		exerciseKey: '',
		padding: 0,
		component: T6,
		inputSize: 10,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: 'none',
			width: 190,
			fontSize: 20,
		},
		textareaStyle: { width: 600 },
		titleQuestion: [
			{
				num: '4',
				title: `
          <audioimage name="1.17"></audioimage>
          Listen to four people from other countries
          talking about their view of the British. Which
          person do you think has the most negative view?
        `,
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
          <textarea id="0" rows="2"></textarea>
        `,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'SB10-U1-P20-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_17.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page20/E5/Key/answerKey.png',
		padding: 0,
		component: T6,
		inputSize: 45,
		maxLength: 1,
		titleQuestion: [
			{
				num: '5',
				title: `
          <audioimage name="1.17"></audioimage>
          Listen again. Match the speakers (1–4) with sentences A–F below
        `,
				color: '#92278f',
			},
		],
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		questions: [
			{
				title: `
          <div>Which speaker(s) ...</div>

          <div style="margin-top: 7px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">A</div>
              <div>are not keen on the weather in the UK? #, #</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">B</div>
              <div>makes a positive comment about the food? #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">C</div>
              <div>does not think British people are hard-working? #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">D</div>
              <div>have a negative opinion of young people’s behaviour? #, #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">E</div>
              <div>is a fan of British culture? #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">F</div>
              <div>are generally positive about the people in Britain? #, #, #</div>
            </div>	
          </div>

        `,
				answer: ['1', '4', '3', '2', '3', '4', '1', '1', '3', '4'],
			},
		],
	},
};

export default json;
