import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> 
          Work in pairs. Look at the photo of Aaron Levie. 
          What type of company do you think Aaron Levie runs?
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page76/E2/1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E2',
    exerciseKey: '/img/FriendsPlus/Page76/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '2',
        title: `
           Read the first two paragraphs of the text and check your answer from exercise 1. 
           Do you think “Box” is a good name for Aaron’s company? Why? / Why not?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="flex: 1;">
            <div style="font-weight: bold; font-style: italic;">Answer: </div>
            <div><textarea id="0" rows="2"></textarea></div>
          </div>
          <div style="flex: 1;"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 75%;" /></div>
        `,
        answer: [
          `Aaron Levie runs an IT company. 'Box' is a good name because the company offers a way of storing data`,
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E3',
    exerciseKey: '/img/FriendsPlus/Page76/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 18,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 21,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '3',
        title: `
          Read the <span style="color: rgb(62,63,153);">Reading Strategy</span>. 
          Then look at the underlined words in the sentences after the gaps in the text. 
          What kind of connection do they imply: contrast, similarity, cause / result, or a different option?
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="font-size: 21px;">
            <div style="font-weight: bold; font-style: italic;">Answer:</div>
            <div style="display: flex; gap: 30px;">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div>#</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div>#</div>
              </div>    	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div>#</div>
              </div>    	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div>#</div>
              </div>    	
            </div>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 30px;">
            <div style="flex: 1; font-size: 21px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(66, 64, 153); background-color: rgb(228, 227, 241); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <span style="font-weight: bold; color: rgb(66, 64, 153);">Reading strategy</span>
              <div>
                When you do a task with gapped sentences, 
                study the sentence after each gap and look for 
                any connections with the missing sentence. 
                For example, if it begins with <i>but</i>, there is a contrast. 
                Other words suggest other types of connection:
                <ul>
                  <li>similarity: <i>too</i></li>
                  <li>cause / result: <i>so</i></li>
                  <li>a different option: <i>or</i></li>
                </ul>
              </div>
            </div>
            <div style="flex: 3"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 100%;" /></div>
          </div>
        `,
        answer: ['contrast', 'cause / result', 'contrast', 'a different option'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E4',
    exerciseKey: '/img/FriendsPlus/Page76/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '4',
        title: `
          Read the text. Match sentences A–D with gaps 1–4 in the text. 
          Use your answers from exercise 3 to help you.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="font-size: 20px;">
            <div style="font-weight: bold; font-style: italic;">Answer:</div>
            <div style="display: flex; gap: 30px;">
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">1</div>
                <div>#</div>
              </div>    	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">2</div>
                <div>#</div>
              </div>    	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">3</div>
                <div>#</div>
              </div>    	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">4</div>
                <div>#</div>
              </div>    	
            </div>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 30px;">
            <div style="flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(0, 178, 208);">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">A</div>
                <div>Back in 2005, cloud storage was quite a new idea</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">B</div>
                <div>If you met him, you probably wouldn’t realise that he is a multi-millionaire.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">C</div>
                <div>Most people of his age would lead an extravagant lifestyle if they had so much money.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">D</div>
                <div>He only goes to expensive restaurants if an important customer wants to eat there.</div>
              </div>	
            </div>
            <div style="flex: 2"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 100%;" /></div>
          </div>
        `,
        answer: ['B', 'A', 'C', 'D'],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E5',
    exerciseKey: '',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_32.mp3',
    video: '',
    component: T6,
    inputSize: 50,
    maxLength: 1,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
        	<headphone1 name="2.32"></headphone1> 
					Listen and check your answers to exercise 4
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
					<img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 90%;" />
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E6',
    exerciseKey: '/img/FriendsPlus/Page76/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      // fontSize: 20,
    },
    titleQuestion: [
      {
        num: '6',
        title: `
					Are these sentences true or false? Write T or F.
        `,
        color: '#860052',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
          <div style="font-size: 20px;">
						<div style="width: fit-content; padding: 10px; border-radius: 10px; border: 1px solid rgb(6, 179, 213);">
							<div style="display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">1</div>
								<div>Aaron Levie lives in a luxury apartment. #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">2</div>
								<div>Levie doesn’t mind working long hours because he is really enthusiastic about the work. #</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">3</div>
								<div>Levie was a student when he co-founded the company Box. #</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">4</div>
								<div>Mark Cuban was the first businessman to invest in Box. #</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">5</div>
								<div>Since he became rich and successful, Levie’s lifestyle has not changed very much. #</div>
							</div>	
						</div>

						<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 75%;" /></div>
          </div>
        `,
        answer: ['F', 'T', 'T', 'T', 'T'],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E7',
    exerciseKey: '/img/FriendsPlus/Page76/E7/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 140,
    maxLength: 12,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: '20px',
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '7',
        title: `
					<b style="color: rgb(146,39,143);">VOCABULARY</b> 
					Match the highlighted words in the text with the definitions below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				  <div style="font-size: 20px;">
						<div style="width: fit-content; padding: 10px; border-radius: 10px; border: 1px solid rgb(6, 179, 213);">
							<div style="display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">1</div>
								<div>money received by a business or person #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">2</div>
								<div>a business person who takes risks #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">3</div>
								<div>money that a business or person needs for a particular purpose #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">4</div>
								<div>the money a business makes after paying its expenses #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">5</div>
								<div>the person in charge of a large company #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">5</div>
								<div>formal agreements to do business #</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">5</div>
								<div>people who support a business by lending it money #</div>
							</div>
						</div>

						<div style="margin-top: 20px;"><img src="img/FriendsPlus/Page76/E2/1.jpg" style="max-width: 75%;" /></div>
				  </div>
				`,
        answer: ['income', 'entrepreneur', 'funding', 'profits', 'CEO', 'contracts', 'investors'],
      },
    ],
  },
  8: {
    unit: 'Unit 6',
    id: 'SB10-U6-P76-E8',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '8',
        title: `
					<b style='color:rgb(0,147,70);'>SPEAKING</b> 
						Work in pairs. Ask and answer these questions. 
						Give reasons for your answers and ask follow-up questions. 
	        <div>
          <div style="margin-top: 10px;">
            <span>If you were a multi-millionaire, …</span>
          </div>
          <ul style="margin-top: 10px;">
            <li> would you live in a big house?</li>
            <li> would you work very long hours?</li>
            <li>would you go on expensive holidays?</li>
          </ul>
        </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
