import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				Work in pairs. Describe the photo. Where are the people? What are they doing?
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page90/E1/1.jpg' style="width: 600px; margin-left: 100px"/></div>
			  `,
        answer: [],
      },
    ],
  },

  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				<span style='color:rgb(124, 8, 79);'>VOCABULARY</span> Check the meaning of the tourist attractions below. Which are places and which are events?
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 240,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'art gallery',
      'carnival',
      'church',
      'concert',
      'festival',
      'monument',
      'musical',
      'safari park',
      'old town',
      'opera house',
      'park',
      'restaurant',
      'shopping district',
      'square',
      'theatre',
    ],

    checkDuplicated: true,
    hintBox: [
      {
        src: [
          'art gallery',
          'carnival',
          'church',
          'concert',
          'festival',
          'monument',
          'musical',
          'safari park',
          'old town',
          'opera house',
          'park',
          'restaurant',
          'shopping district',
          'square',
          'theatre',
        ],
        // arrow: true,
        width: 800,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    questions: [
      {
        title: `
        <b style='color:rgb(2, 146, 97);'>Tourist attractions (2)</b>
				<div style='margin: 0px 100px 10px 0px;'><hintBox id='0' ></hintBox></div>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> Places: </b></div>
                <b><select id='0'></select></b>, 
                <b><select id='1'></select></b>, 
                <b><select id='2'></select></b>, 
                <b><select id='3'></select></b>, 
                <b><select id='4'></select></b>, 
                <b><select id='5'></select></b>, 
                <b><select id='6'></select></b>,
                <b><select id='7'></select></b>, 
                <b><select id='8'></select></b>, 
                <b><select id='9'></select></b>, 
                <b><select id='10'></select></b>.
              </div>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> Events: </b></div>
                  <b><select id='11'></select></b>, 
                  <b><select id='12'></select></b>, 
                  <b><select id='13'></select></b>, 
                  <b><select id='14'></select></b>.
                </div>
              </div>
			  `,
        answer: [
          'art gallery',
          'church',
          'monument',
          'safari park',
          'old town',
          'opera house',
          'park',
          'restaurant',
          'shopping district',
          'square',
          'theatre',
          'carnival',
          'concert',
          'festival',
          'musical',
        ],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_10.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				<headphone1 name='3.10' src=''></headphone1> Read the task below. Then listen to a candidate doing the task. Which attractions from exercise 2 do they mention?
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 240,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'art gallery',
      'carnival',
      'church',
      'concert',
      'festival',
      'monument',
      'musical',
      'safari park',
      'old town',
      'opera house',
      'park',
      'restaurant',
      'shopping district',
      'square',
      'theatre',
    ],

    checkDuplicated: true,
    hintBox: [
      {
        src: [
          'art gallery',
          'carnival',
          'church',
          'concert',
          'festival',
          'monument',
          'musical',
          'safari park',
          'old town',
          'opera house',
          'park',
          'restaurant',
          'shopping district',
          'square',
          'theatre',
        ],
        // arrow: true,
        width: 800,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    questions: [
      {
        title: `
        <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div>You are planning a trip to London with a friend. Discuss the trip with your friend and agree on:</div>
            <ul>
              <li>accommodation in London.</li>
              <li>the tourist attractions you will visit.</li>
              <li>places to eat.</li>
              <li>the best way to travel around.</li>
            </ul>
          </div>
        </div>
        <b style='color:rgb(2, 146, 97);'>Tourist attractions (2)</b>
				<div style='margin: 0px 100px 10px 0px;'><hintBox id='0' ></hintBox></div>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> Places: </b></div>
                <b><select id='0'></select></b>, 
                <b><select id='1'></select></b>, 
                <b><select id='2'></select></b>.
              </div>
			  `,
        answer: ['art gallery', 'concert', 'theatre'],
      },
    ],
  },

  4: {
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:rgb(124, 8, 79);'>KEY PHRASES</span> Look at the phrases for making suggestions (1–7) and say what follows them: a) verb + <i>-ing</i>, b) infinitive with <i>to</i>, c) infinitive without <i>to</i>.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['a', 'b', 'c'],
    questions: [
      {
        title: `
                
        <div style='border-left: 5px solid rgb(192, 232, 198)'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Making suggestions</b></div>
            <div style='display: flex; gap:120px;'>
              <div>
                 <div style='display: flex;'><b>1 &emsp;</b>Shall we …?<b><select id='0'></select></b>.</div>
                 <div style='display: flex;'><b>2 &emsp;</b>Let's …?<b><select id='1'></select></b>.</div>
                 <div style='display: flex;'><b>3 &emsp;</b>Why don't we …?<b><select id='2'></select></b>.</div>
                 <div style='display: flex;'><b>4 &emsp;</b>What about …?<b><select id='3'></select></b>.</div>
              </div>
              <div>
                 <div style='display: flex;'><b>5 &emsp;</b>It would be nice …<b><select id='4'></select></b>.</div>
                 <div style='display: flex;'><b>6 &emsp;</b>Do you fancy …?<b><select id='5'></select></b>.</div>
                 <div style='display: flex;'><b>7 &emsp;</b>We could always …<b><select id='6'></select></b>.</div>
              </div>
            </div>
          </div>
        </div>
			  `,
        answer: ['c', 'c', 'c', 'a', 'b', 'a', 'c'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style='color:rgb(124, 8, 79);'>KEY PHRASES</span> Complete the phrases with the words below.
			  `,
        color: '#7c084f',
      },
    ],
    isHiddenCheck: true,
    titleImage: '',
    inputSize: 150,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['both', 'good', 'keen', 'like', 'mind', 'not', 'rather', 'sounds', 'suggestion', 'sure', 'that', 'think'],
        // arrow: true,
        width: 600,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 50px;'><hintBox id='0' ></hintBox></div>
        <div style='border-left: 5px solid rgb(192, 232, 198)'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'> Accepting a suggestion</b></div>
            <div>That <b><sup>1</sup>#</b> like a great idea.</div>
            <div>What a<b><sup>2</sup>#</b>  idea!</div>
            <div>I <b><sup>3</sup>#</b> that idea.</div>
            <div>Yes. Why <b><sup>4</sup>#</b> ?</div>
            <div>Let's do <b><sup>5</sup>#</b> </div>
            <div><b style='color:rgb(2, 146, 97);'> Declining a suggestion</b></div>
            <div>I'm not <b><sup>6</sup>#</b> about that.</div>
            <div>I don't <b><sup>7</sup>#</b> that's a great idea.</div>
            <div>I'm not very <b><sup>8</sup>#</b> on that idea.</div>
            <div>I'd <b><sup>9</sup>#</b> not.</div>
            <div><b style='color:rgb(2, 146, 97);'> Expressing no preference</b></div>
            <div>I don't <b><sup>10</sup>#</b> .</div>
            <div>Either <b><sup>11</sup>#</b> is fine by me.</div>
            <div>They're <b><sup>12</sup>#</b>  good ideas.</div>
          </div>
        </div>
        

			  `,
        answer: [
          'sounds',
          'good',
          'like',
          'not',
          'that',
          'sure',
          'think',
          'keen',
          'rather',
          'mind',
          'suggestion',
          'both',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_10.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<headphone1 name='3.10' src=''></headphone1> <span style='color:rgb(43, 125, 233));'> PRONUNCIATION </span> Listen again and notice the intonation of the phrases for making suggestions. 
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 340,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'Shall we …?',
      "Let's …",
      "Why don't we … ?",
      'What about … ?',
      'It would be nice …',
      'Do you fancy …?',
      'We could always …',
      'That sounds like a great idea',
      'What a good idea!',
      'I like that idea.',
      'Why not ?',
      "Let's do that.",
      "I'm not sure about that.",
      "I don't think that's a great idea.",
      "I'm not very keen on that idea.",
      "I'd rather not.",
      "I don't mind.",
      'Either suggestion is fine by me.',
      "They're both good ideas.",
      "That's a really good plan.",
    ],

    questions: [
      {
        title: `
              <b>Which phrases from exercise 4 and 5 did the speakers use?</b>
              <div style='display: flex;flex-wrap:wrap;'>
                <div style='margin-right: 10px;'><b> Answer: </b></div>
                <b><select id='0'></select></b>, 
                <b><select id='1'></select></b>, 
                <b><select id='2'></select></b>, 
                <b><select id='3'></select></b>, 
                <b><select id='4'></select></b>, 
                <b><select id='5'></select></b>, 
                <b><select id='6'></select></b>, 
                <b><select id='7'></select></b>, 
                <b><select id='8'></select></b>, 
                <b><select id='9'></select></b>, 
                <b><select id='10'></select></b>, 
                <b><select id='11'></select></b>, 
                <b><select id='12'></select></b>, 
                <b><select id='13'></select></b>, 
                <b><select id='14'></select></b>, 
                <b><select id='15'></select></b>, 
              </div>
			  `,
        answer: [
          'Shall we …?',
          "Let's do that.",
          "Why don't we … ?",
          "I'm not sure about that.",
          'What about … ?',
          "I don't mind.",
          'It would be nice …',
          'I like that idea',
          'Do you fancy …?',
          "I'm not very keen on that idea.",
          'Why not ?',
          'What about … ?',
          'Do you fancy …?',
          'What a good idea!',
          'We could always …',
          "That's a really good plan.",
        ],
      },
    ],
  },
  // 6: {
  // 	unit: 'Unit 7',
  // 	id: 'SB10-U7-P90-E6',
  // 	audio: 'Audios/Friends Global Grade 10 SB_CD3_10.mp3',
  // 	video: '',
  // 	component: T6,
  // 	exerciseKey: '/img/FriendsPlus/Page90/E6/key/answerKey.png',
  // 	titleQuestion: [
  // 		{
  // 			num: '6',
  // 			title: `
  // 			<headphone1 name='3.10' src=''></headphone1> <span style='color:rgb(43, 125, 233));'> PRONUNCIATION </span> Listen again and notice the intonation of the phrases for making suggestions.
  // 		  `,
  // 			color: '#7c084f',
  // 		},
  // 	],
  // 	titleImage: '',
  // 	selectStyle: {
  // 		color: '#00ADFE',
  // 		width: 340,
  // 		textAlign: 'center',
  // 		// fontSize: 17,
  // 	},
  // 	// selectOptionRandom: true,
  // 	selectOptionValues: [
  // 		'Shall we …?',
  // 		"Let's …",
  // 		"Why don't we … ?",
  // 		'What about … ?',
  // 		'It would be nice …',
  // 		'Do you fancy …?',
  // 		'We could always …',
  // 		'That sounds like a great idea',
  // 		'What a good idea!',
  // 		'I like that idea.',
  // 		'Why not ?',
  // 		"Let's do that.",
  // 		"I'm not sure about that.",
  // 		"I don't think that's a great idea.",
  // 		"I'm not very keen on that idea.",
  // 		"I'd rather not.",
  // 		"I don't mind.",
  // 		'Either suggestion is fine by me.',
  // 		"They're both good ideas.",
  // 	],
  //
  // 	checkDuplicated: true,
  // 	questions: [
  // 		{
  // 			title: `
  //             <b>Which phrases from exercise 4 and 5 did the speakers use?</b>
  //             <div style='display: flex;flex-wrap:wrap;'>
  //               <div style='margin-right: 10px;'><b> Answer: </b></div>
  //               <b><select id='0'></select></b>,
  //               <b><select id='1'></select></b>,
  //               <b><select id='2'></select></b>,
  //               <b><select id='3'></select></b>,
  //               <b><select id='4'></select></b>,
  //               <b><select id='5'></select></b>,
  //               <b><select id='6'></select></b>,
  //               <b><select id='7'></select></b>,
  //               <b><select id='8'></select></b>,
  //               <b><select id='9'></select></b>,
  //               <b><select id='10'></select></b>,
  //               <b><select id='11'></select></b>,
  //               <b><select id='12'></select></b>,
  //             </div>
  // 		  `,
  // 			answer: [
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 				"Shall we …? / Let's do that. / Why don't we … ? / I'm not sure about that. / What about … ? / I don't mind. / It would be nice … / I like that idea. / Do you fancy …? / I'm not very keen on that idea. / Why not ? / What a good idea! / We could always …",
  // 			],
  // 		},
  // 	],
  // },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '7',
        title: `
				Work in pairs. Take turns to make suggestions using the prompts below. Your partner either accepts the suggestion, or declines it and suggests something else. Use expressions in exercises 4 and 5.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          'go to an Indian restaurant',
          'stay in an expensive hotel',
          'book the hotel in advance',
          'book the hotel in advance',
          'go on an out-of-town excursion',
          'get some pizza',
          'travel by underground',
          'visit the science museum',
        ],
        // arrow: true,
        width: 900,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 50px;'><hintBox id='0' ></hintBox></div>
        
        <div style='border-left: 5px solid rgb(192, 232, 198)'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Making suggestions</b></div>
            <div style='display: flex; gap:120px;'>
              <div>
                 <div style='display: flex;'><b>1 &emsp;</b>Shall we …?</div>
                 <div style='display: flex;'><b>2 &emsp;</b>Let's …?</div>
                 <div style='display: flex;'><b>3 &emsp;</b>Why don't we …?</div>
                 <div style='display: flex;'><b>4 &emsp;</b>What about …?</div>
              </div>
              <div>
                 <div style='display: flex;'><b>5 &emsp;</b>It would be nice …</div>
                 <div style='display: flex;'><b>6 &emsp;</b>Do you fancy …?</div>
                 <div style='display: flex;'><b>7 &emsp;</b>We could always …</div>
              </div>
            </div>
          </div>
        </div>
          <div><img src= 'img/FriendsPlus/Page90/E7/1.jpg' style="width: 400px; margin-left: 0px"/></div>
        

			  `,
        answer: [
          'sounds',
          'good',
          'like',
          'not',
          'that',
          'sure',
          'think',
          'keen',
          'rather',
          'mind',
          'suggestion',
          'both',
        ],
      },
    ],
  },
  8: {
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E8',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_11.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page90/E8/key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `
				<headphone1 name='3.11' src=''></headphone1> Read the <span style='color:rgb(2, 146, 97);'> Speaking Strategy </span>. Then listen to another candidate doing the task in exercise 3. How many times does the candidate ask for clarification? Which words did she not understand?
			  `,
        color: '#7c084f',
      },
    ],
    inputSize: 93,
    stylesTextInput: [],
    maxLength: 7,
    textAlign: 'center',

    checkDuplicated: true,
    isHiddenCheck: true,
    titleImage: '',
    questions: [
      {
        title: `
        
			  <div style='background-color: rgb(229, 241, 231); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(2, 146, 97)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(2, 146, 97) ;'>Speaking Strategy</b></div>
            <div>If you don’t understand something someone has said, don’t panic! Ask for clarification. You can use these phrases:</div>
            <div><i style='color:rgb(1, 142, 211);'>Pardon?</i></div>
            <div><i style='color:rgb(1, 142, 211);'>Could you repeat that, please?</i></div>
            <div><i style='color:rgb(1, 142, 211);'>Sorry, did you say … ?</i></div>
            <div><i style='color:rgb(1, 142, 211);'>Sorry, what does ‘tourist attraction’ mean?</i></div>
          </div>
        </div>

        <div style='background-color: rgb(210, 222, 244); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div>You are planning a trip to London with a friend. Discuss the trip with your friend and agree on:</div>
            <ul>
              <li>accommodation in London.</li>
              <li>the tourist attractions you will visit.</li>
              <li>places to eat.</li>
              <li>the best way to travel around.</li>
            </ul>
          </div>
        </div>

        
            <div><b> How many times does the candidate ask for clarification? </b></div>
            <div>She asks #</div>
            <div><b> Which words did she not understand? </b></div>
            <div>She didn’t understand '#' and '#'.</div>
			  `,
        answer: ['twice', 'market / pricey', 'market / pricey'],
      },
    ],
  },
  9: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P90-E9',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '9',
        title:
          'Work in groups. Plan a holiday for your class. Use photos, pictures or objects to make it more interesting',
        color: '#7c084f',
      },
    ],
    questionImage: [
      // Row
      [],
    ],
  },
};

export default json;
