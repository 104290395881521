import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    textareaStyle: { width: 1100 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '1',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Which of these kinds of video games do you prefer? Give an example of each kind. If you do not like any, say why.
			  `,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: ['adventure', 'city-building', 'combat', 'puzzle', 'racing', 'role-playing', 'sports'],
        width: 700,
        borderColor: '#f48f20',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 170px;'><hintBox id='0' ></hintBox></div>
			  <textarea id="0" rows="4">
        `,
        answer: [],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '2',
        title: 'Read the text. Does the game sound interesting to you? Why? / Why not ? ',
        color: '#92278f',
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page36/E2/1.jpg' },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page36/E3/key/answerKey.png',
    inputSize: 100,
    textAlign: 'center',
    textareaStyle: { width: 500 },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
				Find examples of <i>some</i> and <i>any</i> in the text in exercise 2. Then complete the rules in the <span style='color:rgb(244, 143, 32);'>Learn this!</span> box.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
            <div style='display: flex;'>
              <div>
                <div style='display: flex;'>
                  <div style='margin-top: 7px;'><b> some:  </b></div>
                  <div><textarea id="2" rows="1"></textarea></input></div>
                </div><div style='display: flex;'>
                  <div style='margin-top: 7px;'><b> any:  </b></div>
                  <div><textarea id="3" rows="2"></textarea></input></div>
                </div>
                <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); '>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b><i> some</i> and <i>any</i></b></div>
                    <div>We use <i>some</i> and <i>any</i> with uncountable and plural countable nouns.</div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div>We use # in affirmative sentences.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div>We use # in negative sentences and questions.</div>
                    </div>
                  </div>
                </div>
              </div>
            <img src='img/FriendsPlus/Page36/E3/1.jpg' alt='' style='width: 600px;'/>
            </div>
            

        `,
        answer: [
          'some',
          'any',
          'some important differences, some hobbies.',
          'any countries or continents, any coal or oil, any food, any special missions.',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page36/E4/key/answerKey.png',
    inputSize: 100,
    textAlign: 'center',
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
				Complete the sentences with <i>some</i> and <i>any</i>.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: ` 
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> This game doesn’t contain # violence at all.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>I spent # time choosing a good game for my sister.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>There are # well-known sports games, like FIFA series.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>Racing games often include # amazing special effects.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div> Twenty-five years ago, there weren’t # 3D graphics. </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>Do you play # role-playing games?</div>
        </div>

        `,
        answer: ['any', 'some', 'some', 'some', 'any', 'any'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page36/E5/key/answerKey.png',
    inputSize: 140,
    textAlign: 'center',
    textareaStyle: { width: 500 },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
				Look at the bold phrases and the nouns that follow them in the text in exercise 2. Then complete the rules in the <span style='color:rgb(244, 143, 32);'>Learn this!</span> box.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
            <div style='display: flex;'>
                <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); '>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b><i> not much, not many, a lot of, a little, a few</i></b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div>We use  # or # + uncountable noun for a small quantity of something.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div>We use  # or # + plural noun for a <u>small number</u> of something.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div> We use <i>much</i> + uncountable noun for a <u>large quantity</u> of something. </div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> d </b></div>
                      <div> We use <i>many</i> + plural noun for a <u>large number</u> of something.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> e </b></div>
                      <div>We use  # + uncountable or plural noun for a <u>large quantity or number</u> of something.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> f </b></div>
                      <div>We use  # … ? + uncountable noun or # … ? + plural noun for <u>questions about quantity or number</u>.</div>
                    </div>
                  </div>
                </div>
            <img src='img/FriendsPlus/Page36/E5/1.jpg' alt='' style='width: 600px;'/>
            </div>
            

        `,
        answer: ['not much', 'a little', 'not many', 'a few', 'a lot of', 'how much', 'how many'],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'SB10-U3-P36-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_34.mp3',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page36/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<headphone1 name='1.34' src=''></headphone1> Read the <span style='color:rgb(244, 143, 32);'>Look out!</span> box above and the dialogue below. Circle the correct words to complete the dialogue. Then listen and check.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
        },
        answers: [],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'many / some',
          'any / some',
          'many / much',
          'any / some',
          'a_few / many',
          'many / much',
          'a_few / a_little',
          'a_few / a_little',
          'any / many',
          'a_little / much',
          'a_lot_of / a_few',
          'some / any',
        ],
        answers: ['0-4', '1-0', '2-4', '3-4', '4-4', '5-4', '7-0', '6-0', '8-0', '9-0', '10-0', '11-4'],
        initialValue: [],
      },
      Layout: `
      <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); margin: 30px;'>
        <div style='margin:15px;'>
          <div><b style='color:rgb(244, 143, 32);'>LOOK OUT!</b></div>
          <div>In affirmative sentences, <i>much</i> and <i>many</i> often sound very formal. In everyday English, we use <i>a lot of</i>.</div>
        </div>
      </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>Look, there’s a building with<b> <sup>1</sup><input id='0' type='Circle' /></b> people outside. Turn left just before you get there. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Evie </b></div>
            <div>I can’t see<b> <sup>2</sup><input id='1' type='Circle' /></b>  people. There isn’t<b> <sup>3</sup><input id='2' type='Circle' /></b> light. Hey! What’s that noise? </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>Nothing. There was<b> <sup>4</sup><input id='3' type='Circle' /></b> rubbish in the road. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Evie </b></div>
            <div>I hate this part of town. There aren’t<b> <sup>5</sup><input id='4' type='Circle' /></b> nice areas. Let’s go north. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>How<b> <sup>6</sup><input id='5' type='Circle' /></b> fuel have we got? Only<b> <sup>7</sup><input id='6' type='Circle' /></b> litres. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Evie </b></div>
            <div>Oh no, listen. The police are chasing us now. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>Let me drive. I know<b> <sup>8</sup><input id='7' type='Circle' /></b> tricks. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Mum </b></div>
            <div>Jack, are you doing<b> <sup>9</sup><input id='8' type='Circle' /></b> homework in there? </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>Er … Yes. We’re doing <b><sup>10</sup><input id='9' type='Circle' /></b>  IT homework. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Evie </b></div>
            <div>Jack! That’s not true … </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Jack </b></div>
            <div>Well, we’re spending <b><sup>11</sup><input id='10' type='Circle' /></b>  time on the computer! </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 90px;'><b> Evie </b></div>
            <div>But we aren’t doing <b><sup>12</sup><input id='11' type='Circle' /></b>  work! </div>
          </div>

      `,
    },
  },
};

export default json;
