import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P99-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 800 },
		titleQuestion: [
			{
				num: '1',
				title: `
          Would you like to travel to another planet? Why? / Why not?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <textarea id="0" rows="10"></textarea>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P99-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E2/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 12,
		textareaStyle: { width: 400, fontSize: 20 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the text. 
					Do you think it is a good idea to colonise the moon and/or Mars? 
					Give reasons for your answer.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="position: relative;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page99/E2/1.jpg" style="max-width: 100%;" />
							</div>

							<div style="width: 488px; position: absolute; top: 197px; left: 23px; font-size: 15px;">
								<div>
									<strong>NASA</strong> is planning to send people back to the moon and Mars. This will be the Artemis program. 
									The idea of living on the moon clearly <span style="background-color: rgb(199, 160, 202);">appeals to</span> a lot of people. 
									Teams of scientists from several countries, including India, China and Israel, 
									are working hard to <span style="background-color: rgb(199, 160, 202);">take part in</span> the race. Films like Ad Astra, starring Brad Pitt, 
									show us people on scheduled flights to the moon showing their ID on arrival almost 
									as if they were arriving at an airport on Earth. Indeed, many people believe that people 
									will set up home on the moon soon. So, if that is true, how will the settlers adjust to the conditions 
									on the moon? How will they deal with problems like lack of oxygen, zero gravity and extreme temperatures? 
									Robotic rovers have already searched for water on the moon, but have found none in liquid form. 
									However, they have discovered some ice below the surface.
								</div>
								<div style="margin-top: 10px;">
									<strong>NASA</strong>'s plan is to work with private companies to establish a base on the moon around 2028. 
									Then, part of their job will be to prepare for the future exploration of Mars. 
									Will some of us have the opportunity to travel into space and maybe even settle on Mars? 
									Nobody knows for sure. But if we believe what we see in films like Passengers and Ad Astra, 
									future generations may enjoy a very different life!
								</div>
							</div>
						</div>

						<div style="margin-left: 20px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(227, 151, 101); padding: 10px;">
							<textarea id="0" rows="16"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P99-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E3/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 11,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					Look at the highlighted verb + preposition collocations in the text. 
					Find five more verbs (with <i>for</i> (x2), <i>to</i>, <i>with</i>, and <i>at</i>).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="position: relative;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page99/E2/1.jpg" style="max-width: 100%;" />
							</div>

							<div style="width: 488px; position: absolute; top: 197px; left: 23px; font-size: 15px;">
								<div>
									<strong>NASA</strong> is planning to send people back to the moon and Mars. This will be the Artemis program. 
									The idea of living on the moon clearly <span style="background-color: rgb(199, 160, 202);">appeals to</span> a lot of people. 
									Teams of scientists from several countries, including India, China and Israel, 
									are working hard to <span style="background-color: rgb(199, 160, 202);">take part in</span> the race. Films like Ad Astra, starring Brad Pitt, 
									show us people on scheduled flights to the moon showing their ID on arrival almost 
									as if they were arriving at an airport on Earth. Indeed, many people believe that people 
									will set up home on the moon soon. So, if that is true, how will the settlers adjust to the conditions 
									on the moon? How will they deal with problems like lack of oxygen, zero gravity and extreme temperatures? 
									Robotic rovers have already searched for water on the moon, but have found none in liquid form. 
									However, they have discovered some ice below the surface.
								</div>
								<div style="margin-top: 10px;">
									<strong>NASA</strong>'s plan is to work with private companies to establish a base on the moon around 2028. 
									Then, part of their job will be to prepare for the future exploration of Mars. 
									Will some of us have the opportunity to travel into space and maybe even settle on Mars? 
									Nobody knows for sure. But if we believe what we see in films like Passengers and Ad Astra, 
									future generations may enjoy a very different life!
								</div>
							</div>
						</div>

						<div style="margin-left: 40px; border-radius: 10px; border: 1px solid rgb(227, 151, 101); padding: 20px;">
							<i>Answer:</i><br/>
							<div style="display: flex;">
								<div style="font-weight: bold; margin-right: 10px;">
									for<br/>
									to<br/>
									with<br/>
									at
								</div>
								<div>
									#, # <br/>
									# <br/>
									# <br/>
									# <br/>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['search for', 'prepare for', 'adjust to', 'deal with', 'arrive at'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P99-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E4/Key/answerKey.png',
		component: T6,
		inputSize: 70,
		maxLength: 4,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					<span style="color: rgb(146, 39, 144)">DICTIONARY WORK</span> 
					Read the <span style="color: rgb(146, 39, 144)">Dictionary Skills Strategy</span>.  
					Then look at the dictionary entry for <i>recover</i>. 
					Which preposition is used with it?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="font-size: 22px;">
						<i>Answer: </i>#
					</div>

					<div style="margin-top: 20px; display: flex;">
						<div style="width: 400px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(146, 39, 144); background-color: rgb(239, 228, 240); box-shadow: 3px 3px 5px rgba(0,0,0,0.4); font-size: 22px;">
							<span style="font-weight: bold; color: rgb(146, 39, 144);">Dictionary Skills Strategy</span>
							<div>A dictionary will tell you if the verb is followed by a particular preposition.</div>
						</div>

						<div style="margin-left: 40px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page99/E4/1.jpg" style="max-width: 100%;" />
						</div>
					</div>
				`,
				answer: ['from'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P99-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E5/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 10,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          Complete the sentences with the words below. 
					Use a dictionary to check your answers if you need to.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(146, 39, 144);">
						<span>about</span>
						<span style="margin-left: 43px;">apologised</span>
						<span style="margin-left: 43px;">asked</span>
						<span style="margin-left: 43px;">laugh</span>
						<span style="margin-left: 43px;">of</span>
						<span style="margin-left: 43px;">with</span>
					</div>

					<div style="margin-top: 20px; padding: 25px; border-radius: 10px; border: 1px solid rgb(146, 39, 144);">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>I # for my late arrival.</span>				
						</div>
						<div style="margin-top: 7px; display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Dad complained # the noise from the neighbours.</span>
						</div>
						<div style="margin-top: 7px; display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>I’ve often dreamed # flying to the moon.</span>
						</div>
						<div style="margin-top: 7px; display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>We finished our meal and # for the bill.</span>
						</div>
						<div style="margin-top: 7px; display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>Don’t # at your little sister. It isn’t kind.</span>
						</div>
						<div style="margin-top: 7px; display: flex;">
							<strong style="padding-right: 10px;">6</strong>
							<span>Can you help me # my science homework?</span>
						</div>
					</div>
				`,
				answer: ['apologised', 'about', 'of', 'asked', 'laugh', 'with'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P99-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E6/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '6',
				title: `
					Read the <span style="color: rgb(138, 40, 144);">Look out!</span> box. 
					Circle the correct prepositions to complete the sentences.
				`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: [
					'of / about',
					'of / about',
					'for / about',
					'for / about',
					'about / of',
					'about / of',
					'to / about',
					'to / about',
					'with / to',
					'with / to',
				],
				answers: ['0-0', '1-4', '2-0', '3-4', '4-0', '5-4', '6-0', '7-4', '8-4', '9-0'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex;">
					<div style="display: flex; width: 430px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(138, 40, 144); background-image: radial-gradient(rgb(250 244 244), rgb(225, 206, 228)); font-size: 20px;">
							<div style="margin-right: 20px; margin-top: 20px; font-weight: bold; font-size: 50px; color: rgb(138, 40, 144);">!</div>
							<div>
								<span style="color: rgb(138, 40, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LOOK OUT!</span>
								<div>
									<div>
										Some verbs can be followed by more than one preposition, 
										sometimes with a slight change in meaning, 
										e.g. <i>shout at / shout to, agree with / agree to</i>.
									</div>
									<div style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">
										My sister <u>shouted at</u> me because I lost her mobile. <br/>
										I saw Liz on the other side of the road and <u>shouted to</u> her.
									</div>
								</div>
							</div>
						</div>

						<div style="margin-left: 40px; padding: 10px; border-radius: 10px; border: 1px solid rgb(226, 153, 100); font-size: 20px;">
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">1</span>
								<div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">a</span>
										<span>Have you ever heard <input id='0' type='Circle' /> wearable gadgets?</span>
									</div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">b</span>
										<span>Have you heard <input id='1' type='Circle' /> John’s new job?</span>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">2</span>
								<div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">a</span>
										<span>She has to care <input id='2' type='Circle' /> her elderly mum.</span>
									</div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">b</span>
										<span>I don’t care <input id='3' type='Circle' /> money</span>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">3</span>
								<div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">a</span>
										<span>You look worried. What are you thinking <input id='4' type='Circle' />?</span>
									</div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">b</span>
										<span>What do you think <input id='5' type='Circle' /> my new dress?</span>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">4</span>
								<div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">a</span>
										<span>I write <input id='6' type='Circle' /> my penfriend about once a month.</span>
									</div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">b</span>
										<span>I wrote <input id='7' type='Circle' /> my holiday on my blog.</span>
									</div>
								</div>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">5</span>
								<div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">a</span>
										<span>My teacher agreed <input id='8' type='Circle' /> my request to leave early.</span>
									</div>
									<div>
										<span style="padding-right: 10px; font-weight: bold;">b</span>
										<span>I don’t agree <input id='9' type='Circle' /> you.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
      `,
		},
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P99-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page99/E7/Key/answerKey.png',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '7',
				title: `
					Complete the questions with the correct preposition.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">1</span>
							<span>What kinds of things do you worry # ?</span>				
						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">2</span>
							<span>When did you last argue # someone? Who? What # ?</span>				
						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">3</span>
							<span>When did you last take part # a race? How did you do?</span>				

						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">4</span>
							<span>If you were going to write a letter # someone famous, who would it be? Why?</span>				
						</div>
					</div>
				`,
				answer: ['about', 'with', 'about', 'in', 'to'],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P99-E8',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Ask and answer the questions in exercise 7. 
					Give extra details if you can.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">1</span>
							<span>What kinds of things do you worry _____ ?</span>				
						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">2</span>
							<span>When did you last argue _____ someone? Who? What _____ ?</span>				
						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">3</span>
							<span>When did you last take part _____ a race? How did you do?</span>				

						</div>
						<div style="margin-top: 7px; display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">4</span>
							<span>If you were going to write a letter _____ someone famous, who would it be? Why?</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
}

export default json
