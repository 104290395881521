import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P57-E1',
    exerciseKey: '/img/FriendsPlus/Page57/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: 'Reading',
        title: `
           <br>
          <div style='margin-left: -90px;'> Read the following passage. Are the sentences true or false? Write T or F.</div>
					
        `,
        color: '#28829d',
      },
    ],

    questions: [
      {
        title: `
      <div style="border: 1px solid white; background-color:  rgb(227, 243, 243); width: 900px; padding: 25px;">
        <b>Hurricane in Jamaica</b>
        <div>My aunt and uncle have lived in Jamaica all their lives 
so they know about hurricanes. I, however, was born 
in Britain because my parents came here when they 
got married. They always tell wonderful stories about 
Jamaica, and we love going to visit our aunt and uncle. 
Last year was the first time I went on my own, which 
meant the visit was even more exciting. </div>
        <div>I went in December because between October and 
mid-December the weather isn’t as hot, but it’s still 
lovely. However, it’s also the hurricane season during 
those months. Luckily, my aunt and uncle knew a 
hurricane was coming and, therefore, there was already 
lots of extra food and drink in the house. </div>
        <div>As soon as we saw the first signs of the hurricane, we 
closed the windows and the doors, and then we waited. 
The storm was a Category 1 hurricane, with heavy rain 
and winds of about 125 km/h. I played games and 
chatted with my cousins to pass the time, and we ate 
lots of food. But I felt quite scared with the sound of 
the wind crashing around the house, day and night. My 
aunt told me there was nothing to worry about and that 
they’d had worse storms than that, so I felt a bit better. 
We heard radio reports of flooding on the roads and on 
farms, and some damage to buildings. The storm began 
on Monday, and then luckily by Wednesday it was moving 
out to sea.</div>
      </div>
						<div style="margin-top: 10px;line-height: 50px;">
						<b>1</b> <span>The writer used to live in Jamaica.</span> <span style="margin-left:10px">#</span><br>
						<b>2</b> <span>Her parents are always positive about their home country.</span> <span style="margin-left:10px">#</span><br>
						<b>3</b> <span>The writer was worried about going to Jamaica this time.</span> <span style="margin-left:10px">#</span><br>
						<b>4</b> <span>Most hurricanes in Jamaica take place at the end of the year.</span> <span style="margin-left:10px">#</span><br>
						<b>5</b> <span>The storm surprised the writer and her family.</span> <span style="margin-left:10px">#</span><br>
						<b>6</b> <span>The writer was afraid for the whole time.</span> <span style="margin-left:10px">#</span><br>
						<b>7</b> <span>The rain flooded their house.</span> <span style="margin-left:10px">#</span><br>
						<b>8</b> <span>The hurricane lasted for less than a week.</span> <span style="margin-left:10px">#</span>
					</div>
        `,
        answer: ['F', 'T', 'F', 'T', 'F', 'F', 'F', 'T'],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P57-E2',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_12.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: 'Listening',
        title: `
          <br>
          <span style='margin-left: -90px;'> <audioimage name="2.12"></audioimage> Listen to the text. Circle the correct
            option (a–c).</span>
        `,
        color: 'rgb(40,130,157)',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page57/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['a<br> b<br> c', 'a<br> b<br> c', 'a<br> b<br> c', 'a<br> b<br> c', 'a<br> b<br> c'],
        answers: ['1-2', '2-2', '4-2', '3-4', '0-4'],

        initialValue: [],
      },
      Layout: `
        <div>
            <div><b>1</b> When the writer was a child, her parents</div>
            <div style="margin-left: 15px; display: flex;">
              <div><b><input type="Circle" id="0" /></b> </div>
              <div style="margin-left: 10px;">
                taught her the names of all the
                stars. <br />
                bought her books about space.<br />
                spent time listening to her talk about the planets.
              </div>
            </div>
        </div>
        <div>
        <div style="margin-top:20px">
          <div><b>2</b> What is true about the writer at school?</div>
          <div style="margin-left: 15px; display: flex">
            <div >
              <b><input type="Circle" id="1" /></b>
            </div>
            <div style="margin-left: 10px">
              She liked science best. <br />
              She imagined being a musician one day.<br />
              She was talented at maths.
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
          <div><b>3</b> When did she decide to be a space scientist?</div>
          <div style="margin-left: 15px; display: flex">
            <div >
              <b><input type="Circle" id="2" /></b>
            </div>
            <div style="margin-left: 10px">
              After a lesson at school about meteoroids<br />
              After she saw a shooting star<br />
              After she watched a ·lm about space travel
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
        <div><b>4</b> What did she do when she first finished her education?</div>
        <div style="margin-left: 15px; display: flex">
          <div >
            <b><input type="Circle" id="3" /></b>
          </div>
          <div style="margin-left: 10px">
            She worked abroad.<br />
            She went travelling.<br />
            She stayed in Britain
          </div>
        </div>
      </div>
      <div style="margin-top:20px">
        <div><b>5</b> What is her advice to people who want to be space scientists?</div>
        <div style="margin-left: 15px; display: flex">
          <div >
            <b><input type="Circle" id="4" /></b>
          </div>
          <div style="margin-left: 10px">
            Work hard at university<br />
            Get a good science degree<br />
            Study di¸erent subjects
          </div>
        </div>
      </div>
      `,
    },
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P57-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: 'Speaking',
        title: `
          <br>
          <div style='margin-left: -90px;'> Work in pairs. Compare and contrast the twophotos. Use the following phrases to talk aboutsimilarities, differences and speculation.
          </div>
          
        `,
        color: 'rgb(40,130,157)',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="display: flex">
        <div style="margin-top: 15px">
              <b>1</b> <span>The common theme in the photos is …</span><br>
              <b>2</b> <span>It must be ...</span><br>
              <b>3</b> <span>Both photos show …</span><br>
              <b>4</b> <span>One obvious difference is …</span><br>
              <b>5</b> <span>Another difference is ...</span><br>
              <b>6</b> <span>There may be ...</span><br>
            </div>
            <div style="margin-top: 15px">
              <img src="/img/FriendsPlus/Page57/E3/E3.png" alt="">
            </div>
        </div>    
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P57-E4',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 950 },
    titleQuestion: [
      {
        num: 'Writing',
        title: `
            <br>
          <div style='margin-left: -90px;'> 
              Write an article (120– 150 words) expressing your
              opinion on the following statement.<br>
              <i>"Climate change cannot be reversed at all."</i>
            </div>
					
        `,
        color: '#28829d',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
					<div>
            <textarea rows="10"></textarea>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
