import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E1/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: `Match pictures 1–5 with five of the places below.`,
				color: 'black',
			},
		],
		inputSize: 250,
		maxLength: 21,
		isHiddenCheck: true,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>canteen</span><span>classroom</span><span>corridor</span><span>gym</span><span>hall</span>
            <span>library</span><span>head teacher's office</span><span>language lab</span><span>playground</span><span>playing field</span>
            <span>reception</span><span>science lab</span><span>staff room</span><span>stairs</span><span>store room</span><span>toilets</span>           
          </div>

          <div style="margin-top: 20px; display: flex; gap: 30px; align-items: start;">
            <div><img src="img/FriendsPlus/Page127/E1/1.jpg" style="max-width: 100%;" /></div>
            <div style="padding: 10px; border-radius: 10px 20px; border: 1px solid rgb(146, 39, 144);">
              <span style="font-weight: bold; font-style: italic;">Answer:</span><br />
              Picture 1: #<br />
              Picture 2: #<br />
              Picture 3: #<br />
              Picture 4: #<br />
              Picture 5: #<br />
            </div>
          </div>
				`,
				answer: [],
				// answer: ['hall', "head teacher's office", 'stairs', 'playground', 'science lab'],
			},
		],
	},
	2: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E2/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: `Choose six of the places in exercise 1 and say where they 
          are in your school. Use the examples below to help you.`,
				color: 'black',
			},
		],
		inputSize: 190,
		maxLength: 21,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(146, 39, 144);">
            <div style="font-weight: bold; font-style: italic;">Places:</div>
            <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>canteen</span><span>classroom</span><span>corridor</span><span>gym</span><span>hall</span>
              <span>library</span><span>head teacher's office</span><span>language lab</span><span>playground</span><span>playing field</span>
              <span>reception</span><span>science lab</span><span>staff room</span><span>stairs</span><span>store room</span><span>toilets</span>           
            </div>
          </div>

          <div style="width: fit-content; margin-top: 20px; padding: 10px; border-radius: 10px; border: 1px solid rgb(10, 111, 143); color: rgb(10, 111, 143);">
            <div style="font-weight: bold; font-style: italic; color: black;">Examples:</div>
            <div>The staff room is on the first floor, next to the language lab.</div>
            <div>The gym is at the end of the corridor on the ground floor.</div>
            <div>There’s a store room near the stairs opposite reception.</div>
          </div>

          <div style="margin-top: 20px;"><textarea id="0" rows="6"></textarea></div>
        `,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E3/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: `
          Complete the sentences with the prepositions below. 
          You need to use some prepositions more than once.`,
				color: 'black',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
		},
		inputSize: 70,
		maxLength: 4,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="padding-left: 25px; font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>for</span><span>from</span><span>in</span><span>off</span><span>on</span><span>to</span>
          </div>

          <div style="margin-top: 10px;">
            <div style="display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">1</div>
              <div>How much did you spend # those trainers?</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">2</div>
              <div>I’m saving up # some new clothes.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">3</div>
              <div>You shouldn’t lend money # friends. It can cause arguments!</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">4</div>
              <div>Don’t worry. My mum is paying # everything.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">5</div>
              <div>I don’t want to borrow money because I don’t like being # debt.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">6</div>
              <div>Use this coupon to get £1 # your next sandwich.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">7</div>
              <div>How much do they charge # a haircut?</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">8</div>
              <div>I borrowed some money # my brother</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">9</div>
              <div>Don’t waste money # a new phone. Your old one is fine.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">10</div>
              <div>The company owes thousands of pounds # the bank.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 35px; font-weight: bold; text-align: right;">11</div>
              <div>They’ll replace the battery in your phone # £35.</div>
            </div>	
          </div>
        `,
				answer: ['from'],
			},
		],
	},
	4: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E4/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          In pairs, talk about...
        `,
				color: 'black',
			},
		],
		inputSize: 190,
		maxLength: 21,
		questions: [
			{
				title: `
          <ul>
            <li>what you spend your money on.</li>
            <li>something you would like to save up for.</li>
            <li>the best person to borrow money from.</li>
          <ul>
        `,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E5/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: `
          Put the words below in the correct groups, A, B or C.
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
		},
		inputSize: 220,
		maxLength: 18,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>boat trip</span><span style="text-decoration: line-through;">bus fare</span><span>day trip</span><span>excursion</span><span>fish and chip shop</span>
            <span>food van</span><span style="text-decoration: line-through;">open-top bus tour</span><span>restaurant</span><span>route</span><span>sandwich bar</span>
            <span style="text-decoration: line-through;">street café</span><span>tea room</span><span>timetable</span><span>travel pass</span><span>travel zone</span><span>walking tour</span>
          </div>

          <div style="margin-top: 20px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">A</div>
              <div>Places to eat: <span style="color: rgb(10, 111, 143);">street café</span>, #, #, #</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">B</div>
              <div>Organised sightseeing: <span style="color: rgb(10, 111, 143);">open-top bus tour</span>, #, #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">C</div>
              <div>Getting around: <span style="color: rgb(10, 111, 143);">bus fare</span>, #, #, #</div>
            </div>	
          </div>
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E6/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: `
          Complete the sentences with words from exercise 1.
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			// fontSize: 18,
			// height: 24,
			// borderBottom: "none",
			// backgroundColor: 'transparent',
		},
		inputSize: 220,
		maxLength: 18,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>boat trip</span><span>bus fare</span><span>day trip</span><span>excursion</span><span>fish and chip shop</span>
            <span>food van</span><span>open-top bus tour</span><span>restaurant</span><span>route</span><span>sandwich bar</span>
            <span>street café</span><span>tea room</span><span>timetable</span><span>travel pass</span><span>travel zone</span><span>walking tour</span>
          </div>

          <div style="margin-top: 10px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">1</div>
              <div>The # is valid for one day on all buses and underground trains.</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">2</div>
              <div>Can I have a # with bus and tram times, please?</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">3</div>
              <div>You can get takeaway burgers at the # in the main square. But they close and drive away at about 11p.m.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">4</div>
              <div>You get the best views of the city if you take the #. It leaves every 30 minutes from the bus station.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">5</div>
              <div>You must have the correct # when you get on. The driver won’t give you change.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">6</div>
              <div>This ticket is only valid in two of the nine # in London.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">7</div>
              <div>If you want traditional British food, try the # on Queen Street.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">8</div>
              <div>Make sure you’re wearing comfortable shoes if you’re planning to go on a # of the city.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">9</div>
              <div>I recommend taking a # on the river.</div>
            </div>	
          </div>
        `,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E7/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: `
          Complete the phrases with the verbs below.
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
		},
		inputSize: 70,
		maxLength: 5,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>eat</span><span>buy</span><span>go</span><span>go</span>
            <span>hire</span><span>lie</span><span>play</span><span>visit</span>
          </div>

          <div style="margin-top: 20px; padding-left: 20px; border-left: 5px solid rgb(146, 39, 144);">
            <div style="color: rgb(146, 39, 144); font-weight: bold;">Holiday activities</div>
            <div style="display: flex; gap: 20px;">
              <div><sup>1</sup>#</div>
              <div>a museum / a castle / a cathedral / a theme park / a water park</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>2</sup>#</div>
              <div>shopping / swimming / cycling / surfing / kayaking / abseiling / mountain biking</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>3</sup>#</div>
              <div>for a walk / for a bike ride / on an excursion / on a boat trip / up a tower</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>4</sup>#</div>
              <div>a bike / a kayak / a car / a boat</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>5</sup>#</div>
              <div>table tennis / beach volleyball / cards / board games</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>6</sup>#</div>
              <div>on the beach</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>7</sup>#</div>
              <div>out</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>8</sup>#</div>
              <div>souvenirs</div>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	// todo
	8: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P127-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page127/E8/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Ask and answer about the activities in exercise 3. 
          Find three you both like and three neither of you like.
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			// fontSize: 18,
			// height: 24,
			// borderBottom: "none",
			// backgroundColor: 'transparent',
		},
		inputSize: 190,
		maxLength: 21,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hintBox: [
			{
				src: ['Do you like visiting museums?'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 2,
				width: 380,
			},
			{
				src: ['No, I don’t. Do you?'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 1,
				width: 280,
			},
		],
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>eat</span><span>buy</span><span>go</span><span>go</span>
            <span>hire</span><span>lie</span><span>play</span><span>visit</span>
          </div>

          <div style="margin-top: 20px; padding-left: 20px; border-left: 5px solid rgb(146, 39, 144);">
            <div style="color: rgb(146, 39, 144); font-weight: bold;">Holiday activities</div>
            <div style="display: flex; gap: 20px;">
              <div><sup>1</sup>_____</div>
              <div>a museum / a castle / a cathedral / a theme park / a water park</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>2</sup>_____</div>
              <div>shopping / swimming / cycling / surfing / kayaking / abseiling / mountain biking</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>3</sup>_____</div>
              <div>for a walk / for a bike ride / on an excursion / on a boat trip / up a tower</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>4</sup>_____</div>
              <div>a bike / a kayak / a car / a boat</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>5</sup>_____</div>
              <div>table tennis / beach volleyball / cards / board games</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>6</sup>_____</div>
              <div>on the beach</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>7</sup>_____</div>
              <div>out</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div><sup>8</sup>_____</div>
              <div>souvenirs</div>
            </div>
          </div>

          <div style="margin-top: 30px; display: flex; gap: 50px;">
            <hintbox id='0'></hintbox>
            <hintbox id='1'></hintbox>
          </div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
