import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E1',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page32/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          '<span style="color:rgb(10 127 64)">SPEAKING</span> Work in pairs. Discuss and match the three famous women <br/>with their great achievements.',
        color: '#8e258c',
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '32px',
              left: '135px',
              width: '255px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '177px',
              left: '135px',
              width: '255px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '106px',
              left: '135px',
              width: '255px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '32px',
              left: '505px',
              width: '380px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '106px',
              left: '505px',
              width: '380px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '177px',
              left: '505px',
              width: '380px',
              height: '65px',
              // borderRadius: '50%',
              // border: '1px solid #F58024',
              // background: 'white',
            },
          }, // 6
        ],
        answers: ['0-4', '2-5', '1-3'],
        initialValue: [],
      },
      Layout: `
        
          <img src='img/FriendsPlus/Page32/E1/1.jpg'>
          <div style='margin-left: 50px;'>
            <input id='0' type= 'boxMatch' />
            <input id='1' type= 'boxMatch' />
            <input id='2' type= 'boxMatch' />
            <input id='3' type= 'boxMatch' />
            <input id='4' type= 'boxMatch' />
            <input id='5' type= 'boxMatch' />
          </div>
          
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_29.mp3',
    video: '',
    component: Circle_Write,
    inputSize: 200,
    // titleImage: 'img/FriendsPlus/Page32/E2/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: '<headphone1 name="1.29"></headphone1> Look at the calendar and write.',
        color: '#8e258c',
        prefix: { icon: [], text: '' },
      },
    ],
    questionImage: [],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['in / on', 'few / little', 'go / set', 'rough / hard', 'offshore / ashore'],
        answers: ['0-4', '1-0', '2-4', '3-0', '4-4'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page32/E2/1.jpg' />
          <div>
             Margaret Ann Longstaffe was born <sup>1</sup><input id='0' type='Circle' />  5 June 1914 in 
             Surrey, England. As a child, she really loved adventures. When she grew up, she travelled a lot. 
             She liked riding horses, driving fast cars and flying aeroplanes. Ann 
             became one of the very <sup>2</sup><input id='1' type='Circle' /> women pilots in
             Britain in the 1930s.
          </div>
          <div style='margin-top:10px'>
             In 1947 Ann and her husband, Frank Davison, also a pilot, 
             bought an old boat “Reliance” and decided to <sup>3</sup><input id='2' type='Circle' /> sail
             to travel the world. However, they were caught in <sup>4</sup><input id='3' type='Circle' /> 
             weather in the Irish Sea. The ship was wrecked and Frank was killed. Ann survived, washed 
             <sup>5</sup><input id='4' type='Circle' />  at the foot of a cliff.
          </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E3',
    audio: '',
    video: '',
    component: T6,

    maxLength: 9,
    stylesTextInput: { borderBottom: 'dash' },
    textareaStyle: { width: 400 },
    inputSize: 150,
    // isHiddenCheck: true,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page32/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Which of the following words can replace the ones in bold in the second part of the text?',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex; line-height:50px'>
           <img src='img/FriendsPlus/Page32/E3/1.jpg'  />  
           <div style='margin:70px 10px'>
             left -#<br>alone -#<br>sad event -#<br>courage -#<br>very bad -#<br>
           </div>
        </div>
          


            
          `,
        answer: ['departed', 'solo', 'tragedy', 'nerve', 'severe'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E4',
    audio: '',
    video: '',
    component: T6,

    maxLength: 1,
    stylesTextInput: { borderBottom: 'dash' },
    textareaStyle: { width: 400 },
    inputSize: 50,
    // isHiddenCheck: true,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page32/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: 'Rearrange the following events of Ann Davison’s life <br/>in the order they happened.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
           #She started to write.<br>
           #She bought “Reliance”.<br>
           #Her boat arrived in Dominica.<br>
           #She learned how to fly a plane.<br>
           #She luckily survived a shipwreck.<br>
           #She bought “Felicity Ann”.<br>
           #She left Plymouth. 
        </div>
          


            
          `,
        answer: ['4', '2', '7', '1', '3', '5', '6'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    inputSize: 200,
    // titleImage: 'img/FriendsPlus/Page32/E2/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page32/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: 'Read the whole text again. Are the sentences True or False? Write T or F. ',
        color: '#8e258c',
        prefix: { icon: [], text: '' },
      },
    ],
    questionImage: [],
    question: {
      Write: {
        inputStyle: { width: 50, textAlign: 'center' },
        answers: ['T', 'F', 'F', 'T', 'T', 'F'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          padding: 3,
          border: 'none',
        },
        limitSelect: 1,
        listWords: ['in / on', 'few / little', 'go / set', 'rough / hard', 'offshore / ashore'],
        answers: [],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page32/E2/1.jpg' />
          <div>
             Margaret Ann Longstaffe was born <sup>1</sup><input id='0' type='Circle' />  5 June 1914 in 
             Surrey, England. As a child, she really loved adventures. When she grew up, she travelled a lot. 
             She liked riding horses, driving fast cars and flying aeroplanes. Ann 
             became one of the very <sup>2</sup><input id='1' type='Circle' /> women pilots in
             Britain in the 1930s.
          </div>
          <div style='margin:10px 0px'>
             In 1947 Ann and her husband, Frank Davison, also a pilot, 
             bought an old boat “Reliance” and decided to <sup>3</sup><input id='2' type='Circle' /> sail
             to travel the world. However, they were caught in <sup>4</sup><input id='3' type='Circle' /> 
             weather in the Irish Sea. The ship was wrecked and Frank was killed. Ann survived, washed 
             <sup>5</sup><input id='4' type='Circle' />  at the foot of a cliff.
          </div>
          <div style='line-height:50px' >
            <b>1</b> Ann always loved adventures.<input id='0'><br>
            <b>2</b> There were quite a few women pilots in Britain in the 1930s.<input id='1'><br>
            <b>3</b> “Reliance” was caught in a tsunami and was wrecked.<input id='2'><br>
            <b>4</b> It took Ann 2 years to prepare “Felicity Ann”.<input id='3'><br>
            <b>5</b> Ann was not experienced at sailing, but she was brave.<input id='4'><br>
            <b>6</b> Ann hardly felt lonely when she was sailing across
            the Atlantic.<input id='5'><br> 
          </div>
      `,
    },
  },
  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P32-E6',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '6',
        title:
          '<span style="color:rgb(10 127 64)">SPEAKING</span> Work in groups. Talk about Sally Ride, using the following prompts.',
        color: '#8e258c',
      },
    ],
    questionImage: [
      // Row

      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E6/1.jpg' },
      ],
    ],
  },
};

export default json;
