import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 49,
		titleQuestion: [
			{
				num: '1',
				title: `
          Read the article. What job is Harris applying for?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px;">
						<img src="img/FriendsPlus/Page62/E1/1.jpg" style="max-width: 100%;" />
						<div style="margin-top: 40px;">
							<i>Answer:</i> #
						</div>
					</div>
        `,
				answer: ['He is applying for the job of CEO of Nisa Retail.'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
		component: DrawColor,
		titleQuestion: [
			{
				num: '2',
				title: `
					Underline these relative pronouns in the article in exercise 1: <i>which, who, whose, where.</i> 
					Which do we use for a) people, b) things and animals, c) places and d) indicating possession?
				`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 100,
					textAlign: 'center',
					fontSize: 22,
					color: 'black',
					maxLength: 5,
				},
				answers: ['who', 'which', 'where', 'whose'],
				initialValue: [],
			},
			Circle: {
				listStyle: {
					normal: { padding: 2 },
					// circle: { padding: 0.75, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
					// square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
					// choose: { fontWeight: 900 },
					underline: { borderBottom: '2px solid #000' },
				},
				// limitSelect: 1,
				listWords: [
					'man who left', //0
					'town where he', //1
					'business whose annual', //2
					'job which usually', //3
				],
				answers: { '0-2': 'underline', '1-2': 'underline', '2-2': 'underline', '3-2': 'underline' },
				initialValue: {},
			},
			Layout: `
        <div style="width: 800px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
					<div style="display: flex; padding: 10px; background-color: rgb(221, 235, 210); border-radius: 30px;">
						<div style="display: flex; justify-content: center; align-items: center; flex: 1; margin-left: -20px">
							<img src="img/FriendsPlus/Page62/E2/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-left: 20px; flex: 3; font-size: 20px;">
							Harris Aslam is an ambitious young <input id='0' type='Circle' /> school at the age of thirteen to work in his family’s grocery business. 
							Now, at the age of eighteen, he owns three shops in Kirkcaldy, Scotland, the <input id='1' type='Circle' /> was born and brought up. 
							But Harris has bigger ambitions and wants a new challenge. The job he is now applying for is CEO of Nisa Retail, 
							a grocery <input id='2' type='Circle' /> sales are about £1.6 billion! 
							This is a <input id='3' type='Circle' /> attracts middle-aged business people, not eighteen-year-olds.
						</div>
					</div>
				</div>

				<div style="margin-top: 20px; font-size: 22px;">
					<i>Which do we use for:</i><br/>
					<strong>a) people</strong> <input id='0' /><br/>
					<strong>b) things and animals</strong> <input id='1' /><br/>
					<strong>c) places</strong> <input id='2' /><br/>
					<strong>d) indicating possession</strong> <input id='3' /><br/>
				</div>
      `,
		},
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E3/Key/answerKey.png',
		component: T6,
		inputSize: 720,
		maxLength: 71,
		hideBtnFooter: true,
		stylesTextInput: {
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
					Read the <span style="color: rgb(246, 141, 31);">Learn this!</span> box. 
					Can you find examples of all three rules in the article in exercise 1?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="width: 450px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
							<div>
								<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
								<strong>Defining relative clauses</song>
							</div>
							<div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">a</strong>
									<span>
										A defining relative clause tells us which person, thing, 
										or place we are talking about. <br/>
										<span style="color: rgb(75, 142, 168);">He’s the police officer who arrested the man. 
										The police officer who arrested the man is over there.</span>
									</span>			
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">b</strong>
									<span>
										In informal style, we often use <i>that</i> instead of <i>who</i> and <i>which</i>. <br/> 
										<span style="color: rgb(75, 142, 168);">The boy that you saw is my brother.</span>
									</span>			
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">c</strong>
									<span>
										In formal style, we can use <i>whom</i> instead of <i>who</i> when the pronoun is the object of 
										the clause or follows a preposition.<br/> 
										<span style="color: rgb(75, 142, 168);">The nurse whom the police questioned has moved abroad. 
										Is this the man to whom you are referring?</span>
									</span>			
								</div>
							</div>
						</div>

						<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page62/E1/1.jpg" style="max-width: 100%;" />
						</div>
					</div>

					<div style="margin-top: 10px;">
						<i>Answer:</i> <br/> 
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">rule a</span>
							<span># <br> # <br> # <br> </span>
						</div>
					</div>
        `,
				answer: [
					'Harris Aslam is an ambitious young man who left school',
					'This is a job which usually attracts middle-aged business people,',
					'And indeed, Nisa Retail is looking for someone who is very experienced,',
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E4/Key/answerKey.png',
		component: T6,
		inputSize: 90,
		maxLength: 5,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: ` 
					Complete the text with <i>who, which, where and whose.</i>
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 800px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="display: flex; padding: 10px; background-color: rgb(241, 237, 217); box-shadow: 5px 5px 10px rgba(0,0,0,0.4)">
							<div style="display: flex; justify-content: center; align-items: center; flex: 1;">
								<img src="img/FriendsPlus/Page62/E4/1.jpg" style="max-width: 100%;" />
							</div>

							<div style="margin-left: 20px; flex: 2">
								This is Katie Stagliano, a girl <sup>1</sup># idea to grow vegetables for the homeless 
								has improved the lives of thousands of people. 
								In 2008, she planted a seed <sup>2</sup># grew into a 20-kg cabbage. 
								She donated it to a soup kitchen <sup>3</sup># it fed more than 275 
								people <sup>4</sup># were living on the streets.
							</div>
						</div>

						<div style="margin-top: 30px; width: 500px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
							<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;"><span style="font-weight: bold; padding-right: 20px;">Grammar Builder 5D</span> Exercise 6, 7: page 116</span>
						</div>
					</div>
        `,
				answer: ['whose', 'which', 'where', 'who'],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E5/Key/answerKey.png',
		component: T6,
		inputSize: 970,
		maxLength: 92,
		titleQuestion: [
			{
				num: '5',
				title: `
					<span style="color: rgb(0, 147, 69); font-weight: bold;">SPEAKING</span> 
					Work in pairs. Take turns to define these jobs and places of work. 
					Use defining relative clauses.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 300px; display: flex; justify-content: space-between;">
						<div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>a doctor</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>an actor</span>				
							</div>
						</div>
						<div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>a school</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>your ideas</span>				
							</div>
						</div>
					</div>

					<div style="margin-top: 20px;">
						<i>Answer:</i> <br/>
						<div style="padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>#</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>#</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>#</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>#</span>				
							</div>
						</div>
					</div>
        `,
				answer: [
					'A doctor is a person who has been trained in medicine and who treats people who are ill.',
					'An actor is a person who acts in a play, film or on TV.',
					'A school is a place where children go to be educated.',
					'Your ideas are your plans or suggestions that you are going to do in a particular situation.',
				],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E6/Key/answerKey.png',
		component: T6,
		inputSize: 800,
		maxLength: 209,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					Read the article. Do you think this interview was fair? 
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page62/E6/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-top: 20px; width: 500px; background-color: rgb(253, 249, 231); padding: 10px; border-radius: 10px; border: 3px solid rgb(138, 139, 142); box-shadow: 10px 10px rgb(222, 222, 222); font-size: 21px;">
							Alan Bacon, <span style="background-color: rgb(252, 194, 135);">who left university in July,</span> was invited to an interview with a major retailer.
							He prepared thoroughly for the interview, <span style="background-color: rgb(252, 194, 135);">which was for a role in the marketing department.</span>
							But Alan, <span style="background-color: rgb(252, 194, 135);">whose degree is in film and TV studies,</span> was shocked that the interview included dancing.
							The retailer has apologised and said that they are investigating managers at the store in <span style="background-color: rgb(252, 194, 135);">Cardiff,
							where the interview took place.</span>
						</div>
					</div>

					<div style="margin-top: 20px; font-size: 21px;">
						<i>Possible answer:</i> <br/> #
					</div>
		    `,
				answer: [
					"No, it wasn't, because he was asked to do something which had nothing to do with the job for which he was being interviewed. He was embarrassed and didn't want to work for the company after his bad experience.",
				],
			},
		],

		// questions: [
		// 	// For image capturing purpose only
		// 	{
		// 		title: `
		// 			<div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
		// 				<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
		// 					<img src="img/FriendsPlus/Page62/E6/1.jpg" style="max-width: 100%;" />
		// 				</div>

		// 				<div style="margin-top: 20px; width: 500px; background-color: rgb(253, 249, 231); padding: 10px; border-radius: 10px; border: 3px solid rgb(138, 139, 142); box-shadow: 10px 10px rgb(222, 222, 222); font-size: 21px;">
		// 					Alan Bacon, <span style="background-color: rgb(252, 194, 135);">who left university in July,</span> was invited to an interview with a major retailer.
		// 					He prepared thoroughly for the interview, <span style="background-color: rgb(252, 194, 135);">which was for a role in the marketing department.</span>
		// 					But Alan, <span style="background-color: rgb(252, 194, 135);">whose degree is in film and TV studies,</span> was shocked that the interview included dancing.
		// 					The retailer has apologised and said that they are investigating managers at the store in <span style="background-color: rgb(252, 194, 135);">Cardiff,
		// 					where the interview took place.</span>
		// 				</div>
		// 			</div>

		// 			<div style="margin-top: 20px; font-size: 21px;">
		// 				<i>Possible answer:</i> <br/>
		// 				# <br/> # <br/> # <br/>
		// 			</div>
		//     `,
		// 		answer: [
		// 			"No, it wasn't, because he was asked to do something which ",
		// 			'had nothing to do with the job for which he was being interviewed. ',
		// 			"He was embarrassed and didn't want to work for the company after his bad experience.",
		// 		],
		// 	},
		// ],
	},
	7: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E7/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '7',
				title: `
					Look at the highlighted examples of non-defining relative clauses in the text. 
					Circle the correct words below.
				`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['before / after', 'makes_sense / does_not_make_sense', "has / doesn't_have"],
				answers: ['0-4', '1-0', '2-0'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex; flex-direction: column; align-items: start;">
					<div style="width: 610px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
						<div>
							<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
							<strong>Non-defining relative clauses</song>
						</div>
						<div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">a</strong>
								<span>
									In non-defining relative clauses, 
									we use <i>who, which, where</i> and <i>whose</i>, 
									but we do not use <i>that</i>.
								</span>			
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">b</strong>
								<span>
									A non-defining relative clause:
									<ul>
										<li>
											comes immediately <sup>1</sup><input id='0' type='Circle' /> a noun 
											and gives us information about that noun.
										</li>
										<li>
											adds extra information to the sentence; 
											the sentence <sup>2</sup><input id='1' type='Circle' /> without it.
										</li>
										<li>
											<sup>3</sup><input id='2' type='Circle' /> a comma at the start. 
											It has a comma or a full stop at the end.
										</li>
									</ul>
								</span>			
							</div>
						</div>
					</div>

					<div style="margin-top: 30px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
						<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
						<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;"><strong>Grammar Builder 5D</strong> Exercise 8: page 116</span>
					</div>
				</div>
      `,
		},
	},
	8: {
		unit: 'Ambition',
		id: 'FG10-A-P62-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E8/Key/answerKey.png',
		component: T6,
		inputSize: 250,
		maxLength: 19,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 22,
		},
		titleQuestion: [
			{
				num: '8',
				title: `
				<span style="color: rgb(146, 39, 144);">USE OF ENGLISH</span> 
					Complete the second sentence in each pair so that it means 
					the same as the first.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								Ben lives in San Francisco and his dad works for Apple. <br/>
								Ben, # Apple, lives in San Francisco.
							</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								Lucy is a talented chef and has a lot of experience. <br/>
								Lucy, # chef, has a lot of experience.
							</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								My sister used to study in Ha Noi and now works there. <br/>
								My sister works in Ha Noi, # study.
							</span>				
						</div>
					</div>
        `,
				answer: ['whose dad works for', 'who is a talented', 'where she used to'],
			},
		],
	},
};

export default json;
