import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Extra Speaking Tasks',
		id: 'SB10-GBAR-P129-U4',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='color: rgb(0 147 69);'>Unit 4</strong> <br /> <span style='color: rgb(0 147 69);'>SPEAKING</span> Work in pairs. Take turns to compare and contrast photos A and B",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 110,
		maxLength: 7,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
      
					<div style="background-color:rgb(209 222 241); padding: 5px 15px ">
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">Describe each photo in general.</span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">Describe any differences and similarities between the photos.</span>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">Speculate on how people in the situations may be feeling.</span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <img src="img/FriendsPlus/Page129/U4/1.png" style="width: 500px" />
          </div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Extra Speaking Tasks',
		id: 'SB10-GBAR-P129-U5',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='color: rgb(0 147 69);'>Unit 5</strong> <br /> <span style='color: rgb(0 147 69);'>SPEAKING</span>  Work in pairs. Take turns to do the task below. Spend about a minute preparing your answer. Use phrases from exercise 4 in lesson 3G and exercises 4 and 5 in lesson 5G to help you.",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 110,
		maxLength: 7,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
      
					<div style="background-color:rgb(209 222 241); padding: 5px 15px ">
            <span>
              After leaving school, you have decided to take a gap year
              and work in Britain. Discuss the three jobs shown in the
              pictures with a member of staff at the job agency. Decide
              which job you will apply for and why.
            </span>
          </div>
          <div>
            <img src="img/FriendsPlus/Page129/U5/1.png" style="width: 550px" />
          </div>
        
				`,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Extra Speaking Tasks',
		id: 'SB10-GBAR-P129-U6',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='color: rgb(0 147 69);'>Unit 6</strong> <br /> <span style='color: rgb(0 147 69);'>SPEAKING</span>  Work in pairs. Take turns to do the tasks below.",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 110,
		maxLength: 7,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
     
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <div>
                <img src="img/FriendsPlus/Page129/U6/1.png" style="width: 500px"/>
              </div>
              <div>
                <div style="background-color:rgb(209 222 241); padding: 5px 15px; margin-top:20px ">
                    <div><strong>Student A:</strong> Describe photo A in detail first, then briefly compare it with photo B.</div>
                    <div><strong>Student B:</strong> Describe photo B in detail first, then briefly compare it with photo A.</div>
                </div>
                <div style="background-color:rgb(209 222 241); padding: 5px 15px; margin-top: 20px">
                    <ul>
                      <li>
                        Discuss this statement together:
                        "It is more important for schools to spend money on
                        teaching materials than extra-curricular activities".
                        Do you agree? Why? / Why not?
                      </li>
                    </ul>
                </div>
              
              </div>
            
          
          </div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
