import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P50-E1',
    exerciseKey: '/img/FriendsPlus/Page50/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 830,
    maxLength: 96,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="color: rgb(0, 147, 69);">SPEAKING </span>
        <span>Describe the photo. What do you think
          happened? Use the words below to help you.
        </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div style="display: flex; margin-left:180px;">
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>carry</b></span>
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>dry land</b></span>
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>leave</b></span>
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>lift (v)</b></span>
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>tsunami</b></span>
          <span style="margin-left:20px;color:rgb(246,140,32)"><b>wave (n)</b></span>
        </div>
        <div >
          <img src="/img/FriendsPlus/Page50/2-E1.jpg" alt="">
        </div>
        <div style="margin-top: 30px">
          <b>Answers:</b>
          <span>#</span>
        </div>
        `,
        answer: ['There was a tsunami and a wave lifted the ship and carried it onto dry land.'],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P50-E2',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: `Read the text and check your answers from
            exercise 1.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-top: 30px">
          <img src="/img/FriendsPlus/Page50/4-E2.jpg" alt="" style="width: 900px;"/>
        </div>
        `,
        answer: ['There was a tsunami and a wave lifted the ship and carried it onto dry land.'],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P50-E3',
    exerciseKey: '/img/FriendsPlus/Page50/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    inputSize: 830,
    textareaStyle: { width: 830 },
    titleQuestion: [
      {
        num: '3',
        title: `
              Read the 
              <span style="color: rgb(246,140,32) ;">Learn this!</span>
              box. Then study the highlighted
              superlative forms in the text and match them with
              the rules.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-top: 0px; margin-bottom: 20px">
          <img src="/img/FriendsPlus/Page50/E3.png" alt="">
          <img src="/img/FriendsPlus/Page50/E3/1.jpg" alt="" style="width: 800px" />
        </div>
      <div>
        <div style="margin-bottom:10px;">
          <b>a.</b> #
        </div>
        <div style="margin-bottom:10px;">
          <b>b.</b> #
        </div>
        <div style="margin-bottom:10px;"> 
          <b>c.</b> #
        </div>
        <div style="margin-bottom:10px;"> 
          <b>d.</b> #
        </div>
        <div style="display:flex">
          <b>f. </b> 
          <textarea id='4' row='2'></textarea>  
        </div>
      </div>
        `,
        answer: [
          'the toughest ',
          ' the biggest',
          'the most powerful; the most difficult',
          'the worst ',
          'the most powerful earthquake ever in Japan and the fifth most powerful in the world.',
        ],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P50-E4',
    exerciseKey: '/img/FriendsPlus/Page50/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 16,
    textAlign: 'center',
    titleQuestion: [
      {
        num: '4',
        title: `
              Complete these earthquake facts using the superlative form of the adjectives in brackets
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
      <div>
        <b>1.</b> <span style="color: rgb(40,130,157);">The largest</span> (large) earthquake ever recorded was
        in Chile in 1960 (magnitude 9.5).
      </div>
      <div>
        <b>2.</b> # (deadly) tsunami happened in
        2004 in the Indian Ocean. Nearly 230,000 people
        died.
      </div>
      <div>
        <b>3.</b># (deep) earthquake recorded
        occurred 450 km below the surface of the Earth.
      </div>
      <div>
        <b>4.</b> Antarctica is the continent with #
        (small) number of earthquakes.
      </div>
      <div>
        <b>5.</b>The # (destructive) earthquake ever
        recorded was in 1906 in San Francisco.
      </div>
    </div>
        `,
        answer: ['The deadliest ', 'The deepest', 'the smallest', ' most destructive'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P50-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `
                Study the underlined examples of <i>too</i> and <i>enough</i>
              in the text in exercise 2. Complete the <span style="color:rgb(239,140,27);">Learn this!</span>
              box with <i>after</i> and <i>before</i>.
        `,
        color: '#860052',
      },
    ],
    stylesTextInput: {
      fontSize: 28,
      textAlign: 'center',
    },
    inputSize: 140,
    maxLength: 10,
    exerciseKey: '/img/FriendsPlus/Page50/E5/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,

    questions: [
      {
        title: `
				<div style='margin-top: 10px;'>
          <div style='position: relative;'>
            <div><img src='/img/FriendsPlus/Page50/E5/1.jpg' style='width: 800px;' /></div>
            <div style='position: absolute; top: 64px; left: 210px;'>#</div>
            <div style='position: absolute; top: 111px; left: 265px;'>#</div>
            <div style='position: absolute; top: 201px; left: 266px;'>#</div>
          </div>
				`,
        answer: ['before', 'after', 'before'],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P50-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: `
          Rewrite the sentences using the adjective in brackets and <i>too</i> or <i>enough</i>.
        `,
        color: '#860052',
      },
    ],
    inputSize: 700,
    maxLength: 51,
    exerciseKey: '/img/FriendsPlus/Page50/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,

    questions: [
      {
        title: `
        <div>
          <div>
            <b>1.</b> He isn’t tall enough to reach the shelf. (short)<br>  
            <span style="color: rgb(24,112,142);margin-left: 17px;">He’s too short to reach the shelf</span>   
          </div>   
          <div style="margin-top: 10px;">
            <b>2.</b> Skiing holidays aren’t cheap enough for me.(expensive)<br>
          #
          </div>   
          <div style="margin-top: 10px;">
            <b>3.</b> My dad’s old car is too dangerous to drive. (safe)<br>#
          </div>  
          <div style="margin-top: 10px;">
            <b>4.</b> The storm was too weak to cause much damage. (strong)<br>#
          </div> 
          <div style="margin-top: 10px;">
            <b>5.</b> My shoes aren’t clean enough to wear to the party. (dirty)<br>#
          </div> 
        </div>
				`,
        answer: [
          `Skiing holidays are too expensive for me.`,
          `My dad's old car isn't safe enough to drive.`,
          `The storm wasn't strong enough to cause much damage.`,
          `My shoes are too dirty to wear to the party.`,
        ],
      },
    ],
  },
  7: {
    unit: 'Weather',
    id: 'FG10-W-P50-E7',
    exerciseKey: '/img/FriendsPlus/Page50/E7/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 89,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',

      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
            <span style="color:rgb(45,206,137) ; margin-right:5px">SPEAKING</span>
           Work in pairs. Ask and answer questions using the superlative form of the adjectives.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
          <div>
            <span><b>1.</b> what / interesting subject / at school / ?</span><br>
            <span style="color: rgb(24,112,142); margin-left:26px">What’s the most interesting subject at school?</span>
          </div>
          <div>
            <div style="margin-top: 10px;"><span><b>2.</b> who / attractive actor / in the world / ?</span><br/>#</div>
            <div style="margin-top: 10px;"><span><b>3.</b> what / funny comedy / on TV / ?</span><br/>#</div>
            <div style="margin-top: 10px;"><span><b>4.</b> what / interesting city / in your country / ?</span><br/>#</div>
            <div style="margin-top: 10px;"><span><b>5.</b> which / good film / you've seen / ?</span><br/>#</div>
          </div>
        </div>
        `,
        answer: [
          `Who's the most attractive actor in the world?`,
          `What's the funniest comedy on TV?`,
          `What's the most interesting city in your country?`,
          `Which is the best film you have seen?`,
        ],
      },
    ],
  },
};
export default json;
