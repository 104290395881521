import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Money',
    id: 'FG10-M-P80-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(45,206,137);">SPEAKING</span> 
          Look at the logos of three international organisations. 
          What do you think they are responsible for?
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page80/E1/E1.png' }]],
  },
  2: {
    unit: 'Money',
    id: 'FG10-M-P80-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title: `
          <span style="color: rgb(146,39,143);">USE OF ENGLISH</span> 
          Read the text about the World Bank. 
          Circle the correct words to complete the text.
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page80/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          //marginLeft: 10,
          fontWeight: 'bold',
          padding: '5px',
        },
        selectWordStyle: {
          border: 'solid 2px',
          //padding: 5,
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: [
          `a|&ensp;at&emsp;&emsp;&emsp;&emsp;&ensp;&ensp;&nbsp;&nbsp;&nbsp b|&ensp;in&emsp;&emsp;&emsp;&nbsp; c|&ensp;on`,
          `a|&ensp;connected&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp; b|&ensp;link&emsp;&emsp;&nbsp;&nbsp; c|&ensp;relative`,
          `a|&ensp;little&emsp;&emsp;&emsp;&emsp;&emsp; b|&ensp;more&emsp;&ensp;&nbsp; c|&ensp;few`,
          `a|&ensp;live&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp; b|&ensp;life&emsp;&emsp;&ensp;&nbsp; c|&ensp;living`,
          `a|&ensp;has&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;&nbsp; b|&ensp;gets&emsp;&emsp;&nbsp c|&ensp;takes`,
          `a|&ensp;effect&emsp;&emsp;&emsp;&emsp;&nbsp; b|&ensp;effective&nbsp;&nbsp; c|&ensp;effectively`,
        ],
        answers: ['0-4', '1-0', '2-4', '3-8', '4-0', '5-8'],
        initialValue: [],
      },
      Layout: ` 
        <div style="display: flex; gap: 50px; align-items: start;">
          <div style="flex: 1"><img src="img/FriendsPlus/Page80/E2/1.jpg" style="max-width: 100%;" /></div>
          
          <div style="margin-top: 20px; flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(0, 121, 88);">
            <div><b>1 </b><input type="Circle" id="0"></div>  
            <div><b>2 </b><input type="Circle" id="1"></div>
            <div><b>3 </b><input type="Circle" id="2"></div>
            <div><b>4 </b><input type="Circle" id="3"></div>
            <div><b>5 </b><input type="Circle" id="4"></div>
            <div><b>6 </b><input type="Circle" id="5"></div>
          </div>
        </div>
      `,
    },
  },
  3: {
    unit: 'Money',
    id: 'FG10-M-P80-E3',
    exerciseKey: '/img/FriendsPlus/Page80/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 450,
    maxLength: 108,
    stylesTextInput: {
      fontSize: 21,
    },
    textareaStyle: { width: 450, fontSize: '21px' },
    titleQuestion: [
      {
        num: '3',
        title: `
          Answer the questions about the text.
        `,
        color: '#860052',
      },
    ],
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
          <div style="display: flex; gap: 50px; align-items: start;">
            <div style="flex: 1"><img src="img/FriendsPlus/Page80/E2/1.jpg" style="max-width: 100%;" /></div>

            <div style="font-size: 21px; flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(0, 121, 88);">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div>Where are the main offices of the World Bank?<br />#</div>
              </div>
              <div style="margin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div>When was the World Bank founded?<br />#</div>
              </div>	
              <div style="margin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div>Name the two institutions which form the World Bank.<br /><textarea id="2" rows="3"></textarea></div>
              </div>	
              <div style="margin-top: -17px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div>In which areas have World Bank projects been involved in Viet Nam?<br /><textarea id="3" rows="2"></div>
              </div>	
            </div>
        `,
        answer: [
          'In Washington',
          'In 1994',
          'International Bank for Reconstruction and Development (IBRD) and International Development Association (IDA)',
          'Agriculture, energy, transport, health, education,banking and finance',
        ],
      },
    ],
  },
  4: {
    unit: 'Money',
    id: 'FG10-M-P80-E4',
    exerciseKey: '/img/FriendsPlus/Page80/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_35.mp3',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '4',
        title: `
          <headphone1 name="2.35"></headphone1> 
          Listen to a talk about the World Bank’s spending on education in Viet Nam. 
          Match each project in column A with the amount spent in column B.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div>
            <img src="/img/FriendsPlus/Page80/E4/E4.png" style="width: 600px;" >
          </div>
         <div style="margin-top: 20px;">
            <b>Answers:</b>
          </div>
          <div style="display: flex;justify-content: space-around;">
            <div><b>1</b> <span> #</span></div>
            <div><b>2</b> <span> #</span></div>
            <div><b>3</b> <span> #</span></div>
            <div><b>4</b> <span> #</span></div>
          </div>
        `,
        answer: ['c', 'd', 'a', 'b'],
      },
    ],
  },
  5: {
    unit: 'Money',
    id: 'FG10-M-P80-E5',
    exerciseKey: '/img/FriendsPlus/Page80/E5/Key/AnswerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_35.mp3',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name="2.35"></headphone1> 
          Listen again. Are these sentences true or false? Write T or F.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">1</div>
              <div>The project to help deaf children was in 2011. #</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">2</div>
              <div>The primary project in 2012 focused on disadvantaged areas. #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">3</div>
              <div>Viet Nam welcomed two projects in 2013. #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">4</div>
              <div>Renovation of General Education Project was approved in 2014. #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">5</div>
              <div>The World Bank continues to support education in Viet Nam. #</div>
            </div>	
          </div>
        `,
        answer: ['T', 'T', 'F', 'F', 'T'],
      },
    ],
  },
  6: {
    unit: 'Money',
    id: 'FG10-M-P80-E6',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '6',
        title: `
          Work in groups. 
          Research for other World Bank projects in Viet Nam on energy, environment, health, or transport. 
          Make a note of the information you found out.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <textarea id="0" rows="10"></textarea>
        `,
        answer: [],
      },
    ],
  },
  7: {
    unit: 'Money',
    id: 'FG10-M-P80-E7',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
          <span style='color:rgb(0,147,70);'>SPEAKING</span> 
          Work in groups. Prepare a short
          presentation on the projects in exercise 6, using
          the notes you made. Practise and then give your
          presentation to the class. Make notes on the other
          groups’ presentations and give feedback. 
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
