import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P88-E1',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_09.mp3',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Describe the photos. Then say which holiday looks more enjoyable, and why.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <img src='/img/FriendsPlus/Page88/E1/1.jpg' style='width:850px;'/>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P88-E2',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_09.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page88/E2/key/answerKey.png',
    inputSize: 60,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'left',
    titleQuestion: [
      {
        num: '2',
        title: `
				Look through the texts quickly. Match two of the three texts (A–C) with photos 1–2.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        
        <div style='display: flex; gap:50px; margin-left: 100px'>
          <div><b> 1 - #</b></div>
          <div><b> 2 - #</b></div>
        </div>
        <img src='/img/FriendsPlus/Page88/E2/1.jpg' style='width:850px; margin-top : 10px;'/>
			  `,
        answer: ['A', 'B'],
      },
    ],
  },

  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P88-E3',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_09.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page88/E3/key/answerKey.png',
    inputSize: 100,
    stylesTextInput: [],
    maxLength: 6,
    textAlign: 'left',
    titleQuestion: [
      {
        num: '3',
        title: `
				Read the texts. Match the three teens (Hilary, Kevin and Terry) with the sentences below.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div>This teen …</div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> had an enjoyable experience with animals. #</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> had a scary experience. #</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> experienced lovely smells. #</div>
        </div>
        <img src='/img/FriendsPlus/Page88/E3/1.jpg' style='width:850px; margin-top : 10px;'/>
			  `,
        answer: ['Kevin', 'Terry', 'Hilary'],
      },
    ],
  },
};

export default json;
