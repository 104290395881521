import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P96-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. 
          How many different things do people use mobile phones for? 
          Think of as many as you can in two minutes.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <i>Answer:</i> <br/>
          # <br/> # <br/> # <br/> # <br/> # <br/> # <br/> # <br/> # <br/> 
				`,
				answer: [
					'make calls',
					'send messages / text messages',
					'listen to music',
					'take photos',
					'play games',
					'tweet',
					'send and receive emails',
					'search the internet',
				],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P96-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E2/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 54,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the text. What happened in 1973, 1986, 1992 and 1997?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; font-size: 20px;">
            <div>
              <i>Answer:</i><br/>
              <div><span style="padding-right: 10px;">1973</span>#</div>
              <div><span style="padding-right: 10px;">1986</span>#</div>
              <div><span style="padding-right: 10px;">1992</span>#</div>
              <div><span style="padding-right: 10px;">1997</span>#</div>
            </div>

            <div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page96/E2/1.jpg" style="max-width: 100%;" />
            </div>
          </div>
				`,
				answer: [
					'The first mobile phone call was made by Martin Cooper.',
					'Voicemail was added to phones.',
					'The first text message was sent.',
					'The camera phone was invented by Philippe Kahn.',
				],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P96-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E3/Key/answerKey.png',
		component: T6,
		inputSize: 100,
		maxLength: 7,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					Study the first two sentences of the text in exercise 2. 
					Then complete the <span style="color: rgb(246, 141, 31);">Learn this!</span> box.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="width: 530px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
							<div>
								<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
								<strong>The present and past passive</song>
							</div>
							<div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">a</strong>
									<span>
										The passive is formed with the correct form of the verb <sup>1</sup># 
										and the past participle.
									</span>			
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">b</strong>
									<span>
										We use the <sup>2</sup># simple of <i>be</i> for the present passive 
										and the <sup>3</sup># simple of <i>be</i> for the past passive.
									</span>			
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">c</strong>
									<span>
										If we want to say who performed the action, 
										we use <sup>4</sup># + their name.
									</span>			
								</div>
							</div>
						</div>

						<div style="margin-left: 20px; display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page96/E2/1.jpg" style="max-width: 100%;" />
            </div>
					</div>
				`,
				answer: ['be', 'present', 'past', 'by'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P96-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E4/Key/answerKey.png',
		component: T6,
		inputSize: 500,
		maxLength: 50,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
          Find twelve examples of the passive in the text. 
					Which examples are a) plural, b) negative or c) a question?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; font-size: 20px;">
            <div>
              <i>Answer:</i><br/>
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
              # <br />
            </div>

            <div style="margin-left: 30px; display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page96/E2/1.jpg" style="max-width: 100%;" />
            </div>
          </div>
				`,
				answer: [
					'Nearly a billion mobile phones are sold ... (a)',
					'The first mobile phone call was made ...',
					'The call was answered ...',
					'The first mobiles were powered ... (a)',
					'Voicemail was added ...',
					'Internet access was not added ... (b)',
					'The first text message was sent ...',
					'It was not typed ... (b)',
					'The camera phone was invented ...',
					'Photos of his newborn daughter were shared ... (a)',
					'In the UK, a mobile phone is stolen ...',
					'What are mobile phones used for the most? (c)',
				],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P96-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E5/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 19,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          Read the <span style="color: rgb(246, 141, 31);">Look out!</span> box. 
					Complete the sentences with the present simple 
					or past simple passive of the verbs in brackets.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; ">
						<div>
							<div style="display: flex; width: 470px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 141, 31); background-image: radial-gradient(rgb(250 244 244), rgb(254, 223, 191)); font-size: 20px;">
								<div style="margin-right: 20px; margin-top: 20px; font-weight: bold; font-size: 50px; color: rgb(246, 141, 31);">!</div>
								<div>
									<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LOOK OUT!</span>
									<div>
										When we use adverbs with the passive, 
										we usually put them immediately before the past participle.
										<div style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">
											Phones <u>are sometimes used</u> as alarm clocks. <br/>
											It <u>was probably invented</u> by accident.
										</div>
									</div>
								</div>
							</div>

							<div style="margin-top: 30px; width: 470px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
								<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;">
									<span style="font-weight: bold; padding-right: 20px;">Grammar Builder 8B</span> 
									The passive (present simple and past simple): page 122
								</span>
							</div>
						</div>

						<div style="margin-left: 30px; max-width: 540px; padding: 15px; border-radius: 10px; border: 1px solid rgb(246, 141, 31); font-size: 20px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>Mobile phones <span style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">are owned</span> (own) by about 4.3 billion people in the world today.</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									The best-selling phone ever was the Nokia 1100. <br />
									More than 250 million # (sell).
								</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>
									In Japan, mobiles # (often / use) in the shower, 
									so most of them are waterproof.
								</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>
									The average text message # (usually / answer) within 90 seconds.
								</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>
									Today, the internet # (access) more often from a phone than from a computer.
								</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">6</strong>
								<span>
									More text messages # (send) every year in the Philippines than in any other country.

								</span>				
							</div>
						</div>
					</div>
				`,
				answer: ['were sold', 'are often used', 'is usually answered', 'is accessed', 'are sent'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P96-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page96/E6/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          Complete the questions about the reading texts in Units 1-7 
					using the correct passive form of the verbs in brackets.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								Why # Ashlyn Blocker # (watch) more carefully 
								than all the other children at her school? [1F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								# Dan and Kate Suski # (rescue) 
								by helicopter after their boat sank? [2F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								What skills # (improve) by playing online video games, 
								according to a report? [3F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
								Who # (nearly / kill) by a storm at a paragliding event? [4F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
								Where # Andrew Johnson # (employ) as a Master Model Builder? [5F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">6</strong>
							<span>
								Where # meetings # (sometimes / hold) if Aaron Levie 
								is choosing the venue? [6F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">7</strong>
							<span>
								What # Hillary Bradt # (show) to do on her holiday? [7F]
							</span>				
						</div>
					</div>
				`,
				answer: [
					'was',
					'watched',
					'Were',
					'rescued',
					'are improved',
					'was nearly killed',
					'is',
					'employed',
					'are',
					'sometimes held',
					'was',
					'shown',
				],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P96-E7',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Ask and answer the questions in exercise 6.

				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								Why # Ashlyn Blocker _____ (watch) more carefully 
								than all the other children at her school? [1F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								_____ Dan and Kate Suski _____ (rescue) 
								by helicopter after their boat sank? [2F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								What skills _____ (improve) by playing online video games, 
								according to a report? [3F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
								Who _____ (nearly / kill) by a storm at a paragliding event? [4F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
								Where _____ Andrew Johnson _____ (employ) as a Master Model Builder? [5F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">6</strong>
							<span>
								Where _____ meetings _____ (sometimes / hold) if Aaron Levie 
								is choosing the venue? [6F]
							</span>				
						</div>
						<div style="display: flex; margin-top: 15px;">
							<strong style="padding-right: 10px;">7</strong>
							<span>
								What _____ Hillary Bradt _____ (show) to do on her holiday? [7F]
							</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
