import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
                    <b style='color:rgb(0,147,70);'>SPEAKING</b> 
                      Work in pairs. Describe the photo. What do
                      you think the woman is doing? 
          
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page75/E1/E1.png' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E2',
    exerciseKey: '/img/FriendsPlus/Page75/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '2',
        title: `
           Read the text. Then answer the questions
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style="margin-left: 70px;">
                <img src="/img/FriendsPlus/Page75/E2/E2.png">
            </div>
            <div style="margin-top: 20px;">
              <b>1</b> <span> What is ‘showrooming’? Have you or someone you
                know ever showroomed?</span> <br>
              <b>2</b> <span> Do you prefer to buy things in shops or online? Why?</span> <br>
              <b>3</b> <span> Should you expect to pay more for goods in a shop
                than on a website? Why? / Why not?</span> <br>
              <b>4</b> <span>  What would you do if you had to pay to look around
                a shop?</span> <br>
            </div>
            <div style="margin-top: 10px;">
                <b>Answers:</b>
                <textarea id="0" rows="2"></textarea>
            </div>
        `,
        answer: [`‘Showrooming’ is when people visit a shop, examine a product, and then buy it online`],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E3',
    exerciseKey: '/img/FriendsPlus/Page75/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '3',
        title: `
              Read the <b style="color: rgb(146,39,143);">Learn this!</b> box. Then find all the verbs
              in the text in exercise 2 that are followed by the
              infinitive, bare form or -ing form of another verb.
              Make three lists in your notebook.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div >
              <img src="/img/FriendsPlus/Page75/E3/E3.jpg">
          </div>
          <div >
      <div >
      <div><b>Answers</b></div>
      <div style="display: flex;"><b>infinitive:</b> <span style="margin-left: 20px;"> #</span></div>
      <div style="display: flex;"><b>-ing form:</b><span style="margin-left: 20px;"> #</span></div>
      <div style="display: flex;"><b>bare form:</b><span style="margin-left: 20px;"> #</span></div>
   </div>
        `,
        answer: [
          `decide, pretend, refuse, fail, prefer, expect, choose`,
          `keep, spend (time), can't stand, avoid, end up, risk`,
          `make`,
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E4',
    exerciseKey: '/img/FriendsPlus/Page75/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 100,
    maxLength: 10,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '4',
        title: `
           <b style="color: rgb(146,39,143);">DICTIONARY WORK</b> Read the <span style="color: rgb(146,39,143);">Dictionary Skills Strategy</span>
              and study <br/>the dictionary entries below. Which verb 
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        
         <div style="width:800px;background-color: rgb(224, 205, 227); border: 3px dotted rgb(146,39,143);padding: 10px 20px; border-radius: 20px;margin:10px;">
            <div><b style="color: rgb(146,39,143);">Dictionary Skills Strategy</b></div>
            <div>Entries for verbs in learner’s dictionaries will show you 
patterns the verb is used in. They may show the pattern 
like this at the start of the entry: <i>suggest (doing), want 
(to do), let ... (do)</i> or give an example within the entry:
<i>She suggested eating out</i>.</div>
         </div>

         <div style="display: flex; gap:30px;">
          <div style="margin-top: 70px;flex:1;">
            <div><b>a)takes an infinitive:</b> &ensp;#</div>
            <div><b>b)takes an <i>-ing</i> form:</b> &ensp;#</div>
            <div><b>c)takes a bare form?</b> &ensp;#</div>
          </div>
          <div style="flex:1;">
            <img width='60%' src="/img/FriendsPlus/Page75/E4/E4.jpg" alt="" style="width:600px;"/>
          </div>
         </div>
        `,
        answer: ['refuse', 'avoid', 'let'],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E5',
    exerciseKey: '/img/FriendsPlus/Page75/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 300,
    maxLength: 27,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '5',
        title: `
           Look up these words in a dictionary and add them to
               the lists you made in exercise 3.
          
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div> <span style="color: rgb(146,39,143);">admit agree help mind offer promise</span></div>
         <div >
    <b>Answers:</b> <br>
    <b>infinitive</b> &ensp;# <br>
    <b>-ing form</b> &ensp;# <br>
    <b>bare form</b> &ensp;#
  </div>
        `,
        answer: ['agree, offer, promise, help', 'admit, mind', 'help'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E6',
    exerciseKey: '/img/FriendsPlus/Page75/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 130,
    maxLength: 8,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      // fontWeight: 'bold',
      // fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '6',
        title: `
           Complete the sentences. Use the infinitive, bare
               form or <i>-ing</i> form of the verbs in brackets
          
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div >
          <b>1</b> <span> Jason promised</span> <span> #</span> <span>(phone) me</span> <br>
          <b>2</b> <span> Joe enjoys</span> <span> #</span> <span>(shop) in town but refuses </span> <span> #</span> <span> (shop) online</span> <br>
          <b>3</b> <span> My sister lets me</span> <span> #</span> <span> (use) her laptop</span> <br>
          <b>4</b> <span> Mum offered</span> <span> #</span> <span> (give) me a lift to the shops</span> <br>
          <b>5</b> <span> You can expect</span> <span> #</span> <span> (pay) less online than in a shop</span> <br>
          <b>6</b> <span> Do you fancy</span> <span> #</span> <span> (go) to the cinema this evening?</span> <br>
          <b>7</b> <span> Parents do not let children</span> <span> #</span> <span> (stay) up late.</span> <br>
          <b>8</b> <span> My mum usually makes me</span> <span> #</span> <span> (tidy) up my room.</span>
        </div>
        `,
        answer: ['to phone', 'shopping', 'to shop', 'use', 'to give', 'to pay', 'going', 'stay', 'tidy'],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'SB10-U6-P75-E7',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
              <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Give true information about
                yourself.
          <div style="display: flex;margin-top: 20px; font-weight: 400;">
              <div  style="margin-right: 80px;">
                <b>1.</b> <span> I usually avoid …</span> <br>
                <b>2.</b> <span> I really can’t stand …</span> <br>
                <b>3.</b> <span> I don’t let …</span> <br>
              </div>
              <div>
                <b>4.</b> <span> I spend a lot of time …</span> <br>
                <b>5.</b> <span> I sometimes pretend …</span> <br>
                <b>6.</b> <span> I really want …</span> <br>
              </div>
            </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
