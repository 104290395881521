import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P104-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in groups. 
          Look at the photo above and the title of the text. 
          Guess the answers to the questions.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
            <div style="box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/1.jpg" style="max-width: 100%;" /></div>
            <div style="margin-left: 40px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/2.jpg" style="max-width: 100%;" /></div>
          </div>

          <div style="margin-top: 20px;">
            <div>
              <span style="padding-right: 10px; font-weight: bold;">1</span>
              <span>What is the large machine in the photo for?</span>				
            </div>
            <div>
              <span style="padding-right: 10px; font-weight: bold;">2</span>
              <span>When was the machine developed?</span>				
            </div>
          </div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P104-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E2/Key/answerKey.png',
		component: T6,
		inputSize: 350,
		maxLength: 30,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the text. Find out the answers to the questions in exercise 1.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
            <div style="box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/1.jpg" style="max-width: 100%;" /></div>
            <div style="margin-left: 40px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/2.jpg" style="max-width: 100%;" /></div>
          </div>

          <div style="margin-top: 20px;">
            <div style="display: flex;">
                <span style="padding-right: 10px; font-weight: bold;">1</span>
                <span>What is the large machine in the photo for? <br />#</span>				
              </div>
              <div style="margin-top: 10px; display: flex;">
                <span style="padding-right: 10px; font-weight: bold;">2</span>
                <span>When was the machine developed? <br />#</span>				
              </div>
          </div>
				`,
				answer: ['It creates virtual reality.', 'It was developed in the 1950s.'],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P104-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E3/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          Put the events in the order they happened, according to the text.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="display: flex; flex-direction: column; justify-content: space-between;">
							<div style="padding: 10px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">a</span>
									<span>The internet grew in popularity.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">b</span>
									<span>Early experiments of virtual reality were conducted.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">c</span>
									<span>WebLourve was launched.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">d</span>
									<span>WebLouvre was renamed WebMuseum.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">e</span>
									<span>The Virtual Computer by the Apple Computer Inc. came out.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">f</span>
									<span>Online virtual museums emerged.</span>				
								</div>
							</div>

							<div style="padding: 10px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
								<span style="font-weight: bold; font-style: italic;">Answer:</span> <br/>
								<span style="font-weight: bold; padding-right: 10px;">1</span>#
								<span style="font-weight: bold; padding-right: 10px; padding-left: 30px;">2</span>#
								<span style="font-weight: bold; padding-right: 10px; padding-left: 30px;">3</span>#
								<span style="font-weight: bold; padding-right: 10px; padding-left: 30px;">4</span>#
								<span style="font-weight: bold; padding-right: 10px; padding-left: 30px;">5</span>#
								<span style="font-weight: bold; padding-right: 10px; padding-left: 30px;">6</span>#
							</div>
						</div>

            <div style="margin-left: 40px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);"><img src="img/FriendsPlus/Page104/E1/2.jpg" style="max-width: 100%;" /></div>
          </div>
				`,
				answer: ['b', 'e', 'a', 'c', 'd', 'f'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P104-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E4/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span>
					Work in pairs. Discuss the advantages of virtual museums.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<textarea id="0" rows="10"></textarea>
				`,
				answer: ['b', 'e', 'a', 'c', 'd', 'f'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P104-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_22.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E5/Key/answerKey.png',
		component: T6,
		inputSize: 750,
		maxLength: 68,
		titleQuestion: [
			{
				num: '5',
				title: `
					<audioimage name="3.22"></audioimage> 
          Listen to a talk about the benefits of virtual museums. 
					Make a list of the main points. Compare it with your answers in exercise 4.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<span style="font-weight:bold; font-style: italic;">Answer:</span><br/>
						# <br/> # <br/> # <br/> # <br/> 
					</div>
				`,
				answer: [
					'Visitors can see what the past was like.',
					'Heritages can be admired without any damage.',
					'They offer education and entertainment.',
					'Visitors can access virtual museums regardless of time and location.',
				],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P104-E6',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_22.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E6/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		titleQuestion: [
			{
				num: '6',
				title: `
					<audioimage name="3.22"></audioimage> 
          Listen again. Are the sentences true or false? Write T or F.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">1</span>
							<span>The Museum of Flight presents the predictions about future aviation. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">2</span>
							<span>Virtual museums help preserve world heritages. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">3</span>
							<span>The Terracotta Warriors and Horses Museum in China allows visitors to actually walk among the soldiers. #</span>
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">4</span>
							<span>A visit to a virtual museum is only a form of entertainment. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">5</span>
							<span>Visitors can get to any virtual museums easily whenever they want to. #</span>				
						</div>
					</div>
				`,
				answer: ['F', 'T', 'F', 'F', 'T'],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P104-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page104/E7/Key/answerKey.png',
		component: T6,
		inputSize: 420,
		maxLength: 46,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500 },
		titleQuestion: [
			{
				num: '7',
				title: `
         <span style="color: rgb(148, 149, 153)">INTERNET RESEARCH</span> 
				 Find out some facts about one of the virtual museums mentioned in exercise 5. 
				 Share your information with the class.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div><img src="img/FriendsPlus/Page104/E7/1.jpg" style="max-width: 100%;" /></div>
					<div><textarea id="0" rows="10"></textarea></div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
