import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P58-E1',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 720,
		maxLength: 79,
		recorder: true,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 770 },
		titleQuestion: [
			{
				num: '1',
				title: `Look at the photos (A–D). Which job looks the most interesting, <br/>in your opinion? Why?`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page58/E1/1.jpg" style="max-width: 100%;" />
          </div>
          <textarea id="0" rows="5"></textarea>
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P58-E2',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_13.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page58/E2/Key/answerKey.png',
		component: T6,
		inputSize: 145,
		maxLength: 20,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
					<audioimage name="2.13"></audioimage><span style="color: rgb(146, 39, 144)">VOCABULARY</span> and <span style="color: rgb(5, 107, 174)">PRONUNCIATION</span> Match four of the words below
					with photos A–D. Then listen to the wordlist and underline the stress.
					Listen again and repeat.
					Make sure you understand the meaning of all the words.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(146, 39, 144);">
						<div>
							<strong>Jobs (1)</strong>
							<span style="margin-left: 85px;">architect</span>
							<span style="margin-left: 43px;">cleaner</span>
							<span style="margin-left: 51px;">dentist</span>
							<span style="margin-left: 52px;">engineer</span>
							<span style="margin-left: 30px;">farm worker<span/>
						</div>
						<div>
							<span>hairdresser</span>
							<span style="margin-left: 56px;">paramedic</span>
							<span style="margin-left: 20px;">pilot</span>
							<span style="margin-left: 86px;">programmer</span>
							<span style="margin-left: 113px;">receptionist</span>
						</div>
						<div>
							<span>sales assistant</span>
							<span style="margin-left: 20px;">solicitor</span>
							<span style="margin-left: 50px;">sports coach</span>
							<span style="margin-left: 20px;">travel agent</span>
							<span style="margin-left: 92px;">waiter</span>
						</div>
					</div>
					<div style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
						<img src="img/FriendsPlus/Page58/E2/1.jpg" style="max-width: 100%;" />
					</div>
					<div style="display: flex; gap: 80px;">
						<div style="margin-right: 30px;"><strong>A</strong> #</div>
						<div style="margin-right: 30px;"><strong>B</strong> #</div>
						<div style="margin-right: 30px;"><strong>C</strong> #</div>
						<div><strong>D</strong> #</div>
					</div>
	      `,
				answer: ['engineer', 'hairdresser', 'paramedic', 'architect'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P58-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page58/E3/Key/answerKey.png',
		component: T6,
		inputSize: 720,
		maxLength: 79,
		recorder: true,
		hintBox: [
			{
				src: ['Which job is the most creative?'],
				borderColor: '#D3B5D6',
				arrow: true,
				position: 2,
				width: 400,
			},
			{
				src: ['In my opinion, it’s … / I think it’s probably …'],
				borderColor: '#D3B5D6',
				arrow: true,
				position: 1,
				width: 520,
			},
		],
		textareaStyle: { width: 900 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
				<span style="color: rgb(146, 39, 144)">VOCABULARY</span> Check the meaning of the adjectives below. 
				In groups, ask and answer questions about the jobs in exercise 2 
				using the superlative form. Give your own opinions.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(146, 39, 144);">
						<div>
							<strong>Describing jobs</strong>
							<span style="margin-left: 30px;">creative</span>
							<span style="margin-left: 30px;">challenging</span>
							<span style="margin-left: 30px;">repetitive</span>
						</div>
						<div>
							<span>rewarding</span>
							<span style="margin-left: 105px;">stressful</span>
							<span style="margin-left: 26px;">tiring</span>
							<span style="margin-left: 100px;">varied</span>
						</div>
					</div>
					<div style="margin-top: 10px; color: rgb(146, 39, 144);">
						<div>
							<strong>Jobs (1)</strong>
							<span style="margin-left: 85px;">architect</span>
							<span style="margin-left: 43px;">cleaner</span>
							<span style="margin-left: 51px;">dentist</span>
							<span style="margin-left: 52px;">engineer</span>
							<span style="margin-left: 30px;">farm worker<span/>
						</div>
						<div>
							<span>hairdresser</span>
							<span style="margin-left: 56px;">paramedic</span>
							<span style="margin-left: 20px;">pilot</span>
							<span style="margin-left: 86px;">programmer</span>
							<span style="margin-left: 113px;">receptionist</span>
						</div>
						<div>
							<span>sales assistant</span>
							<span style="margin-left: 20px;">solicitor</span>
							<span style="margin-left: 50px;">sports coach</span>
							<span style="margin-left: 20px;">travel agent</span>
							<span style="margin-left: 92px;">waiter</span>
						</div>
					</div>
					<div style="margin-top: 20px; display: flex;">
						<hintbox id='0'></hintbox>
						<div style="margin-left: 20px;"><hintbox id='1'></hintbox></div>
					</div>
					<div style="margin-top: 40px;"><textarea id="0" rows="5"></textarea></div>
				`,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P58-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page58/E4/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
				<span style="color: rgb(146, 39, 144)">VOCABULARY</span> Look at the photos on page 59. 
				What do you think each part-time job involves? 
				Match two or more phrases from the list below with each job.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 1000px;">
						<div style="margin-top: 20px; display: flex;">
							<img src="img/FriendsPlus/Page58/E4/1.jpg" style="max-width: 100%;" />
							<div style="margin-left: 30px;"><img src="img/FriendsPlus/Page58/E4/2.jpg" style="max-width: 100%;" /></div>
						</div>
						<div style="margin-top: 20px; color: rgb(146, 39, 144);">
							<div>
								<strong>Work activities</strong>
								<span style="margin-left: 40px;">answer the phone</span>
								<span style="margin-left: 40px;">be on your feet</span>
								<span style="margin-left: 40px;">be part of a team</span>
							</div>
							<div>
								<span>deal with the public</span>
								<span style="margin-left: 40px;">earn a lot (of money)</span>
								<span style="margin-left: 40px;">make phone calls</span>
							</div>
							<div>
								<span>serve customers</span>
								<span style="margin-left: 70px;">travel a lot</span>
								<span style="margin-left: 40px;">use a computer</span>
								<span style="margin-left: 40px;">wear a uniform</span>
							</div>
							<div>
								<span>work alone</span>
								<span style="margin-left: 131px;">work indoors / outdoors</span>
								<span style="margin-left: 40px;">work with children</span>
							</div>
							<div>
								<span>work long hours / nine-to-five</span>
							</div>
							<div style="color: rgb(10, 111, 143); font-style: italic;">A gardener works outdoors.</div>
						</div>
						<div>
							<strong>A</strong> #, #, # <br/ >
							<strong>B</strong> #, #, #, <input id='0' width='200px'>
						</div>
					</div>
        `,
				answer: [
					'be on your feet',
					'work outdoors',
					'work nine-to-five',
					'be on your feet',
					'wear a uniform',
					'work outdoors',
					'work with children',
				],
			},
		],
	},
};

export default json;
