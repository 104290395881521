import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E1',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 700 },
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: 'Read the opening paragraph of a story.  Why does the narrator notice the tall man?',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           <textarea id='0' row='1'></textarea>
          <img src='img/FriendsPlus/Page24/E1/1.jpg'>
          `,
        answer: [
          'Because the man is standing very still, wearing summer clothes and staring at the narrator.',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E2',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    maxLength: 31,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title:
          'Read the <span style="color: rgb(246,141,31)">Learn this!</span> box. Then find all the examples of the past continuous in the text in exercise 1.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page24/E2/1.jpg'>
        <textarea id='1' row='3'></textarea>
          `,
        answer: [
          `were hurrying, were sitting, was talking, wasn't looking, was singing, (was) playing, were shouting, (were) waving, (were) carrying, (were) running, was standing, was he wearing, was looking.`,
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E3',
    audio: '',
    video: '',
    component: T6,
    inputSize: 230,
    textareaStyle: { width: 700 },
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page24/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the paragraph below with the past continuous form of the verbs in brackets.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width:25cm;line-height:46px'>
              I left the hotel early the next morning. Already, the sun <sup>1</sup>#(shine) brightly and the
              temperature <sup>2</sup>#(rise). In the square, café owners  <br><sup>3</sup>#(carry) tables outside. A dog 
              <sup>4</sup>#(lie) on the pavement nearby, but it  <sup>5</sup>#(not sleep). Two teenage girls <br><sup>6</sup>#(sit) on a bench
               <sup>7</sup>#(share) headphones. What <sup>8</sup>#(they / listen) to?

          </div>
          `,
        answer: [
          'was shining',
          'was rising',
          'were carrying',
          'was lying',
          `wasn't sleeping`,
          'were sitting',
          'sharing',
          'were they listening',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E4',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
    question: [],
    questionImage: [[{ url: 'img/FriendsPlus/Page24/E4/1.jpg' }], [{ url: 'img/FriendsPlus/Page24/E4/2.jpg' }]],
  },
  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 230,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    maxLength: 31,
    titleImage: '',
    textAlign: 'center',
    // exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          'Imagine that yesterday you arrived in the centre of a new city. Write sentences in the past continuous to describe the scene. Use the words below or your own ideas.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <span style='color: rgb(81 131 142)'>A tourist was reading a book.</span>
        <div style='display:flex'>
            <div style='width:10cm;line-height:46px;padding: 10px 20px 10px 20px;background:rgb(167 212 233);border-radius:20px'>
              a tourist<br>some birds<br>two taxi drivers<br>a street vendor<br>a
               police officer<br>some schoolchildren<br>a shop owner<br>some workmen<br>some shoppers
            </div>
            <div style='margin-left:20px; width:10cm;line-height:46px;padding: 10px 20px 10px 20px;background:rgb(167 212 233);border-radius:20px'>
              stand / sit<br>argue / fight<br>take photos<br>eat / drink<br>talk on the phone<br>read a book / magazine<br>laugh / smile / sing<br>walk / run / ride a bike
            </div>
          </div>
          <textarea id='0' row='4'></textarea>
          `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 230,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    maxLength: 31,
    titleImage: '',
    textAlign: 'center',
    // exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title:
          'Work in pairs. Swap the sentences you wrote in exercise 5. Choose three of your partner’s sentences and write a question about each one using the past continuous.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <span style='color: rgb(81 131 142)'>A tourist was reading a book.
                What book was the tourist reading?</span>
        
          <textarea id='0' row='4'></textarea>
          `,
        answer: [],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E7',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
    question: [],
    questionImage: [[{ url: 'img/FriendsPlus/Page24/E7/1.jpg' }], [{ url: 'img/FriendsPlus/Page24/E7/2.jpg' }]],
  },
  8: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P24-E8',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 700 },
    textAlign: 'center',
    // exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title:
          'Write the opening paragraph of a story. Use your ideas from exercise 5 and include the extra information from exercise 7.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page24/E7/2.jpg'>
        <div style='display:flex'>
            <div style='width:10cm;line-height:46px;padding: 10px 20px 10px 20px;background:rgb(167 212 233);border-radius:20px'>
              a tourist<br>some birds<br>two taxi drivers<br>a street vendor<br>a
               police officer<br>some schoolchildren<br>a shop owner<br>some workmen<br>some shoppers
            </div>
            <div style='margin-left:20px; width:10cm;line-height:46px;padding: 10px 20px 10px 20px;background:rgb(167 212 233);border-radius:20px'>
              stand / sit<br>argue / fight<br>take photos<br>eat / drink<br>talk on the phone<br>read a book / magazine<br>laugh / smile / sing<br>walk / run / ride a bike
            </div>
          </div>
          <textarea id='0' row='4'></textarea>
          `,
        answer: [],
      },
    ],
  },
};

export default json;
