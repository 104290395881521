import { useState } from "react";

const useContent = () => {
  const [answers, setAnswers] = useState([]);

  const handleContent = (column, position, value) => {
    const result = answers.findIndex(
      (x) => x.column === column && x.position === position
    );
    if (result === -1) {
      setAnswers([...answers, { column, position, value }]);
    } else {
      setAnswers((prev) => {
        prev.splice(result, 1);
        return [...prev, { column, position, value }];
      });
    }
  };

  return {
    answers,
    setAnswers,
    handleContent,
  };
};

export default useContent;
