import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
        <strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>IB</span>Past simple (affirmative)</strong> <br /> <strong>1</strong>
        Write the past simple form of verbs 1–20. Verbs 1–10 are
regular and verbs 11–20 are irregular. <leftarrow name='1.2, 1.3'></leftarrow>`,
        color: 'black',
      },
    ],

    inputSize: 170,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='display:flex;line-height:50px'>
          <div style='margin:0px 100px;'>
              <b>1</b> look#<br>
              <b>2</b> study#<br>
              <b>3</b> stop#<br>
              <b>4</b> die#<br>
              <b>5</b> move#<br>
              <b>6</b> compare#<br>
              <b>7</b> agree#<br>
              <b>8</b> enjoy#<br>
              <b>9</b> realise#<br>
              <b>10</b> drop#<br>
          </div>
          <div>
              <b>11</b> feel#<br>
              <b>12</b> leave#<br>
              <b>13</b> spend#<br>
              <b>14</b> have#<br>
              <b>15</b> give#<br>
              <b>16</b> win#<br>
              <b>17</b> begin#<br>
              <b>18</b> go#<br>
              <b>19</b> get#<br>
              <b>20</b> say#<br>
          </div>
        </div>
				`,
        answer: [
          'looked',
          'studied',
          'stopped',
          'died',
          'moved',
          'compared',
          'agreed',
          'enjoyed',
          'realised',
          'dropped',
          'felt',
          'left',
          'spent',
          'had',
          'gave ',
          'won',
          'began',
          'went',
          'got',
          'said',
        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `Write the correct past simple affirmative form of the verb
be. <leftarrow name='1.4, 1.5'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 110,
    maxLength: 10,
    exerciseKey: 'img/FriendsPlus/Page108/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp; I#very relieved about my exam results.<br>
          <b>2</b>&ensp; The sports centre#shut last weekend.<br>
          <b>3</b>&ensp; You#cross with me for being late.<br>
          <b>4</b>&ensp; There#five winners in last week’s lottery.<br>
          <b>5</b>&ensp; Nobody#at home this morning.<br>
          <b>6</b>&ensp; My grandfather#a student at Harvard University.<br>
          <b>7</b>&ensp; We#on holiday in Indonesia last August.<br>
          <b>8</b>&ensp; Adam’s party#amazing.<br>
        </div>
				`,
        answer: ['was', 'was', 'were', 'were', 'was', 'was', 'were', 'was'],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `Complete the sentences with the past simple affirmative
form of the verbs in brackets. All the verbs are regular. <leftarrow name='1.1, 1.2 1.5'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 110,
    maxLength: 20,
    exerciseKey: 'img/FriendsPlus/Page108/E3/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b>1</b>&ensp; The train to Birmingham #(stop) at Oxford.<br>
          <b>2</b>&ensp; My friends #(work) very hard for their exams.<br>
          <b>3</b>&ensp; We #(try) some interesting dishes at the Lebanese restaurant.<br>
          <b>4</b>&ensp; Last night, I #(plan) my summer holiday.<br>
          <b>5</b>&ensp; You #(seem) very upset yesterday.<br>
          <b>6</b>&ensp; I #(chat) with my cousin for hours last night.<br>
          <b>7</b>&ensp; My uncle #(marry) his next-door neighbour.<br>
          <b>8</b>&ensp; Theo #(move) house three times last year.

        </div>
				`,
        answer: ['stopped', 'worked', 'tried', 'planned', 'seemed', 'chatted', 'married', 'moved'],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: `Complete the email. Use the past simple affirmative form
of the verbs in brackets. <leftarrow name='1.1, 1.2 1.5'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 110,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/E4/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='position: relative;color:rgb(36 94 109)'>
              <div><img src='/img/FriendsPlus/Page108/E4/1.jpg' style='width:21cm'/></div>
              <div style='position: absolute;width:19cm ;top: 65px; left: 40px;'>
                  Dear Abby<br>
                  How are you? I <sup><b>1</b></sup>#(see) Ryan yesterday and we 
                <sup><b>2</b></sup>#(chat) for hours about Zak’s party. We both <sup><b>3</b></sup>#(have)
                  a great time. We really <sup><b>4</b></sup>#(enjoy) the music and the
                  food <sup><b>5</b></sup>#(be) good too. Ryan <sup><b>6</b></sup>#(leave) just before
                  midnight, but I <sup><b>7</b></sup>#(stay) until 2 a.m.! I <sup><b>8</b></sup>#(feel) so
                  tired the next day! I hope Ryan’s parents <sup><b>9</b></sup>#(be) OK
                  about the mess. They <sup><b>10</b></sup>#(go) out for the evening.
                  See you soon! <br>
                  Ella
              </div>
            </div>
				`,
        answer: ['saw', 'chatted', 'had', 'enjoyed', 'was', 'left', 'stayed', 'felt', 'were', 'went'],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
        <strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>ID</span>Past simple (negative and interrogative)</strong> <br /> <strong>5</strong>
        Complete the sentences with the past simple negative form
of the verbs below. <leftarrow name='1.5, 1.6, 1.7'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 200,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/E5/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='color:rgb(244 145 41)'>be&emsp;can&emsp;enjoy&emsp;feel&emsp;leave&emsp;spend&emsp;study&emsp;win</div>
            <div style='line-height:50px'>
              <b>1</b>&emsp;I#the film. It was terrible!<br>
              <b>2</b>&emsp;Ben#talk until he was three.<br>
              <b>3</b>&emsp;We#the match. The score was 2–1 to the other team.<br>
              <b>4</b>&emsp;The weather#very good yesterday.<br>
              <b>5</b>&emsp;I#very well yesterday. I had a headache.<br>
              <b>6</b>&emsp;Joe failed the exam because he for#it.<br>
              <b>7</b>&emsp;We were late because we#early enough.<br>
              <b>8</b>&emsp;Fred#all his pocket money. He saved some.<br>
            </div>
				`,
        answer: [
          `didn't enjoy`,
          `couldn't`,
          `didn't win`,
          `wasn't`,
          `didn't feel`,
          `didn't study`,
          `didn't leave`,
          `didn't spend`,
        ],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: `Complete the dialogue with the correct past simple form of
the verbs be and can. <leftarrow name='1.7'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 210,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='width:23cm;'>
                <div style='display:flex;'>
                  <b>Sam</b>
                  <div style='margin-left:25px'>
                    You <sup><b>1</b></sup>#(not be) at the party last night.
                    Where <sup><b>2</b></sup>#(you / be)?
                  </div>
                </div>
                <div style='display:flex'>
                  <b>Leah</b>
                  <div style='margin-left:20px'>
                    I <sup><b>3</b></sup>#(not can) go. My cousins <sup><b>4</b></sup>#(be) here, so I had to stay in.
                  </div>
                </div>
                <div style='display:flex'>
                  <b>Sam</b>
                  <div style='margin-left:25px'>
                    <sup><b>5</b></sup>#(it / be) fun?
                  </div>
                </div>
                <div style='display:flex'>
                  <b>Leah</b>
                  <div style='margin-left:20px'>
                    Yes, it <sup><b>6</b></sup>#(be) good. We watched a film.
                    It <sup><b>7</b></sup>#(be) a really funny comedy.
                    <sup><b>8</b></sup>#(there / be) a lot of people at the
                    party?
                  </div>
                </div>
                <div style='display:flex'>
                  <b>Sam</b>
                  <div style='margin-left:25px'>
                    No, not many. It <sup><b>9</b></sup>#(not be) much fun.
                    And the music <sup><b>10</b></sup># (be) too loud!
                    I <sup><b>11</b></sup># (not can) hear what people were
                    saying. The film sounds better!
                  </div>
                </div>
            </div>
				`,
        answer: [
          `weren't`,
          'were you',
          `couldn't`,
          `were`,
          'Was',
          'was',
          'was',
          'Were there',
          `wasn't`,
          'was',
          `couldn't`,
        ],
      },
    ],
  },
  7: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E7',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
        <strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>ID</span>Question words</strong> <br /> <strong>7</strong>
        Complete the questions with the words below. <leftarrow name='1.8'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    inputSize: 200,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page108/E7/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='color:rgb(244 145 41)'>How&emsp;How often&emsp;What&emsp;When&emsp;Where&emsp;Who</div>
              <div style='line-height:50px'>
                <b>1</b>&ensp;#do you live?<br>
                <b>2</b>&ensp;#did you do on Saturday evening?<br>
                <b>3</b>&ensp;#did you get up this morning?<br>
                <b>4</b>&ensp;#did you travel to school this morning?<br>
                <b>5</b>&ensp;#do you go to the cinema?<br>
                <b>6</b>&ensp;#do you sit next to in English lessons?
              </div>
				`,
        answer: ['Where', 'What', 'When', 'How', 'How often', 'Who'],
      },
    ],
  },
  8: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E8',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '8',
        title: `<strong style='color:rgb(0 147 69);'>SPEAKING</strong> Work in pairs. Ask and answer the questions
in exercise 7.`,
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 500,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ ``,
        answer: [],
      },
    ],
  },
  9: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P108-E9',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '9',
        title: `Read the answers and complete the questions. <leftarrow name='1.8'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'left',
    inputSize: 510,
    maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page108/E9/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style='display:flex'>
              <b>1</b>
              <div style='margin-left:20px'>
                 What <span style='rgb(95 165 178)'>are you thinking about</span>? <br>
                I’m thinking about my next holiday.
              </div>
            </div>
            <div style='display:flex'>
              <b>2</b>
              <div style='margin-left:20px'>
                 Who#?<br>
                  She danced with Tom.
              </div>
            </div>
            <div style='display:flex'>
              <b>3</b>
              <div style='margin-left:20px'>
                 Who#?<br>
                 I’m looking for Zoe.
              </div>
            </div>
            <div style='display:flex'>
              <b>4</b>
              <div style='margin-left:20px'>
                 Where#?<br>
                 They walked to the beach.
              </div>
            </div>
            <div style='display:flex'>
              <b>5</b>
              <div style='margin-left:20px'>
                 What#?<br>
                 He’s worried about his exams.
              </div>
            </div>
				`,
        answer: [`did she dance with`, 'are you looking for', 'did they walk (to)', 'is he worried about'],
      },
    ],
  },
};

export default json;
