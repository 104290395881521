import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB5-U3-P23-E1',
    audio: '',
    video: '',
    component: TB2,
    textareaStyle: { width: 500 },
    exerciseKey: 'img/FriendsPlus/Page23/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: 'Work in pairs. Match each adjective in exercise 3 with two or more nouns from exercise 2.',
        color: '#8e258c',
      },
    ],
    hintBox: [
      {
        src: [
          "<s style='text-decoration-color: grey;'>dance</s>",
          'jeans',
          'run',
          'skirt',
          'catch',
          'fly',
          'talk',
          'sing',
          'scarf',
        ],
        borderColor: '#8e258c',
        width: 170,
      },
    ],
    questions: [
      {
        title: `
    <div style='color:rgb(142 37 140); width: 25cm;margin-bottom:20px'>
        <b >Landscape: features</b> &emsp;cave&emsp;&emsp;cliff&emsp;&emsp;desert&emsp;&emsp;forest&emsp;&emsp;hill&emsp;&emsp;lake&emsp;&emsp;
        mountain&emsp;&emsp;ocean&emsp;&emsp;river&emsp;&emsp;rocks&emsp;&emsp;shore&emsp;&emsp; stream&emsp;&emsp;valley&emsp;&emsp;
        volcano&emsp;&emsp;waterfall
        
    </div>`,
        answer: [],
      },
    ],
    tb2Style: { width: 1200 },
    type: 4, // Dạng 4 thì nhập số 4
    data: [
      {
        title: 'deep',
        content: ['#', '#', '#', '#', '#',],
        answers: ['cave', 'ocean', 'lake', 'river', 'valley'],
        commonStyle: {
          borderColor: '#8e258c',
          width: 170,
          fontSize: 30,
          marginBottom: 30,
          marginTop: -20,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 28 },
      },
      {
        title: 'icy',
        content: ['#', '#', '#', '#', '#', '#', '#', '#'],
        answers: ['lake', 'river', 'stream', 'cliff', 'hill', 'mountain', "ocean", 'rocks'], // content có nội dung thì không nhập đáp án.
        commonStyle: {
          marginTop: -20,
          width: 170,
          borderColor: '#8e258c',
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'low',
        content: ['#', '#', '#', '#'],
        answers: ['hill', 'waterfall', 'rocks', 'mountain'],
        commonStyle: {
          marginTop: -20,
          borderColor: '#8e258c',
          width: 170,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'narrow',
        content: ['#', '#', '#', '#', '#'],
        answers: ['cave', 'lake', 'river', 'stream', 'valley'],
        commonStyle: {
          marginTop: -20,
          borderColor: '#8e258c',
          width: 170,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'rocky',
        content: ['#', '#', '#', '#', ],
        answers: ['desert', 'mountain', 'shore', 'valley', ],
        commonStyle: {
          marginTop: -20,
          borderColor: '#8e258c',
          width: 180,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'shallow',
        content: ['#', '#', '#', '#', '#'],
        answers: ['cave', 'ocean', 'river', 'stream', 'waterfall'],
        commonStyle: {
          borderColor: '#8e258c',
          width: 170,
          marginTop: 20,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'steep',
        content: ['#', '#', '#', '#', '#'],
        answers: ['cliff', 'hill', 'mountain', 'valley', 'volcano'],
        commonStyle: {
          marginTop: 20,
          borderColor: '#8e258c',
          width: 180,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'tall',
        content: ['#', '#', '#', '#'],
        answers: ['cliff', 'waterfall', 'hill', 'mountain'],
        commonStyle: {
          marginTop: 20,
          borderColor: '#8e258c',
          width: 170,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
      {
        title: 'wide',
        content: ['#', '#', '#', '#', '#', '#', '#', '#'],
        answers: ['cave', 'lake', 'river', 'shore', 'stream', 'valley', 'ocean', 'waterfall'],
        commonStyle: {
          marginTop: 20,
          borderColor: '#8e258c',
          width: 170,
        },
        titleStyle: { backgroundColor: '#8e258c' },
        contentStyle: { fontSize: 27 },
      },
    ],
  },

  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P23-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_20.mp3',
    video: '',
    component: T6,
    textareaStyle: { width: 300 },
    inputSize: 50,
    maxLength: 1,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page23/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          "<headphone1 name='1.20' src='' ></headphone1>  Listen to four adverts. Match three of them with photos A–E.",
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display:flex; justify-content:space-around'>
                <div><b>1</b>#</div>  <div><b>2</b>#</div>  <div><b>3</b>#</div>  <div><b>4</b>#</div>
            </div>
            
          <img src='img/FriendsPlus/Page22/E1/1.jpg'>
         
          `,
        answer: ['E', 'D', '', 'A'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P23-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_20.mp3',
    video: '',
    component: T6,
    textareaStyle: { width: 300 },
    inputSize: 120,
    maxLength: 31,
    titleImage: '',
    textAlign: 'center',
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page23/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title:
          "<headphone1 name='1.20' src='' ></headphone1> Listen again. Complete the extracts (1– 6) from the adverts using the prepositions below, nouns from exercise 2 and adjectives from exercise 3.",
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='color:rgb(142 37 140);width: 20cm; border:3px solid rgb(142 37 140);border-radius: 20px;text-align: center;padding: 5px 20px'><i style='text-decoration:line-through'>across</i>&emsp;&emsp;along&emsp;&emsp;behind&emsp;&emsp;beside&emsp;&emsp;inside&emsp;&emsp;through</div>
          <div style='color:rgb(142 37 140); width: 25cm;margin:10px 0px; border:3px solid rgb(142 37 140);border-radius: 20px;text-align: center;padding: 5px 20px'>
            <b >Landscape: features</b> &emsp;dark&emsp;&emsp;deep&emsp;&emsp;icy&emsp;&emsp;low&emsp;&emsp;narrow&emsp;&emsp;rocky&emsp;&emsp;
            shallow&emsp;&emsp;steep&emsp;&emsp;tall&emsp;&emsp;wide
          </div>
          <div style='color:rgb(142 37 140); width: 25cm; border:3px solid rgb(142 37 140);border-radius: 20px;text-align: center;padding: 5px 20px'>
            <b >Landscape: features</b> &emsp;cave&emsp;&emsp;cliff&emsp;&emsp;desert&emsp;&emsp;forest&emsp;&emsp;hill&emsp;&emsp;lake&emsp;&emsp;
            mountain&emsp;&emsp;ocean&emsp;&emsp;river&emsp;&emsp;rocks&emsp;&emsp;shore&emsp;&emsp; stream&emsp;&emsp;valley&emsp;&emsp;
            volcano&emsp;&emsp;waterfall
          </div>
          <div style='line-height:50px'>
              <b>1</b>&emsp;Kayak <span style='color:rgb(118 188 205)'>across</span> icy <span style='color:rgb(118 188 205)'>lakes</span> and <span style='color:rgb(118 188 205)'>shallow</span> rivers.<br>
              <b>2</b>&emsp;Journey##mountains and
              deep# .<br>
              <b>3</b>&emsp;Explore the# caves# the
              coast of Mexico.<br>
              <b>4</b>&emsp;Stand# Lake Pinatubo, #a# lake
              a .<br>
              <b>5</b>&emsp;A# lake formed, which soon became#
              .<br>
              <b>6</b>&emsp;Find dark##tall#
              .
          </div>
          `,
        answer: [
          'through',
          'tall',
          'valleys',
          'rocky',
          'along',
          'beside',
          'inside',
          'volcano',
          'shallow',
          'deep',
          'caves',
          'behind',
          'waterfalls',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P23-E7',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E7/1.jpg' },
      ],
    ],
  },
};

export default json;
