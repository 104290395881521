import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E1/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 49,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
        <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
        Work in pairs. What are the people doing in the photos? 
        What do you think their jobs are? Compare your ideas.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; max-width: 700px; display: flex; align-items: start;">
						<img src="img/FriendsPlus/Page64/E1/1.jpg" style="max-width: 100%;" />
						<img src="img/FriendsPlus/Page64/E1/2.jpg" style="max-width: 100%;" />
					</div>
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Look quickly at texts A and B opposite. 
          Match each text with a photo from exercise 1 and one of the titles below. 
          There is one extra title.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: center;">
						<div>
							<div style="padding-left: 10px; margin-bottom: 20px;">
								<div style="display: flex;">
									<strong style="padding-right: 10px;">a</strong>
									<div><span>Model builder</span> <span style="padding-left: 70px;">Photo</span># <span style="padding-left: 20px;">Text</span>#</div>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">b</strong>
									<div><span>Costume designer</span> <span style="padding-left: 20px;">Photo</span># <span style="padding-left: 20px;">Text</span>#</div>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">c</strong>
									<div><span>Film tagger</span> <span style="padding-left: 97px;">Photo</span># <span style="padding-left: 20px;">Text</span>#</div>				
								</div>
							</div>

							<img src="img/FriendsPlus/Page64/E1/1.jpg" style="max-width: 100%;" />
						</div>
						
						<img src="img/FriendsPlus/Page64/E1/2.jpg" style="max-width: 100%;" />
					</div>

					<div style="max-width: 1000px; font-size: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="font-size: 50px; word-spacing: 5px; text-align: center;">
							The <span style="transform: translate(0, -3px); display: inline-block;"><span style="color: rgb(236, 0, 141); transform: rotate(-5deg); display: inline-block;">best</span></span> job in the world ... probably
						</div>

						<div style="margin-top: 10px;">
							<div style="display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">A</span>
								<div style="margin-left: 30px;">
									Back in 2012, Andrew Johnson planned to apply to a film school after graduation, rather than look for a job. 
									But then his dad noticed an advertisement for a job with toy manufacturer Lego. 
									Andrew, who was already a Lego fan, sent in an application. It included a video of himself making models. 
									The company loved it and invited him to participate in an unusual interview. 
									He had to compete against seven other finalists in a model-building test. Andrew won. 
									As a result, he was employed at Legoland Discovery Centre in Illinois as a Master Model Builder. 
									“Lego was just a hobby, but now I can do what I love and get paid for it,” said Andrew happily in an interview.
								</div>
							</div>

							<div style="margin-top: 10px; display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">B</span>
								<div style="margin-left: 30px;">
									Some film providers like Netflix employ people to watch new films and TV series, 
									then ask them to make a note of what type of film it is (horror, rom-com, etc.), 
									what age group and type of person might like it. The film provider can then recommend it to their members. 
									Joe Mason finished his degree in film studies about two years ago and didn’t know what to do next. 
									Then he read an article about film taggers. The job was so fantastic, and he wrote to Netflix. 
									At first, they weren’t interested, but when Joe told them he speaks fluent French, they gave him a job. 
									He watches French films and TV programmes. “It’s a great job because I can have flexible working hours,” said he. 
									The only problem with the job is that Joe can’t choose what he wants to watch.
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['2', 'A', '', '', '1', 'B'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E3/Key/answerKey.png',
		component: T6,
		inputSize: 140,
		maxLength: 21,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '3',
				title: `
					Read the <span style="color: rgb(66, 64, 153);">Reading Strategy</span>. 
					Then look at the sentences in exercise 4 below. 
					Find a synonym or paraphrase in the text for each underlined phrase in the sentences.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<i>Answer:</i><br/> <strong>1</strong> # <strong>2</strong> # <strong>3</strong> # <strong>4</strong> <input id='3' width='260px' /> 
					</div>

					<div style="margin-top: 20px;display: flex; font-size: 20px;">
						<div style="margin-left: 20px; max-width: 450px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(66, 64, 153); background-color: rgb(230, 229, 240); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
							<span style="font-weight: bold; color: rgb(66, 64, 153);">Reading strategy</span>
							<div>
								When you complete gap-fill sentences about a text, the words you need to write are in the text. 
								However, the words immediately before or after the gap may be different. 
								Think carefully about the meaning and look for synonyms and paraphrases.
							</div>
						</div>

						<div style="margin-left: 20px; max-width: 550px; padding: 20px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(66, 64, 153); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
							<div><strong>Exercise 4</strong></div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>The toy company <u>was very keen on</u> the _____ that Andrew Johnson sent with his job application. [1word]</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>Andrew is happy <u>to earn money for</u> something that used to be a _____. [1 word]</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>Netflix <u>offered</u> Joe the job because he speaks _____. [2 words]</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span><u>A disadvantage</u> of the job is that Joe can’t _____ what programmes to watch. [1 word]</span>
							</div>
						</div>
					</div>
				`,
				answer: ['loved', 'get paid for', 'gave', 'The only problem with'],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E4/Key/answerKey.png',
		component: T6,
		inputSize: 90,
		maxLength: 13,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '4',
				title: `
					Complete the sentences with information from the text. 
					Use your answers to exercise 3 to help you.
			`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="font-size: 20px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>The toy company <u>was very keen on</u> the # that Andrew Johnson sent with his job application. [1word]</span>
						</div>
						<div style="display: flex; margin-top: 5px;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Andrew is happy <u>to earn money for</u> something that used to be a #. [1 word]</span>
						</div>
						<div style="display: flex; margin-top: 5px;">
							<strong style="padding-right: 10px;">3</strong>
							<span>Netflix <u>offered</u> Joe the job because he speaks <input id='2' width='140px' />. [2 words]</span>
						</div>
						<div style="display: flex; margin-top: 5px;">
							<strong style="padding-right: 10px;">4</strong>
							<span><u>A disadvantage</u> of the job is that Joe can’t # what programmes to watch. [1 word]</span>
						</div>
					</div>

					<div style="margin-top: 20px; max-width: 1000px; font-size: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="font-size: 50px; word-spacing: 5px; text-align: center;">
							The <span style="transform: translate(0, -3px); display: inline-block;"><span style="color: rgb(236, 0, 141); transform: rotate(-5deg); display: inline-block;">best</span></span> job in the world ... probably
						</div>

						<div style="margin-top: 10px;">
							<div style="display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">A</span>
								<div style="margin-left: 30px;">
									Back in 2012, Andrew Johnson planned to apply to a film school after graduation, rather than look for a job.
									But then his dad noticed an advertisement for a job with toy manufacturer Lego.
									Andrew, who was already a Lego fan, sent in an application. It included a video of himself making models.
									The company loved it and invited him to participate in an unusual interview.
									He had to compete against seven other finalists in a model-building test. Andrew won.
									As a result, he was employed at Legoland Discovery Centre in Illinois as a Master Model Builder.
									“Lego was just a hobby, but now I can do what I love and get paid for it,” said Andrew happily in an interview.
								</div>
							</div>

							<div style="margin-top: 10px; display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">B</span>
								<div style="margin-left: 30px;">
									Some film providers like Netflix employ people to watch new films and TV series,
									then ask them to make a note of what type of film it is (horror, rom-com, etc.),
									what age group and type of person might like it. The film provider can then recommend it to their members.
									Joe Mason finished his degree in film studies about two years ago and didn’t know what to do next.
									Then he read an article about film taggers. The job was so fantastic, and he wrote to Netflix.
									At first, they weren’t interested, but when Joe told them he speaks fluent French, they gave him a job.
									He watches French films and TV programmes. “It’s a great job because I can have flexible working hours,” said he.
									The only problem with the job is that Joe can’t choose what he wants to watch.
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['video', 'hobby', 'fluent French', 'choose'],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E5/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: '20px',
		},
		titleQuestion: [
			{
				num: '5',
				title: `
					Read the text again. 
					Are the sentences true or false. Write T or F.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; font-size: 20px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>Andrew’s brother told him about the job with Lego. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Andrew had to take part in a model-building competition. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>Joe Mason didn’t complete his university degree. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>Netflix were very pleased to receive Joe’s application. #</span>				
						</div>
					</div>

					<div style="max-width: 1000px; font-size: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="font-size: 50px; word-spacing: 5px; text-align: center;">
							The <span style="transform: translate(0, -3px); display: inline-block;"><span style="color: rgb(236, 0, 141); transform: rotate(-5deg); display: inline-block;">best</span></span> job in the world ... probably
						</div>

						<div style="margin-top: 10px;">
							<div style="display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">A</span>
								<div style="margin-left: 30px;">
									Back in 2012, Andrew Johnson planned to apply to a film school after graduation, rather than look for a job. 
									But then his dad noticed an advertisement for a job with toy manufacturer Lego. 
									Andrew, who was already a Lego fan, sent in an application. It included a video of himself making models. 
									The company loved it and invited him to participate in an unusual interview. 
									He had to compete against seven other finalists in a model-building test. Andrew won. 
									As a result, he was employed at Legoland Discovery Centre in Illinois as a Master Model Builder. 
									“Lego was just a hobby, but now I can do what I love and get paid for it,” said Andrew happily in an interview.
								</div>
							</div>

							<div style="margin-top: 10px; display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">B</span>
								<div style="margin-left: 30px;">
									Some film providers like Netflix employ people to watch new films and TV series, 
									then ask them to make a note of what type of film it is (horror, rom-com, etc.), 
									what age group and type of person might like it. The film provider can then recommend it to their members. 
									Joe Mason finished his degree in film studies about two years ago and didn’t know what to do next. 
									Then he read an article about film taggers. The job was so fantastic, and he wrote to Netflix. 
									At first, they weren’t interested, but when Joe told them he speaks fluent French, they gave him a job. 
									He watches French films and TV programmes. “It’s a great job because I can have flexible working hours,” said he. 
									The only problem with the job is that Joe can’t choose what he wants to watch.
								</div>
							</div>
						</div>
					</div>
        `,
				answer: ['F', 'T', 'F', 'F'],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P64-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E6/Key/answerKey.png',
		component: T6,
		inputSize: 100,
		maxLength: 6,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		titleQuestion: [
			{
				num: '6',
				title: `
					<span style="color: rgb(146, 39, 144)">VOCABULARY</span>
					Complete the job-related collocations from the texts using the verbs below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 400px; display: flex; justify-content: space-between; color: rgb(66, 64, 153);">
						<span>join</span> <span>look</span> <span>notice</span> <span>offer</span> <span>send</span> <span>take</span> 
					</div>

					<div style="font-weight: bold;">Collocations: jobs</div>

					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span># for a job</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span># an advertisement</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span># in an application</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span># somebody a job</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span># a team</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">6</strong>
							<span># a day off</span>				
						</div>
					</div>

					<div style="max-width: 1000px; font-size: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
						<div style="font-size: 50px; word-spacing: 5px; text-align: center;">
							The <span style="transform: translate(0, -3px); display: inline-block;"><span style="color: rgb(236, 0, 141); transform: rotate(-5deg); display: inline-block;">best</span></span> job in the world ... probably
						</div>

						<div style="margin-top: 10px;">
							<div style="display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">A</span>
								<div style="margin-left: 30px;">
									Back in 2012, Andrew Johnson planned to apply to a film school after graduation, rather than look for a job. 
									But then his dad noticed an advertisement for a job with toy manufacturer Lego. 
									Andrew, who was already a Lego fan, sent in an application. It included a video of himself making models. 
									The company loved it and invited him to participate in an unusual interview. 
									He had to compete against seven other finalists in a model-building test. Andrew won. 
									As a result, he was employed at Legoland Discovery Centre in Illinois as a Master Model Builder. 
									“Lego was just a hobby, but now I can do what I love and get paid for it,” said Andrew happily in an interview.
								</div>
							</div>

							<div style="margin-top: 10px; display: flex;">
								<span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">B</span>
								<div style="margin-left: 30px;">
									Some film providers like Netflix employ people to watch new films and TV series, 
									then ask them to make a note of what type of film it is (horror, rom-com, etc.), 
									what age group and type of person might like it. The film provider can then recommend it to their members. 
									Joe Mason finished his degree in film studies about two years ago and didn’t know what to do next. 
									Then he read an article about film taggers. The job was so fantastic, and he wrote to Netflix. 
									At first, they weren’t interested, but when Joe told them he speaks fluent French, they gave him a job. 
									He watches French films and TV programmes. “It’s a great job because I can have flexible working hours,” said he. 
									The only problem with the job is that Joe can’t choose what he wants to watch.
								</div>
							</div>
						</div>
					</div>
		    `,
				answer: ['look', 'notice', 'send', 'offer', 'join', 'take'],
			},
		],
	},
};

export default json;
