import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P51-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
            <span style="color: rgb(45,206,137);margin-right:10px">SPEAKING</span> 
            <span>Describe the photo. Use the words below to help you.</span>
          <div style="color: rgb(146,39,143);">
            <span> nouns chimney factory  pollution smoke <br> verbs  breathe pollute pour out</span>
          </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page51/E1/E1.jpg' }]],
  },

  2: {
    unit: 'Weather',
    id: 'FG10-W-P51-E2',
    exerciseKey: '/img/FriendsPlus/Page51/E2/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_07.mp3',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 10,
    textAlign: 'center',
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '2',
        title: `
         <audioimage name="2.07"></audioimage> 
            <span>
                Complete the compound nouns in the fact file
                with the words below. Use a dictionary to help you.
                Then listen and check.
            </span>
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
          <span style="color: rgb(146,39,143);">
                climate fossil global greenhouse ice rain  renewable sea surface
          </span>
        </div>
            
          <div style='position: relative;'>
              <div><img src='/img/FriendsPlus/Page51/E3-02.png' style="width:800px;"/></div>
                <div style='position: absolute; top: 154px; left: 490px;'>#</div>
                <div style='position: absolute; top: 222px; left: 250px;'>#</div>
                <div style='position: absolute; top: 256px; left: 85px;'>#</div>
                <div style='position: absolute; top: 290px; left: 70px;'>#</div>
                <div style='position: absolute; top: 326px; left: 530px;'>#</div>
                <div style='position: absolute; top: 494px; left: 555px;'>#</div>
                <div style='position: absolute; top: 564px; left: 70px;'>#</div>
                <div style='position: absolute; top: 564px; left: 555px;'>#</div>
                <div style='position: absolute; top: 700px; left: 85px;'>#</div>
              </div>
          </div>

      
        `,
        answer: ['surface', 'fossil', 'greenhouse', 'global', 'rain', 'climate', 'Sea', 'ice', 'renewable'],
      },
    ],
  },

  3: {
    unit: 'Weather',
    id: 'FG10-W-P51-E3',
    exerciseKey: '/img/FriendsPlus/Page51/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 370,
    // maxLength: 33,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',

      fontSize: 23,
    },
    titleQuestion: [
      {
        num: '3',
        title: `
        Read the <span style="color:rgb(146,39,143) ;">Learn this!</span> box. Find five phrasal verbs in
          the fact file. Which have a similar meaning to the
          main verb and which have a completely different
          meaning?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style =" display: flex">
            <div><img src="img/FriendsPlus/Page51/E3.png" alt="">
            <div style="margin-top: 10px;">
              <span ><b>Answers:</b></span>
            </div>
            <div style ="margin-top:10px">
              <span><b>Similar meaning to the main verb:</b></span>#
            </div>
            <div style ="margin-top:20px; display:flex">
              <span><b>Different meaning from the main verb:</b></span>
              <span> <input type="text" width="100px"></span>
            </div>
        </div>
            <img src="img/FriendsPlus/Page51/E3-02.png" style="width:620px">
          </div>
        `,
        answer: ['heat up, give off, cut down, die out', 'run out'],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P51-E4',
    exerciseKey: '/img/FriendsPlus/Page51/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 330,
    maxLength: 21,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',

      fontSize: 23,
    },
    textareaStyle: { width: 340 },
    titleQuestion: [
      {
        num: '4',
        title: `
        Read what two students say about global warming
          and climate change. Who is more worried about
          the situation?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="margin-top: 20px;">
          <span ><b>Answers:</b></span>#
			  <div ><img src='img/FriendsPlus/Page51/E4/1.jpg' alt='' style='margin-top: 20px; width:1100px;'/></div>
        </div>
        `,
        answer: ['Thoa is more worried.'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P51-E5',
    exerciseKey: '/img/FriendsPlus/Page51/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 102,
    textAlign: 'center',
    selectStyle: {
      color: '#00ADFE',
      width: 230,
      textAlign: 'center',
    },
    // selectOptionRandom: true,
    selectOptionValues: ['similar', 'different'],
    titleQuestion: [
      {
        num: '5',
        title: `
          Find nine phrasal verbs in the texts in exercise 4.
          Match five of them with the definitions below.
          Check the meaning of the others in the dictionary.
          Are the meanings similar to the main verbs or
          different?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
      <div style="margin: 10px;">
        <div style="display: flex;margin-bottom: 10px;"><b style="margin-right: 10px">1.</b> continue - # <b> <select id='9'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;"><b style="margin-right: 10px">2.</b> delay - # <b> <select id='10'></select>  </b>  </div>
        <div style="display: flex;margin-bottom: 10px;"><b style="margin-right: 10px">3.</b> stop using or doing something - # <b> <select id='11'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;"><b style="margin-right: 10px;">4.</b> take care of - # <b> <select id='12'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;"><b style="margin-right: 10px;">5.</b> think of  - # <b> <select id='13'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;margin-left: 104px;"><b style="margin-right: 10px;"></b>- # <b> <select id='14'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;margin-left: 104px;"><b style="margin-right: 10px;"></b>- # <b> <select id='15'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;margin-left: 104px;"><b style="margin-right: 10px;"></b>- # <b> <select id='16'></select>  </b></div>
        <div style="display: flex;margin-bottom: 10px;margin-left: 104px;"><b style="margin-right: 10px;"></b>- # <b> <select id='17'></select>  </b></div>
      </div>
			  <div ><img src='img/FriendsPlus/Page51/E5/1.jpg' alt='' style='width:1200px;'/></div>
        `,
        answer: [
          'carry on',
          'put off',
          'give up',
          'look after',
          'come up with',
          'use up',
          'wake up',
          'close down',
          'rely on',

          'different',
          'different',
          'different',
          'different',
          'different',
          'similar',
          'similar',
          'similar',
          'similar',
        ],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P51-E6',
    exerciseKey: '/img/FriendsPlus/Page51/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 110,
    maxLength: 6,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: { width: 650, fontSize: 23 },
    titleQuestion: [
      {
        num: '6',
        title: `
            Complete the phrasal verbs in the sentences with
            the words below. 
        <div >
          <span style="color: rgb(146,39,143);">cut down looked out up used</span>
      </div>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div >
          <div style="margin-bottom: 10px;">
            <span><b>1.</b></span> Internet cafés are dying# because people use smartphones instead.
          </div>
          <div style="margin-bottom: 10px;">
            <span><b>2.</b></span> My dad# down the tree in the front garden.
          </div>
          <div style="margin-bottom: 10px;">
            <span><b>3.</b></span> Jo gave# sugar because she wants to be healthier.
          </div>
          <div style="margin-bottom: 10px;">
            <span><b>4.</b></span> Supermarkets are causing small shops to close#.
          </div>
          <div style="margin-bottom: 10px;">
            <span><b>5.</b></span> We’ve# up all the bread. Can you buy some more?
          </div>
          <div style="margin-bottom: 10px;">
            <span><b>6.</b></span> I# after my little brother while Mum was out
          </div>
        </div>
        `,
        answer: ['out', 'cut', 'up', 'down', 'used', 'looked'],
      },
    ],
  },
};
export default json;
