import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E1',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_15.mp3',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 720,
		maxLength: 79,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 800 },
		titleQuestion: [
			{
				num: '1',
				title: `<audioimage name="2.15"></audioimage> 
          Look at the photo. How do you think the girl is feeling? 
          Then read and listen to the dialogue.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; align-items: start;">
            <div style="margin-top: 20px;"><img src="img/FriendsPlus/Page60/E1/1.jpg" style="max-width: 100%;" /></div>
            <div style="margin-left: 20px; width: 600px">
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Hi, Mia. Are you OK? You look a bit anxious.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>I’ve got a job interview in twenty minutes.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Oh! I won’t chat, then, I promise!</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>It’s OK. I’m going to leave soon anyway. I need to walk to Hill Top Road. Is it far?</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Not really. It’ll take about ten minutes.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>Oh no. Look at that rain! I’m going to get wet!</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>I’ll lend you my umbrella.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>It’s OK. I’ll call a taxi.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>There isn’t time for that. Here, take it.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>Thanks. I’ll give it back later. Where will you be?</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>I’ll wait here for you. Good luck!</span>
              </div>
            </div>
          </div>
          <textarea id="0" rows="3"></textarea>
        `,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E2/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
					Read the <span style="color: rgb(246, 141, 31);">Learn this!</span> box. 
          Then find all the examples of <i>will</i> and <i>be going to</i> in the dialogue in exercise 1.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-bottom: 10px;">
            <i>Answers</i>: <br/> # # # # <br/> # # # # # 
          </div>
          <div style="display: flex;">
            <div style="padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
              <div>
                <span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
                <i>will</i> and <i>be going to</i>
              </div>
              <div>
                <div style="display: flex;">
                  <strong style="padding-right: 10px;">a</strong>
                  <span>For predictions, we use:</span>			
                </div>
                <div style="padding-left: 10px; margin-bottom: 16px;">
                  <div style="margin-left: 15px; display: flex;">
                    <strong style="padding-right: 10px;">1</strong>
                    <span>
                      <i>be going to</i> based on what we can see or hear. <br/>
                      <span style="color: rgb(75, 142, 168);">Look at those clouds! There’s going to be a storm.</span>
                    </span>			
                  </div>
                  <div style="margin-left: 15px; display: flex;">
                    <strong style="padding-right: 10px;">2</strong>
                    <span>
                      <i>will</i> based on what we know or is just a guess. <br/>
                      <span style="color: rgb(75, 142, 168);">I <span style="color: black">(</span>don’t<span style="color: black">)</span> think the weather will be warmer next month.</span>
                    </span>			
                  </div>
                </div>
                <div>
                  <div style="display: flex;">
                    <strong style="padding-right: 10px;">b</strong>
                    <span>For plans, we use:</span>			
                  </div>
                  <div style="padding-left: 10px; margin-bottom: 16px;">
                    <div style="margin-left: 15px; display: flex;">
                      <strong style="padding-right: 10px;">1</strong>
                      <span>
                        <i>be going to</i> when we have already decided what to do. <br/>
                        <span style="color: rgb(75, 142, 168);">I’m going to stay in tonight. I’ve got the DVD ready!</span>
                      </span>			
                    </div>
                    <div style="margin-left: 15px; display: flex;">
                      <strong style="padding-right: 10px;">2</strong>
                      <span>
                        <i>will</i> when we are deciding what to do as we speak. <br/>
                        <span style="color: rgb(75, 142, 168);">Somebody’s at the door. I’ll see who it is.</span>
                      </span>			
                    </div>
                  </div>
                </div>
                <div>
                  <div style="display: flex;">
                    <strong style="padding-right: 10px;">c</strong>
                    <span>For offers and promises, we use <i>will</i>.</span>			
                  </div>
                  <span style="margin-left: 50px;: 10px; color: rgb(75, 142, 168);">I’ll phone you later. I won’t forget.</span>
                </div>
              </div>
            </div>
            <div style="margin-left: 20px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); width: 500px; font-size: 20px;">
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Hi, Mia. Are you OK? You look a bit anxious.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>I’ve got a job interview in twenty minutes.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Oh! I won’t chat, then, I promise!</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>It’s OK. I’m going to leave soon anyway. I need to walk to Hill Top Road. Is it far?</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>Not really. It’ll take about ten minutes.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>Oh no. Look at that rain! I’m going to get wet!</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>I’ll lend you my umbrella.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>It’s OK. I’ll call a taxi.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>There isn’t time for that. Here, take it.</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 36px; font-weight: bold;">Mia</span>
                <span>Thanks. I’ll give it back later. Where will you be?</span>
              </div>
              <div style="display: flex;">
                <span style="margin-right: 20px; font-weight: bold;">Toby</span>
                <span>I’ll wait here for you. Good luck!</span>
              </div>
            </div>
          </div>
	      `,
				answer: [
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
					"I won't chat / I'm going to leave / It'll take / I'm going to get / I'll lend / I'll call / I'll give / Where will you be? / I'll wait",
				],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E3/Key/answerKey.png',
		component: T6,
		inputSize: 210,
		maxLength: 20,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
					Match each example of <i>will</i> and <i>be going to</i> in the dialogue with a rule in the <span style="color: rgb(246, 141, 31);">Learn this!</span> box.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				  <div style="margin-bottom: 10px;">
				    <i>Answers</i>: <br/>
						<div><span style="font-weight: bold; padding-right: 10px;">a</span> <strong>1</strong># <strong style="padding-left: 10px;">2</strong>#</div>
						<div><span style="font-weight: bold; padding-right: 10px;">b</span> <strong>1</strong># <strong style="padding-left: 10px;">2</strong>#,#</div>
						<div><span style="font-weight: bold; padding-right: 10px;">c</span> # # # <br/><span style="padding-right: 30px;"></span> # #</div>
				  </div>

				  <div style="display: flex;">
						<div style="padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); width: 500px; font-size: 20px;">
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>Hi, Mia. Are you OK? You look a bit anxious.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 36px; font-weight: bold;">Mia</span>
								<span>I’ve got a job interview in twenty minutes.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>Oh! I won’t chat, then, I promise!</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 36px; font-weight: bold;">Mia</span>
								<span>It’s OK. I’m going to leave soon anyway. I need to walk to Hill Top Road. Is it far?</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>Not really. It’ll take about ten minutes.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 36px; font-weight: bold;">Mia</span>
								<span>Oh no. Look at that rain! I’m going to get wet!</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>I’ll lend you my umbrella.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 36px; font-weight: bold;">Mia</span>
								<span>It’s OK. I’ll call a taxi.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>There isn’t time for that. Here, take it.</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 36px; font-weight: bold;">Mia</span>
								<span>Thanks. I’ll give it back later. Where will you be?</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 20px; font-weight: bold;">Toby</span>
								<span>I’ll wait here for you. Good luck!</span>
							</div>
						</div>

						<div style="margin-left: 20px;">
							<div style="padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
								<div>
									<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span>
									<i>will</i> and <i>be going to</i>
								</div>
								<div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">a</strong>
										<span>For predictions, we use:</span>
									</div>
									<div style="padding-left: 10px; margin-bottom: 16px;">
										<div style="margin-left: 15px; display: flex;">
											<strong style="padding-right: 10px;">1</strong>
											<span>
												<i>be going to</i> based on what we can see or hear. <br/>
												<span style="color: rgb(75, 142, 168);">Look at those clouds! There’s going to be a storm.</span>
											</span>
										</div>
										<div style="margin-left: 15px; display: flex;">
											<strong style="padding-right: 10px;">2</strong>
											<span>
												<i>will</i> based on what we know or is just a guess. <br/>
												<span style="color: rgb(75, 142, 168);">I <span style="color: black">(</span>don’t<span style="color: black">)</span> think the weather will be warmer next month.</span>
											</span>
										</div>
									</div>
									<div>
										<div style="display: flex;">
											<strong style="padding-right: 10px;">b</strong>
											<span>For plans, we use:</span>
										</div>
										<div style="padding-left: 10px; margin-bottom: 16px;">
											<div style="margin-left: 15px; display: flex;">
												<strong style="padding-right: 10px;">1</strong>
												<span>
													<i>be going to</i> when we have already decided what to do. <br/>
													<span style="color: rgb(75, 142, 168);">I’m going to stay in tonight. I’ve got the DVD ready!</span>
												</span>
											</div>
											<div style="margin-left: 15px; display: flex;">
												<strong style="padding-right: 10px;">2</strong>
												<span>
													<i>will</i> when we are deciding what to do as we speak. <br/>
													<span style="color: rgb(75, 142, 168);">Somebody’s at the door. I’ll see who it is.</span>
												</span>
											</div>
										</div>
									</div>
									<div>
										<div style="display: flex;">
											<strong style="padding-right: 10px;">c</strong>
											<span>For offers and promises, we use <i>will</i>.</span>
										</div>
										<span style="margin-left: 50px;: 10px; color: rgb(75, 142, 168);">I’ll phone you later. I won’t forget.</span>
									</div>
								</div>

								
							</div>
							<div style="margin-top: 10px; width: 90%; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
									<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
									<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;"><strong>Grammar Builder 5B</strong> <i>will, be going to</i>: page 116</span>
								</div>
							
				    </div>
				`,
				answer: [
					"I'm going to get wet",
					"It'll take",
					"I'm going to leave",
					"I'll call",
					'Where will you be',
					"I'll move my bag",
					"I won't chat",
					"I'll lend",
					"I'll give",
					"I'll wait",
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E4/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		recorder: true,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		hintBox: [
			{
				src: ['I’ll probably work in a café some time in the future.'],
				borderColor: 'rgb(254, 217, 179)',
				arrow: true,
				position: 2,
				width: 600,
			},
		],
		titleQuestion: [
			{
				num: '4',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in groups. Talk about your plans and predictions of the future. 
					Use <i>will</i> and <i>be going to.</i>
				`,
				color: '#860052',
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
					<div style="margin-top: 30px;"><hintbox id='0'></hintbox></div>
        `,
				answer: [
					'be on your feet',
					'work outdoors',
					'work nine-to-five',
					'be on your feet',
					'wear a uniform',
					'work outdoors',
					'work with children',
				],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E5/Key/answerKey.png',
		component: T6,
		inputSize: 1090,
		maxLength: 127,
		hideBtnFooter: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '5',
				title: `
					Describe the photo. What is the job of the man on the right? 
					Use the words below to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					
					<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
						<div style="margin-bottom: 10px; width: 600px; display: flex; justify-content: space-between; color: rgb(246, 141, 31); font-size: 22px;">
							<span>button</span> <span>floor</span> <span>lift</span> <span>operate (v)</span> <span>open (v)</span> <span>press (v)</span> 
						</div>
						<img src="img/FriendsPlus/Page60/E5/1.jpg" style="max-width: 100%;" />
					</div>

					<div>
						<i>Answer:</i> <br/> #
					</div>
        `,
				answer: [
					"The man's job is to operate the lift. He is pressing buttons to open and close the doors and move the lift to different floors.",
				],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E6/Key/answerKey.png',
		component: T6,
		inputSize: 1090,
		maxLength: 127,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		textareaStyle: { width: 600 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
					Read the text and check your answer to exercise 5. 
					What other jobs do you think might disappear in the future?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; justify-content: center; align-items: center;">
						<img src="img/FriendsPlus/Page60/E5/1.jpg" style="max-width: 100%;" />
					</div>
					<textarea id="0" rows="3"></textarea>
        `,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E7/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 14,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '7',
				title: `
				Look at the highlighted first conditional sentence in the text in exercise 6, 
				and read the <span style="color: rgb(246, 141, 31);">Learn this!</span> box below. 
				Complete rule a with <i>present simple</i> and <i>will + verb</i>. 
				Then find two more examples in the text.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page60/E5/1.jpg" style="max-width: 100%;" />
						</div>
						<div style="margin-left: 20px; width: 550px;">
							<div style="padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(246, 134, 32); background-image: radial-gradient(rgb(250 244 244), rgb(254, 230, 204)); font-size: 20px;">
								<div>
									<span style="color: rgb(246, 141, 31); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
									<strong>The first conditional</song>
								</div>
								<div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">a</strong>
										<span>
											We use the first conditional to predict the result of an action. 
											We use the <sup>1</sup># to describe the action, 
											and <sup>2</sup># to describe the result. <br/>
											<span style="color: rgb(75, 142, 168);">If I get the job, I’ll have to move to New York.</span>
										</span>			
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">b</strong>
										<span>
											The if clause can come before or after the main clause. 
											If it comes after, we don’t use a comma. <br/> 
											<span style="color: rgb(75, 142, 168);">I won’t take the job if it isn’t challenging enough.</span>
										</span>			
									</div>
								</div>
							</div>
							<div style="margin-top: 15px; margin-left: 10px; width: 95%; border-radius: 10px; box-shadow: 0 0 10px rgb(246, 141, 31); display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
								<span style="margin-left: 10px; color: rgb(246, 141, 31); font-size: 20px;"><strong>Grammar Builder 5B</strong> The first conditional: page 116</span>
							</div>
						</div>
					</div>
        `,
				answer: ['present simple', 'will + verb'],
			},
		],
	},
	8: {
		unit: 'Ambition',
		id: 'FG10-A-P60-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page60/E8/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		recorder: true,
		hideBtnFooter: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Ask and answer the questions.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div>What will you do if ...</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>you lose your mobile phone?</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>it rains all day on Saturday?</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>you get good marks in your final exams?</span>				
						</div>
					</div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
