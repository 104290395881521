import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong><span style='color: rgb(246 140 31)'>8B</span> The passive (present simple and past simple)</strong> <br /> 1 Write the past participle of these verbs.",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 150,
		exerciseKey: 'img/FriendsPlus/Page122/E1/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px; justify-content: space-between; width: 650px;">
            <div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>1</strong>
                  </div>
                  <span>breake</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>2</strong>
                  </div>
                  <span>take</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>3</strong>
                  </div>
                  <span>make</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>4</strong>
                  </div>
                  <span>send</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>5</strong>
                  </div>
                  <span>spend</span>
                </div>  
                <input />
              </div>
            </div>
            <div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>6</strong>
                  </div>
                  <span>fly</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>7</strong>
                  </div>
                  <span>try</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>8</strong>
                  </div>
                  <span>show</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>9</strong>
                  </div>
                  <span>grow</span>
                </div>  
                <input />
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between">
                <div style="display: flex; align-items: center">
                  <div style="width: 50px">
                    <strong>10</strong>
                  </div>
                  <span>know</span>
                </div>  
                <input />
              </div>
            </div>
          </div>
				`,
				answer: ['broken', 'taken', 'made', 'sent', 'spent', 'flown', 'tried', 'shown', 'grown', 'known'],
			},
		],
	},
	2: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title:
					"Complete the sentences with the correct form of the verb be. <img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.1</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page122/E2/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <span>I <input /> mugged last summer in New York.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <span>The museum <input /> renovated two years ago.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <span>Good food <input /> served every night in our restaurant.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <span>Nowadays, most food <input /> made in factories.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <span>The video clip <input /> viewed over a million times that day.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <span>Most newspapers <input /> published online these days.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <span>The first TV programmes <input /> broadcast about 90 years ago.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <span>Every year, we <input /> invited to my aunt’s house for New Year, but we never go!</span>
          </div>
        `,
				answer: ['was', 'was', 'is', 'is', 'was', 'are', 'were', 'are'],
			},
		],
	},
	3: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title:
					"Complete the description using the present simple passive form of the verbs in brackets.<img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.1</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 150,
		exerciseKey: 'img/FriendsPlus/Page122/E3/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="background-color: rgb(238 234 220); padding: 20px">
            <span>
              Our pizzas <sup>1</sup> <input /> 
              (make) freshly every evening from a recipe which <sup>2</sup> <input/>
              (know) by only a few people. Only the finest ingredients <sup>3</sup> <input />
              (use). The tomatoes <sup>4</sup> <input />
              (grow) in the south of Italy and the cheese <sup>5</sup> <input />
              (produce) using only the finest buffalo milk. The pizzas  <sup>6</sup> <input />
              (prepare) by our skilled pizza chefs. Then they  <sup>7</sup> <input />
              (cook) in our traditional pizza ovens. They <sup>8</sup><input />
              (serve) with fresh salad.
            </span>
          </div>
        `,
				answer: [
					'are made',
					'is known',
					'are used',
					'are grown',
					'is produced',
					'are prepared',
					'are cooked',
					'are served',
				],
			},
		],
	},
	4: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					"Complete the broadcasting facts. Use the past simple passive form of the verbs in brackets <img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.1, 8.2</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			// fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 270,
		exerciseKey: 'img/FriendsPlus/Page122/E4/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1920</strong>
            <span>
              The first radio broadcast <sup>1</sup> <input /> (transmit) by KDKA in the USA.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1925</strong>
            <span>
              The first soap opera, <i>The Smith Family</i> <sup>2</sup> <input /> (broadcast) on the radio.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1928</strong>
            <span>
              A television image <sup>3</sup> <input /> (send) from England to the USA for the first time.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1928</strong>
            <span>
              The first television set <sup>4</sup> <input /> (sell).
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1936</strong>
            <span>
              The first BBC TV programmes <sup>5</sup> <input /> (make) in the UK.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1940</strong>
            <span>
              Colour TV sets <sup>6</sup> <input /> (demonstrate) by CBS in New York.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1947</strong>
            <span>
              Baseball games <sup>7</sup> <input /> (televise) for the first time.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1954</strong>
            <span>
              More money <sup>8</sup> <input /> (earn) by TV broadcasters than by radio broadcasters.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1964</strong>
            <span>
              Colour TV sets <sup>9</sup> <input /> (buy) by families across the USA.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1983</strong>
            <span>
             The final episode of the comedy series <span style="font-style: oblique">M*A*S*H</span> <sup>10</sup><input /> (watch) by more than 125 million viewers.
            </span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1990</strong>
            <span>
             The first episode of <span style="font-style: oblique">The Simpsons</span> <sup>11</sup> <input /> (show) on Fox.
            </span>
          </div>
        `,
				answer: [
					'was transmitted',
					'was broadcast',
					'was sent',
					'was sold',
					'were made',
					'were demonstrated',
					'were televised',
					'was earned',
					'were bought',
					'was watched ',
					'was shown',
				],
			},
		],
	},
	5: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E5',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong><span style='color: rgb(246 140 31)'>8D</span> The passive (present perfect, future and modal verbs)</strong> <br />5 Complete the sentences. Use the correct affirmative, negative, or interrogative form of the present perfect passive. <img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.4</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 250,
		exerciseKey: 'img/FriendsPlus/Page122/E5/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>My watch <span style="color: rgb(4 84 121); font-style: oblique"> has been stolen</span> (steal).</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              These socks <input /> 
              (not wash).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <input /> 
              your ticket
              <input />
              (check)?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              A new road
              <input />
              (build) between Kingsbridge and Abbotsville.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              The latest <span style="font-style: oblique ">Avengers film</span>
              <input />
              (not show) in our local cinema yet.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <input />
              your parce
              <input />
              (deliver) yet?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              They’re going to cancel the concert because not enough tickets 
              <input />
              (sell).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              How many people
              <input />
              (invite) to Emily’s party?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>9</strong>
            <div>
              My homework
              <input />
              (not mark) by the teacher.
            </div>
          </div>
        `,
				answer: [
					`haven't been washed`,
					'Has',
					'been checked',
					'has been built ',
					`hasn't been shown`,
					'Has ',
					'been delivered',
					'have been sold',
					'have been invited',
					`hasn't been marked`,
				],
			},
		],
	},
	6: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E6',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '6',
				title:
					"Complete the advertisement. Use the future passive form <span style='font-style: oblique'>(will)</span> of the verbs in brackets. <img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.5</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 250,
		exerciseKey: 'img/FriendsPlus/Page122/E6/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="width: 1100px; height: 400px; border: 3px solid rgb(73 196 213); border-radius: 20px ;background-image: linear-gradient(180deg, rgb(73 196 213) 30%, rgb(255 255 255)); padding: 20px">
          <div style="display: flex; align-items: center; column-gap: 10px">
            <strong style="margin-top: 5px; color: rgb(255 255 255); font-weight: 800; font-size: 30px">SuperCloud</strong>  
            <img src="img/FriendsPlus/Page122/E6/1.png" style="width: 50px"/>
          </div>  
          <div>
            <strong style="font-size: 25px; font-weight: 600; color: rgb(255 255 255); ">FREE cloud storage for your computer data!</strong>
          </div>
          <div style="margin-top: 20px">
            <span style="font-size: 20px">
              It’s so easy! Simply select the files you want to store and they <sup>1</sup><input /> (upload) to SuperCloud.
              They <sup>2</sup><input /> (scan) for viruses and then <sup>3</sup><input /> (store) on our servers.
              Your files and photos <sup>4</sup><input /> (back up) automatically, so they <sup>5</sup><input /> (not lose).
              Remember, you <sup>6</sup><input /> (not charge) for the SuperCloud service. It’s FREE!
              <span style="text-decoration: underline">Click here</span> to sign up. Once you sign up, an email <sup>7</sup><input /> (send) to you with full instructions. What are you waiting for?!
            </span>
          </div>
        </div>
        `,
				answer: [
					'will be uploaded',
					'will be scanned',
					'will be stored',
					'will be backed up',
					`won't be lost`,
					`won't be charged`,
					'will be sent',
				],
			},
		],
	},
	7: {
		unit: 'Grammar Builder',
		id: 'SB10-GBAR-P122-E6',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '7',
				title:
					"Rewrite the sentences. Use the present perfect passive, future passive or passive with modal verbs. Use by where necessary. <br /> <img src='img/FriendsPlus/Page122/1.png' style='width: 20px; margin-bottom: 5px' /> <strong style='color: rgb(246 140 31);'>8.2, 8.3, 8.4, 8.5, 8.6</strong>",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 700,
		exerciseKey: 'img/FriendsPlus/Page122/E7/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="display: flex; column-gap: 20px">
          <strong>1</strong>
          <div>
            <span>Robots will build the cars in the new factory.</span> <br/>
            <span style="font-style: oblique; color: rgb(4 84 121);">The cars will be built by robots in the new factory</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>2</strong>
          <div>
            <span>We probably won’t need desktop computers ten years from now.</span> <br/>
            <input/>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>3</strong>
          <div>
            <span>Digital cameras have replaced celluloid film cameras.</span> <br/>
            <input/>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>4</strong>
          <div>
            <span>Robots can help passengers with the check-in at the airports now.</span> <br/>
            <input/>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>5</strong>
          <div>
            <span>Using this electronic dictionary may enrich your vocabulary.</span> <br/>
            <input/>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>6</strong>
          <div>
            <span>They haven’t invented computers that can think like humans</span> <br/>
            <input/>
          </div>
        </div>
        `,
				answer: [
					`Desktop computers probably won't be needed ten years from now.`,
					`Celluloid film cameras have been replaced by digital cameras.`,
					`Passengers can be helped with the check-in at the airports by robots now.`,
					`Your vocabulary may be enriched by using this electronic dictionary.`,
					`Computers that can think like humans haven't been invented.`,
				],
			},
		],
	},
}

export default json
