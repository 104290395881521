import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P102-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500, fontSize: 20 },
		titleQuestion: [
			{
				num: '1',
				title: `
          Describe the photo. Answer the questions.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: center;">
						<div style="flex: 1;">
							<div>
								<span style="padding-right: 10px; font-weight: bold;">1</span>
								<span>What are the people saying, do you think?</span>				
							</div>
							<div>
								<span style="padding-right: 10px; font-weight: bold;">2</span>
								<span>Do you often take things you have bought back to the shop? Why?</span>				
							</div>
							<div><textarea id="0" rows="10"></textarea></div>
						</div>

						<div style="flex: 1; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page102/E1/1.jpg" style="max-width: 100%;" />
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P102-E2',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_19.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E2/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '2',
				title: `
					<audioimage name="3.19"></audioimage> 
          Read the task above. Then listen to a student doing the task. 
					Circle the correct answers.
				`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['a<br> b', 'a<br> b', 'a<br> b', 'a<br> b'],
				answers: ['0-2', '1-2', '2-0', '3-0'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex;">
					<div style="flex: 3; padding: 10px 20px; border-radius: 10px; background-color: rgb(209, 223, 242); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
						<div>
							You are returning a gadget to a shop because something has gone wrong with it. 
							Discuss these four issues with the shop assistant:
						</div>
						<ul>
							<li>What the gadget is and when you bought it.</li>
							<li>What the problem is.</li>
							<li>What you want to happen next.</li>
							<li>What you will do if that isn’t possible.</li>
						</ul>
					</div>

					<div style="margin-left: 20px; flex: 2; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>The student is returning</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='0' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>a CD player.</div>
								<div>a DVD player.</div>
							</div>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>The student can’t</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='1' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>charge the batteries.</div>
								<div>turn on the player.</div>
							</div>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>The student would like</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='2' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>an exchange.</div>
								<div>a refund.</div>
							</div>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>The sales assistant offers to</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='3' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>repair it.</div>
								<div>exchange it.</div>
							</div>
						</div>
					</div>
				</div>
      `,
		},
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P102-E3',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_19.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E3/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 11,
		textareaStyle: { width: 400 },
		titleQuestion: [
			{
				num: '3',
				title: `
					<audioimage name="3.19"></audioimage> 
					Read the <span style="font-weight: bold; color: rgb(0, 147, 69);">Speaking Strategy</span>. 
					Listen again and say if the student mentioned all the points 
					in the task and responded well to the sales assistant’s contributions.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="max-width: 600px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(0, 147, 69); background-color: rgb(229, 240, 228); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
							<span style="font-weight: bold; color: rgb(0, 147, 69);">Speaking Strategy</span>
							<div>
								When you are doing a speaking task, make sure you refer to all four points in the task. 
								It is also important that you react and respond properly to what is said during the conversation.
							</div>
						</div>

						<div style="margin-left: 30px; padding: 10px; border-radius: 10px; border: 1px solid rgb(0, 147, 69); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
							<textarea id="0" rows="4"></textarea>
						</div>
					</div>
				`,
				answer: [
					"Yes. The student mentioned all four points and responded well to the sales assistant's contributions.",
				],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P102-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E4/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 14,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},

		titleQuestion: [
			{
				num: '4',
				title: `
					<span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
					Which of these gadgets in A do you like best? 
					Can you identify different parts in B that you find on your gadgets?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="font-size: 22px;">
						<div style="display: flex;">
							<strong>A</strong>
							<div style="margin-left: 20px; color: rgb(0, 147, 69);">
								<span>digital camera</span>
								<span style="padding-left: 20px;">DVD player</span>
								<span style="padding-left: 20px;">e-book reader</span> <br/>
								<span>games console</span>
								<span style="padding-left: 20px;">smartphone</span>
								<span style="padding-left: 20px;">tablet</span>
							</div>
						</div>
						<div style="margin-top: 10px; display: flex;">
							<strong>B</strong>
							<div style="margin-left: 20px; color: rgb(0, 147, 69);">
								<span>battery</span>
								<span style="padding-left: 20px;">case</span>
								<span style="padding-left: 20px;">charger</span>
								<span style="padding-left: 20px;">on|off button</span> <br/>
								<span>remote control</span>
								<span style="padding-left: 20px;">screen</span>
								<span style="padding-left: 20px;">strap</span>
								<span style="padding-left: 20px;">USB port</span> <br/>
								<span>volume control</span>
							</div>
						</div>
					</div>

					<div style="margin-top: 10px; font-size: 22px;">
						<span style="font-weight: bold; font-style: italic;">Answer:</span>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">digital camera</span>
							<span>#, #, #,<br/> #, #, #</span>
						</div>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">DVD player</span>
							<span>#, #, #,<br/> #, #</span>
						</div>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">e-book reader</span>
							<span>#, #, #,<br/> #, #, #</span>
						</div>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">games console</span>
							<span>#, #, #</span>
						</div>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">smartphone</span>
							<span>#, #, #,<br/> #, #, #,<br/> #</span>
						</div>
						<div style="display: flex;">
							<span style="font-weight: bold; padding-right: 20px;">tablet</span>
							<span>#, #, #,<br/> #, #, #</span>
						</div>
					</div>
				`,
				answer: [
					'battery',
					'case',
					'charger',
					'on|off button',
					'screen',
					'strap',
					'on|off button',
					'power lead',
					'remote control',
					'USB port',
					'volume control',
					'battery',
					'case',
					'charger',
					'on|off button',
					'screen',
					'USB port',
					'on|off button',
					'power lead',
					'volume control',
					'battery',
					'case',
					'charger',
					'on|off button',
					'screen',
					'USB port',
					'volume control',
					'battery',
					'case',
					'charger',
					'on|off button',
					'screen',
					'volume control',
				],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P102-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_20.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E5/Key/answerKey.png',
		component: T6,
		inputSize: 100,
		maxLength: 6,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          <audioimage name="3.20"></audioimage>  
					Listen to another student. 
					What parts of the gadget did he and the sales assistant mention?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<span style="font-weight: bold; font-style: italic">Answer: </span>#, #
					</div>
				`,
				answer: ['screen', 'case'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P102-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E6/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 9,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          <span style="font-weight: bold; color: rgb(146, 39, 144);">KEY PHRASES</span> 
					Complete the phrases with the words below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="color: rgb(0, 147, 69);">
						<div>
							<span>broken</span>
							<span style="padding-left: 20px;">come</span>
							<span style="padding-left: 20px;">exchange</span>
							<span style="padding-left: 20px;">happy</span>
							<span style="padding-left: 20px;">manager</span>
						</div>
						<div>
							<span>money</span>
							<span style="padding-left: 20px;">problem</span>
							<span style="padding-left: 20px;">repair</span>
							<span style="padding-left: 20px;">something</span>
							<span style="padding-left: 20px;">stopped</span>
						</div>
						<div>
							<span>work</span>
							<span style="padding-left: 20px;">write</span>
						</div>
					</div>

					<div style="margin-top: 20px; padding-left: 10px; border-left: 5px solid rgb(5, 201, 97);">
						<div style="font-weight: bold; color: rgb(0, 147, 69);">Making a complaint</div>
						<div>There’s a <sup>1</sup># with …</div>
						<div>There’s <sup>2</sup># wrong with …</div>
						<div>It doesn’t <sup>3</sup>#.</div>
						<div>It has <sup>4</sup># working.</div>
						<div>It’s <sup>5</sup># .</div>
						<div>The (dial) has <sup>6</sup># off.</div>
						<div>Can I have my <sup>7</sup># back, please?</div>
						<div>Can I <sup>8</sup># it, please?</div>
						<div>Can you <sup>9</sup># it?</div>
						<div>I’m not <sup>10</sup># about that.</div>
						<div>Can I see the <sup>11</sup># , please?</div>
						<div>I’m going to <sup>12</sup># to (customer services).</div>
					</div>
				`,
				answer: [
					'problem',
					'something',
					'work',
					'stopped',
					'broken',
					'come',
					'money',
					'exchange',
					'repair',
					'happy',
					'manager',
					'write',
				],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P102-E7',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_20.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E7/Key/answerKey.png',
		component: T6,
		inputSize: 160,
		maxLength: 15,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
					<audioimage name="3.20"></audioimage> 
          <span style="color: rgb(0, 103, 179)">PRONUNCIATION</span> 
					Listen again and notice how the student pronounces the phrases in exercise 6. 
					Work in pairs and practise saying them.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="color: rgb(0, 147, 69);">
						<div>
							<span>broken</span>
							<span style="padding-left: 20px;">come</span>
							<span style="padding-left: 20px;">exchange</span>
							<span style="padding-left: 20px;">happy</span>
							<span style="padding-left: 20px;">manager</span>
						</div>
						<div>
							<span>money</span>
							<span style="padding-left: 20px;">problem</span>
							<span style="padding-left: 20px;">repair</span>
							<span style="padding-left: 20px;">something</span>
							<span style="padding-left: 20px;">stopped</span>
						</div>
						<div>
							<span>work</span>
							<span style="padding-left: 20px;">write</span>
						</div>
					</div>

					<div style="margin-top: 20px; padding-left: 10px; border-left: 5px solid rgb(5, 201, 97);">
						<div style="font-weight: bold; color: rgb(0, 147, 69);">Making a complaint</div>
						<div>There’s a <sup>1</sup>__________ with …</div>
						<div>There’s <sup>2</sup>__________ wrong with …</div>
						<div>It doesn’t <sup>3</sup>__________.</div>
						<div>It has <sup>4</sup>__________ working.</div>
						<div>It’s <sup>5</sup>__________ .</div>
						<div>The (dial) has <sup>6</sup>__________ off.</div>
						<div>Can I have my <sup>7</sup>__________ back, please?</div>
						<div>Can I <sup>8</sup>__________ it, please?</div>
						<div>Can you <sup>9</sup>__________ it?</div>
						<div>I’m not <sup>10</sup>__________ about that.</div>
						<div>Can I see the <sup>11</sup>__________ , please?</div>
						<div>I’m going to <sup>12</sup>__________ to (customer services).</div>
					</div>
				`,
				answer: ['invention', 'recordings', 'movement', 'arrangement', 'discovery', 'reconstructions'],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P102-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page102/E8/Key/answerKey.png',
		component: MatchDots,
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
					Match the sentence halves.
			  `,
				color: '#860052',
			},
		],
		stylesTextInput: { borderBottom: '10px dashed', textAlign: 'center' },
		inputSize: 50,
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '55px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '96px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '137px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '174px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '253px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '292px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '332px',
							left: '250px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // 8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '55px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // a
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '96px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // b
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '137px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // c
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '174px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // d
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '215px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // e
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '253px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // f
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '292px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // g
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '332px',
							left: '452px',
							width: '30px',
							height: '30px',
							borderRadius: '50%',
							border: '1px solid #dc2c39',
							background: 'white',
						},
					}, // h
				],
				answers: ['0-13', '1-15', '11-2', '3-8', '12-4', '10-5', '6-9', '14-7'],
				initialValue: [],
			},
			Layout: `
				<div style=" padding-left: 20px; border-left: 5px solid rgb(5, 201, 97);">
					<div style="font-weight: bold; color: rgb(0, 147, 69); font-size: 26px">Dealing with a complaint</div>
          <div style="display: flex; font-size: 26px;">
            <div>
                <div><span style="font-weight: bold; padding-right: 10px;">1</span>What’s wrong</div>
                <div><span style="font-weight: bold; padding-right: 10px;">2</span>When did you</div>
                <div><span style="font-weight: bold; padding-right: 10px;">3</span>Have you got</div>
                <div><span style="font-weight: bold; padding-right: 10px;">4</span>Would you like</div>
                <div><span style="font-weight: bold; padding-right: 10px;">5</span>We don’t give</div>
                <div><span style="font-weight: bold; padding-right: 10px;">6</span>I can give you</div>
                <div><span style="font-weight: bold; padding-right: 10px;">7</span>We can</div>
                <div><span style="font-weight: bold; padding-right: 10px;">8</span>There’s nothing</div>
            </div>    
            <div style="margin-left: 250px;">
              <div><span style="font-weight: bold; padding-right: 10px;">a</span>a refund?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">b</span>repair it.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">c</span>a credit note.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">d</span>the receipt?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">e</span>refunds.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">f</span>with it?</div>
              <div><span style="font-weight: bold; padding-right: 10px;">g</span>I can do.</div>
              <div><span style="font-weight: bold; padding-right: 10px;">h</span>buy it?</div>
            </div>     
          </div>
				</div>
				<input id='0' type= 'boxMatch' />
				<input id='1' type= 'boxMatch' />
				<input id='2' type= 'boxMatch' />
				<input id='3' type= 'boxMatch' />
				<input id='4' type= 'boxMatch' />
				<input id='5' type= 'boxMatch' />
				<input id='6' type= 'boxMatch' />
				<input id='7' type= 'boxMatch' />
				<input id='8' type= 'boxMatch' />
				<input id='9' type= 'boxMatch' />
				<input id='10' type= 'boxMatch' />
				<input id='11' type= 'boxMatch' />
				<input id='12' type= 'boxMatch' />
				<input id='13' type= 'boxMatch' />
				<input id='14' type= 'boxMatch' />
				<input id='15' type= 'boxMatch' />
      `,
		},
	},
	9: {
		unit: 'Science',
		id: 'FG10-S-P102-E9',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

		titleQuestion: [
			{
				num: '9',
				title: `
					Work in pairs. Do the exam task in exercise 2. 
					Take turns to be the shop assistant and the customer. 
					Use words and phrases from this lesson to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				`,
				answer: [],
			},
		],
	},
};

export default json;
