import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Do the slogans quiz. Then decide which slogan is the best, in your opinion. Say why.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    recorder: true,
    inputSize: 39,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'center',

    isHiddenCheck: true,
    questions: [
      {
        title: `
			  <div ><img src='img/FriendsPlus/Page37/E1/1.jpg' alt='' style='margin-left: 100px; width:850px;'/></div>        
            <div style='display: flex; justify-content: space-around;'>
                <b>Match : </b> 
                <div><b> 1 - # </b> </div>
                <div><b> 2 - # </b> </div>
                <div><b> 3 - # </b> </div>
                <div><b> 4 - # </b> </div>
                <div><b> 5 - # </b> </div>
                <div><b> 6 - # </b> </div>
                <div><b> 7 - # </b> </div>
            </div>     
			  `,
        answer: ['b', 'f', 'a', 'd', 'e', 'g', 'c'],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the <span style='color:rgb(193, 26, 101);'>Listening Strategy</span> . You are going to hear an advertisement for bread. What kind of language do you think you will hear? Choose one from the list below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 39,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'center',

    questions: [
      {
        title: `
			  <div style='background-color: rgb(230, 196, 203); margin-top: 20px; margin-left:200px; width:700px; border-radius: 15px; border: 5px dotted rgb(193, 26, 101)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(193, 26, 101) ;'>Listening Strategy</b></div>
            <div>
               In listening tasks, the instructions and questions usually give you some idea of the kind of topic and the type of language you are going to hear. Reading them carefully before you listen will help you to ‘tune in’.
            </div>
          </div>
        </div>
      
            <div style='display: flex; justify-content: space-around;margin-top: 20px;'>
                <div><b> a </b> agreeing and disagreeing</div>
                <div><b> b </b> giving directions</div>
                <div><b> c </b> narrating events</div>
            </div>     
            <div style='display: flex; justify-content: space-around;'>
                <div><b> d  </b> complaining</div>
                <div><b> e  </b> persuading</div>
            </div>    
            <div style='margin-top: 20px;'><b> Language for:  #</b>  (a/b/c/d/e)</div>
			  `,
        answer: ['e'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_35.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `<headphone1 name='1.35' src=''></headphone1> Listen to the advert and complete these phrases. Write one to three words for each gap. Do the phrases support your answer to exercise 2?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',
    questions: [
      {
        title: `
				<div style='margin-top:20px; margin-left:50px; width: 1000px;'>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>1.</b> # Hathaway’s new organic bread?</div>
						<div style='display: flex;'><b style='padding-top:4px;'>2.</b> #  for sandwiches and toast.</div>
					</div>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>3.</b> # Hathaway’s organic wholemeal.</div>
						<div style='display: flex;'><b style='padding-top:4px;'>4.</b> #  the day with Hathaway.</div>
					</div>
				</div>
			  `,
        answer: ['Why not try', 'It’s perfect', ' Ask for', 'Start'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<b style='color:rgb(0,147,70);'>SPEAKING</b>  In pairs, take turns to ask and answer the questions below about the advert in exercise 3.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    recorder: true,
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'left',
    questions: [
      {
        title: `
				<div style='margin-top:20px; margin-left:50px; width: 1000px;'>
					<div>
						<div style='display: flex;'><b>1.</b> Is it a good advert, in your opinion? Why? / Why not?</div>
            <input id='0' width='600px'/>
						<div style='display: flex;'><b>2.</b> Is it aimed at children or adults?</div>
            <input id='1' width='600px'/>
						<div style='display: flex;'><b>3.</b> Which extract from exercise 3 do you think is the slogan?</div>
            <input id='2' width='600px'/>
					</div>
				</div>
			  `,
        answer: ['', 'adults', 'Start the day with Hathaway.'],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				Read situations 1–3 below. What kind of language from exercise 2 (a–e) would you expect to hear in each situation?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 39,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'center',

    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-around; width:1000px;'>
            <div >
                <div><b> 1  </b> an advertising executive in a business meeting</div>
                <div><b> 2  </b> somebody telling a friend what happened earlier</div>
                <div><b> 3  </b> somebody trying to follow a satnav</div>
            </div>    
            <div >
                <div><b> a </b> agreeing and disagreeing</div>
                <div><b> b </b> giving directions</div>
                <div><b> c </b> narrating events</div>
                <div><b> d  </b> complaining</div>
                <div><b> e  </b> persuading</div>
            </div>     
        </div>
        <div style='display: flex; justify-content: space-around;'>
                <b>Match : </b> 
                <div><b> 1 - # </b> </div>
                <div><b> 2 - # </b> </div>
                <div><b> 3 - # </b> </div>
            </div>   
        
            `,
        answer: ['a/e', 'c', 'b'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_36.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page37/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `<headphone1 name='1.36' src=''></headphone1> Now listen to the three situations. Match them with sentences A–D below. <br/>There is one extra sentence.
			  `,
        color: '#92278f',
      },
    ],
    stylesTextInput: { borderBottom: '10px dashed', textAlign: 'center' },
    inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '17px',
              left: '130px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '58px',
              left: '130px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '95px',
              left: '130px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '17px',
              left: '280px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // A
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '58px',
              left: '280px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // B
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '95px',
              left: '280px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // C
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '135px',
              left: '280px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // D
        ],
        answers: ['0-4', '1-6', '2-3'],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex; justify-content: space-around; width:1000px; font-size: 26px;'>
            <div >
                <div><b> 1  </b></div>
                <div><b> 2  </b></div>
                <div><b> 3  </b></div>
            </div>    
            <div >
              <div style='display: flex;'><b >A.</b> Not everyone is happy with the final decision.</div>
              <div style='display: flex;'><b >B.</b> One of the speakers was very unlucky.</div>
              <div style='display: flex;'><b >C.</b> This person finds it impossible to make a decision. </div>
              <div style='display: flex;'><b >D.</b> This person gets confused and cross.</div>
            </div>     
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
      `,
    },
  },
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E7',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_36.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E7/key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<headphone1 name='1.36' src=''></headphone1> Listen again. Complete the adjectives with the ending that you hear, <i>-ed</i> or <i>-ing</i>.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 52,
    stylesTextInput: [],
    maxLength: 3,
    textAlign: 'left',
    questions: [
      {
        title: `
				  <div style='display: flex; justify-content: space-around; width:1000px; font-size: 26px;'>
            <div >
              <div style='display: flex;'><b >1.</b> annoy#</div>
              <div style='display: flex;'><b >2.</b> disappoint#</div>
              <div style='display: flex;'><b >3.</b> confus# </div>
            </div>    
            <div >
              <div style='display: flex;'><b >4.</b> exhaust#</div>
              <div style='display: flex;'><b >5.</b> bor#</div>
              <div style='display: flex;'><b >6.</b> amus# </div>
            </div>     
          </div>
			  `,
        answer: ['ing', 'ed', 'ing', 'ed', 'ing', 'ing'],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P37-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page37/E8/key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: ` <span style='color:rgb(193, 26, 101);'>PROJECT </span>Write and perform your own TV advert in groups. Choose from the products below or use your own ideas. Think of five adjectives to describe the product and make up a good slogan.</i>.
			  `,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: [
          'computer game',
          'online language course',
          'online language course',
          'electric car',
          'smartphone',
          'trainers',
          'fruit snack',
        ],
        borderColor: 'rgb(193, 26, 101)',
        arrow: false,
        position: 2,
        width: 800,
      },
    ],
    textareaStyle: { width: 900 },
    hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
				  <div style="margin-left: 20px;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 40px;"><textarea id="0" rows="5"></textarea></div>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
