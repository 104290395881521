import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-p86-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title:
          'Read the text opposite. Do you think the twin sisters have a special connection or are there other explanations? Give reasons for your opinions.',
        color: '#92278f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page86/E1/1.jpg' style="width: 800px;"/></div>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 7',
    id: 'SB10-U7-p86-E2',
    audio: '',
    video: '',
    component: T6v2,
    exerciseKey: 'img/FriendsPlus/Page86/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: 'Read the text again and study the highlighted examples of reported speech.   ',
        color: '#92278f',
      },
    ],
    inputSize: 230,
    maxLength: 20,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: { width: 460 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div><b>What are the speakers’ original words?</b><textarea id="6" rows="9"></textarea></div>
          <div><img src= 'img/FriendsPlus/Page86/E2/1.jpg' style="width: 600px;"/></div>
        </div>
<br/>
        <b>Complete the table with the correct tenses for the direct speech.</b>
        <table style="width:800px; text-align: center;">
          <tr style="border:2px solid rgb(244, 143, 32);background-color: rgb(244, 143, 32); color: rgb(255, 255, 255); padding: 5px;">
            <th style="">Direct speech</th>
            <th style="border-left: 2px solid rgb(255, 255, 255);">Reported speech</th>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>1</sup>#</b></td>
            <td style="border:2px solid rgb(244, 143, 32);">past simple</td>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>2</sup>#</b></td>
            <td style="border:2px solid rgb(244, 143, 32);">past continuous</td>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>3</sup>#</b></td>
            <td style="border:2px solid rgb(244, 143, 32);">past perfect</td>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>4</sup>#</b></td>
            <td style="border:2px solid rgb(244, 143, 32);">past perfect</td>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>5</sup></b># </td>
            <td style="border:2px solid rgb(244, 143, 32);"><i>could / couldn’t</i></td>
          </tr>
          <tr>
            <td style="border:2px solid rgb(244, 143, 32);"><b><sup>6</sup></b>#</td>
            <td style="border:2px solid rgb(244, 143, 32);"><i>would / wouldn’t</i></td>
          </tr>
        </table>

        `,
        answer: [
          'present simple',
          'present continuous',
          'present perfect',
          'past simple',
          "can / can't",
          "will / won't",
          "The speakers' original words are: My right ankle is hurting, but I don't know why. I haven't twisted it or anything, but I can't put any weight on it. I watched a really good video clip on the internet yesterday. I'll send you a link to the website where I found it. You must watch it.",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page86/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: ` Read the <span style='color:rgb(244, 143, 32);'>LEARN THIS!</span> box. Match the underlined verbs in the text with rules a–c. What are the speaker’s original words?
			  `,
        color: '#92278f',
      },
    ],
    selectStyle: {
      border: '2px solid #000',
      color: '#00ADFE',
      width: 360,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ["I'd found it", 'she must watch it', "she'd finished watching it"],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					  <div style=" width: 800px; background-image: url('/img/FriendsPlus/Page86/E3/LEARNTHIS.jpg');  background-repeat: no-repeat;background-size: 800px 530px; margin-left: 150px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b> Reported speech</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div> These verbs do not usually change in reported speech:<br/><i>might, must, should, could, would, would like.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div> The past perfect does not change in reported speech.<br/><i style='color:rgb(124, 162, 211)'> ‘I’d never been there.’ She said she’d never been there.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div> Verbs in subordinate clauses in the reported sentence usually change in the same way.<br/><i style='color:rgb(124, 162, 211)'> ‘I think I’m ill.’ He said he thought he was ill.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> d </b></div>
                      <div> We always use a personal object with tell. With say, we do not need a personal object.<br/><i style='color:rgb(124, 162, 211)'> I told Jo that I’d phone. / I said (to Jo) that I’d phone.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> e </b></div>
                      <div> I told Jo that I’d phone. / I said (to Jo) that I’d phone.<br/><i style='color:rgb(124, 162, 211)'> He said he’d call you.</i></div>
                    </div>
                </div>
            </div>
				  <div style='display:flex; gap:30px;'>
            <div style='align-self: center;'>
              <div style='display: flex;'><b style='margin-right: 7px;'> a </b> <select id="0" ></select></div>
              <div style='display: flex;'><b style='margin-right: 7px;'> b </b> <select id="1" ></select></div>
              <div style='display: flex;'><b style='margin-right: 7px;'> c </b> <select id="2" ></select></div>
            </div>
            <img src='img/FriendsPlus/Page86/E3/1.jpg' alt='' style="margin-left: 5px; width: 750px;"></img>
          </div>

			  `,
        answer: ['she must watch it', "she'd finished watching it", " I'd found it"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page86/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: ` Circle examples in the text of rules d and e.
			  `,
        color: '#92278f',
      },
    ],
    selectStyle: {
      border: '2px solid #000',
      color: '#00ADFE',
      width: 180,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['rule d', 'rule e', 'rules d & e'],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					  <div style=" width: 800px; background-image: url('/img/FriendsPlus/Page86/E4/LEARNTHIS.jpg');  background-repeat: no-repeat;background-size: 800px 530px; margin-left: 150px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b> Reported speech</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div> These verbs do not usually change in reported speech:<br/><i>might, must, should, could, would, would like.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div> The past perfect does not change in reported speech.<br/><i style='color:rgb(124, 162, 211)'> ‘I’d never been there.’ She said she’d never been there.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div> Verbs in subordinate clauses in the reported sentence usually change in the same way.<br/><i style='color:rgb(124, 162, 211)'> ‘I think I’m ill.’ He said he thought he was ill.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> d </b></div>
                      <div> We always use a personal object with tell. With say, we do not need a personal object.<br/><i style='color:rgb(124, 162, 211)'> I told Jo that I’d phone. / I said (to Jo) that I’d phone.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> e </b></div>
                      <div> I told Jo that I’d phone. / I said (to Jo) that I’d phone.<br/><i style='color:rgb(124, 162, 211)'> He said he’d call you.</i></div>
                    </div>
                </div>
            </div>
            <div style='margin-top: 20px'>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 &emsp;</b></div>
                <div> She told me that her right ankle was hurting, but she didn’t know why.</div>
                <b> <select id='0'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 2 &emsp;</b></div>
                <div> She said she hadn’t twisted it or anything, but told me she couldn’t put any weight on it.</div>
                <b> <select id='1'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 3 &emsp;</b></div>
                <div> I told Harriet that I had watched a really good video clip on the internet the day before.</div>
                <b> <select id='2'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 4 &emsp;</b></div>
                <div> I said that I would send her a link to the website where I’d found it.</div>
                <b> <select id='3'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 5 &emsp;</b></div>
                <div> I told her she must watch it.</div>
                <b> <select id='4'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 6 &emsp;</b></div>
                <div> ...she said she had come across the same clip at the same time!</div>
                <b> <select id='5'></select>  </b>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 7 &emsp;</b></div>
                <div> She said that after she’d finished watching it, she had thought about sending me the link.</div>
                <b> <select id='6'></select> </b>
              </div>
            </div>
                   
			  `,
        answer: ['rule d', 'rules d & e', 'rule d', 'rule d', 'rules d & e', 'rules d & e', 'rule d'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E5',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: '/img/FriendsPlus/Page86/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: ` Underline examples of changes to pronouns, possessive adjectives and references to time in the text in exercise 1.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, textDecorationLine: 'underline' },
        limitSelect: 1,
        listWords: [
          'She_told_me_that_her_right_ankle_was_hurting',
          'She_said_she_hadn’t_twisted_it',
          'she_couldn’t_put_any_weight_on_it',
          'I_had_watched_a_really_good_video_clip',
          'the_day_before',
          'I_told_her_she_must_watch_it',
          'she_said_she_had_come_across',
          'She_said_that_after_she’d_finished_watching_it,_she_had_thought_about_sending_me_the_link',
        ],
        answers: ['0-0', '1-0', '2-0', '3-0', '4-0', '5-0', '6-0', '7-0'],
        initialValue: [],
      },
      Layout: `
					  <div style=" width: 800px; background-image: url('/img/FriendsPlus/Page86/E5/LEARNTHIS.jpg');  background-repeat: no-repeat;background-size: 800px 559px; margin-left: 150px;">
              <div style='padding:25px 15px 25px 59px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LOOK OUT!</b><b></b></div>
                      <div> Pronouns, possessive adjectives and references to time and place usually change in reported speech.
                        <br/><i style='color:rgb(124, 162, 211)'> ‘I’d never been there.’ She said she’d never been there.</i>
                        <br/><i style='color:rgb(124, 162, 211)'> He told me he’d phone me the following day.</i>
                      </div>
                      <div><i>now <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> then / at that moment</i></div>
                      <div><i>today <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> that day</i></div>
                      <div><i>an hour ago <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> an hour earlier / an hour before</i></div>
                      <div><i>yesterday <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> the day before / the previous day</i></div>
                      <div><i>last Tuesday, month, year, etc. <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> the Tuesday, the month, the year before, etc.</i></div>
                      <div><i>tomorrow <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> the following day / the next day</i></div>
                      <div><i>next week, month, etc. <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> the following week, month, etc.</i></div>
                      <div><i>here <i style='font-size:24px' class='fas fa-long-arrow-alt-right'></i> there</i></div>
               </div>
            </div>
            <div style='background-color:rgb(1, 142, 211); width: 800px; margin-left: 160px;'>
              <div style='padding:20px;'>
                <div style='padding: 20px; border-radius: 20px; background-color:rgb(229, 241, 205);'>
                  <h1 style='font-size: 50px;'>A special connection</h1>
                  <div>Beth and her twin sister Harriet are convinced that they are able to share experiences and feelings without seeing or speaking to each other. Here’s what Beth says:</div>
                  <div>‘Last year I was on a walking holiday with Harriet.
                   <input id='0' type='Circle' />, but she didn’t know why. 
                   <input id='1' type='Circle' /> or anything, but told me 
                   <input id='2' type='Circle' />. About a minute later, I suddenly felt a sharp pain in my right ankle! On another occasion, I told Harriet that 
                   <input id='3' type='Circle' /> on the internet 
                   <input id='4' type='Circle' />. I said that I would send her a link to the website where I’d found it. 
                   <input id='5' type='Circle' />. Imagine my surprise when 
                   <input id='6' type='Circle' /> the same clip at the same time! 
                   <input id='7' type='Circle' />.’</div>
                </div>
              </div>
            </div>
                   
      `,
    },
  },
  6: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page86/E6/key/answerKey.png',
    inputSize: 950,
    maxLength: 81,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '6',
        title: `
				Rewrite the sentences in reported speech. (Imagine that the speakers were talking to you.)
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 1 </b></div>
            <div>
              <div> “I’ll call you when I get back this evening,” said Maisy.</div>
              <div><i style='color:rgb(124, 162, 211)'> Maisy said she would call me when she got back that evening.</i></div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 2 </b></div>
            <div>
              <div> “I might disable data roaming when I go abroad next week,” said Joel.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 3 </b></div>
            <div>
              <div> “I haven’t topped up my mobile since last month,” said Freddy.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 4 </b></div>
            <div>
              <div> “You mustn’t let your battery run out,” said my mum.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 5 </b></div>
            <div>
              <div> “Liam saved your number to his phone book,” said Sally.</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 6 </b></div>
            <div>
              <div> “This time next week, I’ll be flying to Prague,” said Tom.</div>
              <div>#</div>
            </div>
          </div>
          <img src='/img/FriendsPlus/Page86/E6/2.jpg' style='width: 500px;'/>

			  `,
        answer: [
          'Joel said he might disable data roaming when he went abroad the following week. / Joel said that he might disable data roaming when he went abroad the following week.',
          'Freddy said he hadn’t topped up his mobile since the month before. / Freddy said that he hadn’t topped up his mobile since the month before.',
          'My mum said I mustn’t let my battery run out./ My mum said that I mustn’t let my battery run out.',
          'Sally said Liam had saved my number to his phone book./ Sally said that Liam had saved my number to his phone book.',
          'Tom said this time the following week, he would be flying to Prague./Tom said that time the following week, he would be flying to Prague.',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    inputSize: 850,
    maxLength: 81,
    // checkDuplicated: true,
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: `
				Write answers to these questions. 
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 1 </b></div>
            <div>
              <div> Where did you go last summer?</div>
              <div><i style='color:rgb(124, 162, 211)'>  I went to … </i></div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 2 </b></div>
            <div>
              <div> What are you doing this evening?</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 3 </b></div>
            <div>
              <div> Can you write with both hands?</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 4 </b></div>
            <div>
              <div> Could you walk before you could talk? </div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 5 </b></div>
            <div>
              <div> What are you going to do at the weekend?</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 6 </b></div>
            <div>
              <div> Have you been to Italy?</div>
              <div>#</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 7 </b></div>
            <div>
              <div> What time do you usually get to school?</div>
              <div>#</div>
            </div>
          </div>

			  `,
        answer: ['', '', '', '', '', ''],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P86-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '8',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Ask the questions in exercise 7, note your partner’s answers. Then work in groups and report your partner’s answers to the group.
			  `,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: ['Martin said that he’d been to London the summer before.'],
        arrow: true,
        position: 1,
        width: 500,
        borderColor: 'rgb(244, 143, 32)',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 50px 100px;'><hintBox id='0' ></hintBox></div>

        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 1 </b></div>
            <div>
              <div> Where did you go last summer?</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 2 </b></div>
            <div>
              <div> What are you doing this evening?</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 3 </b></div>
            <div>
              <div> Can you write with both hands?</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 4 </b></div>
            <div>
              <div> Could you walk before you could talk? </div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 5 </b></div>
            <div>
              <div> What are you going to do at the weekend?</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 6 </b></div>
            <div>
              <div> Have you been to Italy?</div>
            </div>
          </div>
        	<div style='display: flex;'>
            <div style='margin-right: 10px;'><b> 7 </b></div>
            <div>
              <div> What time do you usually get to school?</div>
            </div>
          </div>


			  `,
        answer: [],
      },
    ],
  },
};

export default json;
