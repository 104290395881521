import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container, Modal, ModalBody, Row } from 'reactstrap';
import Animate from '../../../Animate';
import FooterModal from '../../../FooterModal';
import Fireworks from '../../../ResultsAndEffects/fireworks';
import TitleQuestion from '../../../TitleQuestion';
import HintBox from '../../../Utilities/HintBox';
import Content from './Content';
import useContent from './Content/useContent';
import Header from './Header';
import styles from './styles.module.css';

const TB2 = (props) => {
  const { titleQuestion, data, tb2Style, type } = props;
  console.log('🚀 ~ props', props);
  // console.log(props)
  const [form] = Form.useForm();
  const countInput = count(); // initialize the count function

  const [state, setState] = useState({
    star: 0,
    audioUrl: null,
    videoUrl: null,
    sentences: null,
    isPointed: false,
    studentAnswer: null,
    videoVisible: false,
    isDisabledRetry: true,
    isDisabledSubmit: true,
    booleanArray: [],
  });
  const [modal, setModal] = useState(false);
  const { answers, setAnswers } = useContent();
  const [userAnswers, setUserAnswers] = useState([]);

  useEffect(() => {
    const sentences = JSON.parse(JSON.stringify(props.questions));
    const audioUrl = props.audio;
    const videoUrl = props.video;

    let isDisabledSubmit = true;
    if (answers.length !== 0) {
      isDisabledSubmit = false;
    }

    setState((prev) => ({
      ...prev,
      audioUrl,
      videoUrl,
      isDisabledSubmit,
      sentences,
    }));
  }, [answers, props.audio, props.questions, props.video]);

  const onModal = useCallback(() => {
    setModal(true);
    setTimeout(() => {
      setModal(false);
    }, 3000);
  }, []);

  const onRetry = useCallback(() => {
    setState((preState) => ({
      ...preState,
      isDisabledInput: false,
      isDisabledRetry: true,
      isPointed: false,
      resultString: null,
      isDisabledSubmit: false,
      booleanArray: [],
      inputArray: new Array(state.maxInput).fill(),
    }));
  }, [state.maxInput]);

  const onSubmit = useCallback(() => {
    form.submit();

    onModal();
  }, [form, onModal]);

  const toggleState = useCallback(
    (fieldName) => () => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: !prevState[fieldName],
      }));
    },
    []
  );

  const onPlayVideo = useCallback(() => {
    toggleState('videoVisible')();
  }, [toggleState]);

  const transform = useCallback(
    (node, index) => {
      if (node.type === 'tag' && node.name === 'input') {
        //render tag Input
        let currentInputNo = countInput.next();
        //<div><input id="0" width='165px' background-color='transparent' /></div>
        const { id, ...elementStyle } = node.attribs;
        //
        let Color = 'black';
        if (state.isPointed) {
          Color = state.booleanArray?.[currentInputNo] ? '#2ecc71' : '#e74c3c';
        }
        return (
          <div key={index} style={{ display: 'inline-flex', marginBottom: 0 }}>
            <Form.Item className='ml-1 mr-1' name={`${currentInputNo}`} style={{ marginBottom: 0 }} initialValue=''>
              <Input
                maxLength={props.maxLength ?? ''}
                autoComplete='off'
                disabled={state.isPointed}
                className={styles.input}
                style={{
                  height: 30,
                  width: props.inputSize,
                  fontSize: 26,
                  fontWeight: '500',
                  textTransform: props.textTransform ?? '',
                  textAlign: props.textAlign ?? '',
                  border: '0px solid #A8A8A8',
                  borderRadius: 0,
                  borderBottomWidth: '1px',
                  padding: 10,
                  display: 'inline',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  color: Color,
                  ...props.stylesTextInput,
                  ...elementStyle,
                }}
              />
            </Form.Item>
            {state.isPointed &&
              !props.isHiddenCheck &&
              (state.booleanArray[currentInputNo] ? (
                <CheckCircleOutlined style={{ fontSize: 25, color: '#2ecc71' }} />
              ) : (
                <CloseCircleOutlined style={{ fontSize: 25, color: '#e74c3c' }} />
              ))}
          </div>
        );
      }
      if (node.type === 'tag' && node.name === 'textarea') {
        //render tag textarea
        //<div><textarea id="0" rows="7" text-indent='200px' color='red'  margin-top='500px' ></textarea></div>
        const { id, rows, ...elementStyle } = node.attribs;
        const { textareaStyle } = props;
        //
        let Color = 'black';
        if (state.isPointed) {
          Color = state.booleanArray?.[id] ? '#2ecc71' : '#e74c3c';
        }
        textareaStyle.color = Color;
        return (
          <div key={index}>
            <Form.Item name={id} initialValue={node.children[0]?.data}>
              <textarea
                rows={rows}
                className={styles.notes}
                style={{
                  fontSize: 24,
                  border: 'none',
                  ...props.textareaStyle,
                  ...elementStyle,
                }}
              ></textarea>
            </Form.Item>
          </div>
        );
      }
      if (node.type === 'tag' && node.name === 'select') {
        //render tag select
        const { id, ...elementStyle } = node.attribs;
        const { isPointed } = state;
        const selectStyle = Object.assign({}, props.selectStyle);
        const options = props.selectOptionValues.map((item) => ({
          value: item,
        }));
        //
        if (isPointed) {
          selectStyle.color = state.booleanArray?.[id] ? '#2ecc71' : '#e74c3c';
        }
        return (
          <div key={index}>
            <Form.Item name={id} initialValue=''>
              <Select
                options={props.selectOptionRandom ? _.shuffle(options) : options}
                open={isPointed ? false : undefined} // sau khi submit đóng bảng chọn
                style={{ fontSize: 24, ...selectStyle, ...elementStyle }}
              ></Select>
            </Form.Item>
          </div>
        );
      }
      if (node.type === 'tag' && node.name === 'hintbox') {
        const { id } = node.attribs;
        return props.hintBox.map((v, i) => {
          if (id !== `${i}`) return null;
          const { src, borderColor, width, position, arrow } = v;
          return (
            <HintBox key={i} src={src} borderColor={borderColor} width={width} position={position} arrow={arrow} />
          );
        });
      }
    },
    [countInput, props, state]
  );

  //kiểm tra các field
  const handleChange = useCallback(
    (changedValues, allValues) => {
      // const isDisabledSubmit = allValues.some(item => !item.value) // tìm field còn trống
      const count_answer_none = state.sentences[0]?.answer?.filter((x) => x === 'none').length;
      const count_input_empty = allValues.filter((item) => !item.value).length;

      const isDisabledSubmit = count_input_empty > count_answer_none;
      setState((pre) => ({ ...pre, isDisabledSubmit }));
    },
    [state]
  );

  const onFinish = useCallback(
    (value) => {
      // Tổng số đáp án trong máy.
      const totalAnswers = _.flatten(
        data.map((x) => {
          return _.compact(x.answers); // Xóa phần tử rỗng.
        })
      );
      const filterKey = data.map(
        (columnData) => columnData.answers.filter(Boolean) // Xóa các phần tử Falsy
      );

      const result = answers.map((x) => {
        const isCorrect = filterKey[x.column].includes(x.value); // kiểm tra có trong đáp án không
        if (isCorrect) {
          const index = filterKey[x.column].indexOf(x.value); // lấy index của câu trả lời
          filterKey[x.column].splice(index, 1); // nếu có thì xóa để tránh lặp lại
        }
        return {
          ...x,
          isCorrect: isCorrect,
        };
      });
      const booleanArray = result.map((x) => x.isCorrect); // Tổng số đáp án người dùng.
      const correctAnswer = result.filter((x) => x.isCorrect); // Tổng số đáp án đúng trong tổng đáp án người dùng nhập.

      const percent = parseInt((correctAnswer.length / totalAnswers.length) * 100);
      const resultString = `${correctAnswer.length}/${totalAnswers.length}`;
      const star = percent / 20;
      setAnswers(result);
      setUserAnswers(result);

      const params = {
        score: percent,
        unit: props.unit,
        results: resultString,
        exerciseId: props.id,
        sreatedDate: moment().format(),
        studentAnswer: JSON.stringify(result),
      };
      props.postAnswerToApi(params);

      setState((preState) => ({
        ...preState,
        isDisabledInput: true,
        isDisabledRetry: false,
        isPointed: true,
        resultString: null,
        isDisabledSubmit: true,
        booleanArray,
        inputArray: new Array(state.maxInput).fill(),
        star,
      }));
    },
    [answers, data, props, setAnswers, state.maxInput]
  );

  const handleHeader = (type) => {
    switch (type) {
      case 1:
      case 2:
        return false;
      case 3:
      case 4:
        return true;

      default:
        break;
    }
  };

  return (
    <Container className='fluid'>
      <Row>
        <TitleQuestion titleQuestion={titleQuestion} />
      </Row>
      {/* Phần render nội dung từ html */}
      <Row
        style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        <Form autoComplete='off' form={form} onFinish={onFinish} onFieldsChange={handleChange}>
          <div style={{ fontSize: 24, marginBottom: 50 }}>
            {state.sentences && state.sentences.length > 0 && ReactHtmlParser(state.sentences[0].title, { transform })}
          </div>
        </Form>
      </Row>
      {/* Phần Header */}
      <Header data={data} hideHeader={handleHeader(type)} headerStyle={tb2Style} />
      {/* Phần content */}
      <Content
        data={data}
        contentStyle={tb2Style}
        type={type}
        handleAnswers={(e) => setAnswers(e)}
        submitted={state.isPointed}
        userAnswers={userAnswers}
      />
      <Modal centered isOpen={modal} style={{ maxWidth: 640 }}>
        <ModalBody>
          {/* {renderContent} */}
          {state.star >= 1 && <Fireworks />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 240,
            }}
          >
            <Animate resultString={state.star} />
          </div>
        </ModalBody>
      </Modal>

      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        onPlayVideo={onPlayVideo}
        audioUrl={state.audioUrl}
        result={state?.booleanArray}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
        hideBtnFooter={props.hideBtnFooter ?? false} //not answer
      />
    </Container>
  );
};

export default TB2;

function count() {
  let n = 0;
  return {
    next: () => n++,
    reset: () => (n = 0),
  };
}
