import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P28-E1',
    audio: '',
    component: Circle_Write,
    // exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
    // exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          'Work in pairs. Look at the photos. What do you think the text is about? Choose from headlines a, b and c. ',
        color: '#8e258c',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 1200 },
        answers: [''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '5px',
          // fontWeight: 600,
          color: '',
          fontSize: 27,
          borderRadius: '50%',
        },
        selectWordStyle: { borderColor: 'none' },
        limitSelect: 0,
        listWords: ['a <br> b <br> c'],
        answers: [],
        initialValue: [],
      },
      Layout: `
			<div style="">
						What do you think the text is about?<br><input id='0' />
			</div>
			<div style=' position: relative; '>
					<img src='img/FriendsPlus/Page28/E1/1.jpg'>
          <div style=" position: absolute; top: 40px; left: 705px;"><input id='0' type='Circle' /></div>
          
          
			</div>

          
        `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P28-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_26.mp3',
    component: Circle_Write,
    // exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
    // exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: 'Read the first paragraph of the text. Did you choose correctly in exercise 1? How do you know? ',
        color: '#8e258c',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 1200 },
        answers: [
          '...an American brother and sister...were enjoying a fishing trip in the Caribbean...when their boat sank',
        ],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: '5px',
          // fontWeight: 600,
          color: '',
          fontSize: 27,
          borderRadius: '50%',
        },
        selectWordStyle: { border: '2px solid gray' },
        limitSelect: 1,
        listWords: ['a <br> b <br> c'],
        answers: ['0-0'],
        initialValue: [],
      },
      Layout: `
			<div style="">
						How do you know?<br><input id='0'/>
			</div>
			<div style=' position: relative; '>
					<img src='img/FriendsPlus/Page28/E1/1.jpg'>
          <div style=" position: absolute; top: 40px; left: 705px;"><input id='0' type='Circle' /></div>
         
          
          
			</div>

          
        `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P28-E3',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
    question: [],
    titleQuestion: [
      {
        num: '3',
        title:
          'Read the <span style="color:rgb(142 37 140)">Reading Strategy</span> Then read the text, focusing on each gap and thinking about what might happen next.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page28/E3/1.jpg' }]],
  },
};

export default json;
