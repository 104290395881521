import React, { Fragment, useEffect } from "react";
import { Row } from "reactstrap";
import Case1 from "./components/Case1";
import Case4 from "./components/Case4";
import useContent from "./useContent";

const Content = ({
  data,
  contentStyle,
  type,
  handleAnswers,
  submitted,
  userAnswers,
}) => {
  const { answers, handleContent } = useContent();

  useEffect(() => {
    handleAnswers(answers);
  }, [answers, handleAnswers]);

  const renderContent = (type, column, value) => {
    const { title, content, commonStyle, contentStyle, titleStyle } = value;
    switch (type) {
      case 1:
      case 2:
      case 3:
        return (
          <Case1
            column={column}
            commonStyle={commonStyle}
            content={content}
            contentStyle={contentStyle}
            handleContent={handleContent}
            submitted={submitted}
            userAnswers={userAnswers}
          />
        );
      case 4:
        return (
          <Case4
            column={column}
            commonStyle={commonStyle}
            content={content}
            contentStyle={contentStyle}
            handleContent={handleContent}
            submitted={submitted}
            title={title}
            userAnswers={userAnswers}
            titleStyle={titleStyle}
          />
        );

      default:
        break;
    }
  };

  return (
    <Row style={{ marginBottom: 50, ...contentStyle }}>
      {data.map((item, index) => {
        return (
          <Fragment key={index}>{renderContent(type, index, item)}</Fragment>
        );
      })}
    </Row>
  );
};

export default Content;
