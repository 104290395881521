import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Look at the photo of the beach. Answer the following questions.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    inputSize: 960,
    stylesTextInput: [],
    maxLength: 90,
    textAlign: 'left',
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page92/E1/1.jpg' style="width: 1300px; margin-left: -100px"/></div>
          
	      <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div>Where do you think the beach is?</div>
            <div>#</div>
          </div>
        </div>  
	      <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div>Can you guess how many people visit this beach every year? </div>
            <div>#</div>
          </div>
        </div>

			  `,
        answer: [],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page92/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the text and check your answers to exercise 1.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    inputSize: 560,
    stylesTextInput: [],
    maxLength: 90,
    textAlign: 'left',
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page92/E2/1.jpg' style="width: 700px; margin-left: 0px"/></div>
          
	      <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div>Where do you think the beach is?</div>
            <div>#</div>
          </div>
        </div>  
	      <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div>Can you guess how many people visit this beach every year? </div>
            <div>#</div>
          </div>
        </div>

			  `,
        answer: ['In Viet Nam', 'Millions of tourists visit it a year.'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page92/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				<b style="color:rgb(153, 34, 136);">VOCABULARY </b> Read the text. Find the antonyms of the following adjectives.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    inputSize: 140,
    stylesTextInput: [],
    maxLength: 12,
    textAlign: 'left',
    questions: [
      {
        title: `
          <div><img src= 'img/FriendsPlus/Page92/E3/1.jpg' style="width: 700px; margin-bottom: 20px"/></div>

					<div style="color: rgb(34, 132, 200);"><b>ugly &emsp; unpopular &emsp; unimpressive &emsp; boring &emsp; noisy &emsp; crowded</b></div>
          
	      <div style='display: flex; gap: 90px;'>
          <div>
            <div>ugly -#</div>
            <div>unpopular -#</div>
            <div>unimpressive -#</div>
          </div>
          <div>
            <div>boring -#</div>
            <div>noisy -#</div>
            <div>crowded -#</div>
          </div>
        </div> 

			  `,
        answer: ['beautiful', 'popular', 'impressive', 'interesting', 'peaceful', 'quiet'],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page92/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				Read the text again. Are the sentences true, false or not given? Write T, F or Not given. 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 150,
      textAlign: 'center',
      fontSize: 20,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F', 'Not given'],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 &emsp;</b></div>
          <div> Phu Quoc is in the north of Viet Nam. </div>
          <b> <select id='0'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 &emsp;</b></div>
          <div> You can eat a lot of fish on the island. </div>
          <b> <select id='1'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 &emsp;</b></div>
          <div> Nearly four million people visited the island in 2018. </div>
          <b> <select id='2'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 &emsp;</b></div>
          <div> You can find lots of cheap hotels on the island.</div>
          <b> <select id='3'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 &emsp;</b></div>
          <div> Behind the hotel resorts you can see a lot of wildlife.</div>
          <b> <select id='4'></select>  </b>
        </div>
          <div><img src= 'img/FriendsPlus/Page92/E4/1.jpg' style="width: 700px; margin-top: 20px"/></div>
			  `,
        answer: ['F', 'T', 'F', 'Not given', 'T'],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E5',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_12.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
        <span style="margin-left: 15px;"><headphone1 name="3.12"></headphone1></span> Listen to an interview. Why was the bridge built? Choose A or B.
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page92/E5/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
          marginLeft: 25,
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          marginLeft: 25,
        },
        limitSelect: 1,
        listWords: [`A <br> B`],
        answers: ['0-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;">
      <div> <input type="Circle" id="0"></div>
      <div style="margin-left: 20px;margin-bottom: 20px;">
        <span>To transport people to work.</span> <br>
        <span>To attract tourists.</span> <br>
      </div>
    </div>
      `,
    },
  },
  6: {
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E6',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_12.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style="margin-left: 15px;"><headphone1 name="3.12"></headphone1></span> Listen again and choose the correct option (a–c).
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page92/E6/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
          marginLeft: 25,
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          marginLeft: 25,
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c`, `a <br> b <br> c`, `a <br> b <br> c`, `a <br> b <br> c`, `a <br> b <br> c`],
        answers: ['0-8', '1-8', '2-0', '3-4', '4-8'],
        initialValue: [],
      },
      Layout: `
      <b>1</b> <span> The Golden Bridge transports people between</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="0"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> a rice field and a garden.</span> <br>
          <span> a rice field and a cable car.</span> <br>
          <span> a cable car and some gardens.</span> <br>
        </div>
      </div>
      <b>2</b> <span> Giang says the bridge is special because</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="1"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> it’s in the forest.</span> <br>
          <span> it’s magic.</span> <br>
          <span> it’s a gold colour.</span> <br>
        </div>
      </div>
      <b>3</b> <span> The bridge is</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="2"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> almost 1,000 metres above sea level.</span> <br>
          <span> 1,000 metres from the sea.</span> <br>
          <span> 1,000 metres from Da Nang.</span> <br>
        </div>
      </div>
      <b>4</b> <span> The stone hands look like the hands of</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="3"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> a giant.</span> <br>
          <span> a god.</span> <br>
          <span> a king.</span> <br>
        </div>
      </div>
      <b>5</b> <span> The presenter suggests listeners</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="4"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> take photos of the bridge.</span> <br>
          <span> find out more about the bridge.</span> <br>
          <span> go and see the bridge.</span> <br>
        </div>
      </div>
      `,
    },
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P92-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '7',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in groups. Make a tourist brochure for another famous tourist attraction in Viet Nam. Present your brochure to the class.
			  `,
        color: '#7c084f',
      },
    ],
    titleImage: '',
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <textarea id='0' rows='7'></textarea>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
