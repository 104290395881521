import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `Read the notices. What are they warning you not to do?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    recorder: true,
    questionImage: [],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
					<div><b><i>Answer: </i></b> <textarea id="0" rows="3"> </textarea>
				  </div>
				  <img src='img/FriendsPlus/Page39/E1/1.jpg' style='width: 400px;' />
        </div>
			  `,
        answer: ['They are warning people not to download or copy films, music, etc. illegally.'],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `Read the views of some teenagers. Who thinks illegally downloading things is not a problem? Who thinks it is as serious as any other crime?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 170,
    textAlign: 'center',
    questions: [
      {
        title: `
        <div style='display: flex; gap:50px;'>
					<div>
            <div><b><i>Not a problem: </i></b> </div>
            #
            <div><b><i>As serious as other crimes: </i></b> </div>
            #
          </div>
				  <img src='img/FriendsPlus/Page39/E2/1.jpg' style='width: 500px;' />
        </div>
			  `,
        answer: ['Hannah', 'Samantha'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E3/key/answerKey.png',
    inputSize: 170,
    textAlign: 'center',
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
				Read the <span style='color:rgb(153, 34, 136);'>Learn this!</span> box. Then find the opposites of the adjectives below in the text in exercise 2.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
               <div style='border-radius: 20px; border: 2px solid rgb(153, 34, 136); width: 900px; margin: 10px 50px 30px 50px'>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(153, 34, 136);'>LEARN THIS!</b><b> Negative prefixes: <i> un-, in-, im-, ir-, il-</i> and <i>dis-</i></b></div>
                    <div>The prefixes <i> un-, in-, im-, ir-, il-</i> and <i>dis-</i> are negative. When they come before an adjective, they make its meaning opposite.</div>
                    <div><i>certain – uncertain &emsp; patient – impatient</i></div>
                    <div><i>honest – dishonest &emsp; responsible – irresponsible</i></div>
                  </div>
                </div>
            <div style='display: flex; justify-content: space-around;'>
                <div>
                  <div><b> acceptable - &emsp; </b> #</div>
                  <div><b> dependent - &emsp; </b> #</div>
                  <div><b> fair - &emsp; </b> #</div>
                  <div><b> honest - &emsp; </b> #</div>
                  <div><b> legal - &emsp; </b> #</div>
                  <div><b> likely - &emsp; </b> #</div>
                  <div><b> surprising - &emsp; </b> #</div>
                  <div><b> visible - &emsp; </b> #</div>
                </div>
                <div><img src='img/FriendsPlus/Page39/E3/1.jpg' alt='' style='width: 600px;'/></div>
            </div>
            

        `,
        answer: [
          'unacceptable',
          'independent',
          'unfair',
          'dishonest',
          'illegal',
          'unlikely',
          'unsurprising',
          'invisible',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:rgb(153, 34, 136);'>DICTIONARY WORK</span> Read the <span style='color:rgb(153, 34, 136);'>Dictionary Skills Strategy</span> . Then study the dictionary entry. What synonym and antonym of <i>loyal</i> are given? How are they indicated?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'center',

    questions: [
      {
        title: `
			  
      
        <div style='display: flex; gap:50px;'>
					<div>
            <img src='img/FriendsPlus/Page39/E4/1.jpg' style='width: 500px;' />
            <div><b><i>synonym: </i></b> #</div>
            <div><b><i>antonym: </i></b> #</div>
          </div>
          <div>
            <div style='background-color: rgb(216, 205, 215); margin-top: 20px; margin-left:0px; width:500px; border-radius: 15px; border: 5px dotted rgb(153, 34, 136)'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div ><b style='color:rgb(153, 34, 136) ;'>Dictionary Skills Strategy</b></div>
                <div>You can often find synonyms (words with the same meaning) and antonyms (words with the opposite meaning) of adjectives in a learner’s dictionary.</div>
              </div>
            </div>
          </div>
        </div>
			  `,
        answer: ['faithful/SYN', 'disloyal/OPP'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E5/key/answerKey.png',
    inputSize: 500,
    textAlign: 'left',
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
				Rewrite the sentences using the adjectives in brackets with a negative prefix. You can use a dictionary to help you. 
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `
								<div style='margin-left: 100px;'>
									<div><b>1 &emsp;</b> Her bedroom is in a mess. (tidy)</div>
									<span style='color:rgb(124, 162, 211)'>Her bedroom is untidy</span>
									<div><b>2 &emsp;</b> That answer is wrong. (correct)</div>
									<div>#</div>
									<div><b>3 &emsp;</b> It isn’t nice to tease your little sister. (kind)</div>
									<div>#</div>
									<div><b>4 &emsp;</b> He was very rude to me! (polite)</div>
									<div>#</div>
									<div><b>5 &emsp;</b> I’m unhappy with my exam results. (satisfied)</div>
									<div>#</div>
                  <div><b>6 &emsp;</b> I can’t do this exercise! (possible)</div>
									<div>#</div>
								</div>
        `,
        answer: [
          'That answer is incorrect.',
          'It’s unkind to tease your little sister.',
          'He was very impolite to me!',
          'I’m dissatisfied with my exam results.',
          'This exercise is impossible!',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P39-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				Complete the sentences with adjectives with the opposite meaning. Then take turns to ask and answer the questions.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 170,
    textAlign: 'center',
    hintBox: [
      {
        src: ['Is your handwriting legible or illegible?'],
        arrow: true,
        position: 1,
        width: 600,
        borderColor: '#92278f',
      },
      {
        src: ['My handwriting is legible.'],
        arrow: true,
        position: 2,
        width: 500,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 150px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 350px;'><hintBox id='1' ></hintBox></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> Is your handwriting legible or #?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> Are you generally honest or #?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> Do you think teenagers are generally responsible or #?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div> Are you generally patient or #?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div> Is smoking in public places legal or in your country#?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>Do you think that reversing climate change is possible or #? </div>
        </div>

			  `,
        answer: ['illegible', 'dishonest', 'irresponsible', 'impatient', 'illegal', 'impossible'],
      },
    ],
  },
};

export default json;
