import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	4: {
		unit: 'Science',
		id: 'FG10-S-P95-E4',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 40,
		maxLength: 4,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Look around the classroom. 
          What things are made of the materials in exercise 2? 
          Think about furniture, the building, clothes and possessions.

        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; max-width: 550px; padding: 10px; border-radius: 10px; text-align: center; background-color: rgb(211, 181, 214); box-shadow: 1px 1px 3px rgba(0,0,0,0.4)">
            The windows are made of aluminium and glass. This book ...
          </div>

          <div style="margin-top: 30px; border: 1px solid rgb(146, 39, 144); border-radius: 10px; padding: 10px; color: rgb(146, 39, 144);  box-shadow: 1px 1px 3px rgba(146, 39, 144,0.4);">
            <div>
              <strong>Materials</strong>
              <span style="margin-left: 25px;">aluminium</span>
              <span style="margin-left: 25px;">cardboard</span>
              <span style="margin-left: 25px;">ceramic</span>
              <span style="margin-left: 25px;">concrete</span>
              <span style="margin-left: 25px;">copper<span/>
              <span style="margin-left: 25px;">glass<span/>
            </div>
            <div>
              <span>gold</span>
              <span style="margin-left: 25px;">iron</span>
              <span style="margin-left: 25px;">leather</span>
              <span style="margin-left: 25px;">nylon</span>
              <span style="margin-left: 25px;">paper</span>
              <span style="margin-left: 25px;">plastic</span>
              <span style="margin-left: 25px;">rubber</span>
              <span style="margin-left: 25px;">steel</span>
              <span style="margin-left: 25px;">stone</span>
              <span style="margin-left: 25px;">wood</span>
            </div>
          </div>	
				`,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P95-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page95/E5/Key/answerKey.png',
		component: T6,
		inputSize: 100,
		maxLength: 6,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
          Look at the language for describing technology. 
          Complete the phrases with the words below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; color: rgb(146, 39, 144);">
            <div>
              <span>allows</span>
              <span style="margin-left: 36px;">base</span>
              <span style="margin-left: 33px;">handle</span>
              <span style="margin-left: 41px;">mains</span>
              <span style="margin-left: 42px;">mostly</span>
            </div>
          </div>

          <div style="margin-top: 20px; border-left: 5px solid rgb(146, 39, 144); padding-left: 15px;">
            <span style="font-weight: bold; color: rgb(146, 39, 144);">Describing technology</span>
            <div>
              <strong>Shape</strong> 
              <span style="margin-left: 36px;">straight</span>
              <span style="margin-left: 33px;">curved</span>
              <span style="margin-left: 41px;">rectangular</span>
              <span style="margin-left: 42px;">spherical</span> <br/>
              <span>square</span>
              <span style="margin-left: 42px;">triangular</span>
              <span style="margin-left: 42px;">circular</span>
            </div>

            <div style="margin-top: 5px;">
              <strong>Power</strong> <br/>
              It’s <sup>1</sup># powered / battery powered / solar powered. <br/>
              It’s cordless / rechargeable.
            </div>

            <div style="margin-top: 5px;">
              <strong>Construction</strong> <br/>
              It’s made <sup>2</sup># of glass / steel, etc. <br/>
              It’s got wheels / a long <sup>3</sup># / a triangular <sup>4</sup># / a square lid, etc.
            </div>

            <div style="margin-top: 5px;">
              <strong>Use</strong> <br/>
              You use it to ... <br/>
              It <sup>5</sup># you to ... <br/>
              It prevents you from (+ <i>-ing</i>) ...
            </div>
          </div>
				`,
				answer: ['mains', 'mostly', 'handle', 'base', 'allows'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P95-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_15.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page95/E6/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 26,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          <audioimage name="3.15"></audioimage> 
          Listen to the dialogues. Which phrases from exercise 5 do the people use?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; display: flex; align-items: start;">
            <div style="flex: 2; border-radius: 10px; border: 1px solid rgb(146, 39, 144); padding: 15px;">
              <span style="font-weight: bold; color: rgb(146, 39, 144);">Describing technology</span>
              <div>
                <strong>Shape</strong> 
                <span style="margin-left: 26px;">straight</span>
                <span style="margin-left: 23px;">curved</span>
                <span style="margin-left: 31px;">rectangular</span>
                <span style="margin-left: 32px;">spherical</span> <br/>
                <span>square</span>
                <span style="margin-left: 32px;">triangular</span>
                <span style="margin-left: 32px;">circular</span>
              </div>

              <div style="margin-top: 5px;">
                <strong>Power</strong> <br/>
                It’s <sup>1</sup>_____ powered / battery powered / solar powered. <br/>
                It’s cordless / rechargeable.
              </div>

              <div style="margin-top: 5px;">
                <strong>Construction</strong> <br/>
                It’s made <sup>2</sup>_____ of glass / steel, etc. <br/>
                It’s got wheels / a long <sup>3</sup>_____ / a triangular <sup>4</sup>_____ / a square lid, etc.
              </div>

              <div style="margin-top: 5px;">
                <strong>Use</strong> <br/>
                You use it to ... <br/>
                It <sup>5</sup>_____ you to ... <br/>
                It prevents you from (+ <i>-ing</i>) ...
              </div>
            </div>

						<div style=" margin-left: 20px;flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(246, 141, 31); padding-left: 15px;">
              <span style="color: rgb(246, 141, 31); font-style: italic;" ">Answer:</span> <br/>
              # <br/> # <br/> # <br/> # <br/> # <br/> 
            </div>
          </div>
				`,
				answer: [
					'battery powered',
					'mains powered',
					"it's got a long handle",
					"It's got a triangular base",
					"It's made mostly of iron",
				],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P95-E5',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 280,
		maxLength: 26,
		hintBox: [
			{
				src: ['It’s battery powered, I think. It’s made of ...'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 1,
				width: 525,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Think of a gadget and describe it to your partner. 
					Include phrases from exercise 5. Can your partner guess what you are describing?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<hintbox id='0'></hintbox>
					
					<div style="margin-top: 50px; border-left: 5px solid rgb(146, 39, 144); padding-left: 15px;">
						<span style="font-weight: bold; color: rgb(146, 39, 144);">Describing technology</span>
						<div>
							<strong>Shape</strong> 
							<span style="margin-left: 26px;">straight</span>
							<span style="margin-left: 23px;">curved</span>
							<span style="margin-left: 31px;">rectangular</span>
							<span style="margin-left: 32px;">spherical</span> <br/>
							<span>square</span>
							<span style="margin-left: 32px;">triangular</span>
							<span style="margin-left: 32px;">circular</span>
						</div>

						<div style="margin-top: 5px;">
							<strong>Power</strong> <br/>
							It’s <sup>1</sup>_____ powered / battery powered / solar powered. <br/>
							It’s cordless / rechargeable.
						</div>

						<div style="margin-top: 5px;">
							<strong>Construction</strong> <br/>
							It’s made <sup>2</sup>_____ of glass / steel, etc. <br/>
							It’s got wheels / a long <sup>3</sup>_____ / a triangular <sup>4</sup>_____ / a square lid, etc.
						</div>

						<div style="margin-top: 5px;">
							<strong>Use</strong> <br/>
							You use it to ... <br/>
							It <sup>5</sup>_____ you to ... <br/>
							It prevents you from (+ <i>-ing</i>) ...
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
