import React from "react";
import { Col, Row } from "reactstrap";

const Header = ({ data, hideHeader, headerStyle }) => {
  if (hideHeader) {
    return null;
  }

  return (
    <Row style={{ ...headerStyle }}>
      {data.map((item, index) => {
        const { title, commonStyle, titleStyle } = item;
        return (
          <Col
            key={index}
            style={{
              border: "solid 1px",
              fontWeight: "bold",
              textAlign: "center",
              ...commonStyle,
              ...titleStyle,
            }}
          >
            {title}
          </Col>
        );
      })}
    </Row>
  );
};

export default Header;
