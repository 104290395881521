import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P59-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_14.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page59/E5/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 15,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 23,
		// },
		titleQuestion: [
			{
				num: '5',
				title: `
					<audioimage name="2.14"></audioimage>
                    Now listen to two university students talking about their part-time jobs. 
                    Match one adjective from exercise 3 with each speaker’s job. 
                    Give a reason for your choice.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="margin-right: 30px;"><strong>1</strong> <span style="margin-right: 10px;">Tom:</span> #</div>
						<div style="margin-right: 30px;"><strong>2</strong> Katie: #</div>
					</div>
					<div style="margin-top: 10px; color: rgb(146, 39, 144);">
						<div>
							<strong>Describing jobs</strong>
							<span style="margin-left: 30px;">creative</span>
							<span style="margin-left: 30px;">challenging</span>
							<span style="margin-left: 30px;">repetitive</span>
						</div>
						<div>
							<span>rewarding</span>
							<span style="margin-left: 105px;">stressful</span>
							<span style="margin-left: 26px;">tiring</span>
							<span style="margin-left: 100px;">varied</span>
						</div>
					</div>
                    <div style="margin-top: 20px; display: flex;">
                        <img src="img/FriendsPlus/Page58/E4/1.jpg" style="max-width: 100%;" />
                        <div style="margin-left: 30px;"><img src="img/FriendsPlus/Page58/E4/2.jpg" style="max-width: 100%;" /></div>
                    </div>
	      `,
				answer: ['repetitive', 'rewarding'],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P58-E6',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_14.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page59/E6/Key/answerKey.png',
		component: T6,
		inputSize: 140,
		maxLength: 15,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					<audioimage name="2.14"></audioimage> 
					Listen again. Complete the following sentences about Tom and Katie.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div>
								<strong style="padding-right: 10px;">1</strong>
								Tom # long hours as a gardener.
						</div>
						<div>
								<strong style="padding-right: 10px;">2</strong>
								He # part of a team.
						</div>
						<div>
								<strong style="padding-right: 10px;">3</strong>
								Katie # with children every day.
						</div>
						<div>
								<strong style="padding-right: 10px;">4</strong>
								She # long hours most days.
						</div>
					</div>
					
					<div style="margin-top: 40px; display: flex;">
							<img src="img/FriendsPlus/Page58/E4/1.jpg" style="max-width: 100%;" />
							<div style="margin-left: 30px;"><img src="img/FriendsPlus/Page58/E4/2.jpg" style="max-width: 100%;" /></div>
					</div>
	      `,
				answer: ["didn't work", "wasn't", 'worked / was', 'worked'],
			},
		],
	},
	7: {
		unit: 'Ambition',
		id: 'FG10-A-P59-E7',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_13.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page59/E7/Key/answerKey.png',
		component: T6,
		inputSize: 140,
		maxLength: 15,
		recorder: true,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 23,
		// },
		hintBox: [
			{
				src: ['You want to earn a lot of money and travel around the world. I think you should be a pilot!'],
				borderColor: '#D3B5D6',
				arrow: true,
				position: 2,
				width: 600,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
                    Work in pairs. Discuss your answers to the questionnaire below. 
                    Try to find the ideal job for your partner.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<hintbox id='0'></hintbox>
                    <div style="margin-top: 40px; color: rgb(146, 39, 144);">
						<div>
							<strong>Jobs (1)</strong>
							<span style="margin-left: 85px;">architect</span>
							<span style="margin-left: 43px;">cleaner</span>
							<span style="margin-left: 51px;">dentist</span>
							<span style="margin-left: 52px;">engineer</span>
							<span style="margin-left: 30px;">farm worker<span/>
						</div>
						<div>
							<span>hairdresser</span>
							<span style="margin-left: 56px;">paramedic</span>
							<span style="margin-left: 20px;">pilot</span>
							<span style="margin-left: 86px;">programmer</span>
							<span style="margin-left: 113px;">receptionist</span>
						</div>
						<div>
							<span>sales assistant</span>
							<span style="margin-left: 20px;">solicitor</span>
							<span style="margin-left: 50px;">sports coach</span>
							<span style="margin-left: 20px;">travel agent</span>
							<span style="margin-left: 92px;">waiter</span>
						</div>
					</div>
                    <div style="margin-top: 20px; color: rgb(146, 39, 144);">
							<div>
								<strong>Work activities</strong>
								<span style="margin-left: 40px;">answer the phone</span>
								<span style="margin-left: 40px;">be on your feet</span>
								<span style="margin-left: 40px;">be part of a team</span>
							</div>
							<div>
								<span>deal with the public</span>
								<span style="margin-left: 40px;">earn a lot (of money)</span>
								<span style="margin-left: 40px;">make phone calls</span>
							</div>
							<div>
								<span>serve customers</span>
								<span style="margin-left: 70px;">travel a lot</span>
								<span style="margin-left: 40px;">use a computer</span>
								<span style="margin-left: 40px;">wear a uniform</span>
							</div>
							<div>
								<span>work alone</span>
								<span style="margin-left: 131px;">work indoors / outdoors</span>
								<span style="margin-left: 40px;">work with children</span>
							</div>
							<div>
								<span>work long hours / nine-to-five</span>
							</div>
						</div>
                `,
				answer: [],
			},
		],
	},
};

export default json;
