/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

function LeftArrow({ name, src }) {

  return (
    <div style={{ display: "inline-block" }}>
      <img
        src="img/grammar.png"
        alt=""
        style={{ width: "25px", marginBottom: 5}}
      />
      <span style={{ fontSize: 25, fontWeight: "bold",color: "rgb(246 140 31)"}}>{` ${name}`}</span>
    </div>
  );
}

export default LeftArrow;