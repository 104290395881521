import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>IB</span>Contrast: present simple and continuous</strong> <br /> <strong>1</strong> Complete the sentences with the present simple affirmative form of the verbs below <leftarrow name='I.1, I.2'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page106/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
         <div style="display: flex; column-gap: 20px; flex-wrap: wrap; color: rgb(246 140 31)">
            <span>go</span> 
            <span>have</span>
            <span>live</span>
            <span>miss</span>
            <span>play</span>
            <span>study</span>
            <span>visit</span>
            <span>watch</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              My aunt <input />
              in Scotland.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              Carl <input />
              his grandparents every weekend.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              My aunt <input />
              TV in her bedroom every evening.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              My mum’s a scientist: she <input />
              climate change.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              Brooklyn <input />
              the piano really well.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              My cousin <input />
              to school in Switzerland.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              Now that she’s at secondary school, she <input />
              her old teachers from primary school.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              Grace <input />
              double maths every Tuesday.
            </div>
          </div>
				`,
        answer: ['lives', 'visits', 'watches', 'studies', 'plays', 'goes', 'misses', 'has'],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: "Make the sentences negative. <leftarrow name='I.1'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page106/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>Sophie has a guitar lesson every Saturday</div>
              <div style="color: rgb(4 84 121); font-style: oblique;">Sophie doesn’t have a guitar lesson every Saturday</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>My cat likes cheese.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>ack and Ellie live near the city centre.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>Maya goes bowling every weekend.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>My next-door neighbours work in London</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>Amelia wears sports clothes at home.</div>
              <input />
            </div>
          </div>
				`,
        answer: [
          `My cat doesn't like cheese.`,
          `Jack and Ellie don't live near the city centre.`,
          `Maya doesn't go bowling every weekend.`,
          `My next door neighbours don't work in London.`,
          `Amelia doesn't wear sports clothes at home.`,
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title:
          "Look at the picture and correct the sentences. Use the verbs in brackets.  <leftarrow name='I.1'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page106/E3/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div>
          <div>
            <img src="img/FriendsPlus/Page106/E3/1.jpg" style="width: 400px"/>
          </div>
        </div>
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>The woman in a dress is cooking. (eat)</div>
              <div style="color: rgb(4 84 121); font-style: oblique;">She isn’t cooking. She’s eating.</div>
            </div>
          </div>
           <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>The old man is reading. (sleep)</div>
              <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>The two girls are skateboarding. (read)</div>
              <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>The dog is drinking water. (play)</div>
              <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>The man with the hat is washing. (cook)</div>
              <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>The two boys are playing volleyball. (chat)</div>
              <input/>
            </div>
          </div>
        
				`,
        answer: [
          `He isn't reading. He's sleeping.`,
          `They aren't skateboarding. They're reading.`,
          `It isn't drinking water. It's playing.`,
          `He isn't washing. He's cooking.`,
          `They aren't playing volleyball. They're chatting.`,
        ],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page106/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: "Circle the correct tense.  <img src='img/FriendsPlus/Page106/E4/1.jpg' />",
        color: 'black',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          fontWeight: 'bold',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px gray',
          borderColor: 'gray',
        },
        limitSelect: 1,
        listWords: [
          'sleep / are_sleeping', //2
          'walks / is_walking', //3
          'don’t_belong / aren’t_belonging',
          'it_snows / it’s_snowing',
          'I_meet / I’m_meeting',
          'do_you_wear / are_you_wearing',
          'doesn’t_understand / isn’t_understanding',
          'I_have / I’m_having',
        ],
        answers: ['0-0', '2-0', '3-4', '1-0', '4-4', '5-4', '6-0', '7-4'],
        initialValue: [],
      },
      Layout: `
      <div style='margin-top: 10px;line-height:45px'>
          <b>1</b>&ensp; Cats <input id='0' type='Circle'/> for about sixteen hours a day.<br>
          <b>2</b>&ensp; Mason <input id='1' type='Circle'/> to school every day. <br>
          <b>3</b>&ensp; Jack and Emily <input id='2' type='Circle'/> to our sports club.<br>
          <b>4</b>&ensp; Don’t forget your scarf – <input id='3' type='Circle'/> .<br>
          <b>5</b>&ensp;  <input id='4' type='Circle'/> Paul for a coffee this afternoon.<br>
          <b>6</b>&ensp; Why <input id='5' type='Circle'/> shorts? It’s cold today!<br>
          <b>7</b>&ensp; He’s laughing, but he <input id='6' type='Circle'/> the joke.<br>
          <b>8</b>&ensp; Let’s continue with the game. <input id='7' type='Circle'/>  fun! <br>
    </div>
      `,
    },
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title:
          "Complete the dialogue. Use the correct present simple or present continuous form of the verbs in brackets.  <img src='img/FriendsPlus/Page106/E4/1.jpg' />",
        color: 'black',
      },
    ],
    stylesTextInput: {
      borderBottom: 'dash',
      backgroundColor: 'transparent',
    },
    inputSize: 180,
    exerciseKey: 'img/FriendsPlus/Page106/E5/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='width:22cm  '>
              <div style='display:flex; margin-top:10px'>
                <b>Ryan</b>
                <div style='margin-left:50px'>
                    Hi, Ava. What <sup>1</sup>#(you / do)?
                </div>
              </div>
              <div style='display:flex; margin-top:10px'>
                <b>Ava</b>
                <div style='margin-left:66px'>
                  I <sup>2</sup>#(look) for a present for Laura. 
                  I <sup>3</sup>#(go) to her party tomorrow night
                </div>
              </div>
              <div style='display:flex; margin-top:10px'>
                <b>Ryan</b>
                <div style='margin-left:50px'>
                  Me too. But I <sup>4</sup># usually# (not buy) her a birthday present
                </div>
              </div>
              <div style='display:flex; margin-top:10px'>
                <b>Ava</b>
                <div style='margin-left:66px'>
                  <sup>5</sup>#(want) to get her something <sup>6</sup>#(she / like) DVDs?
                </div>
              </div>
              <div style='display:flex; margin-top:10px'>
                <b>Ryan</b>
                <div style='margin-left:50px'>
                    She <sup>7</sup>#(prefer) books, I think. <sup>8</sup>#(read) the Twilight novels at the
                    moment. Maybe she would like a new one.
                </div>
              </div>
              <div style='display:flex; margin-top:10px'>
                <b>Ava</b>
                <div style='margin-left:66px'>
                  Great idea. Thanks!
                </div>
              </div>
          </div>
				`,
        answer: [
          'are you doing',
          'am looking',
          'am going',
          `don't`,
          'buy',
          'want',
          'Does she like',
          'prefers',
          'is reading',
        ],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>ID</span>Articles</strong> <br /> <strong>6</strong> Complete the sentences with <i>a / an</i>, the or no article.  <img src='img/FriendsPlus/Page106/E6/1.jpg' />",
        color: 'black',
      },
    ],
    textAlign: 'center',
    stylesTextInput: {
      borderBottom: 'dash',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page106/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
            There’s#zoo and#wildlife park near my home, but
          #wildlife park isn’t open in the winter.
          </div>
         </div>
         <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
              Don’t use#DVD player, it’s broken.
          </div>
         </div>
         <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
            Can you pass me my hat, please? It's on#table.
          </div>
         </div>
         <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
            I’ve got#computer and#laptop, but I have to share
          #laptop with my brother.
          </div>
         </div>
         <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
              My sister is at#home. She’s playing#guitar.
          </div>
         </div>
         <div style='display:flex'>
          <b>6</b>
          <div style='margin-left:20px'>
             My dad is#teacher and my mum is#doctor.
          </div>
         </div>
         <div style='display:flex'>
          <b>7</b>
          <div style='margin-left:20px'>
              There’s#CD player in my room so I can lie in#bed
              and listen to#music.
          </div>
         </div>
				`,
        answer: ['a', 'a', 'the', 'the', 'the', 'a', 'a', 'the', 'none', 'the', 'a', 'a', 'a', 'none', 'none'],
      },
    ],
  },
  7: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P106-E7',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title: `Complete the sentences with the or no article. Remember:
we do not use the with generalisations <leftarrow name='I.12'></leftarrow>`,
        color: 'black',
      },
    ],
    textAlign: 'center',
    stylesTextInput: {
      borderBottom: 'dash',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    exerciseKey: 'img/FriendsPlus/Page106/E7/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
         <div style='line-height:50px'>
            <b>1</b>&ensp;Children need#milk for healthy teeth and bones.<br>
            <b>2</b>&ensp;I love the UK, but I don’t like#weather here.<br>
            <b>3</b>&ensp;On holiday, I prefer to stay in#nice hotels.<br>
            <b>4</b>&ensp;I’m not a sports fan, but#table tennis is fun.<br>
            <b>5</b>&ensp;It’s freezing, but the children are enjoying#snow.<br>
            <b>6</b>&ensp;At school, students learn how to use#computers.<br>
            <b>7</b>&ensp;I’m not interested in#science fiction films.<br>
            <b>8</b>&ensp;These dancers are great but I don’t really like#music.
         </div>
				`,
        answer: ['none', 'the', 'none', 'none', 'the', 'none', 'none', 'the'],
      },
    ],
  },
};

export default json;
