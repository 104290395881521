import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Money',
    id: 'FG10-M-P81-E1',
    audio: '',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page81/E1/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 22,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="color: rgb(0,110,147);">Reading</span> <br />
          Read the text. Match sentences A–G with gaps 1–5 in the text. There are two extra sentences.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; gap: 30px; align-items: start; font-size: 22px;">
            <div style="max-width: 700px; padding: 10px 30px; border-radius: 10px; background-color: rgb(224, 240, 218);">
              <div style="font-weight: bold; font-size: 25px;">My first business</div>
              
              <div style="padding-left: 20px;">
                There are quite a lot of stories about young entrepreneurs these days - people who 
                have started their own businesses while they are still at school and have gone on to 
                make large amounts of money. <sup>1</sup># I think I was about five years old when I decided to 
                try to make some money. <sup>2</sup># I had seen it in a toy shop the month before, 
                but my parents could not afford it because it was very expensive. So, I decided to 
              save all of my pocket money until I had enough to buy it myself. <sup>3</sup>#To get the doll more quickly, 
                came up with an idea. My mum grew some pretty flowers in her garden. 
                I decided to take the flowers onto the street, held them out and said, ‘Fifty pence a bunch,’ 
                to the people who passed by. One lady stopped and gave me fifty pence. <sup>4</sup># After half an hour 
                I sold out all of the flowers and earned some money for my doll. I was really proud then.
              </div>

              <div style="padding-left: 20px; margin-top: 7px;">
                Now, I have my own business after many years of working hard. 
                How challenging life as an entrepreneur has been! <sup>5</sup># 
                However, it is wonderful to see my business grow well and make profits.
              </div>
            </div>

            <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(142, 234, 107);">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">A</div>
                <div>However, it was taking a very long time!</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">B</div>
                <div>I think she felt sorry for me.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">C</div>
                <div>I was saving up for a big doll.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">D</div>
                <div>If I had the same courage now, maybe I would be rich.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">E</div>
                <div>My career also started early.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">F</div>
                <div>Of course, it’s a lot harder than that.</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">G</div>
                <div>I have to decide on everything from making money to hiring people.</div>
              </div>	
            </div>
          </div>
        `,
        answer: ['E', 'C', 'A', 'B', 'G'],
      },
    ],
  },
  2: {
    unit: 'Money',
    id: 'FG10-M-P81-E2',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_36.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="color: rgb(0,110,147);">Listening</span><br /> 
          Listen to three recordings. Circle the correct option (a–d).
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page81/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
          marginLeft: 25,
          padding: '5px',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          marginLeft: 25,
        },
        limitSelect: 1,
        listWords: [`a<br> b<br> c<br> d`, `a<br> b<br> c<br> d`, `a<br> b<br> c<br> d`],
        answers: ['0-4', '1-4', '2-6'],
        initialValue: [],
      },
      Layout: `
      <b>1</b> <span> Where would you hear this announcement?</span> <br>
      <div style="display: flex;">
      <div> <input type="Circle" id="0"></div>
      <div style="margin-left: 20px;margin-bottom: 20px;">
        <span>in a shop centre</span> <br>
        <span>in a clothes shop</span> <br>
        <span>in a department store</span> <br>
        <span>in a camera shop</span>
      </div>
    </div>
    
    <b>2</b> <span> What does Lucy do to save money?</span> <br>
      <div style="display: flex;">
      <div> <input type="Circle" id="1"></div>
      <div style="margin-left: 20px;margin-bottom: 20px;">
        <span>She never goes out.</span> <br>
        <span>She works every weekend.</span> <br>
        <span>She doesn’t buy many things.</span> <br>
        <span>She works every day.</span>
      </div>
    </div>

    <b>3</b> <span> What is Jake going to do?</span> <br>
      <div style="display: flex;">
      <div> <input type="Circle" id="2"></div>
      <div style="margin-left: 20px;">
        <span>apply for a job</span> <br>
        <span>make a phone call</span> <br>
        <span>work as a waiter</span> <br>
        <span>tell someone about a job</span>
      </div>
    </div>
      `,
    },
  },
  3: {
    unit: 'Money',
    id: 'FG10-M-P81-E3',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '',
        title: `
          <span style='color:rgb(0,110,147);'>SPEAKING</span> <br>
          Work in groups. Look at the photo and discuss the following questions.
          <div style="line-height: 50px;">
            <b>1</b> <span> How do you think the lives of the people in the photo are different? </span> <br>
            <b>2</b> <span>  If you had 100,000,000 VND, how could you use it to help homeless people?</span>
          </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: `/img/FriendsPlus/Page81/E3/E3.png` }]],
  },
  4: {
    unit: 'Money',
    id: 'FG10-M-P81-E4',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    textareaStyle: { width: 610 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="color: rgb(0,110,147);">Writing</span> <br />
          Write an opinion essay (120–150 words) on the following topic.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
         <span><i>Money does not grow on trees.</i></span>
         <div style="margin-top: 20px;"><textarea rows="10"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
