import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  6: {
    unit: 'Weather',
    id: 'FG10-W-P53-E6',
    exerciseKey: '/img/FriendsPlus/Page53/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 130,
    maxLength: 15,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
					<span style="color: rgb(146,39,143);margin-right:10px">VOCABULARY</span> 
					<span>Match the five highlighted verbs
						of movement in the text with the definitions
						below. Write the infinitives.
						Verbs of movement
					</span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				<b>
					<span style="color: rgb(62,63,153);">Verbs of movement</span>
				</b>
          <div style="margin-top: 10px">
						<span style="margin-top: 10px;"><b>1</b> to go round and round quickly#</span><br>
						<span style="margin-top: 10px;"><b>2</b> to go up from the ground#</span><br>
						<span style="margin-top: 10px;"><b>3</b> to come down to the ground#</span><br>
						<span style="margin-top: 10px;"><b>4</b> to move nearer#</span><br>
						<span style="margin-top: 10px;"><b>5</b> to arrive at a certain place#</span>
					</div>
        <div style="margin-top: 20px">
          <img src="/img/FriendsPlus/Page53/E6/1.jpg" width="1100px">
        </div>
        `,
        answer: ['spin', 'take off', 'land', 'approach', 'reach'],
      },
    ],
  },
  7: {
    unit: 'Weather',
    id: 'FG10-W-P53-E7',
    exerciseKey: '/img/FriendsPlus/Page53/E7/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 630,
    maxLength: 89,
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
					<span style="color: rgb(45,206,137);margin-right:10px">SPEAKING</span> 
					<span>Work in pairs. Prepare an interview withEwa. Then act out your interview</span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
					<b>Student A:</b> You are the interviewer. Prepare six
					questions using the prompts below and/or your
					own ideas.
				</div>
				<div>
					<b>Student B:</b> You are Ewa. Prepare your answers to
					Student A’s questions. Use as much information
					from the text as you can.
				</div>
				 <div style="margin-top: 10px;">
					<span style="margin-top: 10px;"><b>1</b> what / you / do / in Australia?</span><br>
          <span>#</span> <br>
					<span style="margin-top: 10px;"><b>2</b> what / weather / like / that morning?</span><br>
          <span>#</span> <br>
					<span style="margin-top: 10px;"><b>3</b> why / you / take off?</span><br>
          <span>#</span> <br>
					<span style="margin-top: 10px;"><b>4</b> what / can / remember / about the storm?</span><br>
          <span>#</span> <br>
					<span style="margin-top: 10px;"><b>5</b> what / most terrifying part / experience?</span><br>
          <span>#</span> <br>
					<span style="margin-top: 10px;"><b>6</b> how / you / feel / afterwards?</span><br>
          <span>#</span> <br>
				</div>
        `,
        answer: [
          'What were you doing in Australia?',
          'What was the weather like that morning?',
          'Why did you take off?',
          'What can you remember about the storm?',
          'What was the most terrifying part of the experience?',
          'How did you feel afterwards?',
        ],
      },
    ],
  },
};

export default json;
