import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `Describe the photo. What are the people doing? Use the verbs below.`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
        <div style="display:flex; gap:30px;">
          <div style="align-self:center;">
            <div><b style="color:rgb(246,141,31);">laugh &emsp; play &emsp; sit &emsp; smile &emsp; wear</b></div>
            <div><i style="color:rgb(10,111,142);">The girl on the left is playing the guitar</i></div>
            <textarea id="0" rows="6"></textarea>
          </div>
          <div> <img src='img/FriendsPlus/Page7/E1/1.jpg' /> </div>
        </div>
        `,
        answer: [`The girl on the left is playing the guitar and laughing. The other people are listening to her. Everyone is sitting on the grass and smiling. Theyare enjoying themselves. The boys are wearing jeans and T-shirts.`],
      },
    ],
  },
  2: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `Read the messages above. Why does Adam change his mind about going to the park?`,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 500 },
    questions: [
      {
        title: `
        <div style="display:flex; gap:30px;">
          <div style="align-self:center;">
            <div><i >Why does Adam change his mind about going to the park?</i></div>
            <textarea id="0" rows="2"></textarea>
          </div>
          <div> <img src='img/FriendsPlus/Page7/E1/1.jpg' /> </div>
        </div>
        `,
        answer: [`Because he loves volleyball.`],
      },
    ],
  },
  3: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E3',
    audio: '',
    video: '',
    padding: 0,
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    component: T6,
    inputSize: 200,
    stylesTextInput: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      fontSize: 20,
    },
    titleQuestion: [
      {
        num: '3',
        title:
          "Find examples of the present simple and present continuous in the messages in exercise 2. Then read the <span style='color: rgb(246,141,31)'>Learn this!</span> box and complete the rules.",
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div><img src='img/FriendsPlus/Page7/E1/1.jpg' /></div>
            <div style='position: relative;'>
              <div><img src='img/FriendsPlus/Page7/E3/1.jpg' /></div>
              <div style='position: absolute; top: 123px; left: 99px;'>#</div>
              <div style='position: absolute; top: 181px; left: 99px;'>#</div>
              <div style='position: absolute; top: 239px; left: 99px;'>#</div>
              <div style='position: absolute; top: 271px; left: 99px;'>#</div>
              <div style='position: absolute; top: 355px; left: 99px;'>#</div>
            </div>
          </div>
        `,
        answer: [
          'present continuous',
          'present continuous',
          'present simple',
          'present simple',
          'present continuous',
        ],
      },
    ],
  },
  4: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page7/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          "Match each example you found in the messages with the correct rule (a–f) in the <span style='color: rgb(246,141,31)'>Learn this!</span> box.",
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
          <div style='display: flex; gap:15px;'>
            <div><img src='img/FriendsPlus/Page7/E4/2.jpg' /></div>
            <div><img src='img/FriendsPlus/Page7/E4/1.jpg' /></div>
          </div>
          
              <div style='display: flex;'>a.<textarea id="0" rows="2"></textarea></div>
              <div style='display: flex;'>b.<textarea id="1" rows="2"></textarea></div>
              <div style='display: flex;'>c.<textarea id="2" rows="2"></textarea></div>
              <div style='display: flex;'>d.<textarea id="3" rows="2"></textarea></div>
              <div style='display: flex;'>e.<textarea id="4" rows="2"></textarea></div>
              <div style='display: flex;'>f.<textarea id="5" rows="2"></textarea></div>
        `,
        answer: [
          `She has guitar lessons every week.`,
          `What are you doing? At the moment, we're listening to Sue. She's playing the guitar. We're packing boxes today.`,
          `She's getting better. We're moving house next weekend.`,
          `I'm in the park with some friends. She's OK. That's a shame. A game of volleyball doesn't take long.`,
          `Do you want to join us? I don't know. I need to help my parents. I love volleyball!`,
          `So are you coming? We're playing volleyball later.`,
        ],
      },
    ],
  },
  5: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page7/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          'Work in pairs. Explain the difference between sentences a and b in each pair.',
        color: '#92278f',
      },
    ],
    inputSize:600,
    questions: [
      {
        title: `

        <div style="display:flex; gap:10px;">
					<div style='width: 450px;'>
            <br/>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b>1</b></div>
              <div>
                <div><b>a</b> I’m doing my homework after school.</div>
                <div><b>b</b> I do my homework after school.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b>2</b></div>
              <div>
                <div><b>a</b> Joe is learning to drive.</div>
                <div><b>b</b> Many teenagers learn to drive when they are seventeen.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b>3</b></div>
              <div>
                <div><b>a</b> Mark plays the guitar.</div>
                <div><b>b</b> Mark is playing the guitar.</div>
              </div>
            </div>
					</div>

					<div>
            <br/>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
            <br/>
            <div><b>🠪</b></div>
            <div><b>🠪</b></div>
					</div>

          <div>
            <div style="text-align: center;"><b>Eplain</b></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <br/>
            <div>#</div>
            <div>#</div>
          </div>
				</div>
<br/>
          <div style='width: 800px;box-shadow: rgb(246,141,31) 0 0 5px 0; border-radius: 6px; color: rgb(246,141,31); padding: 5px 10px;'><b>Grammar Builder IB</b> Contrast: present simple and continuous: page 106</div>
        `,
        answer: [
          `I have arranged to do my homework after school today.`, `I always do my homework after school.`,
          `Joe is learning to drive at the moment.`, `This is a fact.`,
          `This is a fact.`, `He is playing the guitar at this moment.`,
        ],
      },
    ],
  },
  6: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_03.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page7/E6/Key/answerKey.png',
    inputSize: 210,
    titleQuestion: [
      {
        num: '6',
        title:
          '<audioimage name="1.03"></audioimage> Complete the phone dialogue. Use the correct present simple or present continuous form of the verbs in brackets. Then listen and check.',
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div>Hi!</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div>Hi, Toby. What <sup>1</sup><span style='color: rgb(10,111,142)'>are you doing</span> (you / do)?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div>I’m at the sports centre. I <sup>2</sup># (wait) for Tom.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div><sup>3</sup># (you / go) swimming?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div>No, we <sup>4</sup># (play) table tennis. We <sup>5</sup># (play) every Saturday morning. But he’s really late!</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div>What <sup>6</sup># (he / do)?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div>I <sup>7</sup># (not know). He <sup>8</sup># (not answer) his phone. Anyway, where are you?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div> I’m at the shopping centre, but I <sup>9</sup># (not buy) anything today. I <sup>10</sup># just # (look)</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div><sup>11</sup># (you / like) table tennis? <sup>12</sup># (you / want) to play?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div>Sure! But I <sup>13</sup># (not wear) sports clothes.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div><sup>14</sup># (you / wear) trainers?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Leia</b></div>
            <div>Yes, I am – with jeans and a T-shirt.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 80px;'><b>Toby</b></div>
            <div>That’s fine. You <sup>15</sup># (not need) sports clothes. See you soon!</div>
          </div>
        `,
        answer: [
          "'m waiting",
          'Are you going',
          'are playing',
          'play',
          'is he doing',
          "don't know",
          "isn't answering",
          "'m not buying",
          "'m",
          'looking',
          'Do you like',
          'Do you want',
          "'m not wearing",
          'Are you wearing',
          "don't need",
        ],
      },
    ],
  },
  7: {
    unit: 'Introduction',
    id: 'FG10-I-P7-E7',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    recorder: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '7',
        title: `<span style='color: rgb(0,147,70);'>SPEAKING</span>  Work in pairs. Ask and answer questions 1–4.`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b>1</b></div>
            <div>What do you usually do at the weekend?</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b>2</b></div>
            <div>What are you doing this weekend?</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b>3</b></div>
            <div>What do you usually do during the school holidays?</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b>4</b></div>
            <div>What are you doing next school holiday?</div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
