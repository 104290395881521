import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	7: {
		unit: 'Ambition',
		id: 'FG10-A-P65-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page65/E1/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 49,
		hintBox: [
			{
				src: ['As I see it, the job of model builder would be quite repetitive.'],

				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 2,
				width: 525,
			},
			{
				src: ['I don’t agree. I think …'],
				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 1,
				width: 300,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. 
          Decide which of the two jobs looks a) more fun and b) more difficult. 
          Explain your decision. Use the words below to help you, and your own ideas.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="max-width: 520px; margin-left: 20px; display: flex; color: rgb(66, 64, 153);  padding: 10px; border-radius: 10px; border: 1px solid rgb(66, 64, 153);">
            <div>
              badly-paid<br/>
              rewarding
            </div>
            <div style="margin-left: 20px;">
              creative<br/>
              stressful
            </div>
            <div style="margin-left: 20px;">
              challenging<br/>
              tiring varied
            </div>
            <div style="margin-left: 20px;">
              repetitive<br/>
              well-paid
            </div>
          </div>

          <div style="margin-top: 20px;">
            <hintbox id='0'></hintbox>
            <div style="margin-top: 20px; margin-left: 346px;"><hintbox id='1'></hintbox></div>
          </div>

          <div style="margin-top: 30px; max-width: 1000px; font-size: 20px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div style="font-size: 50px; word-spacing: 5px; text-align: center; font-family: Georgia, serif;">
              The <span style="transform: translate(0, -3px); display: inline-block;"><span style="color: rgb(236, 0, 141); transform: rotate(-5deg); display: inline-block;">best</span></span> job in the world ... probably
            </div>

            <div style="margin-top: 10px;">
              <div style="display: flex;">
                <span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">A</span>
                <div style="margin-left: 30px;">
                  Back in 2012, Andrew Johnson planned to apply to a film school after graduation, rather than look for a job. 
                  But then his dad noticed an advertisement for a job with toy manufacturer Lego. 
                  Andrew, who was already a Lego fan, sent in an application. It included a video of himself making models. 
                  The company loved it and invited him to participate in an unusual interview. 
                  He had to compete against seven other finalists in a model-building test. Andrew won. 
                  As a result, he was employed at Legoland Discovery Centre in Illinois as a Master Model Builder. 
                  “Lego was just a hobby, but now I can do what I love and get paid for it,” said Andrew happily in an interview.
                </div>
              </div>

              <div style="margin-top: 10px; display: flex;">
                <span style="color: rgb(236, 0, 141); font-size: 60px; font-weight: bold; text-shadow: 2px 1px rgb(0,0,0);">B</span>
                <div style="margin-left: 30px;">
                  Some film providers like Netflix employ people to watch new films and TV series, 
                  then ask them to make a note of what type of film it is (horror, rom-com, etc.), 
                  what age group and type of person might like it. The film provider can then recommend it to their members. 
                  Joe Mason finished his degree in film studies about two years ago and didn’t know what to do next. 
                  Then he read an article about film taggers. The job was so fantastic, and he wrote to Netflix. 
                  At first, they weren’t interested, but when Joe told them he speaks fluent French, they gave him a job. 
                  He watches French films and TV programmes. “It’s a great job because I can have flexible working hours,” said he. 
                  The only problem with the job is that Joe can’t choose what he wants to watch.
                </div>
              </div>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	8: {
		unit: 'Ambition',
		id: 'FG10-A-P65-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page65/E8/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 49,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
          <span style="color: rgb(148, 149, 153)">INTERNET RESEARCH</span> 
          Look at online job advertisements. 
          What is the best job you can find? 
          Explain why you think it is the best.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">
            <span>The best job is ... It’s a great job because...</span><br/>
            <ul>
              <li>it’s challenging / rewarding / well-paid, etc.</li>
              <li>it isn’t ...</li>
              <li>you can travel / work alone / work outside, etc.</li>
              <li>you don’t have to ...</li>
            </ul>
          </div>

          <textarea id="0" rows="10"></textarea>
        `,
				answer: [],
			},
		],
	},
};

export default json;
