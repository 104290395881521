import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P69-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 21,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '',
				title: `
          <span style="color: rgb(0, 111, 147)">Reading</span> 
          Read the text and match the interview questions A–E with paragraphs 1–4. 
          There is one extra question.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="font-size: 21px; display: flex; align-items: start;">
            <div style="flex: 4; padding: 20px; background-color: rgb(222, 239, 225);">
              <div>
                <sup>1</sup># I’m Jack Gomez, a firefighter in California. 
                I remember watching an interview with a firefighter pilot when I was a child. 
                He’d been fighting a fire for about five days. He was exhausted then. 
                However, he was so optimistic and committed that I thought that 
                I wanted to do the same thing. And I never changed my mind.
              </div>
              <div style="margin-top: 15px;">
                <sup>2</sup># I did a training camp to get my wildfire 
                qualification card – you can’t fight fires in the US without one. 
                I learned how to light controlled fires, how to put them out again, 
                and how to use all the equipment. The camp was really hard, 
                but I passed first time! After that I was lucky enough to get a job.
              </div>
              <div style="margin-top: 15px;">
                <sup>3</sup># One of the things you have to do in the early days is the pack test. 
                This consists of a fivekilometre walk while carrying a twenty-kilogram backpack. 
                You have to complete it in forty-five minutes or less without jogging or running. 
                This shows how strong you are.
              </div>
              <div style="margin-top: 15px;">
                <sup>4</sup># You can be out alone in vast forests and national parks, 
                and sometimes the smoke is so thick that you can hardly see. 
                So, it’s important to be able to map read, and to use a compass. 
                Also, you must know how to put up a tent, cook outdoors, drive a truck, 
                and have other basic survival skills.
              </div>
            </div>

            <div style="flex: 2; margin-left: 20px; border: 1px solid rgb(0,0,0); padding: 10px;">
              <div style="display: flex;">
                <strong style="padding-right: 10px;">A</strong>
                <span>What is the most dangerous part of being a firefighter?</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">B</strong>
                <span>How physically fit do you have to be?</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">C</strong>
                <span>How did you become a firefighter?</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">D</strong>
                <span>Why did you decide to become a firefighter?</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">E</strong>
                <span>What other skills do you need?</span>				
              </div>
            </div>
          </div>
				`,
				answer: ['D', 'C', 'B', 'E'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P69-E2',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_24.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '',
				title: `
          <audioimage name="2.24"></audioimage> 
          <span style="color: rgb(0, 111, 147)">Listening</span> 
          Listen to three people talking about jobs. 
          Match sentences A–D with speakers 1–3. There is one extra sentence.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex;">
            <div style="border: 1px solid rgba(0,0,0,0.4); border-radius: 10px; padding: 20px;">
              <div style="display: flex;">
                <strong style="padding-right: 10px;">A</strong>
                <span> 
                  Get experience with his / her family <br/>
                </span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">B</strong>
                <span> 
                  Have a fun job abroad <br/>
                </span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">C</strong>
                <span> 
                  Learn to work for himself / herself <br/>
                </span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">D</strong>
                <span> 
                  Learn different ways to perform <br/>
                </span>				
              </div>
            </div>

            <div style="margin-left: 40px; border: 1px solid rgba(0,0,0,0.4); border-radius: 10px; padding: 20px;">
              <i>Answer:</i><br/>
              <div>Speaker 1 - Sentence: #</div>
              <div>Speaker 2 - Sentence: #</div>
              <div>Speaker 3 - Sentence: #</div>
            </div>
          </div>
				`,
				answer: ['B', 'C', 'D'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P69-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
		component: T6,
		inputSize: 420,
		maxLength: 112,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: `
          <span style="color: rgb(0, 111, 147)">Speaking</span> 
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; align-items: start;">
            <div style="flex: 3">
              <div>
                <strong>
                  These two photos show people doing different jobs. 
                  Compare and contrast the photos. Include the following points:
                </strong>
                <ul>
                  <li>The places and their activities</li>
                  <li>The people and their feelings</li>
                  <li>What might happen next.</li>
                </ul>
              </div>
              <div>
                <strong>Answer the questions.</strong>
                <ul>
                  <li>Which job would you prefer? Why?</li>
                  <li>What job do you want to have when you are older? Why?</li>
                </ul>
              </div>
            </div>

            <div style="margin-left: 10px; flex: 2; display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page69/E3/1.jpg" style="max-width: 100%;" />
            </div>
          </div>
				`,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P69-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page69/E4/Key/answerKey.png',
		component: T6,
		inputSize: 300,
    maxLength: 150,
    hideBtnFooter: 150,
		textareaStyle: { width: 900 },
		titleQuestion: [
			{
				num: '',
				title: `
					<span style="color: rgb(0, 111, 147)">Writing</span> 
					Read the advertisement and write your application (120–150 words). 
          Include the points below.
  			`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex;">
            <ul style="flex: 2; border: 1px solid rgba(0,0,0,0.4); border-radius: 10px; padding-top: 20px; padding-right: 20px;">
              <li>How you found out about the part-time job?</li>
              <li>Your current situation</li>
              <li>Why you would be suitable</li>
              <li>A request for further information</li>
            </ul>

            <div style="margin-left: 20px; flex: 3; border-radius: 10px; padding: 20px; background-color: rgb(222, 239, 225); font-family: 'Brush Script MT', cursive;">
              <div>
                Do you like sport? Are you interested in working with children? 
                We are looking for teenagers to help run sports and games for children during the summer holidays. 
                Enthusiasm, a sense of fun and the ability to work well in a team are all important.
              </div>
              <div style="margin-top: 10px;">
                Please send your application by email to info@marstonleisure.com
              </div>
            </div>
          </div>

          <div style="margin-top: 20px; display: flex; justify-content: center; align-items: center;">
            <textarea id="0" rows="7"></textarea>
          </div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
