import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P55-E1',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: `
					Read the article. Do you agree with Ben?
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
           <div>
						<img src="/img/FriendsPlus/Page55/E1/1.jpg" alt="" style='margin-left: 0px;width:750px;'/>
					</div>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P55-E2',
    exerciseKey: '/img/FriendsPlus/Page55/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6v2,
    inputSize: 100,
    // maxLength: 10,
    titleQuestion: [
      {
        num: '2',
        title: `
        Read the <span style="color:rgb(39,131,197) ;">Writing Strategy</span> and answer the
          questions.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style="margin-top:10px">
						<img src="/img/FriendsPlus/Page55/E2/E2.png" alt="">
					</div>
        <div style="margin-top:15px">
          <div><b>1. </b> In paragraph 1 of Ben’s article, how many
              arguments does he give in support of his opinion?
          </div>
          <span style="margin-right: 10px;"><i>Answers: </i><span style="margin-left: 10px;">#</span></span>
          <div>
            <b>2. </b> In paragraph 2, how many arguments does he
            give in support of the opposing view?
          </div>
          <span style="margin-right: 10px;"><i>Answers: </i><span style="margin-left: 10px;">#</span></span>
          <div>
            <b>3. </b> Does he give a counter-argument?
          </div>
          <span style="margin-right: 10px;"><i>Answers: </i><span style="margin-left: 10px;">#</span></span>
        </div> 

						<img src="/img/FriendsPlus/Page55/E2/1.jpg" alt="" style='margin-top: 20px;width:750px;'/>
        `,
        answer: ['two / 2 | 2 / two', 'two / 2 | 2 / two', 'yes'],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P55-E3',
    exerciseKey: '/img/FriendsPlus/Page55/E3/Key/anwserKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 10,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style="color: rgb(146,39,143);">VOCABULARY</span> Complete the collocations from the text with the words below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
       <div style="color: rgb(39,131,197);">
        <b>Climate change</b><br>
        <span>alter &nbsp cause &nbsp combat &nbsp lead &nbsp recycle &nbsp reduce</span>
      </div>
      
      <div>
        <div style="margin-top: 10px;">
          <b>1</b>
          <span>#</span>
          <span style="margin-left: 10px;">plastic / paper / glass</span>
        </div>
        <div style="margin-top: 10px;">
          <div>
            <b>2</b>
            <span>#</span>
            <span style="margin-left: 10px;">carbon emissions</span>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div>
            <b>3</b>
            <span>#</span>
            <span style="margin-left: 10px;">global warming</span>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div>
            <b>4</b>
            <span>#</span>
            <span style="margin-left: 10px;">your behaviour</span>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <div>
            <b>5</b>
            <span>#</span>
            <span style="margin-left: 10px;">a green lifestyle</span>
          </div>
        </div>
        <div>
          <div style="margin-top: 10px;">
            <b>6</b>
            <span>#</span>
            <span style="margin-left: 10px;">climate change</span>
          </div>
        </div>
      </div>
    </div>
        `,
        answer: ['recycle', 'reduce', 'cause', 'alter', 'lead', 'combat'],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P55-E4',
    exerciseKey: '/img/FriendsPlus/Page55/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    maxLength: 39,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
        <span style="color: rgb(146,39,143);">KEY PHRASES </span> Read the phrases below. Add the
          highlighted phrases in Ben’s post to the correct
          group (A–D).
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style='border-left: 5px solid rgb(141, 184, 211); padding: 0 15px;'>
          <div style="color: rgb(39,131,197); font-weight: bold;">A Expressing an opinion</div>
          <div>
            <span>I think / don’t think that … To be honest, … As I see it, … In my opinion,<br> … I believe / don’t believe that … </span>
            <span style="margin-left: 10px;">#</span>
          </div>

          <div style="color: rgb(39,131,197); font-weight: bold;">B Presenting an opposing opinion</div>
          <div>
            <span>Having said that, … On the other hand, … </span>
            <span style="margin-left: 10px;">#</span>
          </div>

          <div style="color: rgb(39,131,197); font-weight: bold;">C Presenting a counter-argument</div>
          <div>
            <span>However, … In spite of this, …</span>
            <span style="margin-left: 10px;">#</span>
          </div>

          <div style="color: rgb(39,131,197); font-weight: bold;">D Making an additional point</div>
          <div>
            <span>Moreover, … Furthermore, … In fact, … </span>
            <span style="margin-left: 10px;">#</span>
          </div>
      </div>
						<img src="/img/FriendsPlus/Page55/E4/1.jpg" alt="" style='margin-top: 10px;width:750px;'/>

        `,
        answer: ['It seems to me that', 'There are some people who believe that', 'Nevertheless', 'What is more'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P55-E5',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `
        Work in pairs. Read the statement and decide if
        you agree or disagree. Prepare arguments for and
        against it. Use the ideas below to help you.
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
      <div style="border: 1px solid white; background-color:  rgb(218, 227, 240); width: 700px; padding: 15px;">
      "Global warming is the most important problem
      facing us today." Do you agree?
     </div>
     <div >
      <div style="margin-top: 15px">
        <div>
          <ul>
            <li>
              <b>For:</b> We need to protect the planet / prevent climate change
              / save endangered species.
            </li>
            <li>
              <b>Against:</b>  There are other problems such as
              poverty, disease, nuclear war, etc. / Millions of
              people die every year because of … / A nuclear
              war could destroy …
            </li>
          </ul>
        </div>

        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P55-E6',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    textareaStyle: { width: 1000 },
    titleQuestion: [
      {
        num: '6',
        title: `
        Write your article (120– 150 words). Use phrases
        from exercise 4 and your ideas from exercise 5 to
        help you. Follow the structure suggested in the
        <span style="color: rgb(39,131,197);">Writing Strategy.</span>
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div><img src="/img/FriendsPlus/Page55/E6/E6.png"  style="margin-left:140px; width: 600px"></div>
        <div><img src="/img/FriendsPlus/Page55/E6/E6-2.png" alt="" style="margin-left:80px; width: 700px"></div>
        <div style="margin-top: 10px">
          <b>Write your article here:</b>
          <textarea rows="7"></textarea>
        </div>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
