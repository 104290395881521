import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page34/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `<b style='color:rgb(0,147,70);'>SPEAKING</b> Look at photos A–D. Do you know any of these films or TV programmes? Can you name any of the actors or characters?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    recorder: true,
    textareaStyle: { width: 1100 },
    questions: [
      {
        title: `
				<img src='img/FriendsPlus/Page34/E1/1234.png' />
				<div>
					<div style='display: flex;'><b style='padding-top:8px;'>A.</b> <textarea id="0" rows="1"> </textarea></div>
					<div style='display: flex;'><b style='padding-top:8px;'>B.</b> <textarea id="1" rows="1"> </textarea></div>
					<div style='display: flex;'><b style='padding-top:8px;'>C.</b> <textarea id="2" rows="1"> </textarea></div>
					<div style='display: flex;'><b style='padding-top:8px;'>D.</b> <textarea id="3" rows="1"> </textarea></div>
				</div>
			  `,
        answer: [
          'Vu Long, Ngoc Trai and Anh Dao playing Tieu Long, Quy “rom”and Hanh “can” in Kinh Van Hoa',
          'Chris Hemsworth playing Thor in a Thor or Avengers film',
          'Gru and the orphans in Despicable Me',
          'TV game show Duong len dinh Olympia',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_31.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page34/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `<headphone1 name='1.31' src=''></headphone1><span style='color:rgb(124, 8, 79);'> VOCABULARY</span> and <span style='color:rgb(43, 125, 233);'>PRONUNCIATION</span> Match four of the words below with photos A–D. Then listen to the wordlist and check your answers. Work in pairs and practise the pronunciation. Mark the stress on the word(s).
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    checkDuplicated: true,
    hintBox: [
      {
        src: [
          'action film',
          'animation',
          'chat show',
          'comedy',
          'documentary',
          'fantasy film',
          'game show',
          'horror film',
          'musical',
          'news bulletin',
          'period drama',
          'reality show',
          'romantic comedy',
          'science fiction film',
          'sitcom',
          'soap opera',
          'talent show',
          'thriller',
          'war film',
          'weather forecast',
          'western',
        ],
        borderColor: '',
        // arrow: true,
        width: 800,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<img src='img/FriendsPlus/Page34/E2/1234.png' />
				<div style='margin: 20px 100px 10px 200px;'><hintBox id='0' ></hintBox></div>
				<div style='display: flex;justify-content: space-around; margin-top:20px;'>
					<div style='display: flex;'><b style='padding-top:4px;'>A.</b> #</div>
					<div style='display: flex;'><b style='padding-top:4px;'>B.</b> #</div>
					<div style='display: flex;'><b style='padding-top:4px;'>C.</b> #</div>
					<div style='display: flex;'><b style='padding-top:4px;'>D.</b> #</div>
				</div>
			  `,
        answer: ['soap opera', 'fantasy film', 'animation ', 'game show'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_32.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page34/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `<headphone1 name='1.32' src=''></headphone1> Match four of the words below with photos A–D. Then listen to the wordlist and check your answers. Work in pairs and practise the pronunciation. Mark the stress on the word(s).
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 230,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    checkDuplicated: true,
    hintBox: [
      {
        src: [
          'action film',
          'animation',
          'chat show',
          'comedy',
          'documentary',
          'fantasy film',
          'game show',
          'horror film',
          'musical',
          'news bulletin',
          'period drama',
          'reality show',
          'romantic comedy',
          'science fiction film',
          'sitcom',
          'soap opera',
          'talent show',
          'thriller',
          'war film',
          'weather forecast',
          'western',
        ],
        borderColor: '',
        // arrow: true,
        width: 800,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 200px;'><hintBox id='0' ></hintBox></div>
				<div style='display: flex;justify-content: space-around; margin-top:20px;'>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>1.</b> #</div>
						<div style='display: flex;'><b style='padding-top:4px;'>2.</b> #</div>
					</div>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>3.</b> #</div>
						<div style='display: flex;'><b style='padding-top:4px;'>4.</b> #</div>
					</div>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>5.</b> #</div>
						<div style='display: flex;'><b style='padding-top:4px;'>6.</b> #</div>
					</div>
				</div>
			  `,
        answer: ['western', 'period drama', 'horror film', 'science fiction film', 'game show', 'news bulletin'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:rgb(124, 8, 79);'>VOCABULARY</span> Work in pairs. Talk about which types of TV programmes and films you like and dislike. Give reasons using the adjectives below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textAlign: 'center',
    textareaStyle: { width: 1100 },
    hintBox: [
      {
        src: [
          'boring',
          'confusing',
          'convincing',
          'embarrassing',
          'exciting',
          'funny',
          'gripping',
          'imaginative',
          'interesting',
          'moving',
          'scary',
          'spectacular',
          'unrealistic',
          'violent',
        ],
        borderColor: '',
        // arrow: true,
        width: 800,
        borderColor: '#92278f',
      },
      {
        src: ['I’m not a fan of horror films. They’re really violent. But I like …'],
        borderColor: '',
        arrow: true,
        position: 2,
        width: 750,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 150px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 350px;'><hintBox id='1' ></hintBox></div>
				<textarea id="0" rows="4"> </textarea>
			  `,
        answer: [],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_33.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page34/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `<headphone1 name='1.33' src=''></headphone1> Listen to four dialogues, pausing after each one. Does each pair of speakers agree or disagree, in general?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 230,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['They agree.', 'They disagree.'],
    questions: [
      {
        title: `
				<div style='display: flex;margin-top:20px; justify-content: space-around; width: 1000px;'>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>1.</b> <b> <select id='0'></select>  </b></div>
						<div style='display: flex;'><b style='padding-top:4px;'>2.</b> <b> <select id='1'></select>  </b></div>
					</div>
					<div>
						<div style='display: flex;'><b style='padding-top:4px;'>3.</b> <b> <select id='2'></select>  </b></div>
						<div style='display: flex;'><b style='padding-top:4px;'>4.</b> <b> <select id='3'></select>  </b></div>
					</div>
				</div>
			  `,
        answer: ['They agree.', 'They disagree.', 'They disagree.', 'They agree.'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P34-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_33.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page34/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<headphone1 name='1.33' src=''></headphone1><span style='color:rgb(124, 8, 79);'> VOCABULARY</span>  Listen again. Complete the sentences with the words below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    checkDuplicated: true,
    hintBox: [
      {
        src: ['acting', 'ending', 'plot', 'scenes', 'script', 'soundtrack', 'special effects'],
        borderColor: '',
        // arrow: true,
        width: 800,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 20px;'><hintBox id='0' ></hintBox></div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>The # was quite moving.</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>There were some really funny # .</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>I found the # really confusing.</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>The # were spectacular.</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>The # was totally convincing.</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>The # was really cool.</div>
        </div>
				<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>The # was really unnatural in places.</div>
        </div>
			  `,
        answer: ['ending', 'scenes', 'plot', 'special effects', 'acting', 'soundtrack', 'script'],
      },
    ],
  },
};

export default json;
