import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P49-E1',
    exerciseKey: '/img/FriendsPlus/Page49/E1/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_04.mp3',
    video: '',
    component: T6,
    inputSize: 950,
    maxLength: 100,
    // checkDuplicated: true,
    textareaStyle: { width: 610, marginBottom: -30, marginTop: 5 },
    titleQuestion: [
      {
        num: '1',
        title: `
          <audioimage name="2.04"></audioimage> <span style="color: rgb(45, 206, 137); margin-left:10px">SPEAKING </span> How observant are you? Look at
                    the photo for 30 seconds and remember as much
                    detail as you can. Then listen to the description.
                    What mistakes do you notice in the description?
                
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <img src="/img/FriendsPlus/Page49/E1/E1.jpg" alt="" style='width:700px;'/>
           <div><b>Anwers:</b></div>
           <div><b>1. </b>#</div>
           <div><b>2. </b>#</div>
           <div><b>3. </b>#</div>
           <div><b>4. </b>#</div>
           <div><b>5. </b>#</div>
           <div><b>6. </b>#</div>
           <div><b>7. </b>#</div>
        `,
        answer: [
          `There are three people in the doorway of a house.`,
          `The boy is wearing trousers and a hoodie. The man is wearing shorts and a jacket.`,
          `The boy on the left has a blue hoodie and blue trousers.`,
          `He's not crying.`,
          `Next to him there's a man with a silver bucket.`,
          `He's emptying the water onto the street.`,
          `She looks happy.`,
        ],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P49-E2',
    exerciseKey: '/img/FriendsPlus/Page49/E2/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_05.mp3',
    video: '',
    component: T6v2,
    inputSize: 500,
    isHiddenCheck: true,
    // maxLength: 66,
    // checkDuplicated: true,
    textareaStyle: {},
    titleQuestion: [
      {
        num: '2',
        title: `
            <audioimage name="2.05"></audioimage>
            Read the 
            <span style="color: rgb(192,19,78);">Listening Strategy</span> 
            Then listen to
            three dialogues and answer questions 1–3 below
            for each one. Give reasons for your answers.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="display:flex; gap: 30px;">
          <div style="margin-top:20px">
            <div><strong>1.</strong><span style="margin-left: 10px;"> Who is speaking?</span></div>
            <div><strong>2.</strong><span style="margin-left: 10px;"> When are they speaking?</span></div>
            <div><strong>3.</strong><span style="margin-left: 10px;"> Where are they?</span></div>
          </div>
          <div>
            <img src="/img/FriendsPlus/Page49/E2/E2.png" alt="">
          </div>
        </div>

        <div style="display:flex; gap: 30px;">
          <div>
            <b>Dialogue 1</b>
            <div> <b>1</b> #</div>
            <div> <b>2</b> #</div>
            <div> <b>3</b> #</div>
          </div>
          <div>
            <b>Give reasons for your answers.</b>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>
        <div style="display:flex; gap: 30px; margin-top:20px;">
          <div>
            <b>Dialogue 2</b>
            <div> <b>1</b> #</div>
            <div> <b>2</b> #</div>
            <div> <b>3</b> #</div>
          </div>
          <div>
            <b>Give reasons for your answers.</b>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>
        <div style="display:flex; gap: 30px;margin-top:20px;">
          <div>
            <b>Dialogue 3</b>
            <div> <b>1</b> #</div>
            <div> <b>2</b> #</div>
            <div> <b>3</b> #</div>
          </div>
          <div>
            <b>Give reasons for your answers.</b>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>

        `,
        answer: [
          `a boy and his mother`,
          `in the morning `,
          `at home`,
          `he says "Bye, Mum!"`,
          `he's on his way to school`,
          `he says "I'm off to school now."`,
          `a couple / husband and wife | husband and wife / a couple`,
          `around lunchtime`,
          `at home`,
          `she says "Bye, darling!"`,
          `he says "And what about your lunch?"`,
          `he says they can take cover in the cellar`,
          `two children / a brother and sister | a brother and sister / two children`,
          `in the morning `,
          `at the beach / a swimming pool | a swimming pool / at the beach`,
          `he says "Where's the money Mum gave you?"`,
          `she says "I'm still full from breakfast."`,
          `he says "Shall we go for a swim?"`,
        ],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P49-E3',
    exerciseKey: '/img/FriendsPlus/Page49/E3/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    video: '',
    component: T6,
    inputSize: 50,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
          <span><audioimage name="2.06"></audioimage></span>
          <span> Listen and match each dialogue with a photo
            of a natural disaster (A–D).</span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div style="margin-top:10px">
            <img src="/img/FriendsPlus/Page49/E3/1.jpg" width="1100px">
          </div>
          <div style="margin-top:30px">
            <b>Answers:</b>
          </div>
        <div style="display: flex; gap :80px;">
          <div><b>1 </b>#</div>
          <div><b>2 </b>#</div>
          <div><b>3 </b>#</div>
          <div><b>4 </b>#</div>
        </div>
        `,
        answer: ['B', 'A', 'C', 'D'],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P49-E4',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_06.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
          <span><audioimage name="2.06"></audioimage></span>
          <span>Listen again and circle the correct option
            (a–c).
          </span>
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page49/E4/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['a<br> b<br> c', 'a<br> b<br> c', 'a<br> b<br> c', 'a<br> b<br> c'],
        answers: ['0-4', '1-0', '2-2', '3-4'],
        initialValue: [],
      },
      Layout: `
        <div>
          <div style="margin-top:10px"><strong>1</strong> The interview is taking place</div>
            <div style="margin-left: 20px; margin-top:5px; display: flex;">
              <div style="margin-right:15px">
                <b><input type='Circle' id='0'></b></div>
              <div>
                <span>in the man’s house.</span><br>
                <span>in the street outside the man’s house.</span><br>
                <span>in a church hall.</span><br>
              </div>
          </div>
          <div style="margin-top:10px"><strong>2</strong> The speaker</div>
            <div style="margin-left: 20px; margin-top:5px; display: flex;">
              <div style="margin-right:15px">
                <b><input type='Circle' id='1'></b></div>
              <div>
                <span>works for a charity.</span><br>
                <span>is a politician.</span><br>
                <span>raises funds for a charity</span><br>
              </div>
          </div> 

          <div style="margin-top:10px"><strong>3</strong> The people who started the fire</div>
            <div style="margin-left: 20px; margin-top:5px; display: flex;">
              <div style="margin-right:15px">
                <b><input type='Circle' id='2'></b></div>
              <div>
                <span>did it intentionally.</span><br>
                <span>were cooking.</span><br>
                <span>left without phoning the fire service</span><br>
           </div>
          </div> 

          <div style="margin-top:10px"><strong>4</strong> The volcanic eruption</div>
            <div style="margin-left: 20px; margin-top:5px; display: flex;">
              <div style="margin-right:15px">
                <b><input type='Circle' id='3'></b></div>
              <div>
                <span>happened last year.</span><br>
                <span>has just finished.</span><br>
                <span>is still happening now</span><br>
              </div>
           </div> 
        </div>
      `,
    },
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P49-E5',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '5',
        title: `
            <span style="color: rgb(0, 147, 69);">SPEAKING </span>
            <span>Work in pairs. Write a dialogue between a
              journalist and a witness to a natural disaster (real or
              imaginary). Include some of this information. Act out
              your dialogue to the class.
            </span>
        
        <div style="margin-top: 20px;margin-bottom: 20px;font-weight: normal;">
          <span><b>1. Where was the witness?</b> (at home, in the street,in a car)</span>
          <br>
          <span><b>2. What did they see and do?</b> (helped children / old
            people, found family members, ran away, phoned
            the emergency services)</span>
            <br>
          <span><b>3. How did they feel?</b> (terrified, upset, shocked,
            worried, helpless)</span>
            <br>
          <span><b>4. Was anyone injured?</b> (people died / were injured /
            were taken to hospital, doctors helped people)</span>
        </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page49/E5/1.jpg' }]],
  },
};

export default json;
