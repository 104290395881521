import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P100-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500, fontSize: 20 },
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Look at the pictures. 
          What do you think these inventions were for?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/1.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/2.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/3.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
					</div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P100-E2',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 150,
		maxLength: 12,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the texts. Check your answers in exercise 1. 
          Were any of your guesses correct?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; width: 87%;">
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/1.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/2.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/3.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
					</div>

          <div>
            <img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
          </div>
				`,
				answer: [
					'The gadget allows people to take a photo simply by blinking. You can also send messages and surf the internet.',
				],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P100-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E3/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 11,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
					In pairs, decide which of the inventions is the most impressive 
					and/or interesting, in your opinion. Give reasons.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; width: 87%;">
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/1.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/2.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/3.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
					</div>

          <div>
            <img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
          </div>
				`,
				answer: ['search for', 'prepare for', 'adjust to', 'deal with', 'arrive at'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P100-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E4/Key/answerKey.png',
		component: T6,
		inputSize: 140,
		maxLength: 9,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					Number the pictures (A–C) in the order in which they were invented.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<i>Answer:</i><br/>
						<span style="font-weight: bold; padding-right: 10px;">A</span>#
						<span style="font-weight: bold; padding-right: 10px; padding-left: 10px;">B</span>#
						<span style="font-weight: bold; padding-right: 10px; padding-left: 10px;">C</span>#
					</div>

					<div style="margin-top: 20px; display: flex; width: 87%;">
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/1.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/2.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
						<div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page100/E1/3.jpg" style="max-width: 100%; border-radius: 10px;" />
            </div>
					</div>

          <div>
            <img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
          </div>
				`,
				answer: ['1 (2 BCE) / 1 (2BCE)', '3 (1897)', '2 (1865)'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P100-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E5/Key/answerKey.png',
		component: T6,
		inputSize: 35,
		maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          Match the texts (1–3) with questions A–E below. 
					Some questions match with more than one text.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="font-size: 20px;">
						<i>Answer:</i><br/>
						<span style="font-weight: bold; padding-right: 10px;">A</span>#
						<span style="font-weight: bold; padding-right: 10px; padding-left: 20px;">B</span>#, #
						<span style="font-weight: bold; padding-right: 10px; padding-left: 20px;">C</span>#, #
						<span style="font-weight: bold; padding-right: 10px; padding-left: 20px;">D</span>#
						<span style="font-weight: bold; padding-right: 10px; padding-left: 20px;">E</span>#, #
					</div>

					<div style="margin-top: 10px; display: flex; align-items: start;">
						<div>
							<img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
						</div>

						<div style="margin-left: 10px; padding: 10px; border-radius: 10px; border: 1px solid rgb(234, 231, 216); font-size: 20px;">
							<strong>Which invention(s)</strong>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">A</span>
								<span>was / were lost and then found again?</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">B</span>
								<span>was / were popular for a time but then disappeared?</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">C</span>
								<span>used electricity?</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">D</span>
								<span>was / were designed by an unknown inventor?</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">E</span>
								<span>was / were first designed in the 19th century?</span>
							</div>
						</div>
					</div>
				`,
				answer: ['3', '1', '2', '1', '2', '3', '1', '2'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P100-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E6/Key/answerKey.png',
		component: T6,
		inputSize: 70,
		maxLength: 3,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
          Decide if the sentences below are true (<i>T</i>), false (<i>F</i>) or ‘does not say’ (<i>DNS</i>).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; font-size: 20px;">
						<div style="flex: 1; padding: 10px; border-radius: 10px; border: 4px dotted rgb(66, 64, 153); background-color: rgb(228, 227, 241); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
							<span style="font-weight: bold; color: rgb(66, 64, 153);">Reading strategy</span>
							<div>
								<ul>
									<li>Read the task instructions carefully.</li>
									<li>Read each statement and underline the key words.</li>
									<li>Look for the relevant section of the text, and read it carefully.</li>
									<li>If the information in the statement is the same as in the text, write T. If not, write F.</li>
									<li>If there is no mention of the fact, or there is not enough information, write DNS.</li>
									<li>Do not use your own knowledge (or guesswork) to fill any information gaps!</li>
								</ul>
							</div>
						</div>

						<div style="margin-left: 20px;flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(66, 64, 153); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">1</span>
								<span>The Telharmonium was invented before the radio.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">2</span>
								<span>Thaddeus Cahill destroyed the final model of his invention before he died.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">3</span>
								<span>The Writing Ball was the first machine that used electricity for typing.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">4</span>
								<span>Other typing machines were cheaper to make because they were not made by hand.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">5</span>
								<span>The Antikythera Mechanism was at the bottom of the sea for nearly 2,000 years.#</span>
							</div>
							<div style="display: flex;">
								<span style="padding-right: 10px; font-weight: bold;">6</span>
								<span>For about 70 years after it was found, the purpose of the mechanism was not known.#</span>
							</div>
						</div>
					</div>

					<div style="margin-top: 20px; display: flex; justify-content: center;">
						<img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
					</div>
				`,
				answer: ['T', 'F', 'DNS', 'T', 'DNS', 'T'],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P100-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page100/E7/Key/answerKey.png',
		component: T6,
		inputSize: 160,
		maxLength: 15,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '7',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
					Find nouns in the text related to these verbs.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; display: flex; align-items: start;">
						<div style="flex: 3;">
							<img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%; border-radius: 10px;" />
						</div>

						<div style="margin-left: 10px; flex: 1 50px; padding: 10px; border-radius: 10px; border: 1px solid rgb(234, 231, 216); font-size: 20px;">
							<span style="font-weight: bold; color: rgb(66, 64, 153);">Noun endings</span>
							<div>
								<strong>Text 1</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">a</span>
									<span>invent #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">b</span>
									<span>record #</span>
								</div>
							</div>
							<div>
								<strong>Text 2</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">c</span>
									<span>move #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">d</span>
									<span>arrange #</span>
								</div>
							</div>
							<div>
								<strong>Text 3</strong>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">e</span>
									<span>discover #</span>
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">f</span>
									<span>reconstruct #</span>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['invention', 'recordings', 'movement', 'arrangement', 'discovery', 'reconstructions'],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P100-E8',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 80,
		maxLength: 5,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		hintBox: [
			{
				src: ['If the wheel didn’t exist, we couldn’t travel by car or bus.'],
				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 2,
				width: 525,
			},
			{
				src: ['If we didn’t have aeroplanes, journeys would take much longer.'],
				borderColor: 'rgb(182, 179, 217)',
				arrow: true,
				position: 1,
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in groups. Decide which three inventions and discoveries from history are the most important. 
					Use the ideas below or think of your own. Give reasons.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(66, 64, 153);">
						<div>
							<span>aeroplanes</span>
							<span style="padding-left: 30px;">antibiotics</span>
							<span style="padding-left: 30px;">books</span>
							<span style="padding-left: 30px;">cars</span>
							<span style="padding-left: 30px;">computers</span>
						</div>
						<div>
							<span>electricity</span>
							<span style="padding-left: 30px;">fire</span>
							<span style="padding-left: 30px;">printing</span>
							<span style="padding-left: 30px;">television</span>
							<span style="padding-left: 30px;">wheels</span>
						</div>
					</div>

					<div style="margin-top: 20px; text-align: center;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 50px; margin-left: 150px; text-align: center;"><hintbox id='1'></hintbox></div>
				`,
				answer: [],
			},
		],
	},
}

export default json
