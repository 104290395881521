import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page42/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `Work in pairs. Look at the posters. What genres of film are they? Which genre do you like most? Why?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 240,
    textAlign: 'left',
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 50px;'>
          <div>
          <b><i>What genres of film are they?</i></b>
            <div><b>1. </b> # </div>
            <div><b>2. </b> # </div>
            <div><b>3. </b> # </div>
				  </div>
				  <img src='img/FriendsPlus/Page42/E1/1.jpg' style='width: 400px;' />
        </div>
			  `,
        answer: ['a horror film', 'a romantic comedy ', 'a period drama'],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the <span style='color:rgb(2, 146, 97);'> Speaking Strategy </span>  and the task below. Think of three nouns and three adjectives that you could use.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
			  <div style='background-color: rgb(229, 241, 231); margin-top: 20px; margin-left:200px; width:700px; border-radius: 15px; border: 5px dotted rgb(2, 146, 97);'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(2, 146, 97) ;'> Speaking Strategy</b></div>
            <div>In the exam, </div>
            <div>- read the task in one minute and look carefully at the visuals;</div>
            <div>- write a framework as a guide for you and your partner to follow (talk about the genres available, what you choose and why, where and when you go);</div>
            <div>- identify key words and phrases that you could use in the conversation.</div>
            <div>- cooperate with your partner.</div>
          </div>
        </div>
        <div style='background-color: rgb(218, 234, 220); margin-top: 20px; margin-left:200px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            Your British friend is coming to visit you and you are proposing to take him / her to the cinema. Decide together which of the films in the posters you will see, where and when to meet, and whether to invite any other people. 
          </div>
        </div>
        <div style='margin-left: 50px;'><textarea id="0" rows="6" ></textarea></div>
			  `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_39.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page42/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				<headphone1 name='1.39' src=''></headphone1> Read the questions. Then listen to a student doing the exam task and answer them.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 600,
    stylesTextInput: [],
    maxLength: 80,
    textAlign: 'left',
    questions: [
      {
        title: `
    <div style='display: flex;'>
          <div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b> Which film </div>
          <div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> a)</b> </div>
              <div>
                <div> does the student want to see? </div>
                <div><input width='60px'/></div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> b) </b> </div>
              <div>
                <div> does the examiner want to see?</div>
                <div><input width='60px'/></div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> c) </b> </div>
              <div>
                <div> do they agree on?</div>
                <div><input width='60px'/></div>
              </div>
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
           <div> Did they use any of the words you chose in exercise 2?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> When and where do they decide to meet?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
           <div> What reason does the examiner give for not wanting to invite Donna?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div> Do they decide to invite someone else, or not?</div>
            <div>#</div>
          </div>
        </div>
      </div>
			<div ><img src='img/FriendsPlus/Page42/E3/1.jpg' alt='' style='margin-left: 0px; width:400px;'/></div>
    </div>


			  `,
        answer: [
          '1',
          '2',
          '3',
          '',
          'At the cinema, at 6.30',
          'She doesn’t get on very well with Donna.',
          'Yes. They decide to invite Tom.',
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page42/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:rgb(124, 8, 79);'>KEY PHRASES</span> Complete the phrases using the words below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 100,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['agree', 'agreed', 'better', 'fan', 'fancy', 'idea', 'keen', 'prefer', 'rather', 'settle', 'stand'],
        // arrow: true,
        width: 600,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 50px;'><hintBox id='0' ></hintBox></div>
        <div style='border-left: 5px solid rgb(192, 232, 198)'>
          <div  style='margin: 20px;'>
            <div><b style='color:rgb(2, 146, 97);'>Expressing likes and dislikes</b></div>
            <div>I quite <b><sup>1</sup>#</b> … / I don’t really fancy …</div>
            <div>I’m / I’m not a big <b><sup>2</sup>#</b> of …</div>
            <div>’m / I’m not <b><sup>3</sup>#</b> on …</div>
            <div>I adore … / I can’t <b><sup>4</sup>#</b> …</div>
            <div><b style='color:rgb(2, 146, 97);'>Expressing a preference</b></div>
            <div>I’d <b><sup>5</sup>#</b> … I’d <b><sup>6</sup>#</b>  (to) …</div>
            <div>I think … will be <b><sup>7</sup>#</b> / more fun, etc.</div>
            <div><b style='color:rgb(2, 146, 97);'>Reaching an agreement</b></div>
            <div>Shall we <b><sup>8</sup>#</b> on … ?</div>
            <div>OK, I <b><sup>9</sup>#</b> .</div>
            <div>That’s <b><sup>10</sup>#</b>  then.</div>
            <div>That’s a great <b><sup>11</sup>#</b>.</div>
          </div>
        </div>
			<div ><img src='img/FriendsPlus/Page42/E4/1.jpg' alt='' style='margin-left: 0px; width:500px;'/></div>
        

			  `,
        answer: ['fancy', 'fan', 'keen', 'stand', 'rather', 'prefer', 'better', 'settle', 'agree', 'agreed', 'idea'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_40.mp3',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '5',
        title: `
				<headphone1 name='1.40' src=''></headphone1> <span style='color:rgb(2, 146, 97);'>PRONUNCIATION</span> Read the <span style='color:rgb(97, 156, 236);'>Learn this!</span> box. Underline all the consonant clusters and practise saying the words. Then listen and check.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    hintBox: [
      {
        src: ['shrink', 'twin', 'problem', 'scream', 'adopt', 'address', 'three', 'next', 'against', 'hops'],
        // arrow: true,
        width: 600,
        borderColor: 'rgb(2, 146, 97)',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 50px;'><hintBox id='0' ></hintBox></div>
        <div style='border-radius: 20px; border: 2px solid rgb(97, 156, 236); width: 600px; margin: 10px 50px 30px 50px'>
          <div style='margin:15px;'>
            <div><b style='color:rgb(97, 156, 236);'>LEARN THIS!</b><b> Consonant clusters</b></div>
            <div>Consonant clusters, combinations of consonant sounds, can be found at the beginning, at the end or within words.</div>
            <div> <i>prefer /pr/ &emsp; British /br/ &emsp; agree /gr/ &emsp; restrict /str/</i></div>
            <div><i>accept /pt/ &emsp; tasks /sks/</i></div>
          </div>
        </div>
        

			  `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P42-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '6',
        title: `
				Work in pairs. Read the task below. Agree with your partner and make notes in the chart below. Present your ideas.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    hideBtnFooter: true,
    stylesTextInput: [],
    maxLength: 80,
    textAlign: 'left',

    questions: [
      {
        title: `
        <div style='background-color: rgb(218, 234, 220); margin: 20px 0 20px 70px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            You and a group of friends are deciding what to do on the Saturday night after your last exam. Decide on an activity, the type of food you will eat, and where and when you will meet.
          </div>
        </div>
        <div style='border: 3px solid rgb(192, 232, 198)'>
          <div  style='margin: 15px;'>
            <div><b>Possible activities:</b></div>
            <div>what? <input width='715px'/></div>
            <div>when? <input width='708px'/></div>
            <div>where? <input width='700px'/></div>
            <div>cost? <input width='722px'/></div>
          </div>
        </div>
        <div style='border: 3px solid rgb(192, 232, 198)'>
          <div  style='margin: 15px;'>
            <div><b>Food:</b></div>
            <div>type? <input width='720px'/></div>
            <div>cost? <input width='722px'/></div>
            <div>where? <input width='700px'/></div>
          </div>
        </div>
        <div style='border: 3px solid rgb(192, 232, 198)'>
          <div  style='margin: 15px;'>
            <div><b>Meet:</b></div>
            <div>where? <input width='700px'/></div>
            <div>when? <input width='708px'/></div>
          </div>
        </div>
        

			  `,
        answer: [],
      },
    ],
  },
};

export default json;
