import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E1',
		audio: '',
		video: '',
		component: T6,
		inputSize: 500,
		titleImage: '',
		exerciseKey: 'img/FriendsPlus/Page26/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title:
					'Read a short extract from a story. Where is Harry at the start of the paragraph? Where is he at the end?.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page26/E1/1.jpg'>
            <div>
                 Where is Harry at the start of the paragraph? 
                  <br/>#<br/>
                 Where is he at the end?<br>
                 #  
            </div>       
          </div>       
          `,
				answer: ['He is on the shore at the start. ', 'He is in the sea at the end.'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E2',
		audio: '',
		video: '',
		component: T6,
		inputSize: 50,
		maxLength: 1,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page26/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title:
					'Read the <span style="color: rgb(246,141,31)">Learn this!</span> box. Match sentences 1–6 from the extract in exercise 1 with rules a–c.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page26/E2/1.jpg'>
            <img src='img/FriendsPlus/Page26/E1/1.jpg'>
          </div>    
            <div style='display:flex; gap:70px; margin-top:40px;margin-left:140px;'>
                <div><b>1</b>#</div>  
                <div><b>2</b>#</div>  
                <div><b>3</b>#</div>  
                <div><b>4</b>#</div>  
                <div><b>5</b>#</div>  
                <div><b>6</b>#</div>  
            </div>          
          `,
				answer: ['b', 'b', 'a', 'a', 'c', 'a'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E3',
		audio: '',
		video: '',
		component: T6,

		inputSize: 170,
		textareaStyle: { width: 700 },
		// inputSize: 50,
		// isHiddenCheck: true,
		maxLength: 12,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page26/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '3',
				title: 'Complete the sentences. Use the past continuous or past simple form of the verbs in brackets.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `

        <div style='width:20cm'>
          <div style='display:flex'>
            <b>a</b>   
            <div style='margin-left:20px'>
              While he #(listen) to their argument, the boat’s engines #(start).
            </div>       
          </div>       
          <div style='display:flex'>
            <b>b</b>   
            <div style='margin-left:20px'>
              Someone #(fall) into the water. Harry #(realise) who it was and #(jump) in too.
            </div>       
          </div>       
          <div style='display:flex'>
            <b>c</b>   
            <div style='margin-left:20px'>
              Still underwater, he #(swim) close to the boat, then #(come) up silently and #(climb) aboard. Three people #(argue) loudly. 
            </div>       
          </div>       
          <div style='display:flex'>
            <b>d</b>   
            <div style='margin-left:20px'>
              The boat #(begin) to move away. As he #(decide) what to do, he #(hear) a scream. 
            </div>       
          </div>       
        </div>       
          `,
				answer: [
					'was listening',
					'started',
					'fell',
					'realised',
					'jumped',
					'swam',
					'came',
					'climbed',
					'were arguing',
					'began',
					'was deciding',
					'heard',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_23.mp3',
		video: '',
		component: T6,

		inputSize: 50,
		textareaStyle: { width: 700 },
		// inputSize: 50,
		// isHiddenCheck: true,
		// maxLength: ,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page26/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					'<headphone1 name="1.23"></headphone1> Put the sentences from exercise 3 in the correct order to continue the story. <br/>Then listen and check.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
      <div style='display:flex;gap:40px;width:1000px'>
        <img src='img/FriendsPlus/Page26/E4/1.jpg'>  
        <div style='width:20cm'>
          <div style='display:flex;'>
            <b>1</b>   
            <div style='margin-left:20px'>
              While he ...(listen) to their argument, the boat’s engines ...(start).
            </div>       
          </div>       
          <div style='display:flex'>
            <b>2</b>   
            <div style='margin-left:20px'>
              Someone ...(fall) into the water. Harry ...(realise) who it was and ...(jump) in too.
            </div>       
          </div>       
          <div style='display:flex'>
            <b>3</b>   
            <div style='margin-left:20px'>
              Still underwater, he ...(swim) close to the boat, then ...(come) up silently and ...(climb) aboard. Three people ...(argue) loudly. 
            </div>       
          </div>       
          <div style='display:flex'>
            <b>4</b>   
            <div style='margin-left:20px'>
              The boat ...(begin) to move away. As he ...(decide) what to do, he ...(hear) a scream. 
            </div>       
          </div>       
        </div>  
        </div>   
          <div style='display:flex;gap:80px;margin:30px 100px;line-height:65px'>
            <div><b>1</b>#</div>
            <div><b>2</b>#</div>
            <div><b>3</b>#</div>
            <div><b>4</b>#</div>
            
          </div>
          `,
				answer: ['a', 'c', 'd', 'b'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E5',
		audio: '',
		video: '',
		component: T6,

		inputSize: 50,
		textareaStyle: { width: 500 },
		// inputSize: 50,
		// isHiddenCheck: true,
		// maxLength: ,
		titleImage: '',
		textAlign: 'center',
		// exerciseKey: 'img/FriendsPlus/Page26/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title:
					'Choose an interruption from box B for each activity in box A. Then write five sentences using the past simple and past continuous. Use <i>while / as</i> or when.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <span style='color:rgb(33 120 152)'>As they were fighting, Harry heard a helicopter.</span>
        <div style='display:flex'>
        <img style='height:8cm' src='img/FriendsPlus/Page26/E5/1.jpg'>     
          <div style='margin:30px 10px;'>
            <textarea id='0' row='3'></textarea>
            
          </div>
        </div>
          `,
				answer: [],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E6',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '6',
				title:
					'<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. What do you think happened next in Harry’s story? Discuss your ideas and make notes. Use the questions below and your sentences from exercise 5 to help you.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page26/E6/1.jpg' }]],
	},

	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P26-E7',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_24.mp3',
		video: '',
		component: T6,
		hideBtnFooter: true,
		recorder: true,
		titleQuestion: [
			{
				num: '7',
				title:
					'<headphone1 name="1.24"></headphone1> Listen to the end of the story. Compare it with your ideas from <br/>exercise 6. How is it different?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <span style='color:rgb(33 120 152)'>In my ending … , but in the actual story …</span>
          `,
				answer: [],
			},
		],
	},
}

export default json
