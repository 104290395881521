import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E1',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    maxLength: 20,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          'Put the adjectives below into three groups describing: a) people, b) extreme sports and c) landscapes. Some adjectives can go into more than oup.',
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='color: rgb(190 6 61); width:24cm'>
             <b>Adjectives to describe adventure</b> &emsp;athletic&emsp;&emsp;brave&emsp;&emsp;
              impressive&emsp;&emsp;remote&emsp;&emsp;risky&emsp;&emsp;spectacular&emsp;&emsp;strong&emsp;&emsp;
              terrifying&emsp;&emsp;thrilling
        </div>
          <div style="line-height:50px; ">
              a) people: #,#,#,#.<br>
              b) extreme sports: #,#,#,#.<br>
              c) landscapes: #,#,#.<br>
            
          </div>
          `,
        answer: [
          `athletic/brave/impressive/strong`,
          `athletic/brave/impressive/strong`,
          `athletic/brave/impressive/strong`,
          `athletic/brave/impressive/strong`,
          `risky/spectacular/terrifying/thrilling`,
          `risky/spectacular/terrifying/thrilling`,
          `risky/spectacular/terrifying/thrilling`,
          `risky/spectacular/terrifying/thrilling`,
          `impressive/remote/spectacular`,
          `impressive/remote/spectacular`,
          `impressive/remote/spectacular`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E2',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    titleQuestion: [
      {
        num: '2',
        title:`
        <b style="color: rgb(2, 146, 97);">SPEAKING</b> Describe the photos above. Where are the people? What are they doing? What kind of people do you think they are? Use adjectives from exercise 1 and words from lesson 2A on page 22.
        `,
        color: '#8e258c',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='color: rgb(190 6 61); width:24cm'>
             <b>Adjectives to describe adventure</b> &emsp;athletic&emsp;&emsp;brave&emsp;&emsp;
              impressive&emsp;&emsp;remote&emsp;&emsp;risky&emsp;&emsp;spectacular&emsp;&emsp;strong&emsp;&emsp;
              terrifying&emsp;&emsp;thrilling
        </div>

          <div style='color:rgb(142 37 140); width: 25cm;margin:10px 0px; border:3px solid rgb(142 37 140);border-radius: 20px;text-align: center;padding: 5px 20px'>
            <b >Landscape: features</b> &emsp;dark&emsp;&emsp;deep&emsp;&emsp;icy&emsp;&emsp;low&emsp;&emsp;narrow&emsp;&emsp;rocky&emsp;&emsp;
            shallow&emsp;&emsp;steep&emsp;&emsp;tall&emsp;&emsp;wide
          </div>

          <div style='color:rgb(142 37 140); width: 25cm; border:3px solid rgb(142 37 140);border-radius: 20px;text-align: center;padding: 5px 20px'>
            <b >Landscape: features</b> &emsp;cave&emsp;&emsp;cliff&emsp;&emsp;desert&emsp;&emsp;forest&emsp;&emsp;hill&emsp;&emsp;lake&emsp;&emsp;
            mountain&emsp;&emsp;ocean&emsp;&emsp;river&emsp;&emsp;rocks&emsp;&emsp;shore&emsp;&emsp; stream&emsp;&emsp;valley&emsp;&emsp;
            volcano&emsp;&emsp;waterfall
          </div>
          <br/>
        <div><img src='img/FriendsPlus/Page25/E2/1.png'></div>
          `,
        answer: [],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_21.mp3',
    video: '',
    component: T6,

    inputSize: 160,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    isHiddenCheck: true,
    maxLength: 10,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title:
          '<headphone1 name="1.21"></headphone1> Read the <span style="color: rgb(190 6 61)">Listening Strategy</span>. Then listen to six people talking about BASE jumping. Write the synonyms or antonyms that they use for the underlined words.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page25/E2/2.jpg'>
        <div style='display:flex; background:rgb(254 242 220);padding:20px ; border-radius:20px'>
          <div>
            <b>1</b>&emsp;Fred loves being in wild, <u>far-away</u> places. <span style='color: rgb(81 131 142)'>remote</span><br>
            <b>2</b>&emsp;Sally says BASE jumping isn’t popular with older  <u>people</u> #<br>
            <b>3</b>&emsp;Chris doesn’t consider himself a <u>courageous</u> person.# <br>
          </div>    
          <div style='margin-left:20px'>
            <b>4</b>&emsp;Celina says people who aren’t strong and <u>fit</u> shouldn’t try BASE jumping.#<br>
            <b>5</b>&emsp;For Shelley, the appeal of BASE jumping is the <u>breathtaking</u> landscapes where you do it. #<br>
            <b>6</b>&emsp;Martin really <u>didn’t enjoy</u> BASE jumping.# <br>
          </div>    
        </div>
          `,
        answer: ['over 30', 'brave', 'unfit', 'spectacular', 'hated'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E4',
    audio: '',
    video: '',
    component: T6,

    inputSize: 160,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    isHiddenCheck: true,
    maxLength: 10,
    titleImage: '',
    textAlign: 'center',
    // exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title:
          'Read the sentences. Can you think of synonyms for any of the underlined words? Write them in your notebook.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='width:20cm'>
            <div style='display:flex;'>
              <b>1</b>
              <div style='margin-left:20px'>
                Tanya <u>likes</u> extreme sports because they’re <u>dangerous</u>.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>2</b>
              <div style='margin-left:20px'>
                A friend <u>told</u> Tanya to try BASE jumping.  
              </div>  
            </div>
            <div style='display:flex;'>
              <b>3</b>
              <div style='margin-left:20px'>
                Tanya <u>didn’t enjoy </u> her first experience of BASE jumping.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>4</b>
              <div style='margin-left:20px'>
                The cliffs in Tonsai, Thailand are the perfect <u> venue</u> for BASE jumping, in Tanya’s opinion.
              </div>  
            </div>
            <div style='display:flex;'>
              <b>5</b>
              <div style='margin-left:20px'>
                Tanya thinks the <u>dangers </u>  of BASE jumping make women unwilling to try it. 
              </div>  
            </div>
            <div style='display:flex;'>
              <b>6</b>
              <div style='margin-left:20px'>
                Tanya thinks Roberta Mancino is a <u>great sportswoman</u>.  
              </div>  
            </div>
        </div>
        <textarea id='0' row='1'></textarea>
          `,
        answer: [],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_22.mp3',
    video: '',
    component: T6,

    inputSize: 60,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    isHiddenCheck: true,
    maxLength: 1,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page25/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          '<headphone1 name="1.22"></headphone1> Listen. Are the sentences in exercise 4 true or false? Write T or F.',
        color: '#8e258c',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='width:20cm; line-height:46px'>
            <div style='display:flex;'>
              <b>1</b>
              <div style='margin-left:20px'>
                Tanya <u>likes</u> extreme sports because they’re <u>dangerous</u>.#
              </div>  
            </div>
            <div style='display:flex;'>
              <b>2</b>
              <div style='margin-left:20px'>
                A friend <u>told</u> Tanya to try BASE jumping.#  
              </div>  
            </div>
            <div style='display:flex;'>
              <b>3</b>
              <div style='margin-left:20px'>
                Tanya <u>didn’t enjoy </u> her first experience of BASE jumping.#
              </div>  
            </div>
            <div style='display:flex;'>
              <b>4</b>
              <div style='margin-left:20px'>
                The cliffs in Tonsai, Thailand are the perfect <u> venue</u> for BASE jumping, in Tanya’s opinion.#
              </div>  
            </div>
            <div style='display:flex;'>
              <b>5</b>
              <div style='margin-left:20px'>
                Tanya thinks the <u>dangers </u>  of BASE jumping make women unwilling to try it.# 
              </div>  
            </div>
            <div style='display:flex;'>
              <b>6</b>
              <div style='margin-left:20px'>
                Tanya thinks Roberta Mancino is a <u>great sportswoman</u>.#  
              </div>  
            </div>
        </div>
          `,
        answer: ['T', 'T', 'F', 'F', 'F', 'T'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P25-E6',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
    question: [],
    questionImage: [
      [{ url: 'img/FriendsPlus/Page25/E6/1.jpg' }],
      [{ url: 'img/FriendsPlus/Page25/E6/2.png' }],
      [{ url: 'img/FriendsPlus/Page25/E2/2.jpg' }],
    ],
  },
};

export default json;
