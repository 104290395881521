import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P70-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Look at the photos. Guess which of the items is the
          most expensive and which is the least expensive.
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page70/E1/1.jpg' }, { url: '/img/FriendsPlus/Page70/E1/2.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P70-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    maxLength: 81,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '2',
        title: `
            Read aloud the numbers and currencies below. What other currencies do
            you know?
            <div style='font-size:35px; margin: 0px 0px -15px 100px; color:rgb(129 46 121)'>
              Numbers and currencies

            </div>

        `,
        color: '#860052',
      },
    ],

    questionImage: [[{ url: '/img/FriendsPlus/Page70/E2/2.jpg' }]],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P70-E3',
    exerciseKey: '/img/FriendsPlus/Page70/E3/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_25.mp3',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 7,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '3',
        title: `
          <headphone1 name="2.25"></headphone1>
            Listen to the descriptions of the items in exercise 1. Complete the prices
            using the numbers below. Which is the most surprising, in your opinion?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
            <b><span style="color: rgb(146,39,143);">5,400 6,850 10,000  12,000</span></b>
          </div>
          <div style="margin-top: 20px;"><b>Answers:</b></div>
          <div>
            <b>1</b>
             &emsp;# <br>
            <b>2</b>
             &emsp;# <br>
            <b>3</b>
             &emsp;# <br>
            <b>4</b>
             &emsp;#
          </div>
        `,
        answer: ['€10,000', '£6,850', '£12,000', '£5,400'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P70-E4',
    exerciseKey: '/img/FriendsPlus/Page70/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_26.mp3',
    video: '',
    component: T6,
    inputSize: 165,
    maxLength: 13,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '4',
        title: `
          <headphone1 name="2.26"></headphone1> <b style="color: rgb(146,39,143);">VOCABULARY </b>
          Work in pairs. Where would you buy the items in
                  exercise 1? Match them with four of the shops and services below. Then
                  listen and check.
            
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="color: rgb(146,39,143);">
             <b>Shops and services</b> baker's bank butcher's charity shop chemist's coffee shop clothes shop cosmetics store delicatessen DIY store
             estate agent's florist's garden centre greengrocer's jeweller's 
             aunderette newsagent's optician's post office
             shoe shop stationer's takeaway
          </div>
          <div style="display: flex;margin:20px 0px; width:20cm;line-height:60px;justify-content: space-around">
            <div >
              <b>1</b>
               #<br>
              <b>2</b>
               #
            </div>
            <div>
              <b>3</b>
               # <br>
              <b>4</b>
               #
            </div>
          </div>
        `,
        answer: ['delicatessen', "stationer's", "greengrocer's", 'shoe shop'],
      },
    ],
  },
};

export default json;
