import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P15-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Look at the title of the text and the four warning signs (A–D). What is the connection? What do you think the text will be about? 
        `,
      },
    ],
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page16/E1/1.jpg' },
      ],
    ],
  },
  2: {
    unit: 'Feelings',
    id: 'FG10-F-P16-E2',
    exerciseKey: '',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page16/E2/1.jpg' }, { url: 'img/FriendsPlus/Page16/E2/2.png' },],
    ],
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the <span style="color:rgb(62, 63, 153)">Reading Strategy</span>. Then read the text quickly to get a general idea of the meaning. Were your ideas in exercise 1 correct?
        `,
        color: '#860052',
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P16-E3',
    audio: '',
    video: '',
    component: T6,
    maxLength: 1,
    textAlign: 'center',
    stylesTextInput: {
      textTransform: 'uppercase',
    },
    exerciseKey: 'img/FriendsPlus/Page16/E3/Key/answerKey.png',
    inputSize: 40,
    titleQuestion: [
      {
        num: '3',
        title: `
          Read the text again. Match the questions below with paragraphs A–E of the text. 
        `,
        color: '#860052',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div><img src="img/FriendsPlus/Page16/E3/2.png" alt=""/></div>
              <div>
                <div>In which paragraph does the writer tell us …</div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">when doctors realised Ashlyn had a medical problem? # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">what causes her condition? # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">what happened when she burned her hands?# </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">why some people die from this condition? # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">how school life for Ashlyn was unusual? # </div></div>
              </div>
        `,
        answer: ['B', 'E', 'A', 'C', 'D'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P16-E4',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: {
      padding: 0,
    },
    exerciseKey: 'img/FriendsPlus/Page16/E4/Key/answerKey.png',
    inputSize: 80,
    titleQuestion: [
      {
        num: '4',
        title: `
          Complete the questions using the question words below. Then take turns <br/>to ask and answer the questions in pairs.
        `,
        color: '#860052',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style="color: rgb(62, 63, 153); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>When</div>
                <div>What</div>
                <div>Who</div>
                <div>Why</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "><span style="color: rgb(0, 68, 98)">How</span> did the doctor feel when he saw Ashlyn’s eye injury?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> # didn’t Ashlyn cry when she was a baby?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> # watched Ashlyn carefully in the playground at school?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> # did Ashlyn’s story first appear in newspapers?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> # is the cause of Ashlyn’s medical condition?</div></div>
              </div>

              <div><b>Answer the questions:</b></div>
              <div style="display:flex; gap:10px">
                <b>2.</b><div><input width="800px"/></div>
              </div>
              <div style="display:flex; gap:10px">
                <b>3.</b><div><input width="800px"/></div>
              </div>
              <div style="display:flex; gap:10px">
                <b>4.</b><div><input width="800px"/></div>
              </div>
              <div style="display:flex; gap:10px">
                <b>5.</b><div><input width="800px"/></div>
              </div>
        `,
        answer: [
          'Why', 'Who', 'When', 'What',
          `She didn't cry because she couldn't feel any pain.`,
          `A teacher watched her.`,
          `It appeared when Ashlyn was five.`,
          `It is a genetic disorder. Pain signals do not reach the brain.`,
      ],
      },
    ],
  },
  5: {
    unit: 'Feelings',
    id: 'FG10-F-P16-E5',
    exerciseKey: '/img/FriendsPlus/Page16/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: {
      textAlign: "center",
      border: "none",
    },
    textareaStyle: {},
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
        <b style="color: rgb(146,39,143);">VOCABULARY </b>
        Find words in the text to do with accidents and injuries. <br/>Then complete gaps 1–7 below.
					
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
<div style="position: relative;">
  <div style="border-left:2px solid rgb(62, 63, 153)">
    <div style="margin-left:20px;">
        <b><span style="color: rgb(62, 63, 153);">Accidents and injuries</span><br>
        Verbs</b>
    <div>
      burn / cut / hurt / &ensp;<sup><b>1</b></sup> in_ _ _e &ensp; yourself / your hand, etc.<br>
      fall / <sup><b>2</b></sup> tr_p / slip over<br>
      <sup><b>3</b></sup> b_ _ _k your arm / finger, etc.<br>
      bleed sprain your ankle / wrist<br>
      <b>Nouns and phrases</b><br>

      <sup><b>4</b></sup> bl_ _d a broken arm / finger, etc.
      <sup><b>&ensp; 5</b></sup><span>a b_ _ _se <br>
      <sup><b>6</b></sup> a b_ _n acut
      <sup><b>&nbsp7</b></sup>an in_ _ _y&nbsp; a sprain pain
    </div>
    </div>
  </div>
  <div style='position: absolute; top: 75px; left: 225px;'> <input width="100px"/></div>
  <div style='position: absolute; top: 114px; left: 56px;'> <input width="100px"/></div>
  <div style='position: absolute; top: 152px; left: 22px;'> <input width="100px"/></div>
  <div style='position: absolute; top: 265px; left: 21px;'> <input width="100px"/></div>
  <div style='position: absolute; top: 264px; left: 418px;'> <input width="100px"/></div>
  <div style='position: absolute; top: 302px; left: 53px;'> <input width="60px"/></div>
  <div style='position: absolute; top: 302px; left: 217px;'> <input width="90px"/></div>
</div>
        `,
        answer: [
          `jur`, `i`, `rea`, `oo`,
          `rui`, `ur`, `jur`, 
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P16-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Work in groups. Tell your classmates about a time when you hurt or injured yourself. Use vocabulary from exercise 5.
        `,
      },
    ],
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page16/E6/4.png' }, { url: 'img/FriendsPlus/Page16/E6/2.jpg' },
      ],
    ],
  },
};

export default json;
